import React, { useState, useEffect } from 'react';
import {
  DynamicTabs,
  FilterAndSearchComponent
} from '../../../../components/Checkout';
import { getLocalData } from '../../../../utils';
import { ApiService } from '../../../../services/ApiService';
import {
  Stack,
  Typography,
  Button,
  Box,
} from '@mui/material';
import { AiFillMinusCircle, AiFillPlusCircle } from 'react-icons/ai';
import { filtersQuery } from '../../../../utils/index';
import { ArrowLeft } from '../../../../assets/SVG/SvgIcons';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Divider } from '@mui/material';
import Loader from '../../../../components/Loader'; 

export const AddToCart = ({ selected, setSelected, setActiveStep }) => {
  const locationId = getLocalData('locationId');
  const BASE_URL = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}`;
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('pricingoption');
  const [allProducts, setAllProducts] = useState([]);
  const [allPricingOptions, setAllPricingOptions] = useState([]);
  const [allContracts, setAllContracts] = useState([]);
  const [allMemberships, setAllMemberships] = useState([]);
  const [allBundles, setAllBundles] = useState([]);
  const [allServiceCategories, setAllServiceCategories] = useState([]);  // THIS CATEGORY ARRAY IS USED FOR PRICING OPTIONS CATEGORIES 
  const [allProductCategories, setAllProductCategories] = useState([]);
  const [displayFilterForm, setDisplayFilterForm] = useState(false);
  const [tempSelectedItems, setTempSelectedItems] = useState([]);
  const [selectedItemKeys, setSelectedItemKeys] = useState([]);


  const handleBeforeUnload = (e) => {
    e.preventDefault();
    e.returnValue = ''; // This is required for Chrome
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  let prevStep = localStorage.getItem('prevStep')
  useEffect(() => {
    console.log(prevStep, "prevstep is consoled here")
    if (prevStep !== 2) {
      setTempSelectedItems([])
      setSelected({
        ...selected,
        items: [],
        values: []
      })
    }
    // else {

    // }
  }, [prevStep])

  const [tabList, setTabList] = useState([]);
  const [value, setValue] = useState(0);

  useEffect(() => {
    let valueArr = selected?.itemsArr?.map(item => item?.sortKey || item?.itemId)
    setTempSelectedItems({
      tempValues: valueArr,
    })
  }, [value])

  const [filters, setSidebarFilters] = useState({
    typeOfService: null,
    contractType: null,
    // billingCycle: null,
    contractBillingCycle: null,
    membershipBillingCycle: null,
    status: 1,
    // categoryName: null,
    productCategory: null,
    pricingOptionCategory: null
  });

  const [isDialogOpen, setIsDialogOpen] = useState(false); // State to control the dialog

  const handleGoBack = () => {
    setIsDialogOpen(true);
    // Open the dialog when Go Back is clicked
  };
  const handleCloseDialog = (value) => {
    setIsDialogOpen(false);
    if (value === 'discard') {
      setActiveStep(0);
      setSelected({
        items: [],
        values: [],
      });
    } else if (value === 'goBack') {
      setActiveStep(1);
    }
  };

  const contractType = [
    { label: '3 Months', value: '3 months' },
    { label: '6 Months', value: '6 months' },
    { label: '9 Months', value: '9 months' },
    { label: '12 Months', value: '12 months' }
  ];

  const contractBillingCycle = [
    { label: 'Monthly', value: 'every month' },
    { label: 'Quarterly', value: 'every quarter' },
    { label: 'Every 6 months', value: 'every 6 months' },
    { label: 'Every year', value: 'every year' }
  ];

  const membershipBillingCycle = [
    { label: 'Every 1 months', value: '0' },
    { label: 'Every 1 year', value: '1' }
  ];

  const serviceType = [
    { label: 'Enrollments', value: 'enrollment' },
    { label: 'Appointments', value: 'appointment' }
  ];

  // GET ALL SERVICE CATEGORIES API FOR (PRICING OPTIONS)

  const getAllServiceCategory = async () => {
    let encodedLocationId = encodeURIComponent(locationId);
    try {
      let response = await ApiService.get(
        `${BASE_URL}services/getAllServiceCategories?locationId=${encodedLocationId}`
      );
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];

      if (response.statusCode && !response.error) {
        setAllServiceCategories(Item);

      }
      setLoading(false)
    } catch (error) {
      console.error(error);
      setLoading(false)
    }
  };

  // GET ALL PRODUCT CATEGORIES API

  const getProductCategories = async () => {
    let encodedLocationId = encodeURIComponent(locationId);
    try {
      let response = await ApiService.get(
        `${BASE_URL}products/getAllProductCategories?locationId=${encodedLocationId}`
      );
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];

      if (response.statusCode && !response.error) {
        setAllProductCategories(Item);

      }
      setLoading(false)
    } catch (error) {
      console.error(error);
      setLoading(false)
    }
  };

  // get all products API
  const getAllProducts = async () => {
    let filterObj = {
      ...filters,
      categoryName: filters.productCategory,
    }
    setLoading(true)
    // let queryString = await filtersQuery(filters);
    let queryString = await filtersQuery(
      filterObj, [
      'contractBillingCycle',
      'membershipBillingCycle',
      'typeOfService',
      'contractType',
      'productCategory',
      'pricingOptionCategory'
    ]
    );
    let filtersParams = queryString ? `&${queryString}` : '';
    let encodedLocationId = encodeURIComponent(locationId);
    try {
      let response = await ApiService.get(
        `${BASE_URL}checkout/getAllProducts?locationId=${encodedLocationId}${filtersParams}`
      );
      if (response && response.data) {
        setLoading(false)
      }
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      if (response?.statusCode === 200) {
        if (Item.length) {
          const updatedProducts = Item.map((i) => {
            const isSelected = selected?.items?.find((newItem) => (newItem?.sortKey || newItem?.itemId) === i?.sortKey);
            return {
              ...i,
              selectedQuantity: 
                i.quantity > 0 
                  ? isSelected?.quantity
                    ? isSelected?.quantity
                    : 1
                  : 0
            };
          });
          setAllProducts(updatedProducts);
        }
        
      }
    } catch (error) {
      console.error(error);
      setLoading(false)
    }
  };

  const getAllBundles = async () => {
    let queryString = await filtersQuery(
      filters, [
      'contractBillingCycle',
      'membershipBillingCycle',
      'typeOfService',
      'contractType',
      'productCategory',
      'pricingOptionCategory'
    ]
    );
    let filtersParams = queryString ? `&${queryString}` : '';
    let encodedLocationId = encodeURIComponent(locationId);
    try {
      let response = await ApiService.get(
        `${BASE_URL}checkout/getAllBundles?locationId=${encodedLocationId}${filtersParams}`
      );
      if (response && response.data) {
        setLoading(false)
      }
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      if (response?.statusCode === 200) {
        setAllBundles(Item);
      }
      setLoading(false)
    } catch (error) {
      console.error(error);
      setLoading(false)
    }
  };

  const getAllPricingOptions = async () => {
    let filterObj = {
      ...filters,
      categoryName: filters.pricingOptionCategory,
    }
    setLoading(true)
    // let queryString = await filtersQuery(filters);
    let queryString = await filtersQuery(
      filterObj, [
      'contractBillingCycle',
      'membershipBillingCycle',
      'typeOfService',
      'contractType',
      'productCategory',
      'pricingOptionCategory'
    ]
    );

    let filtersParams = queryString ? `&${queryString}` : '';
    let encodedLocationId = encodeURIComponent(locationId);
    try {
      let response = await ApiService.get(
        `${BASE_URL}checkout/getAllPricingOptions?locationId=${encodedLocationId}${filtersParams}`
      );
      if (response && response.data) {
        setLoading(false)
      }
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      if (response?.statusCode === 200) {
        setAllPricingOptions(Item);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false)
    }
  };

  const getAllContracts = async () => {
    let filterObj = {
      ...filters,
      billingCycle: filters.contractBillingCycle,
    }
    setLoading(true)
    // let queryString = await filtersQuery(filters);
    let queryString = await filtersQuery(
      filterObj, [
      'contractBillingCycle',
      'membershipBillingCycle',
      'typeOfService',
      'contractType',
      'productCategory',
      'pricingOptionCategory'
    ]
    );
    let filtersParams = queryString ? `&${queryString}` : '';
    let encodedLocationId = encodeURIComponent(locationId);
    try {
      let response = await ApiService.get(
        `${BASE_URL}checkout/getAllContracts?locationId=${encodedLocationId}${filtersParams}`
      );
      if (response && response.data) {
        setLoading(false)
      }
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      if (response?.statusCode === 200) {
        setAllContracts(Item);
      }
      setLoading(false)
    } catch (error) {
      console.error(error);
      setLoading(false)
    }
  };
  const getAllMemberships = async () => {
    let filterObj = {
      ...filters,
      billingCycle: filters.membershipBillingCycle,
    }
    setLoading(true)
    // let queryString = await filtersQuery(filters);
    let queryString = await filtersQuery(
      filterObj, [
      'contractBillingCycle',
      'membershipBillingCycle',
      'typeOfService',
      'contractType',
      'productCategory',
      'pricingOptionCategory'
    ]
    );
    let filtersParams = queryString ? `&${queryString}` : '';
    let encodedLocationId = encodeURIComponent(locationId);
    try {
      let response = await ApiService.get(
        `${BASE_URL}checkout/getAllMembership?locationId=${encodedLocationId}${filtersParams}`
      );
      if (response && response.data) {
        setLoading(false)
      }
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      if (response?.statusCode === 200) {
        setAllMemberships(Item);
      }
      setLoading(false)
    } catch (error) {
      console.error(error);
      setLoading(false)
    }
  };


  // API CALL FOR GETTING ALL ITEMS DATA ON COMPONENT MOUNT 
  useEffect(() => {
    getAllServiceCategory();
    getProductCategories();
    getAllPricingOptions();
    getAllProducts();
    getAllContracts();
    getAllMemberships();
    getAllBundles();
  }, []);

  useEffect(() => {
    if (type === 'pricingoption') {
      getAllPricingOptions();
    }
    if (type === 'products') {
      getAllProducts();
    }
    if (type === 'contracts') {
      getAllContracts();
    }
    if (type === 'memberships') {
      getAllMemberships();
    }
    if (type === 'bundles') {
      getAllBundles();
    }
  }, [filters]);


  useEffect(() => {
    // Now clear the filter
    // setSidebarFilters({...filters, typeOfService: null, billingCycle: null, categoryName: null, contractType: null});
    // set the filters options as per the tabs selected
    if (type === 'pricingoption') {

      filtersOptions[0].subFilters[0].value = 'typeOfService';
      filtersOptions[0].subFilters[0].options = serviceType;
      filtersOptions[0].subFilters[3].options = allServiceCategories.map((item) => ({
        label: item.name,
        value: item.name
      }));
    } else if (type === 'products') {
      filtersOptions[0].subFilters[4].options = allProductCategories.map((item) => ({
        label: item.categoryName,
        value: item.categoryName
      }));
    } else if (type === 'memberships') {
      filtersOptions[0].subFilters[2].options = membershipBillingCycle;
    } else if (type === 'contracts') {
      filtersOptions[0].subFilters[1].options = contractBillingCycle;
      filtersOptions[0].subFilters[0].value = 'contractType';
      filtersOptions[0].subFilters[0].options = contractType;
    }
    else if (type === 'bundles') {
      filtersOptions[0].subFilters[1].options = contractBillingCycle;
      filtersOptions[0].subFilters[0].value = 'contractType';
      filtersOptions[0].subFilters[0].options = contractType;
    }
  }, [type, allServiceCategories, allProductCategories, value]);


  // TABLE COMPONENT (INCLUDING SEARCH AND FILTER)
  useEffect(() => {
    if (allPricingOptions && allProducts && allContracts && allMemberships && allBundles) {
      setTabList([
        {
          label: 'Pricing Options',
          component: (
            <FilterAndSearchComponent
              sideBarFilters={filters}
              filterOptions={filtersOptions}
              loading={loading}
              setSidebarFilters={setSidebarFilters}
              title='All Pricing Options'
              dataList={allPricingOptions}
              isCheckBox
              columns={tables[0]?.columns}
              selected={selected}
              setSelected={setSelected}
              handleChangeFilter={() => handleChangeFilter('pricingoption')}
              tempSelectedItems={tempSelectedItems}
              setTempSelectedItems={setTempSelectedItems}
              setDisplayFilterForm={setDisplayFilterForm}
              displayFilterForm={displayFilterForm}
              setSelectedItemKeys={setSelectedItemKeys}
              selectedItemKeys={selectedItemKeys}
            />
          )
        },
        {
          label: "Products",
          component: (
            <FilterAndSearchComponent
              sideBarFilters={filters}
              loading={loading}
              filterOptions={filtersOptions}
              title="All Products"
              setSidebarFilters={setSidebarFilters}
              dataList={allProducts}
              isCheckBox
              columns={tables[1]?.columns}
              selected={selected}
              setSelected={setSelected}
              handleChangeFilter={() => handleChangeFilter('products')}
              tempSelectedItems={tempSelectedItems}
              setTempSelectedItems={setTempSelectedItems}
              setDisplayFilterForm={setDisplayFilterForm}
              displayFilterForm={displayFilterForm}
              setSelectedItemKeys={setSelectedItemKeys}
              selectedItemKeys={selectedItemKeys}
            />
          )
        },
        {
          label: 'Bundles',
          component: (
            <FilterAndSearchComponent
              sideBarFilters={filters}
              isCheckBox
              loading={loading}
              filterOptions={filtersOptions}
              title='All Bundles'
              setSidebarFilters={setSidebarFilters}
              dataList={allBundles}
              columns={tables[2]?.columns}
              selected={selected}
              setSelected={setSelected}
              handleChangeFilter={() => handleChangeFilter('bundles')}
              tempSelectedItems={tempSelectedItems}
              setTempSelectedItems={setTempSelectedItems}
              setDisplayFilterForm={setDisplayFilterForm}
              displayFilterForm={displayFilterForm}
              setSelectedItemKeys={setSelectedItemKeys}
              selectedItemKeys={selectedItemKeys}
            />
          )
        },
        {
          label: 'Contracts',
          component: (
            <FilterAndSearchComponent
              sideBarFilters={filters}
              isCheckBox
              loading={loading}
              filterOptions={filtersOptions}
              title='All Contracts'
              setSidebarFilters={setSidebarFilters}
              dataList={allContracts}
              columns={tables[3]?.columns}
              selected={selected}
              setSelected={setSelected}
              handleChangeFilter={() => handleChangeFilter('contracts')}
              tempSelectedItems={tempSelectedItems}
              setTempSelectedItems={setTempSelectedItems}
              setDisplayFilterForm={setDisplayFilterForm}
              displayFilterForm={displayFilterForm}
              setSelectedItemKeys={setSelectedItemKeys}
              selectedItemKeys={selectedItemKeys}
            />
          )
        },
        {
          label: "Memberships",
          component: (
            <FilterAndSearchComponent
              sideBarFilters={filters}
              loading={loading}
              isCheckBox
              filterOptions={filtersOptions}
              title='All Memberships'
              setSidebarFilters={setSidebarFilters}
              dataList={allMemberships}
              columns={tables[4]?.columns}
              selected={selected}
              setSelected={setSelected}
              handleChangeFilter={() => handleChangeFilter('memberships')}
              tempSelectedItems={tempSelectedItems}
              setTempSelectedItems={setTempSelectedItems}
              setDisplayFilterForm={setDisplayFilterForm}
              displayFilterForm={displayFilterForm}
              setSelectedItemKeys={setSelectedItemKeys}
              selectedItemKeys={selectedItemKeys}
            />
          )
        }
      ]);

      setLoading(false);
    }
  }, [selected, allPricingOptions, allProducts, allContracts, allMemberships, allBundles, displayFilterForm, type]);

  const handleIncrease = (row) => {
    setAllProducts((prevAllProducts) => {
      return prevAllProducts?.map((product) => {
        if (product.sortKey === row.sortKey) {
          // If sortKey matches, increment the selectedQuantity by 1
          return { 
            ...product, 
            selectedQuantity: 
              product?.quantity > 0 
              ? product?.selectedQuantity < product?.quantity
                ? product.selectedQuantity + 1
                : product?.selectedQuantity
              : 0
            };
        }
        return product;
      });
    });
  };

  const handleDecrease = (row) => {
    setAllProducts((prevAllProducts) => {
      return prevAllProducts?.map((product) => {
        if (product?.sortKey === row?.sortKey) {
          // If sortKey matches, increment the selectedQuantity by 1
          return { 
            ...product, 
            selectedQuantity: 
              product.quantity > 0
              ? product.selectedQuantity > 1 
                ? product.selectedQuantity - 1 
                : 1
              : 0
              };
        }
        return product;
      });
    });
  };

  useEffect(() => {
    const selectedQuantityMap = allProducts?.reduce((map, product) => {
      map[product?.sortKey] = product?.selectedQuantity;
      return map;
    }, {});
  
    setSelected((prevSelected) => ({
      ...prevSelected,
      items: prevSelected?.items?.map((item) => {
        if ((item?.sortKey || item?.itemId) in selectedQuantityMap) {
          return {
            ...item,
            quantity: selectedQuantityMap[item?.sortKey || item?.itemId],
            TotalPrice: 
              Number(selectedQuantityMap[item?.sortKey || item?.itemId]) * Number(item?.price) ?? 0.0 
          };
        }
        return item;
      }),
    }));
  }, [allProducts]);

  // TABLE COLUMNS DETAILS

  const [tables] = useState([
    {

      columns: [
        { field: 'name', headerName: 'Pricing Option', sortable: false },
        { field: 'numberOfSessions', headerName: '# Of Sessions', sortable: false },
        { field: 'price', 
          headerName: 'Price', 
          sortable: false ,
          renderCell: (params) => {
            return (
              <Typography level="body1">
                ${params?.row?.price || 0}
              </Typography>
            );
          }        
        }
      ]
    },
    {
      columns: [
        { field: 'fName', headerName: 'Product Name', sortable: false },
        { field: 'price', 
          headerName: 'Price', 
          sortable: false,
          renderCell: (params) => {
            return (
              <Typography level="body1">
                ${params?.row?.price || 0}
              </Typography>
            );
          }   
        },
        {
          field: 'categoryName',
          sortable: false,
          headerName: 'Category',
          renderCell: (params) => {
            return (
              <Typography level="body1">
                {params?.row?.categoryName || ''}
              </Typography>
            );
          }
        },
        {
          field: 'isActive',
          headerName: 'In Stock',
          renderCell: (params) => {
            return (
              <Typography level="body1">
                {params?.row?.quantity || 0}
              </Typography>
            );
          }
        },
        {
          field: 'actions',
          sortable: false,
          headerName: 'Qty', 
          renderCell: (params) => {
            const { row } = params;            
            // const [selectedQuantity, setSelectedQuantity] = useState(1);
            // console.log(row, "raw is consoled here")
            // const handleDecrease = () => {
            //   checkItemisSelected(false)
            // };
            // const handleIncrease = () => {
            //   checkItemisSelected(true)
            // };        

              // below code for disablled qty increase or descrase
              // useEffect(() => {
              //   checkItemisSelected();
              // }, []);
              // row.selectedQuantity = selectedQuantity;

              // const checkItemisSelected = (isAdd) => {
              //   let data = localStorage?.getItem('checkoutData') ? JSON?.parse(localStorage?.getItem('checkoutData')) : {};
              //   let fileterObj = data?.items?.filter((item) => (item?.itemId || item?.sortKey) === row?.sortKey );
              //   if (fileterObj?.length === 0) {
              //     if (isAdd) {
              //       setSelectedQuantity(selectedQuantity + 1);
              //     } else if (selectedQuantity > 1) {
              //       setSelectedQuantity(selectedQuantity - 1);
              //     }             
              //   } 
              // }

            return (
              <Stack direction="row" alignItems="center" gap={0.5}>
                <AiFillMinusCircle
                  style={{
                    color: '#003087',
                    fontSize: '1.2rem',
                    cursor: 'pointer'
                  }}
                  onClick={() => handleDecrease(row)}
                />

                <Typography level="body1">{row?.selectedQuantity}</Typography>
                <AiFillPlusCircle
                  style={{
                    color: '#003087',
                    fontSize: '1.2rem',
                    cursor: 'pointer'
                  }}
                  onClick={() => handleIncrease(row)}                
                />
              </Stack>
            );
          }
        }
      ]
    },
    {
      columns: [
        { field: 'bundleName', headerName: 'Bundle Name', sortable: false },
        { field: 'bundlePrice', 
          headerName: 'Price',
          sortable: false.valueOf,
          renderCell: (params) => {
            return (
              <Typography level="body1">
                {`$${params?.row?.bundlePrice}` || 0}
              </Typography>
            );
          } 
        },
        { field: 'actualPrice', 
          headerName: 'Actual Price', 
          sortable: false,
          renderCell: (params) => {
            return (
              <Typography level="body1">
                {`$${params?.row?.actualPrice}` || 0}
              </Typography>
            );
          } 
        },
      ]
    },

    {
      columns: [
        { field: 'contractName', headerName: 'Contract Name', sortable: false },
        { field: 'contractType', headerName: 'Contract Type', sortable: false },
        {
          field: 'billingCycle',
          headerName: 'Billing Cycle',
          sortable: false
        },
        {
          field: 'price',
          headerName: 'Price',
          sortable: false,
          renderCell: (params) => {
            return (
              <Typography level="body1">
                ${params.row.price || 0}
              </Typography>
            );
          }
        }
      ]
    },
    {
      columns: [
        { field: 'name', headerName: 'Membership', sortable: false },

        { field: 'billingCycle', headerName: 'Billing Cycle', sortable: false },
        {
          field: 'price',
          sortable: false,
          headerName: 'Price',
          renderCell: (params) => {
            return (
              <Typography level="body1">${params.row.price || 0}</Typography>
            );
          }
        }
      ]
    }
  ]);

  const [filtersOptions, setFiltersOptions] = useState([
    {
      label: 'Status',
      value: 'status',
      enable: false,
      options: [
        // { label: 'All', value: -1, disabled: true },
        { label: 'Active', value: 1, disabled: false },
        // { label: 'Inactive', value: 0, disabled: true },
        // { label: 'Expired', value: 3, disabled: true }
      ],
      subFilters: [
        {
          label: 'Type',
          value: 'type',
          enable: false,
          options: []
        },
        {
          label: 'Billing Cycle',
          value: 'contractBillingCycle',
          enable: false,
          options: []
        },
        {
          label: 'Billing Cycle',
          value: 'membershipBillingCycle',
          enable: false,
          options: []
        },
        {
          label: 'Category',
          value: 'pricingOptionCategory',
          enable: false,
          options: []
        },
        {
          label: 'Category',
          value: 'productCategory',
          enable: false,
          options: []
          // options: allServiceCategories.map((item) => ({
          //   label: item.name,
          //   value: item.name
          // }))
        }
        // {
        //   label: 'Category',
        //   value: 'categoryName',
        //   enable: false,
        //   options: allProductCategories.map((item) => ({
        //     label: item.categoryName,
        //     value: item.categoryName
        //   }))
        // }
      ]
    }
  ]);

  const handleChangeFilter = (value) => {
    setType(value);
    if (value === 'pricingoption') {
      filtersOptions[0].enable = true;
      filtersOptions[0].subFilters[0].enable = false;
      filtersOptions[0].subFilters[1].enable = false;
      filtersOptions[0].subFilters[2].enable = false;
      filtersOptions[0].subFilters[3].enable = true;
      filtersOptions[0].subFilters[4].enable = false;

    } else if (value === 'products') {
      filtersOptions[0].enable = true;
      filtersOptions[0].subFilters[0].enable = false;
      filtersOptions[0].subFilters[1].enable = false;
      filtersOptions[0].subFilters[2].enable = false;
      filtersOptions[0].subFilters[3].enable = false;
      filtersOptions[0].subFilters[4].enable = true;

    } else if (value === 'bundles') {
      filtersOptions[0].enable = true;
      filtersOptions[0].subFilters[0].enable = false;
      filtersOptions[0].subFilters[1].enable = false;
      filtersOptions[0].subFilters[2].enable = false;
      filtersOptions[0].subFilters[3].enable = false;
      filtersOptions[0].subFilters[4].enable = false;

    } else if (value === 'contracts') {
      filtersOptions[0].enable = true;
      filtersOptions[0].subFilters[0].enable = false;
      filtersOptions[0].subFilters[1].enable = true;
      filtersOptions[0].subFilters[2].enable = false;
      filtersOptions[0].subFilters[3].enable = false;
      filtersOptions[0].subFilters[4].enable = false;

    } else if (value === 'memberships') {
      filtersOptions[0].enable = true;
      filtersOptions[0].subFilters[0].enable = false;
      filtersOptions[0].subFilters[1].enable = false;
      filtersOptions[0].subFilters[2].enable = true;
      filtersOptions[0].subFilters[3].enable = false;
      filtersOptions[0].subFilters[4].enable = false;
    }
    setFiltersOptions(filtersOptions);
  };

  useEffect(() => {
    switch (value) {
      case 0:
        setType("pricingoption");
        break;
      case 1:
        setType("products");
        break;
      case 2:
        setType("bundles");
        break;
      case 3:
        setType("contracts");
        break;
      case 4:
        setType("memberships");
        break;
      default:
        setType("pricingoption");
        break;
    }
  }, [value])

  useEffect(() => {
    handleChangeFilter(type)
  }, [type])

  console.log(type, value, "type value is consoled here")

  const addedItemColumn = [
    { field: 'itemName', headerName: 'Name', sortable: false },
    {
      field: 'quantity',
      sortable: false,
      headerName: 'Credit/Qty'
    },
    {
      field: 'price',
      sortable: false,
      headerName: 'Price',
      renderCell: (params) => {
        return (
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Typography level="body1">${params?.row?.price || 0}</Typography>
          </Stack>
        );
      }
    },
    {
      field: 'TotalPrice',
      headerName: 'Total Price',
      sortable: false,
      renderCell: (params) => {
        return (

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Typography level="body1">${params?.row?.TotalPrice || 0}</Typography>

            <Button
              className="details-remove-icon"
              onClick={() => handleDelete(params.row)}
            >
              <svg
                width="24"
                height="26"
                viewBox="0 0 24 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.74999 20.0833H10.5833V7.33333H7.74999V20.0833ZM13.4167 20.0833H16.25V7.33333H13.4167V20.0833ZM2.08332 25.75V4.5H0.666656V1.66667H7.74999V0.25H16.25V1.66667H23.3333V4.5H21.9167V25.75H2.08332Z"
                  fill="#8098C3"
                />
              </svg>
            </Button>
          </Stack>
        );
      }
    }
  ];
  const handleDelete = (row) => {
    let list = [...selected.items];
    list = list?.filter((item) => item?.sortKey !== row?.sortKey);
    localStorage.setItem('checkoutData',JSON.stringify({
      ...selected,
      items: list,
      values: selected?.values?.filter((item) => item !== row?.sortKey)
    }));
    setSelected({
      ...selected,
      items: list,
      values: selected?.values?.filter((item) => item !== row?.sortKey)
    });
  };
  
  return (
    <>
      <Loader IsLoading={loading} />
      <Box
        className="back-arrow-close"
        onClick={handleGoBack}
        style={{
          padding: '10px 23px',
          borderRadius: '10px',
          textTransform: 'capitalize',
          fontWeight: '600',
          fontSize: '16px',
          background: '#fff',
          color: '#003087',
          top: '10px',
          left: '10px',
          position: 'absolute'
        }}>
        <ArrowLeft/>
      </Box>
      {selected.items.length > 0 && (
        <Box sx={{ marginBottom: '25px' }}>
          <Typography
            variant="h4"
            color="primary"
            style={{
              fontWeight: '600',
              fontSize: '20px',
              marginBottom: '35px'
            }}
          >
            All Added Items
          </Typography>
          <FilterAndSearchComponent
            loading={!selected?.items}
            selectedRows={selected?.values}
            dataList={selected?.items || []}
            columns={addedItemColumn}
          />
        </Box>

      )}
      {/* {loading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : ( */}
      <DynamicTabs list={tabList || []} value={value} setValue={setValue} />
      {/* )} */}
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle className="alert-title align-center">
          {'Discard Changes?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                You have unsaved changes, what do you want
                <Typography className="title-text-sub">to do?</Typography>
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            onClick={() => handleCloseDialog('discard')}
            className="font-17 text-black no-hover"
          >
            Discard Changes
          </Button>
          <Divider className="vertical-divider" />
          <Button
            onClick={() => handleCloseDialog('goBack')}
            className="font-17 no-hover"
          >
            Go back to Editing
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};