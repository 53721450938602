/* eslint-disable spaced-comment */
/* eslint-disable no-unused-vars */
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Day,
  Inject,
  ResourceDirective,
  ResourcesDirective,
  ScheduleComponent,
  ViewDirective,
  ViewsDirective
} from '@syncfusion/ej2-react-schedule';
import './style.css';
import { extend } from '@syncfusion/ej2-base';
import { Stack } from '@mui/system';
import { Avatar, Typography, Box, Button } from '@mui/material';

const StaffTimeOffCalender = ({
  currentDate,
  data,
  trainerData,
  handleOpenModal,
  startHour,
  endHour,
  setendTime,
  setfromTime,
  setendDate,
  setFromDate,
  setselectedTrainerData
}) => {
  let scheduleObj = useRef(null);
  const dataS = extend([], data, null, true);
  const navigate = useNavigate();

  const onRenderCell = (args) => {
    if (
      (args.element.classList.contains('e-work-cells') &&
        args.element.classList.length === 1) ||
      (args.element.classList.contains('e-work-cells') &&
        args.element.classList.contains('e-alternate-cells') &&
        args.element.classList.length === 2)
    ) {
      args.element.style.backgroundColor = '#ffff';
    }

    if (
      args.element.classList.contains('e-header-cells') ||
      args.element.classList.contains('e-all-day-cells')
    ) {
      args.element.style.display = 'none';
    }
  };
  function formatDate(inputTime, inputDate) {
    // Ensure that inputTime and inputDate are defined and not null
    if (inputTime && inputDate) {
        // Split the input time string into time components
        const timeComponents = inputTime.split(':');
        const ampmIndex =
            inputTime.indexOf('AM') !== -1
                ? inputTime.indexOf('AM')
                : inputTime.indexOf('PM');
        const ampm = inputTime.substring(ampmIndex, ampmIndex + 2);

        // Convert hours to 24-hour format
        let hours = parseInt(timeComponents[0]);
        if (ampm === 'PM' && hours < 12) {
            hours += 12;
        } else if (ampm === 'AM' && hours === 12) {
            hours = 0;
        }

        // Split the input date string into date components
        const dateComponents = inputDate.split('-');

        // Create a Date object using the components
        const formattedDate = new Date(
            parseInt(dateComponents[2]),
            parseInt(dateComponents[0]) - 1, // Months are zero-indexed
            parseInt(dateComponents[1]),
            hours,
            parseInt(timeComponents[1])
        );

        // Convert the Date object to ISO string
        const isoString = formattedDate.toISOString();

        return isoString;
    } 
        // Handle the case where inputTime or inputDate is undefined or null
         return console.error('inputTime or inputDate is undefined or null');
        // You can return a default value or handle the error as needed
        // Example: return 'N/A';
 
}
  // Test case
  const inputTime = '02:00 PM';
  const inputDate = '10-07-2023';
  console.log(
    formatDate(inputTime, inputDate),
    'datefordatefordatefordateforfdf'
  );

  const splitData = [];

  trainerData.forEach((trainer) => {
    if (
      trainer.unavailableSchedules &&
      trainer.unavailableSchedules.length > 0
    ) {
      trainer.unavailableSchedules.forEach((item) => {
        const startDate = new Date(item.startDate);
        const endDate = new Date(item.endDate);
        const startTime = new Date(`1970-01-01T${item.startTime}`);
        const endTime = new Date(`1970-01-01T${item.endTime}`);

        while (startDate <= endDate) {
          const eventStartTime = new Date(startDate);
          eventStartTime.setHours(startTime.getHours());
          eventStartTime.setMinutes(startTime.getMinutes());

          const eventEndTime = new Date(startDate);
          eventEndTime.setHours(endTime.getHours());
          eventEndTime.setMinutes(endTime.getMinutes());

          splitData.push({
            ...item,
            startDate: formatDates(startDate), // Format date as needed
            endDate: formatDates(startDate) // Format date as needed
          });

          startDate.setDate(startDate.getDate() + 1);
        }
      });
    }
  });

  const combinedData = [];
  splitData.forEach((trainer) => {
    combinedData.push({
      Id: trainer.sortKey,
      Subject: `Unavailable`,
      StartTime: formatDate(trainer.startTime, trainer.startDate),
      EndTime: formatDate(trainer.endTime, trainer.endDate),
      IsAllDay: false,
      TrainerId: trainer.partitionKey,
      EventType: 'unavailability'
    });
  });

  function formatDates(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${month}-${day}-${year}`;
  }

  const resourceHeaderTemplate = (props) => {
    return (
      <Stack flexDirection="row">
        <Avatar
          alt={props?.resourceData?.trainerName}
          src={props?.resourceData?.trainerProfileImage}
        />
        <Stack flexDirection="column" marginLeft="10px">
          <Typography variant="h4">
            {props?.resourceData?.trainerName}
          </Typography>
          <Typography variant="body1">
            {props?.resourceData?.roles[0]?.role}
          </Typography>
        </Stack>
      </Stack>
    );
  };

  const onPopupOpen = (args) => {
    const detail = args?.data ? args?.data : args;
    console.log(detail, 'detaildetaildetail');

    const startDateTimeString = detail?.StartTime;

    const originalStartDate = new Date(startDateTimeString);

    const formattedStartTime = originalStartDate
      .toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      })
      .toUpperCase();
    const formattedStartDate = `${
      originalStartDate.getMonth() + 1
    }/${originalStartDate.getDate()}/${originalStartDate.getFullYear()}`;

    const endDateTimeString = detail?.EndTime;

    const originalEndDate = new Date(endDateTimeString);

    const formattedEndTime = originalEndDate
      .toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      })
      .toUpperCase();
    const formattedEndDate = `${
      originalEndDate.getMonth() + 1
    }/${originalEndDate.getDate()}/${originalEndDate.getFullYear()}`;

    setfromTime(formattedStartTime);
    setFromDate(formattedStartDate);
    setendTime(formattedEndTime);
    setendDate(formattedEndDate);
    setselectedTrainerData(detail?.TrainerId);
    handleOpenModal();

    if (
      args.type === 'QuickInfo' ||
      args.type === 'Editor' ||
      args.target.classList.contains('e-selected-cell') ||
      (args.target && args.target.classList.contains('e-work-cells'))
    ) {
      args.cancel = true;
    }
  };

  // navigate to staff managment
  const navigateToAddStaff = () => {
    navigate('/staff-management');
  };

  return (
    <div className="schedule-control-section">
      <div className="col-lg-12 control-section">
        {trainerData?.length > 0 ? (
          <div className="control-wrapper">
            <ScheduleComponent
              ref={(schedule) => (scheduleObj = schedule)}
              cssClass="customStyling"
              width="100%"
              height="800px"
              currentView="Day"
              resourceHeaderTemplate={resourceHeaderTemplate}
              eventSettings={{
                dataSource: combinedData
              }}
              selectedDate={currentDate}
              showTimeIndicator
              popupOpen={onPopupOpen}
              renderCell={onRenderCell}
              startHour={startHour}
              endHour={endHour}
              showHeaderBar={false}
            >
              <ResourcesDirective>
                <ResourceDirective
                  field="TrainerId"
                  title="Trainer"
                  name="Trainer"
                  dataSource={trainerData}
                  idField="trainerId"
                  allowMultiple
                />
              </ResourcesDirective>

              <ViewsDirective>
                <ViewDirective
                  option="Day"
                  group={{ resources: ['Trainer'] }}
                />
              </ViewsDirective>
              <Inject services={[Day]} />
            </ScheduleComponent>
          </div>
        ) : (
          <Box mt={5} align={'center'}>
            <Typography variant="h1" className="schedule-header" mb={5}>
              You first need to add a staff to set staff timeoff.
            </Typography>
            <Button
              variant="contained"
              className="top-button-style"
              sx={{ padding: '15px' }}
              onClick={navigateToAddStaff}
              mt={5}
            >
              {' '}
              Add Staff{' '}
            </Button>
          </Box>
        )}
      </div>
    </div>
  );
};
export default StaffTimeOffCalender;