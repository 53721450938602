import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
// import { CustomButton, CustomSelect } from 'src/components/Global';
import {
  Box,
  // FormControl,
  // InputLabel,
  Card,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  // Select,
  MenuItem,
  Typography,
  useTheme,
  CardHeader,
  TextField,
  Avatar,
  Grid,
  Slide,
  CardContent,
  Container,
  Menu,
  // DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  Checkbox
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
// import { useNavigate } from 'react-router-dom';
import Filters from './Filters';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import FranchiseeDetails from './FranchiseeDetails';
import FranchiseeEdit from './FranchiseeEdit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOwner from './EditOwner';
import { CustomButton, CustomSelect } from 'src/components/Global';
import { ActiveStatus, InactiveStatus } from 'src/assets/SVG/SvgIcons';
// import { ActiveStatus, InactiveStatus } from 'src/assets/SVG/SvgIcons';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// Apply franchisee filter
// const applyFilters = (franchiseeLists, searchText) => {
//   /* return franchiseeLists.filter((franchiseeItem) => {
//      let matches = true;

//      // if (filters.franchiseeName && franchiseeItem.franchiseeName !== filters.franchiseeName) {
//      //   matches = false;
//      // }

//      if (filters.franchiseeName && franchiseeItem.franchiseeName && !franchiseeItem.franchiseeName.toLowerCase().includes(filters.franchiseeName.toLowerCase())) {
//        matches = false;
//      }

//      return matches;
//    }); */
//   console.log(searchText, 'searchText-------');

//   // Commented the above code and added the below code for search functionality
//   /* let arrayOfMatchedObjects = franchiseeLists.filter(object => {
//     return JSON.stringify(object)
//       .toString()
//       .toLowerCase()
//       .includes(searchText.toLowerCase());
//   });
//   return arrayOfMatchedObjects; */
//   let arrayOfMatchedObjects = [];
//   if (searchText) {
//     arrayOfMatchedObjects = franchiseeLists.filter((object) => {
//       return JSON.stringify(object)
//         .toString()
//         .toLowerCase()
//         .includes(searchText.toLowerCase());
//     });
//   } else {
//     arrayOfMatchedObjects = franchiseeLists;
//   }
//   return arrayOfMatchedObjects;
// };
// const useStyles = makeStyles((theme) => ({
//   button: {margin: theme.spacing(1)}
// }));

// Apply paination on table
// const applyPagination = (franchiseeLists, page, limit) => {
//   // if(page === 0) {
//   //   limit = franchiseeLists.totalCount;
//   // }
//   return franchiseeLists.slice(page * limit, page * limit + limit);
// };
// refreshFranchiseeData
// Main methdo to draw the franchisee table
const DrawFranchiseeTable = ({
  franchiseeLists,
  sideBarfilters,
  setSidebarFilters,
  refreshFranchiseeData,
  showScreen,
  setShowScreen,
  selectedFranchiseeLists,
  setFranchiseeItem,
  rolesList,
  franchiseeItem,
  selectedStatus,
  setSelectedStatus,
  btnLoading,
  handleBulkAdd,
  setSelectedFranchiseeLists
}) => {
  /* Function to redirect on to the other route */
  // const navigate = useNavigate();
  // const classes = useStyles();
  // const [selectedFranchiseeLists] = useState([]);
  const containerRef = React.useRef(null);
  const [selectedFranchiseeItem, setSelectedFranchiseeItem] = useState([]);
  const [selectedOwnerItem, setselectedOwnerItem] = useState(null);
  const [displayFilterForm, setdisplayFilterForm] = useState(false);
  const [page, setPage] = useState(0);
  // const [limit, setLimit] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchor, setAnchor] = useState(null);
  const [open, setOpen] = React.useState(false);

  // const [open, setOpen] = React.useState(false);
  // const [openEdit, setOpenEdit] = React.useState(false);
  const [franchiseecity, setFranchiseecity] = useState([]);
  const [searchText, setSearchText] = useState(''); // New state for search functionality
  // const [showForm, setshowForm] = useState(false);
  const [statesList, setstatesList] = useState([]);
  // const [showScreen, setShowScreen] = useState(0);

  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));

  useEffect(() => {
    getStates();
  }, []);

  const classes = useStyles();
  const getFilterSize = () => {
    return displayFilterForm ? 300 : 0;
  };

  // const [filters, setFilters] = useState({
  //   franchiseeName: null
  // });

  // if (addBtnClicked) {
  //   // set edit mode true
  //   // setOpenEdit(true);
  //   handleClickOpen();
  // }

  // Method to handle to change the franchisee status into the dropdown
  // const handleStatusChange = (e) => {
  //   // let value = null;

  //   // if (e.target.value !== 'all') {
  //   //   value = e.target.value;
  //   // }

  //   // setFilters((prevFilters) => ({
  //   //   ...prevFilters,
  //   //   franchiseeName: value
  //   // }));

  //   if (e && e.target && e.target.value !== 'Franchise') {
  //     navigate('/location-management');
  //   }
  // };

  /* Function to get all states for drop-down */
  const getStates = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_END_POINT}locations/getAllStates`,
      {
        method: 'GET',
        headers: {
          // Accept: 'application/json',
          // 'content-Type': 'application/json',
          Accept: '*/*',
          // 'content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*'
        }
      }
    );

    const data = await response.json();
    console.log(data.data.Items);
    // set the states
    setstatesList(data.data.Items);
  };

  // To change the page into the table
  // const handlePageChange = (event, newPage) => {
  //   setPage(newPage);
  // };

  // To change the list
  // const handleLimitChange = (event) => {
  //   setLimit(parseInt(event.target.value));
  // };

  // Handle the search functionality
  // const handleSearch = (event) => {
  //   let value = '';

  //   if (event.target.value !== '') {
  //     value = event.target.value;
  //   } else {
  //     // value = null;
  //     value = '';
  //   }
  //   console.log(value, 'value-------');

  //   // const filteredArr = franchiseeLists.filter(
  //   //   (element) => (element.franchiseeName === event.target.value)
  //   //   // element.name.includes(event.target.value)
  //   // );

  //   // Now set the filter
  //   // setFilters((prevFilters) => ({
  //   //   ...prevFilters,
  //   //   franchiseeName: value
  //   // }));

  //   // Commented the above code and added the below code for search functionality
  //   setSearchText(value);
  // };

  // Apply filter from object and below is the pagination order
  // const filteredFranchiseeLists = applyFilters(franchiseeLists, searchText); // applyFilters(franchiseeLists, filters);
  // const paginatedFranchiseeLists = applyPagination(
  //   filteredFranchiseeLists,
  //   page,
  //   limit
  // );

  const showFilter = () => {
    if (franchiseecity.length === 0) getCities();
    setdisplayFilterForm(!displayFilterForm);
    console.log(displayFilterForm, 'displayFilterForm-------');
  };

  // const [isExpanded] = useState(false);
  // const [arrowDirection] = useState('down');
  const theme = useTheme();
  console.log(theme, 'theme-------');
  // Method to open the dialog
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // Method to close the dialog
  // const handleClose = (val) => {
  //   if (val === 'Delete') {
  //     // call the api to delete the location
  //     // deleteLocation();
  //   }
  //   // trigger the parent method to refresh the data
  //   // refreshFranchiseeData();
  //   setshowForm(false);
  //   setOpen(false);
  // };

  // Location details
  const handleEditLocation = (franchiseeItem, e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedFranchiseeItem(franchiseeItem);
    setShowScreen(2);
    // setshowForm(true);
    // set edit mode true
    // setOpenEdit(true);
    // handleClickOpen();
  };

  // View location details
  const handleViewFranchisee = (franchiseeItem, e, value) => {
    console.log(value, 'valueeeeee');
    e.stopPropagation();
    // e.preventDefault();
    if (e && e.target && e.target.nodeName !== 'INPUT' && value === 'View') {
      if (!anchor) {
        // set edit mode false
        // setOpenEdit(false);
        setSelectedFranchiseeItem(franchiseeItem);

        setShowScreen(3);
        // handleClickOpen();
      }
    }
  };

  // Method to get the item for edit
  const getItemForEdit = (id) => {
    let elm;

    // id is the selected location's edit button id which is passed from the child component
    if (id) elm = document.getElementById(id);
    console.log(elm, id, 'elm-------');

    // Now trigger the click event on edit button when edit button found
    if (elm) elm.click();
  };

  // Methos to get the cities
  const getCities = async () => {
    fetch(`${process.env.REACT_APP_API_END_POINT}locations/getAllCities`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode && !data.error) {
          let Item = data.data.Items;
          setFranchiseecity(Item);
        }
      });
  };
  // const addFranchiser = () => {
  //   // navigate("/app/addfranchise");
  //   // setAddBtnClick(true);
  //   // handleClickOpen();
  //   // setshowForm(true);
  //   setShowScreen(1)
  //   // console.log("addBtnClick", addBtnClick);
  // }

  // console.log(selectedSomeLocationItems, "selectedAllLocationItems", selectedAllLocationItems);
  console.log('selectedLocationLists', selectedFranchiseeLists);

  const handleSelectAllFranchiseeItems = (event) => {
    setSelectedFranchiseeLists(
      event.target.checked
        ? franchiseeLists.map((franchiseeItem) => franchiseeItem.franchiseeId)
        : []
    );
  };

  const handleSelectOneFranchiseeItem = (event, franchiseeItemId) => {
    console.log('franchiseeItemIdfranchiseeItemId');
    event.stopPropagation();
    // event.preventDefault();
    if (!selectedFranchiseeLists.includes(franchiseeItemId)) {
      setSelectedFranchiseeLists((prevSelected) => [
        ...prevSelected,
        franchiseeItemId
      ]);
    } else {
      setSelectedFranchiseeLists((prevSelected) =>
        prevSelected.filter((id) => id !== franchiseeItemId)
      );
    }
  };

  const selectedSomeFranchiseeItems =
    selectedFranchiseeLists.length > 0 &&
    selectedFranchiseeLists.length < franchiseeLists.length;
  const selectedAllFranchiseeItems =
    selectedFranchiseeLists.length === franchiseeLists.length;

  // HTML rendering started

  const openMenu = (franchiseeItem, event) => {
    setFranchiseeItem(franchiseeItem);

    // setStaffId(staffItem.staffId);
    event.preventDefault();
    event.stopPropagation();
    // setGetStaffItem(staffItem);
    setAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchor(null);
  };
  const onMenuItemClick = (item, event, value) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchor(null);
    if (value === 'addOwners') {
      if (franchiseeItem.ownerCount > 2) {
        setOpen(true);
      } else {
        setShowScreen(4);
      }
    }
  };
  // Method to close the limi existed dialog
  const handleClose = (val) => {
    if (val === 'Ok') {
      setOpen(false);
    }
  };

  useEffect(() => {
    setPage(0);
  }, [franchiseeLists]);

  const handleSearchTextChange = (event) => {
    const searchText = event.target.value.toLowerCase();
    setSearchText(searchText);
    setPage(0); // Reset page when search text changes
  };

  const filteredLocationLists = franchiseeLists.filter((item) => {
    return Object.values(item).some((value) =>
      value ? value.toString().toLowerCase().includes(searchText) : ''
    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };

  const paginatedFranchiseeLists = filteredLocationLists.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  return (
    <>
      {(() => {
        switch (showScreen) {
          case 2:
            return (
              <FranchiseeEdit
                data={selectedFranchiseeItem}
                refreshFranchiseeData={refreshFranchiseeData}
                Transition={Transition}
                setShowScreen={setShowScreen}
                statesList={statesList}
                rolesList={rolesList}
              />
            );
          case 3:
            return (
              <FranchiseeDetails
                data={selectedFranchiseeItem}
                getItemForEdit={getItemForEdit}
                refreshFranchiseeData={refreshFranchiseeData}
                setShowScreen={setShowScreen}
                setselectedOwnerItem={setselectedOwnerItem}
              />
            );
          case 5:
            return (
              <Box sx={{ width: '100%' }}>
                <EditOwner
                  data={selectedOwnerItem}
                  refreshFranchiseeData={refreshFranchiseeData}
                  Transition={Transition}
                  setShowScreen={setShowScreen}
                  statesList={statesList}
                  rolesList={rolesList}
                />
              </Box>
            );
          default:
            return (
              <Container maxWidth="100%" className="main-container relative">
                {/* <Grid container justifyContent="end" alignItems="center" className='add-btn-container'>
                <Grid item>
                  <Button
                    sx={{ mt: { xs: 0, md: 0 } }}
                    variant="contained"
                    className='btn-primary'
                    onClick={addFranchiser}
                  >
                    Add New
                  </Button>
                </Grid> */}
                {/* <Grid item>
          <Button
            sx={{ mt: { xs: 0, md: 0 }, border: '2px solid #003087 !important', color: '#003087 !important', padding: '6px 20px !important' }}
            variant="outlined"
            className='btn-primary'
            disabled
          >
            Manage
          </Button>
        </Grid> */}
                {/* </Grid> */}
                {/* <Grid item>
            <Button
              sx={{ mt: { xs: 0, md: 0 }, border: '2px solid #003087 !important', color: '#003087 !important', padding: '6px 20px !important' }}
              variant="outlined"
              className='btn-primary'
              disabled
            >
              Manage
            </Button>
          </Grid> */}
                {/* / </Grid> */}
                {/* <Grid item>
            <Button
              sx={{ mt: { xs: 0, md: 0 }, border: '2px solid #003087 !important', color: '#003087 !important', padding: '6px 20px !important' }}
              variant="outlined"
              className='btn-primary'
              disabled
            >
              Manage
            </Button>
          </Grid> */}

                {/*   container */}
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="stretch"
                  spacing={3}
                >
                  {/* display={'flex'} justifyContent={'space-between'} */}
                  <Grid
                    container
                    xs={12}
                    xl={12}
                    sm={12}
                    md={12}
                    lg={12}
                    mt={2}
                    pb={2}
                    className="card-grid-container"
                  >
                    <Grid item sx={12} md={4} sm={4} lg={4} xl={4} pr={2}>
                      <Grid
                        className="card-content-blk"
                        sx={{ width: '100%', padding: '0px' }}
                      >
                        {/* sx={{ maxWidth: 345 }} */}
                        <Card sx={{ borderRadius: '19px' }}>
                          <CardHeader
                            className="card-header"
                            avatar={
                              <Avatar
                                alt="board"
                                src="/static/images/logo/cardboardicon.png"
                                sx={{ marginTop: 3 }}
                                className="card-avatar-img"
                              />
                            }
                            action={
                              <IconButton aria-label="settings">
                                <MoreVertIcon />
                              </IconButton>
                            }
                            title="78%"
                            subheader="Customer satisfaction"
                          />
                          <CardContent
                            sx={{
                              paddingTop: 0,
                              paddingLeft: 8,
                              wordBreak: 'break-all',
                              maxHeight: 60
                            }}
                          >
                            <Typography variant="body2" color="text.secondary">
                              Total percentage of customer satisfaction
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                    <Grid item sx={12} md={4} sm={4} lg={4} xl={4} pr={2}>
                      <Grid
                        className="card-content-blk"
                        sx={{ width: '100%', padding: '0px' }}
                      >
                        <Card sx={{ borderRadius: '19px' }}>
                          <CardHeader
                            className="card-header"
                            avatar={
                              <Avatar
                                alt="board"
                                src="/static/images/logo/cardboardicon.png"
                                sx={{ marginTop: 3 }}
                                className="card-avatar-img"
                              />
                            }
                            action={
                              <IconButton aria-label="settings">
                                <MoreVertIcon />
                              </IconButton>
                            }
                            title="$243,342,124"
                            subheader="Average revenue per unit"
                          />
                          <CardContent
                            sx={{
                              paddingTop: 0,
                              paddingLeft: 8,
                              wordBreak: 'break-all',
                              maxHeight: 60
                            }}
                          >
                            <Typography variant="body2" color="text.secondary">
                              Total Average revenue per unit
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                    <Grid item sx={12} md={4} sm={4} lg={4} xl={4}>
                      <Grid
                        className="card-content-blk"
                        sx={{ width: '100%', padding: '0px' }}
                      >
                        <Card sx={{ borderRadius: '19px' }}>
                          <CardHeader
                            className="card-header"
                            avatar={
                              <Avatar
                                alt="board"
                                src="/static/images/logo/cardboardicon.png"
                                sx={{ marginTop: 3 }}
                                className="card-avatar-img"
                              />
                            }
                            action={
                              <IconButton aria-label="settings">
                                <MoreVertIcon />
                              </IconButton>
                            }
                            title="60%"
                            subheader="Employee turnover rate"
                          />
                          <CardContent
                            sx={{
                              paddingTop: 0,
                              paddingLeft: 8,
                              wordBreak: 'break-all',
                              maxHeight: 60
                            }}
                          >
                            <Typography variant="body2" color="text.secondary">
                              Employee turnover rate in the percentage
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={12}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-end"
                    >
                      <Grid
                        item
                        sx={{
                          marginTop: '10px',
                          marginBottom: '20px'
                        }}
                      >
                        <Button
                          onClick={showFilter}
                          ref={containerRef}
                          variant={displayFilterForm ? 'contained' : 'outlined'}
                        >
                          Filters
                          <KeyboardArrowDownRoundedIcon
                            className={clsx(
                              !displayFilterForm && classes.closeY,
                              displayFilterForm && classes.openY
                            )}
                          />
                        </Button>
                      </Grid>

                      <Grid
                        xs={12}
                        sm={6}
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          marginTop: '10px',
                          marginBottom: '20px'
                        }}
                        className="top-filter-cont"
                      >
                        {/* BELOW CODE IS COMMENTED DUE TO BUGID 6116 */}
                        {/* <Box width={212} mr={2}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel className="status-field-lbl">
                              Franchise
                            </InputLabel>
                            <Select
                              // value={filters.franchiseeName}
                              value={'Franchise'}
                              // defaultValue={"Franchisee"}
                              onChange={handleStatusChange}
                              label="Franchisee"
                              autoWidth
                              className="status-field field-label-css"
                            >
                              {franchiseeLists.map((statusOption) => (
                                <MenuItem
                                  key={statusOption.franchiseeId}
                                  value={statusOption.franchiseeName}
                                >
                                  {statusOption.franchiseeName}
                                </MenuItem>
                              ))}
                              <MenuItem disabled value="Franchise">
                                <em className="drodown_menu">Franchise</em>
                              </MenuItem>
                              <MenuItem value={'Location'}>Location</MenuItem>
                            </Select>
                          </FormControl>
                        </Box> */}
                        {/* Search box on table */}
                        <TextField
                          type="search"
                          className="Search-field"
                          label="Search"
                          InputProps={{
                            style: {},
                            endAdornment: <SearchIcon />
                          }}
                          onChange={handleSearchTextChange}
                          // onClick={handleSearch}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    {displayFilterForm === true ? (
                      // <Slide
                      //   direction="right"
                      //   in={displayFilterForm}
                      //   timeout={{ enter: 1200, exit: 10 }}
                      //   container={containerRef.current}
                      // >
                      <Grid item width={getFilterSize()}>
                        <Filters
                          filters={sideBarfilters}
                          setFilters={setSidebarFilters}
                          getCities={franchiseecity}
                          statesList={statesList}
                        />
                      </Grid>
                    ) : // </Slide>
                    null}

                    <Grid
                      item
                      sx={{
                        width: `calc(100% - ${getFilterSize()}px)})`
                      }}
                    >
                      <Card
                        sx={{
                          padding: '15px',
                          borderRadius: '19px 19px 0px 0px'
                        }}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid
                            item
                            xs={12}
                            sm={5}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              gap: '12px'
                            }}
                          >
                            <CardHeader
                              // action={

                              // }
                              title="All Franchise"
                              className="table-header-name"
                              sx={{ fontSize: '20px', color: '#003087' }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={7}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                              gap: '12px'
                            }}
                            className="membership-status-container status-field-ddl"
                          >
                            <CustomSelect
                              fullWidth
                              name="selectedStatus"
                              disabled={!selectedFranchiseeLists?.length}
                              value={selectedStatus}
                              onChange={(type, value) =>
                                setSelectedStatus(value)
                              }
                              label="Select Status"
                              options={[
                                {
                                  label: 'Activate',
                                  value: 1
                                },
                                {
                                  label: 'Deactivate',
                                  value: 0
                                }
                              ]}
                            />
                            <CustomButton
                              variant="contained"
                              disabled={
                                selectedStatus === '' ||
                                !selectedFranchiseeLists?.length
                              }
                              onClick={handleBulkAdd}
                              loading={btnLoading}
                            >
                              Update
                            </CustomButton>
                          </Grid>
                        </Grid>

                        {/* <Divider /> */}
                        <TableContainer className="franchise-table-container">
                          <Table>
                            <TableHead>
                              <TableRow className="table-header">
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    color="primary"
                                    checked={selectedAllFranchiseeItems}
                                    indeterminate={selectedSomeFranchiseeItems}
                                    onChange={handleSelectAllFranchiseeItems}
                                  />
                                </TableCell>
                                <TableCell>Franchise Name</TableCell>
                                <TableCell>Owner Name</TableCell>
                                <TableCell>FA Signing Date</TableCell>
                                <TableCell>MUD</TableCell>
                                <TableCell className="align-center">
                                  Status
                                </TableCell>

                                <TableCell />
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {paginatedFranchiseeLists.map(
                                (franchiseeItem) => {
                                  const formattedDate =
                                    franchiseeItem.faSigningDate
                                      ?.split('-')
                                      ?.join('/');
                                  const isFranchiseeItemSelected =
                                    selectedFranchiseeLists.includes(
                                      franchiseeItem.franchiseeId
                                    );
                                  return (
                                    <TableRow
                                      hover
                                      key={franchiseeItem.franchiseeId}
                                      selected={isFranchiseeItemSelected}
                                      className="table-row cursor-pointer hoverable-row"
                                      onClick={(e) =>
                                        handleViewFranchisee(
                                          franchiseeItem,
                                          e,
                                          'View'
                                        )
                                      }
                                    >
                                      <TableCell padding="checkbox">
                                        <Checkbox
                                          color="primary"
                                          checked={isFranchiseeItemSelected}
                                          onChange={(event) =>
                                            handleSelectOneFranchiseeItem(
                                              event,
                                              franchiseeItem.franchiseeId
                                            )
                                          }
                                          value={isFranchiseeItemSelected}
                                        />
                                      </TableCell>

                                      <TableCell className="table-td">
                                        {/* Icon of the franchise */}
                                        {/* <Avatar
                                          alt="icon"
                                          src={
                                            franchiseeItem.ownerPicture ||
                                            '/static/images/logo/cardboardicon.png'
                                          }
                                          sx={{ marginRight: 2, float: 'left' }}
                                          className="card-avatar-img"
                                        /> */}
                                        <Typography
                                          variant="body1"
                                          fontWeight="bold"
                                          color="text.primary"
                                          sx={{ marginTop: 1 }}
                                          gutterBottom
                                          noWrap
                                        >
                                          {franchiseeItem.franchiseeName}
                                        </Typography>
                                      </TableCell>
                                      <TableCell className="table-td">
                                        <Typography
                                          variant="body1"
                                          fontWeight="bold"
                                          color="text.primary"
                                          sx={{ marginTop: 1 }}
                                          gutterBottom
                                          noWrap
                                        >
                                          {franchiseeItem.ownerName}
                                        </Typography>
                                      </TableCell>
                                      <TableCell className="table-td">
                                        <Typography
                                          variant="body1"
                                          fontWeight="bold"
                                          color="text.primary"
                                          gutterBottom
                                          noWrap
                                        >
                                          {formattedDate}
                                        </Typography>
                                      </TableCell>
                                      <TableCell className="table-td">
                                        <Typography
                                          variant="body1"
                                          fontWeight="bold"
                                          color="text.primary"
                                          gutterBottom
                                          noWrap
                                        >
                                          {franchiseeItem.mud === true
                                            ? 'Yes'
                                            : 'No'}
                                        </Typography>
                                      </TableCell>
                                      <TableCell className="table-td">
                                        <Typography
                                          variant="body1"
                                          fontWeight="bold"
                                          color="text.primary"
                                          gutterBottom
                                          noWrap
                                          className="td-center"
                                        >
                                          {franchiseeItem.status === 1 ? (
                                            // Active Status icon
                                            <ActiveStatus />
                                          ) : franchiseeItem.status === 0 ? (
                                            // Inactive Status icon
                                            <InactiveStatus />
                                          ) : null}
                                        </Typography>
                                      </TableCell>

                                      <TableCell className="table-td">
                                        <IconButton
                                          sx={{
                                            '&:hover': {
                                              background:
                                                theme.colors.primary.lighter
                                            },
                                            color: theme.palette.primary.main
                                          }}
                                          color="inherit"
                                          size="small"
                                          className="edit-btn"
                                          id={
                                            'edit_btn_' +
                                            franchiseeItem.franchiseeId
                                          }
                                          onClick={(e) =>
                                            handleEditLocation(
                                              franchiseeItem,
                                              e
                                            )
                                          }
                                        >
                                          Edit
                                        </IconButton>

                                        <MoreVertIcon
                                          onClick={(e) =>
                                            openMenu(franchiseeItem, e)
                                          }
                                          color="grey"
                                          variant="contained"
                                          className="three-dots-icon"
                                        />

                                        <Menu
                                          open={Boolean(anchor)}
                                          anchorEl={anchor}
                                          onClose={closeMenu}
                                          keepMounted
                                          PaperProps={{
                                            style: {
                                              maxHeight: 40 * 5,
                                              width: '25ch'
                                            }
                                          }}
                                        >
                                          <MenuItem
                                            onClick={(e) =>
                                              onMenuItemClick(
                                                franchiseeItem,
                                                e,
                                                'addOwners'
                                              )
                                            }
                                          >
                                            Add Owners
                                          </MenuItem>
                                        </Menu>
                                      </TableCell>
                                    </TableRow>
                                  );
                                }
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Box p={2}>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={filteredLocationLists.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </Box>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            );
        }
      })()}
      {/* owner existed confirmation dialog */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Ok')}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle className="alert-title align-center">
          {'Delete'}
        </DialogTitle> */}
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                franchise already has 2 secondary owners associated and
                additional owners cannot be associated with it.
                {/* <Typography className="title-text-sub">
                  It will send the record to inactive state.
                </Typography> */}
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleClose('Ok')}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

DrawFranchiseeTable.propTypes = {
  franchiseeLists: PropTypes.array.isRequired
};

DrawFranchiseeTable.defaultProps = {
  franchiseeLists: []
};

export default DrawFranchiseeTable;
