/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import EditIcon from '@mui/icons-material/Edit';

import { Grid, Box, Divider } from '@mui/material';
import {
  getFormattedDate,
  formatTime,
  convertTo24HourFormat,
  calculateTotalHours
} from '.';
import EditClock from 'src/content/applications/StaffManagement/EditClock';

export function WeeklyCard(props) {
  const { key, data, refreshCalenderData } = props;
  const cardRef = useRef(null);

  const [isEditing, setIsEditing] = useState(false);
  const [editClockInTime, setEditClockInTime] = useState('');
  const [editClockOutTime, setEditClockOutTime] = useState('');

  useEffect(() => {
    setEditClockInTime(convertTo24HourFormat(data.clockInTime));
    // setEditClockOutTime(convertTo24HourFormat(data.clockOutTime));
    if (data.clockOutTime) {
      setEditClockOutTime(convertTo24HourFormat(data.clockOutTime));
    } else {
      setEditClockOutTime('');
    }
  }, [data]);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (cardRef.current && !cardRef.current.contains(event.target)) {
  //       setIsEditing(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  return (
    <>
      <Grid
        key={key}
        item
        xs={12}
        sm={6}
        md={3}
        lg={1.714}
        className="week-card"
      >
        <div className="day-name">
          {getFormattedDate(data.clockInDate, 'day')}
        </div>
        <div className="week-contain-wrapper" >
          <div
            className={`week-content ${isEditing ? 'active' : ''}`}
            ref={cardRef}
          >
            <EditIcon
              className="card-action primary-color"
              sx={{ fontSize: '10px' }}
              onClick={() => setIsEditing(!isEditing)}
            />

            <div className="content-inner">
              <span>IN</span>
              {/* {isEditing ? (
            <input
              type="time"
              value={editClockInTime}
              onChange={(e) => setEditClockInTime(e.target.value)}
              className="time-picker"
            />
          ) : ( */}
              <span>{formatTime(editClockInTime) || '---'}</span>
              {/* )} */}
            </div>
            <div className="content-inner">
              <Divider
                flexItem
                sx={{
                  ml: 0,
                  width: '100%'
                }}
                fullwidth
              />
            </div>
            <div className="content-inner">
              <span>OUT</span>
              {/* {isEditing ? (
            <input
              type="time"
              value={editClockOutTime}
              onChange={(e) => setEditClockOutTime(e.target.value)}
              className="time-picker"
            />
          ) : ( */}
              <span>
                {(editClockOutTime && formatTime(editClockOutTime)) ||
                  '-- : --'}
              </span>
              {/* )} */}
            </div>
          </div>

          <div className="week-total">
            {calculateTotalHours(editClockOutTime, editClockInTime) ? (
              <>
                <p>TOTAL</p>
                <p>=</p>
                <p>
                  {/* {calculateTotalHours(editClockOutTime, editClockInTime, true)} */}
                  {editClockOutTime && editClockInTime
                    ? calculateTotalHours(
                      editClockOutTime,
                      editClockInTime,
                      true
                    )
                    : '0 HRS'}

                </p>
              </>
            ) : null}
          </div>
        </div>
      </Grid>
      <Box justifyContent="center" direction="column">
        {isEditing && (
          <EditClock
            getStaffItem={data}
            editClock={isEditing}
            setEditClock={setIsEditing}
            disableEditClock={Boolean(true)}
            refreshStaffData={refreshCalenderData}
          />
        )}
      </Box>
    </>
  );
}
