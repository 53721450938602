import { Grid, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import moment from 'moment';
import React from 'react';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';

const SalesHistoryCard = ({ trophyDetails, handleOpenScreen }) => {
  console.log(trophyDetails, 'trophyDetailstrophyDetailstrophyDetails');
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  };
  const dateFormate = (date) => {
    // let utc = moment(date).format('MMMM Do YYYY, h:mm:ss A');
    let utc;
    utc = date ? moment(date).format('MM-DD-YYYY') : '';
    return utc;
    // let dateFor = date && new Date(date);
    // // let utc = dateFor.toUTCString();
    // let finaldate = dateFor.toLocaleString();
    // return finaldate;
  };

  const numberFormate = (num) => {
    let value = num && num / 100;
    const formatted = Number(value).toLocaleString('en', options);
    // console.log(formatted);
    return formatted;
  };
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        mt={1}
      >
        <Grid item>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Box
              onClick={() => handleOpenScreen(0)}
              className="back-arrow-close"
              sx={{ marginTop: '6px !important' }}
            >
              <ArrowLeft />
            </Box>
            <Typography className="header-blue-20">Purchase History</Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {/* <Button
      sx={{ mt: { xs: 0, md: 0 }, mr: 2 }}
      variant="contained"
      type="submit"
      className="btn-primary m-0"

    >
      Save
    </Button> */}
          </Box>
        </Grid>
      </Grid>
      <Box className="main-sales-detail" p={0} mt={2}>
        <Box className="detailbox-main">
          <Box className="detail-section">
            <Box className="section-main">
              <Box>
                <Stack>DATE :</Stack>
                <Stack>
                  {' '}
                  {trophyDetails?.dateOfPurchase
                    ? dateFormate(trophyDetails.dateOfPurchase)
                    : '-'}
                </Stack>
              </Box>
              <Box>
                <Stack>PAYMENT METHOD :</Stack>
                <Stack>
                  {trophyDetails?.paymentSourceType
                    ? trophyDetails?.paymentSourceType
                    : '-'}
                </Stack>
              </Box>
            </Box>
            <Box className="section-main">
              <Box>
                <Stack>DOG :</Stack>

                <Stack>
                  {trophyDetails?.petName ? trophyDetails?.petName : '-'}
                </Stack>
              </Box>

              <Box>
                <Stack>CARD NUMBER :</Stack>

                <Stack>
                  {trophyDetails?.cardNumber ? trophyDetails?.cardNumber : '-'}
                </Stack>
              </Box>
            </Box>
            <Box className="section-main">
              <Box>
                <Stack>TOTAL AMOUNT :</Stack>
                <Stack>
                  {' '}
                  {(trophyDetails?.paymentAmount &&
                    '$' + numberFormate(trophyDetails?.paymentAmount)) ||
                    '$0.00'}
                </Stack>
              </Box>
              <Box>
                <Stack>DISCOUNT(%):</Stack>
                <Stack>
                  {(trophyDetails?.totalDiscount &&
                    trophyDetails.totalDiscount + '%') ||
                    0}
                </Stack>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SalesHistoryCard;
