import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Filter from './Filter';
import clsx from 'clsx';
// import { CustomButton, CustomSelect } from 'src/components/Global';
import {
  // Tooltip,
  Button,
  // Divider,
  Box,
  // FormControl,
  // InputLabel,
  Card,
  // Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  // Select,
  // MenuItem,
  Typography,
  useTheme,
  CardHeader,
  TextField,
  Grid,
  Slide,
  Container,
  CardContent,
  Checkbox
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Avatar from '@mui/material/Avatar';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
// import { useNavigate } from 'react-router-dom';
import LocationDetails from 'src/content/applications/LocationDetails';
import LocationEdit from 'src/content/applications/LocationManagement/LocationEdit';
import { ActiveStatus, InactiveStatus } from 'src/assets/SVG/SvgIcons';
import { CustomButton, CustomSelect } from 'src/components/Global';

// const [showScreen, setShowScreen] = useState(0);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// Apply location filter
// const applyFilters = (locationLists, searchText) => {
//   /* return locationLists.filter((locationItem) => {
//     let matches = true;

//     // if (filters.locationName && locationItem.locationName !== filters.locationName) {
//     //   matches = false;
//     // }

//     if (filters.locationName && locationItem.locationName && !locationItem.locationName.toLowerCase().includes(filters.locationName.toLowerCase())) {
//       matches = false;
//     }

//     return matches;
//   }); */
//   // Commented above code and added below code for search functionality
//   console.log('searchText', searchText);
//   let arrayOfMatchedObjects = [];
//   if (searchText) {
//     arrayOfMatchedObjects = locationLists.filter((object) => {
//       return JSON.stringify(object)
//         .toString()
//         .toLowerCase()
//         .includes(searchText.toLowerCase());
//     });
//   } else {
//     arrayOfMatchedObjects = locationLists;
//   }
//   return arrayOfMatchedObjects;
// };

// // Apply paination on table
// const applyPagination = (locationLists, page, limit) => {
//   return locationLists.slice(page * limit, page * limit + limit);
// };

const DrawLocationTable = ({
  setShowScreen,
  showScreen,
  locationLists,
  refreshLocationData,
  sideBarfilters,
  setSidebarFilters,
  selectedLocationLists,
  handleBulkAdd,
  btnLoading,
  setSelectedLocationLists,
  selectedStatus,
  setSelectedStatus,
  franchiseeList,
  managerList,
  statesList
}) => {
  /* Function to redirect on to the other route */
  // const navigate = useNavigate();
  // const [selectedLocationLists, setSelectedLocationLists] = useState([]);
  const [selectedLocationItem, setSelectedLocationItem] = useState([]);
  const [page, setPage] = useState(0);
  // const [limit, setLimit] = useState(10);
  // const [open, setOpen] = React.useState(false);
  // const [openEdit, setOpenEdit] = React.useState(false);
  const [displayFilterForm, setdisplayFilterForm] = useState(false);
  const [states, SetStates] = useState([]);

  const [searchText, setSearchText] = useState(''); // Created new state for search functionality
  // const [searchText, setSearchText] = useState('');
  // const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [keys, setKeys] = useState([]);
  console.log(keys);
  // const [showScreen, setShowScreen] = useState(0);
  // State to open dialog box in location edit component

  // const [isDataEdited,setisDataEdited]=useState(false);
  // , setFilters
  // const [filters, setFilters] = useState({
  //   locationName: null
  // });

  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));

  const classes = useStyles();

  const getFilterSize = () => {
    return displayFilterForm ? 300 : 0;
  };

  // Method to close the delete dialog
  // const handleClose = () => {

  //   // Now close the dialog
  //   setOpen(false);
  // };

  // let selectedItem;

  // Method to handle to change the location status into the dropdown
  // const handleStatusChange = (e) => {
  //   // let value = null;

  //   // if (e.target.value !== 'all') {
  //   //   value = e.target.value;
  //   // }

  //   // setFilters((prevFilters) => ({
  //   //   ...prevFilters,
  //   //   locationName: value
  //   // }));

  //   if (e && e.target && e.target.value !== 'Location') {
  //     navigate('/franchisee-management');
  //   }
  // };

  // const addLocation = () => {
  //   // navigate("/app/addfranchise");
  //   // setAddBtnClick(true);
  //   // handleClickOpen();
  //   // setshowForm(true);
  //   setShowScreen(1);
  //   // console.log("addBtnClick", addBtnClick);
  // };
  // // To change the page into the table
  // const handlePageChange = (event, newPage) => {
  //   setPage(newPage);
  // };

  // // To change the list
  // const handleLimitChange = (event) => {
  //   setLimit(parseInt(event.target.value));
  // };

  // // Handle the search functionality
  // const handleSearch = (event) => {
  //   let value = '';

  //   if (event.target.value !== '') {
  //     value = event.target.value;
  //   } else {
  //     value = '';
  //   }

  //   // Now set the filter
  //   // setFilters((prevFilters) => ({
  //   //   ...prevFilters,
  //   //   locationName: value
  //   // }));
  //   setSearchText(value);
  // };

  // Apply filter from object and below is the pagination order
  // const filteredLocationLists = applyFilters(locationLists, searchText);
  // const paginatedLocationLists = applyPagination(
  //   filteredLocationLists,
  //   page,
  //   limit
  // );

  useEffect(() => {
    setPage(0);
  }, [locationLists]);

  const handleSearchTextChange = (event) => {
    const searchText = event.target.value.toLowerCase();
    setSearchText(searchText);
    setPage(0); // Reset page when search text changes
  };

  const filteredLocationLists = locationLists.filter((item) => {
    return Object.values(item).some((value) =>
      value ? value.toString().toLowerCase().includes(searchText) : ''
    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };

  const paginatedLocationLists = filteredLocationLists.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const showFilter = () => {
    if (states.length === 0) getStates();
    setdisplayFilterForm(!displayFilterForm);
    console.log(displayFilterForm, 'displayFilterForm-------');
  };

  const getStates = async () => {
    fetch(`${process.env.REACT_APP_API_END_POINT}locations/getAllStates`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode && !data.error) {
          let Item = data.data.Items;
          SetStates(Item);
        }
      });
  };

  const theme = useTheme();
  // Method to open the delete dialog
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // Location details
  const handleEditLocation = (locationItem, e) => {
    e.preventDefault();
    e.stopPropagation();
    // Set the selected location item
    setSelectedLocationItem(locationItem);
    setShowScreen(2);
    // setopenEditLocation(true)
    // set edit mode true
    // setOpenEdit(true);
    // handleClickOpen();
  };

  // View location details
  const handleViewLocation = (locationItem, e) => {
    // e.preventDefault();
    e.stopPropagation();

    if (e && e.target && e.target.nodeName !== 'INPUT') {
      // Set the selected location item
      setSelectedLocationItem(locationItem);
      setShowScreen(3);
      // set edit mode false
      // setOpenEdit(false);
      // handleClickOpen();
    }
  };

  // Method to get the item for edit
  const getItemForEdit = (id) => {
    let elm;

    // id is the selected location's edit button id which is passed from the child component
    if (id) elm = document.getElementById(id);

    // Now trigger the click event on edit button when edit button found
    if (elm) elm.click();
  };

  // console.log(selectedSomeLocationItems, "selectedAllLocationItems", selectedAllLocationItems);
  console.log('selectedLocationLists', selectedLocationLists);
  useEffect(() => {
    let key =
      locationLists && locationLists.length > 1 && locationLists[0]
        ? Object.keys(locationLists[0])
        : '';
    if (key) setKeys(key);
    // setKeys(['name', 'age', 'profession']);
  }, [locationLists]);

  const handleSelectAllLocationItems = (event) => {
    setSelectedLocationLists(
      event.target.checked
        ? locationLists.map((locationItem) => locationItem.sortKey)
        : []
    );
  };

  const handleSelectOneLocationItem = (event, locationItemId) => {
    event.stopPropagation();
    // event.preventDefault();
    if (!selectedLocationLists.includes(locationItemId)) {
      setSelectedLocationLists((prevSelected) => [
        ...prevSelected,
        locationItemId
      ]);
    } else {
      setSelectedLocationLists((prevSelected) =>
        prevSelected.filter((id) => id !== locationItemId)
      );
    }
  };
  const selectedSomeLocationItems =
    selectedLocationLists.length > 0 &&
    selectedLocationLists.length < locationLists.length;
  const selectedAllLocationItems =
    selectedLocationLists.length === locationLists.length;
  return (
    <>
      {(() => {
        switch (showScreen) {
          case 2:
            return (
              <LocationEdit
                selectedData={selectedLocationItem}
                refreshLocationData={refreshLocationData}
                setShowScreen={setShowScreen}
                SetStates={SetStates}
                Transition={Transition}
                franchiseeList={franchiseeList}
                managerList={managerList}
                statesList={statesList}
                setSearchText={setSearchText}
              />
            );
          case 3:
            return (
              <LocationDetails
                data={selectedLocationItem}
                getItemForEdit={getItemForEdit}
                refreshLocationData={refreshLocationData}
                setShowScreen={setShowScreen}
                setSearchText={setSearchText}
              />
            );
          default:
            return (
              <Container maxWidth="100%" className="main-container">
                {/* <Grid
                  container
                  justifyContent="end"
                  alignItems="center"
                  className="add-btn-container"
                >
                  <Grid item>
                    <Button
                      sx={{ mt: { xs: 0, md: 0 } }}
                      variant="contained"
                      className="btn-primary"
                      onClick={addLocation}
                    >
                      Add New
                    </Button>
                  </Grid> */}
                {/* <Grid item>
              <Button
                sx={{ mt: { xs: 0, md: 0 }, border: '2px solid #003087 !important', color: '#003087 !important', padding: '6px 20px !important' }}
                variant="outlined"
                className='btn-primary'
                disabled
              >
                Manage
              </Button>
            </Grid> */}
                {/* </Grid> */}

                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="stretch"
                  spacing={0}
                >
                  {/* display={'flex'} justifyContent={'space-between'} */}
                  <Grid
                    container
                    xs={12}
                    xl={12}
                    sm={12}
                    md={12}
                    lg={12}
                    mt={6}
                    pl={2}
                    className="card-grid-container"
                  >
                    <Grid item sx={12} md={4} sm={4} lg={4} xl={4} pr={2}>
                      <Grid
                        className="card-content-blk"
                        sx={{ width: '100%', padding: '0px' }}
                      >
                        {/* sx={{ maxWidth: 345 }} */}
                        <Card sx={{ borderRadius: '19px' }}>
                          <CardHeader
                            className="card-header"
                            avatar={
                              <Avatar
                                alt="board"
                                src="/static/images/logo/cardboardicon.png"
                                sx={{ marginTop: 3 }}
                                className="card-avatar-img"
                              />
                            }
                            action={
                              <IconButton aria-label="settings">
                                <MoreVertIcon />
                              </IconButton>
                            }
                            title="987"
                            subheader="Total no of sales"
                          />
                          <CardContent
                            sx={{
                              paddingTop: 0,
                              paddingLeft: 8,
                              wordBreak: 'break-all'
                            }}
                          >
                            <Typography variant="body2" color="text.secondary">
                              Total sales during time period
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                    <Grid item sx={12} md={4} sm={4} lg={4} xl={4} pr={2}>
                      <Grid
                        className="card-content-blk"
                        sx={{ width: '100%', padding: '0px' }}
                      >
                        <Card sx={{ borderRadius: '19px' }}>
                          <CardHeader
                            className="card-header"
                            avatar={
                              <Avatar
                                alt="board"
                                src="/static/images/logo/cardboardicon.png"
                                sx={{ marginTop: 3 }}
                                className="card-avatar-img"
                              />
                            }
                            action={
                              <IconButton aria-label="settings">
                                <MoreVertIcon />
                              </IconButton>
                            }
                            title="78%"
                            subheader="Conversion Rating"
                          />
                          <CardContent
                            sx={{
                              paddingTop: 0,
                              paddingLeft: 8,
                              wordBreak: 'break-all'
                            }}
                          >
                            <Typography variant="body2" color="text.secondary">
                              Customer Conversion Effectiveness
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                    <Grid item sx={12} md={4} sm={4} lg={4} xl={4}>
                      <Grid
                        className="card-content-blk"
                        sx={{ width: '100%', padding: '0px' }}
                      >
                        <Card sx={{ borderRadius: '19px' }}>
                          <CardHeader
                            className="card-header"
                            avatar={
                              <Avatar
                                alt="board"
                                src="/static/images/logo/cardboardicon.png"
                                sx={{ marginTop: 3 }}
                                className="card-avatar-img"
                              />
                            }
                            action={
                              <IconButton aria-label="settings">
                                <MoreVertIcon />
                              </IconButton>
                            }
                            title="$243,342,124"
                            subheader="Average Transaction"
                          />
                          <CardContent
                            sx={{
                              paddingTop: 0,
                              paddingLeft: 8,
                              wordBreak: 'break-all'
                            }}
                          >
                            <Typography variant="body2" color="text.secondary">
                              Total average of transaction
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={12} sx={{ padding: '0 1rem' }}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-end"
                    >
                      <Grid
                        item
                        sx={{
                          marginTop: '10px',
                          marginBottom: '20px'
                        }}
                      >
                        <Button
                          onClick={showFilter}
                          variant={displayFilterForm ? 'contained' : 'outlined'}
                        >
                          Filters
                          <KeyboardArrowDownRoundedIcon
                            className={clsx(
                              !displayFilterForm && classes.closeY,
                              displayFilterForm && classes.openY
                            )}
                          />
                        </Button>
                      </Grid>
                      <Grid
                        xs={12}
                        sm={6}
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          marginTop: '10px',
                          marginBottom: '20px'
                        }}
                        className="top-filter-cont"
                      >
                        {/* BELOW CODE IS COMMENTED DUE TO BUGID 6116 */}
                        {/* <Box width={212} mr={2}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel className="status-field-lbl">
                              Location
                            </InputLabel>
                            <Select
                              value={'Location'}
                              onChange={handleStatusChange}
                              label="Location"
                              autoWidth
                              className="status-field field-label-css"
                            >
                              <MenuItem disabled value="Location">
                                <em className="drodown_menu">Location</em>
                              </MenuItem>
                              <MenuItem value={'Franchisee'}>
                                Franchise
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box> */}
                        <TextField
                          type="search"
                          className="Search-field"
                          label="Search"
                          InputProps={{
                            style: {},
                            endAdornment: <SearchIcon />
                          }}
                          // onChange={handleSearch}
                          // onClick={handleSearch}
                          onChange={handleSearchTextChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ padding: '0 1rem' }}>
                    {displayFilterForm === true ? (
                      <Grid item width={getFilterSize()}>
                        <Filter
                          filters={sideBarfilters}
                          setFilters={setSidebarFilters}
                          getStates={states}
                        />
                      </Grid>
                    ) : null}

                    <Grid
                      item
                      sx={{
                        width: `calc(100% - ${getFilterSize()}px)`
                      }}
                    >
                      <Card
                        sx={{
                          padding: '15px',
                          borderRadius: '19px 19px 0px 0px'
                        }}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid
                            item
                            xs={12}
                            sm={5}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              gap: '12px'
                            }}
                          >
                            <CardHeader
                              title="All Locations"
                              className="table-header-name"
                              sx={{ fontSize: '20px', color: '#003087' }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={7}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                              gap: '12px'
                            }}
                            className="membership-status-container status-field-ddl"
                          >
                            <CustomSelect
                              fullWidth
                              name="selectedStatus"
                              disabled={!selectedLocationLists?.length}
                              value={selectedStatus}
                              onChange={(type, value) =>
                                setSelectedStatus(value)
                              }
                              label="Select Status"
                              options={[
                                {
                                  label: 'Activate',
                                  value: 1
                                },
                                {
                                  label: 'Deactivate',
                                  value: 0
                                }
                              ]}
                            />
                            <CustomButton
                              variant="contained"
                              disabled={
                                selectedStatus === '' ||
                                !selectedLocationLists?.length
                              }
                              onClick={handleBulkAdd}
                              loading={btnLoading}
                            >
                              Update
                            </CustomButton>
                          </Grid>
                        </Grid>
                        <TableContainer className="location-table-container">
                          <Table>
                            <TableHead>
                              <TableRow className="table-header">
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    color="primary"
                                    checked={selectedAllLocationItems}
                                    indeterminate={selectedSomeLocationItems}
                                    onChange={handleSelectAllLocationItems}
                                  />
                                </TableCell>
                                <TableCell>Location Name</TableCell>
                                {/* <TableCell>Owner Name</TableCell> */}
                                <TableCell>Email</TableCell>

                                {/* <TableCell>Location Manager</TableCell> */}
                                <TableCell>Status</TableCell>
                                {/* 
                                <TableCell>Opening Date</TableCell>
                                <TableCell>Location Phone Number</TableCell> */}
                                <TableCell className="">
                                  Assigned Specialist
                                </TableCell>
                                <TableCell />
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {paginatedLocationLists.map((locationItem) => {
                                const isLocationItemSelected =
                                  selectedLocationLists.includes(
                                    locationItem.sortKey
                                  );
                                return (
                                  <TableRow
                                    hover
                                    key={locationItem.sortKey}
                                    selected={isLocationItemSelected}
                                    className="table-row cursor-pointer"
                                    onClick={(e) =>
                                      handleViewLocation(locationItem, e)
                                    }
                                  >
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        color="primary"
                                        checked={isLocationItemSelected}
                                        onChange={(event) =>
                                          handleSelectOneLocationItem(
                                            event,
                                            locationItem.sortKey
                                          )
                                        }
                                        value={isLocationItemSelected}
                                      />
                                    </TableCell>
                                    <TableCell
                                      className="table-td -flex"
                                      sx={{ minWidth: '200px' }}
                                    >
                                      {/* <Avatar
                                        alt="icon"
                                        src={
                                          locationItem.locationImage ||
                                          '/static/images/logo/cardboardicon.png'
                                        }
                                        sx={{ marginRight: 2, float: 'left' }}
                                        className="card-avatar-img align-self-center"
                                      /> */}
                                      <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                        color="text.primary"
                                        sx={{ marginTop: 1 }}
                                        gutterBottom
                                      >
                                        {locationItem.locationName}
                                      </Typography>
                                    </TableCell>
                                    <TableCell className="table-td">
                                      <Typography>
                                        {locationItem.email}
                                      </Typography>
                                    </TableCell>
                                    <TableCell className="table-td">
                                      <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                        color="text.primary"
                                        gutterBottom
                                        noWrap
                                        className=""
                                      >
                                        {locationItem.status ? (
                                          // Avtive status icon
                                          <ActiveStatus />
                                        ) : (
                                          // Inactive status icon
                                          <InactiveStatus />
                                        )}
                                      </Typography>
                                    </TableCell>
                                    <TableCell className="table-td">
                                      <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                        color="text.primary"
                                        gutterBottom
                                        noWrap
                                      >
                                        {locationItem.managerName}
                                      </Typography>
                                    </TableCell>
                                    {/* <TableCell className="table-td">
                                      <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                        color="text.primary"
                                        gutterBottom
                                        noWrap
                                      >
                                        {locationItem.openingDate}
                                      </Typography>
                                    </TableCell> */}
                                    {/* <TableCell className="table-td">
                                      <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                        color="text.primary"
                                        gutterBottom
                                        noWrap
                                      >
                                        {locationItem.phone}
                                      </Typography>
                                    </TableCell> */}
                                    {/* <TableCell className="table-td">
                                      <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                        color="text.primary"
                                        gutterBottom
                                        noWrap
                                        className="td-center"
                                      >
                                        {locationItem.status ? (
                                          // Avtive status icon
                                          <ActiveStatus />
                                        ) : (
                                          // Inactive status icon
                                          <InactiveStatus />
                                        )}
                                      </Typography>
                                    </TableCell> */}
                                    <TableCell className="table-td">
                                      <IconButton
                                        sx={{
                                          '&:hover': {
                                            background:
                                              theme.colors.primary.lighter
                                          },
                                          color: theme.palette.primary.main
                                        }}
                                        color="inherit"
                                        size="small"
                                        className="edit-btn"
                                        id={
                                          'edit_btn_' + locationItem.locationId
                                        }
                                        onClick={(e) =>
                                          handleEditLocation(locationItem, e)
                                        }
                                      >
                                        Edit
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Box p={2}>
                          {/* <TablePagination
                            component="div"
                            count={filteredLocationLists.length}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleLimitChange}
                            page={page}
                            rowsPerPage={limit}
                            rowsPerPageOptions={[5, 10, 25, 30]}
                          /> */}
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={filteredLocationLists.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </Box>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            );
        }
      })()}
    </>
  );
};

DrawLocationTable.propTypes = {
  locationLists: PropTypes.array.isRequired
};

DrawLocationTable.defaultProps = {
  locationLists: []
};

export default DrawLocationTable;
