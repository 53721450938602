/* eslint-disable spaced-comment */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import './style.css';
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography
} from '@mui/material';
import Header from 'src/components/Header';
import StaffTimeOffCalender from './StaffTimeOffCalender';
import StaffCalenderMenu from './StaffCalenderMenu';
// import { getLocalData } from "src/utils";
import { ApiService } from '../../../services/ApiService';
import {
  getLocalData,
  dateFormat,
  convertFrom12To24Format
} from '../../../utils';
import { AddTimeOffModal } from './AddTimeOffModal';
import Loader from '../../../components/Loader';

export default function StaffTimeOffMain(props) {
  const [modalOpen, setModalOpen] = useState(false);

  // let locationId = 'LOC#54123';
  const locationId = getLocalData('locationId');

  const [trainerData, setTrainerData] = useState([]);
  const [schedulerData, setSchedulerData] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [startHour, setStartHour] = useState('00:00');
  const [endHour, setEndHour] = useState('24:00');

  // States for populating data in modal
  const [fromTime, setfromTime] = useState('');
  const [endTime, setendTime] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [endDate, setendDate] = useState('');
  const [selectedTrainerData, setselectedTrainerData] = useState('');

  useEffect(() => {
    getAllTrainers();
  }, []);
  useEffect(() => {
    if (trainerData) {
      getAllStaffTimeOff(trainerData);
    }
  }, [currentDate]);

  const getAllTrainers = async () => {
    try {
      let encodedLocationId = encodeURIComponent(locationId);
      let response = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/getAllTrainers?locationId=${encodedLocationId}`
      );
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      console.log(Item, 'Item');
      let uniqueTrainers = Item?.map((obj, index) => ({
        eventClass: index === 0 ? 'borderLeft' : 'borderLeftAgain',
        color: index !== 1 ? '#D13A0033' : '#32B2AC33',
        designation: obj.designationName,
        trainerId: obj.sortKey,
        trainerName: `${obj.firstName || ''} ${obj.lastName || ''}`,
        trainerProfileImage: obj.profileImage,
        trainerAvailability: obj.availability,
        ...obj
      }));
      setTrainerData(uniqueTrainers);
    } catch (e) {
      setIsLoading(false);
    }
  };
  const getAllStaffTimeOff = async (uniqueTrainers) => {
    console.log(uniqueTrainers, 'uniqueTrainersuniqueTrainersuniqueTrainers');
    setIsLoading(true);
    // let payload = {
    //   locationId: locationId,
    //   fromDate: dateFormat(currentDate),
    // };
    try {
      let encodedLocationId = encodeURIComponent(locationId);
      let response = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/getLocationOperatingHours?locationId=${encodedLocationId}`
      );
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      // const modifiedItem = [];
      Item.map((item) =>
        // eslint-disable-next-line array-callback-return
        item.operatingHours.map((subItem, index) => {
          setStartHour(convertFrom12To24Format(subItem.startTime));
          setEndHour(convertFrom12To24Format(subItem.endTime));
          //   const date = getDateByDayOfWeek(subItem.day);
          //   modifiedItem.push({
          //     Id: index,
          //     "Subject": "Not Available",
          //     "StartTime": convertTimeAndDate(subItem.startTime, date),
          //     "EndTime": convertTimeAndDate(subItem.endTime, date),
          //     "IsUnavailability": true,
          //     "TrainerId": 'USER#2VQVJkSzgLCnGr7HkLRilQV4Hye'
          //   });
        })
      );
      // console.log(modifiedItem, "modifiedItem");
      // setSchedulerData(modifiedItem);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  function getDateByDayOfWeek(dayOfWeek) {
    const today = new Date(); // Get the current date
    const targetDay = dayOfWeek.toLowerCase(); // Convert the input day to lowercase for comparison

    // Iterate through the next 7 days to find the desired day of the week
    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(today); // Create a new date object to avoid modifying the original date
      currentDate.setDate(today.getDate() + i);

      const currentDay = currentDate
        .toLocaleString('en-US', { weekday: 'long' })
        .toLowerCase();

      if (currentDay === targetDay) {
        // Format the date as MM-DD-YYYY
        return `${(currentDate.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${currentDate
          .getDate()
          .toString()
          .padStart(2, '0')}-${currentDate.getFullYear()}`;
      }
    }

    return null; // Return null if the dayOfWeek is not found in the next 7 days
  }

  const handleNextClick = () => {
    const convertedDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 1
    );
    setCurrentDate(convertedDate);
  };

  const handlePreviousClick = () => {
    const convertedDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - 1
    );
    setCurrentDate(convertedDate);
  };

  const handleDateChange = (value) => {
    const date = new Date(value);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const convertedDate = new Date(year, month, day);
    setCurrentDate(convertedDate);
  };
  const convertTimeAndDate = (timeString, dateString) => {
    const dateTimeString = `${dateString} ${timeString}`;
    const dateTime = moment(dateTimeString, 'MM-DD-YYYY hh:mm A');
    const utcDate = dateTime.toDate();
    return utcDate.toISOString();
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      <Container maxWidth="100%" className="main-container">
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          className="add-btn-container"
        >
          <Header title=" Staff Time Off" />
        </Grid>
      </Container>
      <Grid
        container
        spacing={2}
        sx={{ display: 'flex', justifyContent: 'center' }}
      >
        <Grid
          item
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
          alignItems="center"
          width="100%"
          className="calenderToggle"
        >
          <Box
            display="flex"
            justifyContent="flex-start"
            marginLeft={2.5}
            alignItems="center"
          >
            <StaffCalenderMenu
              handlePreviousClick={handlePreviousClick}
              handleNextClick={handleNextClick}
              handleDateChange={handleDateChange}
              currentDate={(dateFormat(currentDate))}
            />
          </Box>
        </Grid>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Grid item width="100%" marginLeft={2.5}>
              <StaffTimeOffCalender
                currentDate={currentDate}
                trainerData={trainerData}
                data={schedulerData || []}
                handleOpenModal={() => setModalOpen(true)}
                startHour={startHour}
                endHour={endHour}
                setendTime={setendTime}
                setfromTime={setfromTime}
                setendDate={setendDate}
                setFromDate={setFromDate}
                setselectedTrainerData={setselectedTrainerData}
              />
            </Grid>
          </>
        )}
      </Grid>
      <AddTimeOffModal
        getAllTrainers={getAllTrainers}
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        trainers={trainerData}
        endTime={endTime}
        fromTime={fromTime}
        fromDate={fromDate}
        endDate={endDate}
        selectedTrainerData={selectedTrainerData}
        setendTime={setendTime}
        setfromTime={setfromTime}
        setendDate={setendDate}
        setFromDate={setFromDate}
        setselectedTrainerData={setselectedTrainerData}
      />
    </>
  );
}
