/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-dupe-else-if */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-undef */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable no-else-return */

import { useState, forwardRef, useEffect } from 'react';
import {
  Box,
  Grid,
  Stack,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Slide,
  Divider,
  CircularProgress
} from '@mui/material';
import { InventoryFilter, InventoryTable } from './index';
import {
  CustomTextField,
  CustomSelect,
  CustomButton,
  SearchInput,
  Toggle
} from 'src/components/Global';
import { getLocalData } from 'src/utils';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
// import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { ArrowLeft } from '../../assets/SVG/SvgIcons';
import ErrorMessage from '../PopupMessage/ErrorMessage';
import Loader from '../Loader';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const CreateOrder = (props) => {
  const {
    allSuppliers,
    inventoryData,
    allInventoryData,
    handleResetStates,
    loading,
    purchaseStatus,
    dialog,
    setDialog,
    quantity,
    setQuantity,
    prevQuantity,
    selectedRows,
    setSelectedRows,
    setSelectedRowsData,
    orderDetails,
    setOrderDetails,
    filters,
    setFilters,
    postData,
    categoryData,
    handleOpenScreen,
    editOrder,
    postReviseData,
    unitCost,
    setUnitCost,
    itemPrice,
    setItemPrice,
    prevCost,
    allLocationsList
  } = props;

 // console.log("purchaseStatus", purchaseStatus, "orderDetails", orderDetails, "isDuplicate",isDuplicate)

  const [searchQuery, setSearchQuery] = useState('');
  const [isFilters, setIsFilters] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const locationId = getLocalData('locationId');

  const [isError, setIsError] = useState({
    title: false,
    supplier: false,
    address: false,
    notes: false
  });

  // use effect to set the location by default
  useEffect(() => {
    // get the location name from local storage and set it to the order details for address field
    // const locationName = localStorage.getItem('locationName');
    if(locationId) {
      const locationAddress = findLocationAddress(locationId);
      if(locationAddress) setOrderDetails({ ...orderDetails, address: locationAddress });
    }
  }, [locationId]);

  function findLocationAddress(id) {
    const foundLocation = allLocationsList?.find(
      (location) => location.locationId == id
    );

    if (foundLocation) {
      return foundLocation.addressLine1+', ' +(foundLocation.addressLine2 && foundLocation.addressLine2+ ', ') +foundLocation.city+', '+foundLocation.state+', '+foundLocation.postalCode;
    }

    return ''; // Return an empty string if no supplier with the given id is found
  }

  // Handler to set the order details
  const handleOrderDetails = (type, value) => {
    setOrderDetails({ ...orderDetails, [type]: value });
    setIsError({ ...isError, [type]: false });
  };

  // Handler to close the screen
  const handleCloseScreen = () => {
    if (!valuesAreDifferent()) {
      setDialog({ ...dialog, state: true });
    } else {
      handleResetStates();
    }
  };

  // Handler to handle the dialog
  const handleDialog = (value) => {
    if (value === 'Continue') {
      setDialog({
        isCreated: false,
        state: false
      });
    } else if (value === 'Discard') {
      handleResetStates();
    } else if (value === 'Cancel') {
      handleCloseScreen();
    }
  };

  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));

  const classes = useStyles();

  const valuesAreDifferent = () => {
    let value = true;
    if (
      !orderDetails.isDefault ||
      orderDetails.title !== '' ||
      orderDetails.notes !== '' ||
      orderDetails.supplier !== '' ||
      orderDetails.address !== '' ||
      selectedRows?.length
    ) {
      value = false;
    }

    return value;
  };

  // Set the heading of the page based on the status
  function getPurchaseHeading(status) {
    if (editOrder == 'edit') {
      return 'Revise Your Purchase Order';
    } else if (status == 'create') {
      return 'Create Order';
    } else if (status == 'receive') {
      return 'Receive Your Purchase Order';
    } else if (status == 'revise') {
      return 'Revise Your Purchase Order';
    } else {
      return 'Purchase Order';
    }
  }

  // Check the selected rows quantity
  function checkAllSelectedQuantity() {
    return selectedRows.every((itemId) => quantity[itemId] > 0);
  }

  // Check the selected rows cost
  function checkAllSelectedItemPrice() {
    // check if all selected rows have price is not available then check the unitcost is greater than 0
    // return selectedRows.every((itemId) => quantity[itemId] > 0 && ((itemPrice[itemId] && Number(itemPrice[itemId]) > 0) || unitCost[itemId] > 0));
    return selectedRows.every(
      (itemId) => quantity[itemId] > 0 && unitCost[itemId] > 0
    );
  }

  // Method to check the empty values of the fields
  function checkForEmptyValues() {
    let hasError = false;
    const updatedErrors = {};

    // Always check for empty values
    // const alwaysCheck = ['title', 'supplier', 'address', 'notes'];
    const alwaysCheck = ['title', 'supplier'];
    for (const key of alwaysCheck) {
      const value = orderDetails[key];
      const isEmpty =
        value === '' || (Array.isArray(value) && value.length === 0);
      updatedErrors[key] = isEmpty;

      if (isEmpty) {
        hasError = true;
      }
    }
    setIsError(updatedErrors);
    return hasError;
  }

  // Method to handle the order form submit
  const handleOrderFormSubmit = (status) => {
    if (checkForEmptyValues()) {
      setShowMessage(true);
      setErrorMessage('Please fill all the required fields');
      // toast.error('Please fill all the required fields');
      if (orderDetails.title == '' || orderDetails.supplier == '') {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    } else if (!selectedRows?.length) {
      setShowMessage(true);
      setErrorMessage('Please select atleast one order row');
      // toast.error('Please select atleast one order row');
    } else if (!checkAllSelectedQuantity()) {
      setShowMessage(true);
      setErrorMessage('All selected rows must have quantity greater than 0');
      // toast.error('All selected rows must have quantity greater than 0');
    } else if (!checkAllSelectedItemPrice()) {
      setShowMessage(true);
      // setErrorMessage('All selected rows must have price or unit cost greater than 0');
      setErrorMessage('All selected rows must have unit cost greater than 0');
    } else {
      // SetEditOrder to edit when user click on Draft button and status is not Arrived
      if (editOrder == 'edit' && status !== 'Arrived') {
        postReviseData('Draft');
      } else if (status == 'Draft') {
        postData('Draft');
      } else if (status == 'Arrived') {
        handleOpenScreen(3);
      }
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  function isAnyFilterApplied() {
    return Object.values(filters).some((value) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value !== null && value !== '';
    });
  }

  return (
    <div className="service-container order-container">
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}
      <Box
        sx={{ width: '100%' }}
        paddingBottom="20px"
        className="Main-container common-form-container"
      >
        <form className="custom-form">
          <Grid mt={1} marginBottom="20px">
            {/* <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid item>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    textAlign: 'left',
                    alignItems: 'center',
                    gap: '8px',
                    marginTop: '10px'

                  }}

                >
                       <Box
                      onClick={handleCloseScreen}
                      className="back-arrow-close"
                    >
                      <ArrowLeft />
                    </Box>
                
                  <Typography
                className="header-blue-32"
              >
            {getPurchaseHeading(purchaseStatus)}
              </Typography>
                </Box>
              </Grid>
            </Grid> */}
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid item>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <Box onClick={handleCloseScreen} className="back-arrow-close">
                    <ArrowLeft />
                  </Box>
                  <Typography className="header-blue-32">
                    {getPurchaseHeading(purchaseStatus)}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}></Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2} columns={12} mt={1}>
            <Grid item xs={12} sm={4}>
              <CustomTextField
                fullWidth
                disabled
                label="ORDER NUMBER"
                defaultValue={orderDetails.number}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <CustomTextField
                fullWidth
                type="text"
                name="title"
                label="ORDER TITLE"
                error={isError.title}
                helperText={isError.title && 'Title is required'}
                value={orderDetails.title}
                onChange={handleOrderDetails}
                disabled={purchaseStatus != 'create'}
                required
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <CustomSelect
                fullWidth
                name="supplier"
                value={orderDetails.supplier}
                onChange={handleOrderDetails}
                label="SELECT SUPPLIER"
                error={isError.supplier}
                disabled={purchaseStatus != 'create'}
                helperText={isError.supplier && 'Supplier is required'}
                options={allSuppliers?.map((item) => ({
                  // label: item.supplierName,
                  // value: item.supplierId
                  label: item.businessName,
                  value: item.sortKey
                }))}
                required
                classLabelProps="input-field-dropdown-styling"
              />
            </Grid>
          </Grid>

          <Grid mt={1}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              marginTop="20px"
            >
              <Grid item>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <h2 className="title mb-0 mt-10 primary-color create-order-heading">
                    Select Products to Order
                  </h2>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            width="100%"
            // marginTop="20px"
          >
            <Grid
              item
              xs={12}
              sm={6}
              marginTop="20px"
              sx={{
                marginTop: '20px'
              }}
            >
              {purchaseStatus != 'receive' ? (
                <CustomButton
                  onClick={() => setIsFilters(!isFilters)}
                  variant={
                    isAnyFilterApplied()
                      ? 'contained'
                      : isFilters
                      ? 'contained'
                      : 'outlined'
                  }
                >
                  Filter (All)
                  <KeyboardArrowDownRoundedIcon
                    className={clsx(
                      !isFilters && classes.closeY,
                      isFilters && classes.openY
                    )}
                  />
                </CustomButton>
              ) : null}
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '10px'
              }}
            >
              <SearchInput
                value={searchQuery}
                setSearchQuery={setSearchQuery}
                classPropName="height-auto"
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            marginTop="10px"
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            {loading ? (
              <Loader IsLoading={loading} />
            ) : (
              <>
                {isFilters ? (
                  <Grid item width={isFilters ? 300 : 0}>
                    <InventoryFilter
                      filters={filters}
                      setFilters={setFilters}
                      allInventoryData={allInventoryData}
                      categoryData={categoryData}
                    />
                  </Grid>
                ) : null}

                <Grid item width={`calc(100% - ${isFilters ? 300 : 0}px)})`}>
                  <InventoryTable
                    tableRows={inventoryData}
                    searchQuery={searchQuery}
                    quantity={quantity}
                    setQuantity={setQuantity}
                    prevQuantity={prevQuantity}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    purchaseStatus={purchaseStatus}
                    unitCost={unitCost}
                    setUnitCost={setUnitCost}
                    prevCost={prevCost}
                  />
                </Grid>
              </>
            )}
          </Grid>

          <Grid container spacing={2} className="order-multiline-grid">
            <Grid item xs={12} paddingRight={{ md: '20px' }} marginTop="16px">
              <h2
                className="title mb-0 mt-10 primary-color create-order-heading"
                variant="variant2"
              >
                Order Details
              </h2>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sm={6}
              lg={6}
              paddingRight={{ md: '20px' }}
              marginTop={{ md: '20px' }}
            >
              <CustomTextField
                fullWidth
                label="ADD NOTES"
                multiline
                rows="10"
                type="text"
                name="notes"
                value={orderDetails.notes}
                onChange={handleOrderDetails}
                // error={isError.notes}
                // helperText={isError.notes && 'Notes is required'}
                disabled={purchaseStatus != 'create'}
                // required
                // className='order-notes-height'
              />
            </Grid>
            <Grid item xs={12} md={6} sm={6} lg={6} position="relative">
              <Stack
                direction="row"
                useFlexGap
                justifyContent="space-between"
                alignItems="center"
                className="create-order-default-address"
              >
                <Typography
                  className="mt-10"
                  variant="variant6"
                  sx={{ color: '#000' }}
                >
                  Add Default Shipping Address
                </Typography>
                <Toggle
                  name="isDefault"
                  value={orderDetails.isDefault}
                  onChange={handleOrderDetails}
                  disabled={purchaseStatus != 'create'}
                />
              </Stack>

              <CustomTextField
                fullWidth
                label="SHIPPING ADDRESS"
                multiline
                rows="5"
                type="text"
                name="address"
                // error={isError.address}
                // helperText={isError.address && 'Shipping Address is required'}
                value={orderDetails.address}
                onChange={handleOrderDetails}
                disabled={purchaseStatus != 'create'}
                // required
              />

              <Stack
                // display={{ xs: 'grid', md: 'flex' }}
                // direction={{ xs: 'column', md: 'row' }}
                // justifyContent={{ md: 'flex-end' }}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                // position="absolute"
                width="100%"
                // bottom="0"
                // right="4px"
                marginTop={{ md: '20px', xs: '10px', lg: '20px', sm: '20px' }}
              >
                <Box
                  display="flex"
                  gap={1}
                  justifyContent="space-between"
                  width="100%"
                >
                  {/* <CustomButton
                    variant="outlined"
                    disabled={purchaseStatus == 'receive'}
                    onClick={() => handleOrderFormSubmit('Draft')}
                    fullWidth
                  >
                    Save Draft
                  </CustomButton>
                  <CustomButton
                    variant="contained"
                    onClick={() => handleOrderFormSubmit('Arrived')}
                    fullWidth
                  >
                    Proceed to Summary
                  </CustomButton> */}
                  <Button
                    variant="outlined"
                    disabled={purchaseStatus == 'receive'}
                    onClick={() => handleOrderFormSubmit('Draft')}
                    fullWidth
                    sx={{
                      border: '2px solid rgba(0, 48, 135, 0.5) !important'
                    }}
                  >
                    Save Draft
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => handleOrderFormSubmit('Arrived')}
                    fullWidth
                  >
                    Proceed to Summary
                  </Button>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </Box>

      <Dialog
        open={dialog.state}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleDialog('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          Discard Changes?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                You have unsaved changes.
                <Typography className="title-text-sub">What do you want to do?</Typography>
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleDialog('Discard')}
          >
            Discard Changes
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 no-hover"
            onClick={() => handleDialog('Continue')}
          >
            Go Back to Editing
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
