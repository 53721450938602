/* eslint-disable no-else-return */
/* eslint-disable eqeqeq */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Avatar,
  Card,
  CardHeader,
  IconButton,
  useTheme,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Checkbox
} from '@mui/material';

import { ActiveStatus, InactiveStatus } from 'src/assets/SVG/SvgIcons';
import { CustomButton, CustomSelect } from 'src/components/Global';

export const MembershipTable = (props) => {
  const {
    tableRows,
    searchQuery,
    loading,
    openDetail,
    selectedRows,
    setSelectedRows,
    selectedStatus,
    setSelectedStatus,
    handleBulkAdd,
    btnLoading
  } = props;
  const theme = useTheme();
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const newArray = tableRows.map((item) => {
    if (item.benefits && Array.isArray(item.benefits)) {
      // Filter out objects with itemType set to "Product"
      const filteredBenefits = item.benefits.filter(
        (benefit) => benefit.itemType !== 'Product'
      );
      // Create a new object with the same properties, but with the filtered benefits array
      return {
        ...item,
        benefits: filteredBenefits
      };
    }
    // If benefits array is not present or not an array, keep the object unchanged
    return item;
  });

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = filteredData.map((n) => n.id);
      setSelectedRows(newSelected);
      return;
    }
    setSelectedRows([]);
  };

  const handleClick = (event, id) => {
    event.stopPropagation();

    const selectedIndex = selectedRows.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows?.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows?.slice(0, selectedIndex),
        selectedRows?.slice(selectedIndex + 1)
      );
    }

    setSelectedRows(newSelected);
  };

  const isSelected = (id) => selectedRows.indexOf(id) !== -1;

  function getBillingCycleValue(value) {
    if (value) {
      return 'Per Year';
    } else if (!value) {
      return `Per Month`;
    } else {
      return '';
    }
  }

  useEffect(() => {
    const searchText = searchQuery.toLowerCase();
    setSearchText(searchText);
    setPage(0);
  }, [searchQuery]);

  const filteredData = newArray.filter((item) => {
    return Object.values(item).some((value) =>
      value?.toString()?.toLowerCase()?.includes(searchText)
    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };

  const paginatedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  console.log(paginatedData, 'paginatedDatapaginatedDatapaginatedData');
  useEffect(() => {
    setPage(0);
  }, [tableRows]);

  return (
    <Box sx={{ pl: '5px' }}>
      <Card
        sx={{
          padding: '15px',
          borderRadius: '19px 19px 0px 0px'
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            {/* <CardHeader title="All Membership" className="table-heade header-blue-20" /> */}
            <Typography className="table-heade header-blue-20">
              {' '}
              All Membership
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={7}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '12px'
            }}
            className="membership-status-container"
          >
            <CustomSelect
              fullWidth
              name="selectedStatus"
              disabled={!selectedRows?.length}
              value={selectedStatus}
              onChange={(type, value) => setSelectedStatus(value)}
              label="Select Status"
              options={[
                {
                  label: 'Activate',
                  value: 1
                },
                {
                  label: 'Deactivate',
                  value: 0
                }
              ]}
            />
            <CustomButton
              variant="contained"
              disabled={selectedStatus === '' || !selectedRows?.length}
              onClick={handleBulkAdd}
              loading={btnLoading}
            >
              Update
            </CustomButton>
          </Grid>
        </Grid>

        <TableContainer className="location-table-container membership-table-container">
          <Table>
            <TableHead>
              <TableRow className="table-header">
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={
                      selectedRows?.length > 0 &&
                      selectedRows?.length < filteredData?.length
                    }
                    checked={
                      filteredData?.length > 0 &&
                      selectedRows?.length === filteredData?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      'aria-label': 'select all'
                    }}
                    sx={{ color: '#003087' }}
                  />
                </TableCell>
                <TableCell>Membership</TableCell>
                <TableCell className="th-center">Label</TableCell>
                <TableCell className="th-center">Active Members</TableCell>
                <TableCell>Pricing Options</TableCell>
                <TableCell>Billing Frequency</TableCell>
                <TableCell className="th-center">Status</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {paginatedData.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    key={row.id}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    selected={isItemSelected}
                    className="table-row cursor-pointer"
                    onClick={(e) => openDetail(row.id, 3, e)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId
                        }}
                        sx={{ color: '#003087' }}
                        onClick={(event) => handleClick(event, row.id)}
                      />
                    </TableCell>

                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        sx={{ marginTop: 1 }}
                        gutterBottom
                        noWrap
                      >
                        {row.name}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                        className="td-center"
                      >
                        <div
                          className="membership-label-color"
                          style={{ backgroundColor: row.label }}
                        ></div>
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                        className="td-center"
                      >
                        {row.activeMembers}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                         {row.benefits.length > 2
                          ? row?.benefits
                              ?.filter(
                                (benefit) => benefit?.itemType === 'PricingOption'
                              )
                              .slice(0, 2)
                              .map((benefit, index, array) => (
                                <span key={index}>
                                  {benefit?.itemName}
                                  {array.length === 2 && index === 0
                                    ? ', '
                                    : ''}
                                  {array.length === 2 && index === 1
                                    ? ' ... '
                                    : ''}
                                </span>
                              ))
                          : row?.benefits
                              ?.filter(
                                (benefit) => benefit?.itemType === 'PricingOption'
                              )
                              .slice(0, 2)
                              .map((benefit, index, array) => (
                                <span key={index}>
                                  {benefit?.itemName}
                                  {index < array.length - 1 ? ', ' : ''}
                                </span>
                              ))}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {getBillingCycleValue(row.billingCycle)}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                        className="td-center"
                      >
                        {row.status === 1 ? (
                          // Active Status icon
                          <ActiveStatus />
                        ) : (
                          // Inactive Status icon
                          <InactiveStatus />
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <IconButton
                        sx={{
                          '&:hover': {
                            background: theme.colors.primary.lighter
                          },
                          color: theme.palette.primary.main
                        }}
                        color="inherit"
                        size="small"
                        className="edit-btn"
                        id={'edit_btn_' + row.id}
                        onClick={(e) => openDetail(row.id, 2, e)}
                      >
                        Edit
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Box className="-paginate-right">
          {/* Pagination */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={filteredData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Card>
    </Box>
  );
};
