import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Grid,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Divider,
  FormControlLabel,
  Checkbox,
  Button,
  Slide
} from '@mui/material';
import {
  CustomSelect,
  CustomTextField,
  CustomButton
} from '../Global';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { getLocalData } from 'src/utils';
import ErrorMessage from '../PopupMessage/ErrorMessage';
import Loader from '../Loader';
import {
  formatTimeOrDate,  
  getValueFromId  
} from './Const';
import { ApiService } from '../../services/ApiService';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export function BookClassDialog(props) {
  const {
    handleResetStates,
    dialog,
    onClose,
    setLoadData,
    newSchedule,
    bookClass,
    setBookClass,
    initialBookClass,
    isBookClassEdit,
    allParents,
    // eslint-disable-next-line no-unused-vars
    allServices,
    allAttendies
  } = props;
  const navigate = useNavigate();
  const BASE_URL = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}`;

  const locationId = getLocalData('locationId');
  const userId = getLocalData('userId');
  const encodedLocationId = encodeURIComponent(locationId);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [allPets, setAllPets] = useState([]);
  const [creditsAvailable, setCreditsAvailable] = useState();
  const [isCreditApplied, setIsCreditApplied] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [payLaterChecked, setPayLaterChecked] = useState(false);
  const [waitingList, setWaitingList] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [waitingNumber, setWaitingNumber] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [maxWaitList, setMaxWaitList] = useState(false)
  const [totalCredits, setTotalCredits] = useState(0);
  const [showCredit, setShowCredit] = useState(false);
  const [noOfCredit, setnoOfCredit] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [prerequisites, setPrerequisites] = useState('');
  const [trophys, setTrophys] = useState([]);
  const [trophyExist, setTrophyExist] = useState(true);
  const [petExist, setPetExist] = useState(false);
  // const [serviceData, setServiceData] = useState([]);
  const [closeDialog, setCloseDialog] = useState({
    state: false,
    isCreated: false,
    creditApplied: false,
    insufficientCredits: false
  });

  useEffect(() => {
    setPetExist(false)
    allAttendies.forEach((item) => {
      if (item.clientChildId === bookClass.pets) {
        setPetExist(true)
      }
    });
  }, [bookClass.pets]);
  console.log(petExist, 'ddsallAttendies');
  const [isError, setIsError] = useState({
    // status: false,
    // appointmentStatus: false,
    fromDate: false,
    toDate: false,
    fromTime: false,
    toTime: false,
    fromTimeFormat: false,
    toTimeFormat: false,
    occurence: false,
    description: false,
    parent: false,
    pets: false,
    intervalDays: false
  });
  //   useEffect(() => {
  //     let foundService = null;
  //     allServices.forEach((item) => {
  //       if (item.sortKey === newSchedule.serviceId) {
  //         foundService = item;
  //       }
  //     });
  //     setServiceData(foundService);
  //   }, [newSchedule.serviceId]);
  // console.log(serviceData);
  const valuesAreDifferent = () => {
    if (typeof bookClass !== typeof initialBookClass) {
      return false;
    }

    if (typeof bookClass !== 'object' || typeof initialBookClass !== 'object') {
      return bookClass === initialBookClass;
    }

    const keys1 = Object.keys(bookClass);
    const keys2 = Object.keys(initialBookClass);
    if (keys1.length !== keys2.length) {
      return false;
    }

    const keys = Object.keys(initialBookClass);
    return keys.some(
      (key) => key !== 'type' && initialBookClass[key] !== bookClass[key]
    );
  };
  useEffect(() => {

    if (bookClass.parent && bookClass.pets && newSchedule.serviceId && newSchedule.serviceCategory) {
      checkCredit()
    }
  }, [bookClass])
  const checkCredit = async () => {
    let data = {
      clientId: bookClass.parent,
      locationId: locationId,
      petId: bookClass.pets,
      categoryId: newSchedule.serviceCategory,
      serviceId: newSchedule.serviceId
    };

    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}checkout/getAllCreditAvailability`,
        data
      );

      // Now, compare the lengths and open the dialog if necessary
      if (response.statusCode && !response.error) {
        setnoOfCredit(response.data.Items)
        setShowCredit(true)
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (noOfCredit.length > 0) {
      setTotalCredits(noOfCredit[0]?.remainingSession)
    } else {
      setTotalCredits(0)
    }
    // setShowCredit(true)
  }, [noOfCredit])
  const handleOnClose = () => {
    setCloseDialog({
      isCreated: false,
      state: false,
      creditApplied: false,
      insufficientCredits: false
    });
    onClose('bookClass');
  };

  const handleCloseScreen = () => {
    if (valuesAreDifferent()) {
      setCloseDialog({ ...closeDialog, state: true });
    } else {
      handleOnClose();
      handleResetStates();
      setPetExist(false)
      setTrophyExist(true)
      setShowCredit(false)
    }
  };

  const handleDialog = (value) => {
    if (value === 'Continue') {

      setCloseDialog({
        isCreated: false,
        state: false
      });
    } else if (value === 'main-cancel') {
      handleCloseScreen();
    } else if (value === 'Cancel') {
      handleCloseScreen();
    } else if (value === 'Discard') {
      setPetExist(false)
      handleResetStates();
      handleOnClose();
      setShowCredit(false)
      setTrophyExist(true)
    } else if (value === 'Close') {
      handleOnClose();
      handleResetStates();
    }
  };

  const handleBookClassInfo = (type, value) => {
    if (type === 'parent') {
      setBookClass({ ...bookClass, [type]: value, pets: '' });
      setIsError({ ...isError, [type]: false, pets: false });
    } else if (type === 'fromDate') {
      setBookClass({
        ...bookClass,
        [type]: formatTimeOrDate('date', value),
        toDate: null,
        toTime: ''
      });
      setIsError({ ...isError, [type]: false, toDate: false });
    } else if (type === 'toDate') {
      setBookClass({
        ...bookClass,
        [type]: formatTimeOrDate('date', value),
        toTime: ''
      });
      setIsError({ ...isError, [type]: false });
    } else if (type === 'fromTime') {
      setBookClass({ ...bookClass, [type]: value });
      setIsError({ ...isError, [type]: false, fromTimeFormat: false });
    } else if (type === 'toTime') {
      setBookClass({ ...bookClass, [type]: value });
      setIsError({ ...isError, [type]: false, toTimeFormat: false });
    } else if (type === 'occurence') {
      setBookClass({ ...bookClass, [type]: value });
      setIsError({ ...isError, [type]: false, intervalDays: false });
    } else {
      setBookClass({ ...bookClass, [type]: value });
      setIsError({ ...isError, [type]: false });
    }
    if (type === 'payLater') {
      // Update the button label based on the checkbox state
      if (value) {
        setPayLaterChecked(true); // Assuming you have state for the checkbox
      } else {
        setPayLaterChecked(false);
      }
    };
  };

  function checkForEmptyValues() {
    let hasError = false;
    const updatedErrors = {};

    // Always check for empty values
    const alwaysCheck = [
      'parent',
      'pets'
    ];

    // eslint-disable-next-line no-restricted-syntax
    for (const key of alwaysCheck) {
      const value = bookClass[key];
      const isEmpty =
        value === '' ||
        value == null ||
        (Array.isArray(value) && value.length === 0);
      updatedErrors[key] = isEmpty;

      if (isEmpty) {
        hasError = true;
      }
    }

    setIsError(updatedErrors);
    return hasError;
  }
  const handleConfirmClose = (value) => {

    if (value === 'Cancel') {
      handleOnClose();
      setShowCredit(false)
      setMaxWaitList(false)
      setOpenDialog(false)
    }
    if (value === 'Save') {
      setOpenDialog(false)
      setWaitingList(true)
      setMaxWaitList(false)
    }
  }

  const getServicesBasedOnCategory = async () => {
    let categoryId = encodeURIComponent(newSchedule.serviceCategory);

    try {
      const response = await ApiService.get(
        `${BASE_URL}schedule/getAllServices?locationId=${encodedLocationId}&categoryId=${categoryId}`
      );

      if (!response.data?.error) {
        setServiceData(response.data?.Items);
      }
    } catch (error) {
      console.error(error);
      setShowErrorMessage(true)
      setErrorMessage('Something went wrong, Try again!')
      // toast.error('Something went wrong, Try again!');
    }
  };
  useEffect(() => {
    if (newSchedule.serviceCategory) {
      getServicesBasedOnCategory();
    }
  }, [newSchedule.serviceCategory]);

  const getPetTrophy = async () => {
    let encodedClientId = encodeURIComponent(bookClass.parent);
    let encodedPetId = encodeURIComponent(bookClass.pets);
    try {
      const response = await ApiService.get(
        `${BASE_URL}trophy/getTrophyByPet?clientId=${encodedClientId}&petId=${encodedPetId}`
      );

      if (!response.data?.error) {
        setTrophys(response.data?.Items)
        // setServiceData(response.data?.Items);
      }
    } catch (error) {
      console.error(error);
      setShowErrorMessage(true)
      setErrorMessage('Something went wrong, Try again!')
      // toast.error('Something went wrong, Try again!');
    }
  };
  useEffect(() => {
    if (bookClass.pets) {
      getPetTrophy();
    }
  }, [bookClass.pets]);
  // useEffect(() => {
  //   if (trophys?.length>0&&prerequisites?.length>0) {
  //     // alert('rje')
  //     const uniqueTrophyIds = [...new Set(trophys.map(obj => obj.trophyId))];
  //     const allMatching = uniqueTrophyIds.every(trophyId => prerequisites.some(item => item.id === trophyId));
  // console.log(allMatching,'fallMatching');
  //   }
  // }, [bookClass]);
  useEffect(() => {
    let foundService = null;
    serviceData.forEach((item) => {
      if (item.sortKey === newSchedule.serviceId) {
        foundService = item;
      }
    });
    setPrerequisites(foundService?.prerequisites)
  }, [serviceData]);
  
  useEffect(() => {
    let trophyIdsInDataset = trophys?.map(item => item.trophyId);
    let extractedIds = Array.isArray(prerequisites) ? prerequisites.map(item => item.id) : [];
    let allIdsExist = Array.isArray(extractedIds) && extractedIds.every(idToCheck =>trophyIdsInDataset?.includes(idToCheck));
    console.log(allIdsExist,extractedIds,trophyIdsInDataset,trophys);
    setTrophyExist(allIdsExist)
  }, [trophys]);

  console.log(trophyExist, 'trophyexistss');
  const getPetsBasedOnClient = async () => {
    let clientId = encodeURIComponent(bookClass.parent);
    try {
      const response = await ApiService.get(
        `${BASE_URL}schedule/getAllPets?clientId=${clientId}`
      );
      if (!response.data?.error) {
        setAllPets(response.data?.Items);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(response.data?.message ||
          'Something went wrong with getting Pets list');
      }
    } catch (error) {
      console.error(error);
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong, Try again!');
    }
  };

  useEffect(() => {
    if (bookClass.parent) {
      getPetsBasedOnClient();
    }
  }, [bookClass.parent]);

  //  console.log(waitingNumber,allAttendies?.length,'waitingNumber111');
  useEffect(() => {
    let credits = getValueFromId(
      bookClass.parent,
      allParents,
      'sortKey',
      'credit'
    );
    if (isCreditApplied) {
      setCreditsAvailable(credits - 1);
    } else {
      setCreditsAvailable(credits);
    }
  }, [bookClass.parent, isCreditApplied]);

  const handleAddClass = (e, type) => {

    e.preventDefault();
    if (!checkForEmptyValues()) {
      if (type === 'credits') {
        // applying credits and deducting one credit from total
        let noOfCredits = getValueFromId(
          bookClass.parent,
          allParents,
          'sortKey',
          'credit'
        );
        if (noOfCredits >= 1) {
          setCloseDialog({
            ...closeDialog,
            state: true,
            creditApplied: true,
            insufficientCredits: false
          });
          setIsCreditApplied(true);
        } else {
          setCloseDialog({
            ...closeDialog,
            state: true,
            creditApplied: true,
            insufficientCredits: true
          });
        }
      } else {
        setBtnLoading((prev) => {
          let toggleId;
          if (prev['add_schedule']) {
            // eslint-disable-next-line no-useless-computed-key
            toggleId = { ['add_schedule']: false };
          } else {
            // eslint-disable-next-line no-useless-computed-key
            toggleId = { ['add_schedule']: true };
          }
          return { ...toggleId };
        });
        if (isBookClassEdit) {
          handleChangeStatus();
        } else {
          handleBookClass();
          setLoading(true)
        }
      }
    } else {
      setShowErrorMessage(true);
      setErrorMessage('Please fill all the required fields');
    }
  };
  // useEffect(() => {
  //   handleBookClass()
  // }, [loadData]);
  //   useEffect(() => {
  //   if((allAttendies?.length===waitingNumber ||allAttendies?.length>waitingNumber) && dialog.bookClass ){
  //       setOpenDialog(true)
  //     }
  // }, [dialog.bookClass]);
  useEffect(() => {
    if (dialog.bookClass === true) {
      getEnrollmentDetail(); // Fetch waitingNumber and handle the dialog opening in getEnrollmentDetail
    }
  }, [dialog.bookClass]);

  const getEnrollmentDetail = async () => {
    let data = {
      occurrenceId: newSchedule.appointmentId,
      locationId: locationId,
    };
    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/getAppointmentDetails`,
        data
      );
      let Item = response && response?.data;
      const waiting = Item.maximumCapacity;
      const maxWaiting = (Item.maximumCapacity + Item.maximumWaitngListCapacity);
      setWaitingNumber(Item.maximumCapacity);
      // Now, compare the lengths and open the dialog if necessary
      if (allAttendies?.length === maxWaiting || allAttendies?.length > maxWaiting) {
        setMaxWaitList(true)
      } else if (allAttendies?.length === waiting || allAttendies?.length > waiting) {
        setOpenDialog(true);

      } else {
        setOpenDialog(false);
        setMaxWaitList(false)// Close the dialog if it was previously open
      }
      setWaitingNumber(waiting);
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(waitingNumber,'waitingNumber');
  // useEffect(() => {
  //   if(newSchedule.appointmentId!=''){
  //     getEnrollmentDetail();
  //   }   
  // }, [newSchedule]);

  const handleBookClass = async () => {
    const data = {
      updatedBy: userId,
      locationId: locationId,
      fromDate: formatTimeOrDate('date', newSchedule.toDate),
      type: 'occurrence',

      clientDetails: {
        payLater: bookClass.payLater,
        creditAvailable: creditsAvailable || 0,
        checkIn: '00:00',
        // status: bookClass.appointmentStatus, //
        clientParentId: bookClass.parent,
        clientChildId: bookClass.pets,
        // clientChildBirthDate: bookClass.pets,
        // clientChildProfile: bookClass.pets,
        clientParentName: getValueFromId(
          bookClass.parent,
          allParents,
          'sortKey',
          'firstName'
        ),
        clientChildName: getValueFromId(
          bookClass.pets,
          allPets,
          'sortKey',
          'firstName'
        ),
        clientChildProfile: getValueFromId(
          bookClass.pets,
          allPets,
          'sortKey',
          'profileImage'
        ),
        clientChildBirthDate: getValueFromId(
          bookClass.pets,
          allPets,
          'sortKey',
          'birthDate'
        ),
        clientEmailId: getValueFromId(
          bookClass.parent,
          allParents,
          'sortKey',
          'email'
        ),
        waitingList: waitingList
      }
    };
    if (totalCredits > 0 && !bookClass.payLater) {

      data.clientDetails.purchaseId = noOfCredit[0]?.sortKey
    }
    if (!bookClass.payLater && totalCredits <= 0) {
      navigate('/checkout', { state: { type: 'enrollment', items: [data] } })
    } else {
      const encodedOccurenceId = encodeURIComponent(newSchedule.id);
      // try {
      //   const response = await axios.put(
      //     `${BASE_URL}schedule/bookAppointment?appointmentId=${encodedOccurenceId}`,
      //     data
      //   );
      try {
        const response = await ApiService.put(
          `${BASE_URL}schedule/bookAppointment?appointmentId=${encodedOccurenceId}`, data
        );
        // setLoading(true)
        if (!response.data?.error && response?.data?.statusCode === 400) {
          setLoading(false)
          setShowErrorMessage(true);
          setErrorMessage(response.data.data.message);
          handleOnClose();
          setShowCredit(false)

        } else {

          setLoadData(true)
          setShowCredit(false)
          setLoading(false)
          setWaitingList(false)
          setCloseDialog({
            isCreated: true,
            state: true
          });
        }

        setBtnLoading(false);
      } catch (error) {
        console.error(error);
        setShowErrorMessage(true);
        setErrorMessage('Something went wrong, Try again!');
        setLoading(false)
        setBtnLoading(false);
        // toast.error('Something went wrong, Try again!');
      }
    }
    setLoadData(false)
  };

  const handleChangeStatus = async () => {    
    const data = {
      updatedBy: 'John Doe',
      locationId: locationId,
      clientId: bookClass.parent,
      occurrenceId: newSchedule.id,
      checkIn: '00:00'
    };

    // try {
    //   const response = await axios.put(
    //     `${BASE_URL}schedule/changeStatus`,
    //     data
    //   );
    try {
      const response = await ApiService.put(
        `${BASE_URL}schedule/changeStatus`, data
      );
      if (!response.data?.error) {
        setLoadData(true)
        setCloseDialog({
          isCreated: true,
          state: true
        });
      } else {
        setShowErrorMessage(true);
        setErrorMessage(response.data?.message ||
          'Something went wrong with update a class');
      }

      setBtnLoading(false);
    } catch (error) {
      console.error(error);
      setBtnLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong, Try again!');

    }
  };

  return (
    <>
      {showErrorMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowErrorMessage} />
      )}
      <Loader IsLoading={isLoading} />
      <Dialog
        className='enrollClassDialog'
        open={dialog.bookClass}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleDialog('main-cancel')}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
      >
        <DialogContent className="custom-scroll">
          <Box
            sx={{ width: '100%' }}
            paddingBottom="20px"
            className="Main-container common-form-container"
          >
            <Box className="custom-form">
              <Grid mt={0} marginBottom="8px">
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        textAlign: 'left',
                        alignItems: 'center',
                        gap: '8px'
                      }}
                    >
                      <h1 className="title primary-color">Enroll In A Class</h1>
                    </Box>
                  </Grid>

                  <Grid item>
                    <CancelOutlinedIcon
                      color="error"
                      sx={{
                        width: '32px',
                        height: '32px',
                        cursor: 'pointer'
                      }}
                      onClick={handleCloseScreen}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* <Grid container spacing={3} columns={12} mt={0.5}>
                <Grid
                  item
                  xs={12}
                  paddingTop={
                    bookClass.type == 'appointment' && '10px !important'
                  }
                >
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: 30,
                          color: '#003087'
                        },
                        '& .MuiFormControlLabel-label': {
                          color: '#000000',
                          letterSpacing: '0.3px',
                          fontWeight: 500
                        },
                        '& .MuiFormControlLabel-root': {
                          marginRight: '40px'
                        }
                      }}
                    >
                      <FormControlLabel
                        value="checkIn"
                        control={<Radio />}
                        label="Check In"
                        checked={bookClass.appointmentStatus == 'checkIn'}
                        onChange={() =>
                          setBookClass({
                            ...bookClass,
                            appointmentStatus: 'checkIn'
                          })
                        }
                      />

                      <FormControlLabel
                        value="arrivedLate"
                        control={<Radio />}
                        label="Arrived Late"
                        disabled={!isBookClassEdit}
                        checked={bookClass.appointmentStatus == 'arrivedLate'}
                        onChange={() =>
                          setBookClass({
                            ...bookClass,
                            appointmentStatus: 'arrivedLate'
                          })
                        }
                      />

                      <FormControlLabel
                        value="earlyCancel"
                        control={<Radio />}
                        label="Early Cancel"
                        disabled={!isBookClassEdit}
                        checked={bookClass.appointmentStatus == 'earlyCancel'}
                        onChange={() =>
                          setBookClass({
                            ...bookClass,
                            appointmentStatus: 'earlyCancel'
                          })
                        }
                      />

                      <FormControlLabel
                        value="lateCancel"
                        control={<Radio />}
                        label="Late Cancel"
                        disabled={!isBookClassEdit}
                        checked={bookClass.appointmentStatus == 'lateCancel'}
                        onChange={() =>
                          setBookClass({
                            ...bookClass,
                            appointmentStatus: 'lateCancel'
                          })
                        }
                      />

                      <FormControlLabel
                        value="absent"
                        control={<Radio />}
                        label="Absent"
                        disabled={!isBookClassEdit}
                        checked={bookClass.appointmentStatus == 'absent'}
                        onChange={() =>
                          setBookClass({
                            ...bookClass,
                            appointmentStatus: 'absent'
                          })
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid> */}

              <Grid
                container
                spacing={3}
                columns={12}
                mt={bookClass.type === 'appointment' ? 0 : 1}
              >
                <Grid item xs={12} sm={6}>
                  <CustomSelect
                    fullWidth
                    name="parent"
                    value={bookClass.parent}
                    onChange={handleBookClassInfo}
                    label="SELECT CLIENT*"
                    error={isError.parent}
                    helperText={isError.parent && 'Parent is required'}
                    options={allParents?.map((item) => ({
                      label: `${item.firstName}`,
                      value: item.sortKey
                    }))}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <CustomSelect
                    fullWidth
                    name="pets"
                    value={bookClass.pets}
                    onChange={handleBookClassInfo}
                    label="SELECT DOG *"
                    disabled={!bookClass.parent}
                    error={isError.pets}
                    helperText={isError.pets && 'Pet is required' || (!trophyExist && trophys?.length > 0) && 'This dog does not meet the prerequisites to enroll in this class.' || petExist && 'This dog is already enrolled in the selected class.'}
                    options={allPets?.map((item) => ({
                      label: `${item.firstName}`,
                      value: item.sortKey
                    }))}
                  />
                </Grid>
              </Grid>

              {/* <Grid container spacing={3} columns={12} mt={1}>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={8}>
                      <CustomDatePicker
                        disabled
                        label="FROM"
                        name="fromDate"
                        fullWidth
                        disablePast
                        inputFormat="MM/dd/yyyy"
                        value={bookClass.fromDate}
                        onChange={handleBookClassInfo}
                        error={isError.fromDate}
                        helperText={
                          isError.fromDate &&
                          (bookClass.fromDate > bookClass.toDate
                            ? 'From date must be smaller than To date'
                            : 'From date is required')
                        }
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <AutoComplete
                        disabled
                        fullWidth
                        freeSolo
                        disableClearable
                        label="Time"
                        name="fromTime"
                        options={generateTimeOptions()}
                        value={bookClass.fromTime}
                        onChange={handleBookClassInfo}
                        error={isError.fromTime || isError.fromTimeFormat}
                        helperText={
                          isError.fromTime &&
                          (isError.fromTimeFormat
                            ? 'Plese enter correct format e.g (12:00 AM/PM)'
                            : 'Time is required')
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={8}>
                      <CustomDatePicker
                        disabled
                        label="TO"
                        name="toDate"
                        fullWidth
                        disablePast
                        // disabled={bookClass.fromDate == null}
                        minDate={bookClass.fromDate && bookClass.fromDate}
                        inputFormat="MM/dd/yyyy"
                        value={bookClass.toDate}
                        onChange={handleBookClassInfo}
                        error={isError.toDate}
                        helperText={
                          isError.toDate &&
                          (!isToDateGreater()
                            ? 'To date must be greater than From date'
                            : 'To date is required')
                        }
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <AutoComplete
                        disabled
                        fullWidth
                        freeSolo
                        label="Time"
                        name="toTime"
                        value={bookClass.toTime}
                        onChange={handleBookClassInfo}
                        options={generateTimeOptions()}
                        // disabled={
                        //   !bookClass.fromDate ||
                        //   !bookClass.toDate ||
                        //   !bookClass.fromTime
                        // }
                        disableClearable
                        error={isError.toTime || isError.toTimeFormat}
                        helperText={
                          isError.toTime &&
                          (isError.toTimeFormat
                            ? 'Plese enter correct format e.g (12:00 AM/PM)'
                            : isToDateGreater()
                            ? 'Time is required'
                            : 'To time must be greater than From time')
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={3} columns={12} mt={1}>
                <Grid item xs={12} sm={6}>
                  <CustomSelect
                    disabled
                    fullWidth
                    name="occurence"
                    value={bookClass.occurence}
                    onChange={handleBookClassInfo}
                    label="OCCURENCE"
                    error={isError.occurence}
                    helperText={isError.occurence && 'Occurence is required'}
                    options={[
                      {
                        label: 'Does Not Repeat',
                        value: 'noRepeat'
                      },
                      {
                        label: 'Daily',
                        value: 'daily'
                      },
                      {
                        label: 'Weekly',
                        value: 'weekly'
                      },
                      {
                        label: 'Monthly',
                        value: 'monthly'
                      }
                    ]}
                  />
                </Grid>

                <Grid item xs={12} sm={6}></Grid>
              </Grid> */}

              {/* {bookClass.occurence == 'weekly' ? (
                <Grid container maxWidth={768} spacing={3} columns={12} mt={1}>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <CustomSelect
                          disabled
                          fullWidth
                          name="intervalValue"
                          value={bookClass.intervalValue}
                          onChange={handleBookClassInfo}
                          label="Interval"
                          options={[
                            {
                              label: '1',
                              value: 1
                            },
                            {
                              label: '2',
                              value: 2
                            },
                            {
                              label: '3',
                              value: 3
                            },
                            {
                              label: '4',
                              value: 4
                            }
                          ]}
                        />
                      </Grid>

                      <Grid item xs={8}>
                        <CustomSelect
                          disabled
                          fullWidth
                          defaultValue="week"
                          label="Every"
                          options={[
                            {
                              label: 'Week',
                              value: 'week'
                            }
                          ]}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <div className="days-of-week-container">
                      {daysOfWeek.map(({ label, value }) => (
                        <div
                          key={value}
                          className={`day-circle ${
                            bookClass.intervalDays.includes(value)
                              ? 'selected'
                              : ''
                          }`}
                          // onClick={() =>  handleToggleDay(value)}
                          //api not supporting custom occurence for book class, now as same as enrollment
                        >
                          {label}
                        </div>
                      ))}
                    </div>
                  </Grid>
                </Grid>
              ) : null} */}

              <Grid container spacing={3} columns={12} mt={1}>
                <Grid item xs={12}>
                  <CustomTextField
                    fullWidth
                    multiline
                    rows="6"
                    type="text"
                    name="description"
                    value={bookClass.description}
                    onChange={handleBookClassInfo}
                    label="NOTES"
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={3}
                columns={12}
                mt={0}
                className="fullWidth-btn"
              >
                <Grid item xs={12} sm={6} paddingTop={'10px !important'}>
                  {/* <FormControlLabel
                    control={
                      <Checkbox
                        // defaultChecked={!bookClass.payLater}
                        // className="check-box-span"
                        // color="default"
                        checked={bookClass.payLater}
                        onChange={(e) =>
                          handleBookClassInfo('payLater', e.target.checked)
                        }
                        // sx={{
                        //   color: '#003087',
                        //   '&.Mui-checked': {
                        //     color: '#003087'
                        //   }
                        // }}
                      />
                    }
                    label="Pay Later"
                  /> */}

                  <FormControlLabel
                    control={<Checkbox checked={bookClass.payLater}
                      onChange={(e) =>
                        handleBookClassInfo('payLater', e.target.checked)
                      }
                      disabled={(!trophyExist && trophys?.length > 0) || petExist}
                      name="payLater" />}
                    label={<span style={{ fontWeight: 'bold', color: 'black' }}>Pay Later</span>}
                  />

                </Grid>
                {showCredit && (
                  <Grid
                    item
                    xs={12}

                    sm={6}
                    display="flex"
                    justifyContent="flex-end"
                    paddingRight="16px"
                    paddingTop={'10px !important'}
                  >

                    <Typography variant="body2" color="#003087">
                      {totalCredits > 0
                        ? <Typography variant="body2" fontWeight={800} fontSize={16} color="green">Credit Available: {totalCredits}  </Typography>
                        : <Typography variant="body2" fontWeight={800} fontSize={16} color="red">No credits available for this service </Typography>
                      }
                    </Typography>

                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} sm={12} className={`${trophyExist || !petExist || (!trophyExist && trophys?.length === 0) ? 'bookClassBtn' : 'bookClassBtndisabled'}`}>
                <CustomButton
                  variant="outline"
                  loading={btnLoading['add_schedule']}
                  onClick={handleAddClass}
                  disabled={(!trophyExist && trophys?.length > 0) || petExist}
                  style={{ color: trophyExist || !petExist ? 'inherit' : 'grey' }}
                >
                  {bookClass.payLater || totalCredits > 0 ? 'Book Now' : 'Proceed to Pay'}
                </CustomButton>
              </Grid>
              {/* <Grid
                container
                spacing={2}
                columns={12}
                mt={1}
                className="fullWidth-btn"
              >
                {bookClass.payLater ? (
                  <Grid item xs={12}>
                    <CustomButton
                      variant="contained"
                      loading={btnLoading['add_schedule']}
                      onClick={handleAddClass}
                    >
                      Book Now
                    </CustomButton>
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12} sm={6}>
                      <CustomButton
                        variant="contained"
                        loading={btnLoading['apply_credit']}
                        disabled={btnLoading['add_schedule'] || isCreditApplied}
                        onClick={(e) => handleAddClass(e, 'credits')}
                      >
                        Apply Credit
                      </CustomButton>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <CustomButton
                        variant="outline"
                        loading={btnLoading['add_schedule']}
                        onClick={handleAddClass}
                      >
                        Proceed to Pay
                      </CustomButton>
                    </Grid>
                  </>
                )}
              </Grid> */}

              {/* <Grid container spacing={3} columns={12} mt={0}>
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="center"
                  className="underline-btn"
                  paddingTop={'10px !important'}
                >
                  <Button
                    className="no-hover-btn"
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <AddIcon fontSize="small" />
                    Book Another Class
                  </Button>
                </Grid>
              </Grid> */}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={closeDialog.state}
        TransitionComponent={Transition}
        keepMounted
        onClose={() =>
          handleDialog(closeDialog.creditApplied ? 'Continue' : 'Cancel')
        }
        aria-describedby="alert-dialog-slide-description"
      >
        {closeDialog.creditApplied ? null : (
          <DialogTitle className="alert-title align-center">
            {closeDialog.isCreated ? 'Congratulations!' : 'Save Changes'}
          </DialogTitle>
        )}
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!closeDialog.isCreated ? (
                <>
                  {closeDialog.creditApplied ? (
                    <Typography className="title-text-sub">
                      {closeDialog.insufficientCredits
                        ? 'Insufficient Credits'
                        : 'Credits Successfully Applied!'}
                    </Typography>
                  ) : (
                    <Typography className="title-text-sub">
                      You have unsaved changes, Do you want to
                      <Typography> save them?</Typography>
                    </Typography>
                  )}
                </>
              ) : (
                <Typography className="title-text-sub">
                  {`Class has been ${isBookClassEdit ? 'updated' : 'booked'
                    } successfully!`}
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          {closeDialog.creditApplied ? (
            <Button
              className="font-17 text-black no-hover"
              onClick={() => handleDialog('Continue')}
            >
              Close
            </Button>
          ) : (
            <>
              <Button
                className="font-17 text-black no-hover"
                onClick={() =>
                  handleDialog(closeDialog.isCreated ? 'Close' : 'Discard')
                }
              >
                {closeDialog.isCreated ? 'Close' : 'Discard changes'}
              </Button>

              {!closeDialog.isCreated && (
                <Divider className="vertical-divider" />
              )}
              {!closeDialog.isCreated && (
                <Button
                  className="font-17 no-hover"
                  onClick={() => handleDialog('Continue')}
                >
                  Go back to Editing
                </Button>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        // onClose={() => handleConfirmClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-scheduling">
                The selected class is already filled to its
                <Typography className="title-text-scheduling">maximum capacity. Do you want to add the</Typography>
                <Typography className="title-text-scheduling"> dog to the waitlist?</Typography>
              </Typography>
            </Box>

          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 cancel-btn-scheduling no-hover"
            onClick={() => handleConfirmClose('Cancel')}
          >
            Cancel
          </Button>
          <Divider className="vertical-divider" />

          <Button
            className="font-17 no-hover"
            onClick={() => handleConfirmClose('Save')}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={maxWaitList}
        TransitionComponent={Transition}
        keepMounted
        // onClose={() => handleConfirmClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-scheduling">
                The selected class and the maximum waitlist
                <Typography className="title-text-scheduling">are already filled to their maximum capacity.</Typography>
                <Typography className="title-text-scheduling">Do you want to override the maximum </Typography>
                <Typography className="title-text-scheduling"> waitlist and add the dog to the waitlist? </Typography>
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 cancel-btn-scheduling no-hover"
            onClick={() => handleConfirmClose('Cancel')}
          >
            Cancel
          </Button>
          <Divider className="vertical-divider" />

          <Button
            className="font-17 no-hover"
            onClick={() => handleConfirmClose('Save')}
          >
            Override
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}