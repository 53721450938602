/* eslint-disable spaced-comment */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/named */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
import {
    Button,
    Box,
    Typography,
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Slide
  } from '@mui/material';
  import CohortsTable from './CohortsTable';
  import React, { useEffect, useState, useImperativeHandle } from 'react';
  import Loader from '../../../components/Loader';
  // import { toast } from 'react-toastify';
  import axios from 'axios';
  import { getLocalData, setFullLocationId } from 'src/utils';
  import ErrorMessage from '../../../components/PopupMessage/ErrorMessage';
  import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
  
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });
  
  function CohortsList({ ref, setShowScreen, showScreen }) {
    const [CohortList, setCohortList] = useState([]);
    const [dataCount, setdataCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [ispopup, setIsPopup] = useState(false);
    const userId = sessionStorage.getItem('UserId');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [btnLoading, setBtnLoading] = useState(false);
    // const [franchiseeId, setFranchiseeId] = useState(false);
    let [filters, setSidebarFilters] = useState({
        status: 'All',
    createdBy: null
    });
    const locationId = setFullLocationId(getLocalData('locationId'));
    const [selectedCohortList, setSelectedCohortList] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [showSucessMessage, setShowSuccessMessage] = useState(false);
    const [sucessMessage, setSuccessMessage] = useState('');
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    // check updated or saved
    const [isUpdated, setIsUpdated] = useState(false);
  
    // Get locations method to get the list of location
    const getAllFranchisorCohorts = async () => {
      // const encodedFranchiseeId = encodeURIComponent(franchiseeId);
      // Now show the loader
      setIsLoading(false);
      if (!ispopup) setIsLoading(true);
      setIsLoading(false);
      let filtersParams = JSON.parse(JSON.stringify(filters));
      Object.keys(filtersParams).forEach((key) => {
        if (
          filtersParams[key] === null ||
          filtersParams[key] === 'All' ||
          filtersParams[key] === 'ALL' ||
          filtersParams[key] === '' ||
          filtersParams[key] === 'undefined' ||
          filtersParams[key] === undefined
        ) {
          delete filtersParams[key];
        }
      });
  
      let queryString = Object.keys(filtersParams)
        .map((key) => key + '=' + filtersParams[key])
        .join('&');
  
      await fetch(`${process.env.REACT_APP_API_END_POINT}pinpoint/getAllFranchisorCohorts?${queryString}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      })
        .then((response) => response.json())
        
        .then((data) => {
        
          if (data.statusCode && !data.error) {
            // Set the list of location
            setCohortList(data.data.Items);
           
            // Set the list of location count
            setdataCount(data.data.TotalCount);
            // Now hide the loader
            setIsLoading(false);
            // let elm = document.getElementsByClassName('page-header-text')[0];
            // if (elm) elm.innerHTML = 'Location Management';
          }
        });
    };
    console.log(CohortList, 'locationList');
    // setFranchiseeId(locationList.partitionKey);
    // gets call the method to get the data
  
    useEffect(() => {
      // Not call the get location method
      getAllFranchisorCohorts();
    }, [JSON.stringify(filters)]);
  
    // Getting hooks from parent to child
    useImperativeHandle(ref, () => ({
      refreshLocationData: () => {
        // Not call the get location method again
        getAllFranchisorCohorts();
      }
    }));
  
    // Handle the event from child to parent. It is being used for refresh the data after edit or delete
    const refreshLocationData = () => {
      // When popup is open then not call the loader
      setIsPopup(true);
      // Not call the get location method again
      getAllFranchisorCohorts();
    };
  
    const handleBulkAdd = async () => {
      setIsUpdated(false);
      setOpen(true);
      
    };

    const confirmBulkUpdate = async () => {
      setOpen(false);
      setBtnLoading(true);
      // show loader
  
      setIsLoading(true);
      const data = {
        // isActive: selectedStatus === 'Active',
        status: selectedStatus,
        cohortIds: selectedCohortList
      };
  
      try {

        const response = await axios.post(
          `${process.env.REACT_APP_API_END_POINT}pinpoint/updateCohortsStatus`,
          data
        );
  
        if (!response.data?.error) {
          setShowSuccessMessage(true);
          setSuccessMessage('All the selected cohorts have been updated successfully!');
          setSelectedStatus('');
          setSelectedCohortList([]);
          setIsUpdated(true);
          // setOpen(true);
          // getLocations();
        } else {
          setShowErrorMessage(true);
          setErrorMessage(
            response.data?.message ||
              'Something went wrong with update Cohorts status'
          );
  
   
        }
        // show loader
        setIsLoading(false);
        setBtnLoading(false);
      } catch (error) {
        console.error(error);
        setBtnLoading(false);
        // show loader
        setIsLoading(false);
        setShowErrorMessage(true);
        setErrorMessage('Something went wrong, Try again!');
        // toast.error('Something went wrong, Try again!');
      }
    };
  
    const handleClose = (val) => {
      if (val === 'Save') {
        setOpen(false);
        confirmBulkUpdate();
      } else if (val === 'Discard') {
        setOpen(false);
        if (isUpdated) {
          getAllFranchisorCohorts();
          setIsUpdated(false);
        }
      }
    };
  
    return (
      <Grid className="card-table-main" xs={12}>
        {showErrorMessage && (
          <ErrorMessage
            message={errorMessage}
            setShowMessage={setShowErrorMessage}
          />
        )}
  
        {showSucessMessage && (
          <SuccessMessage
            message={sucessMessage}
            setShowMessage={setShowSuccessMessage}
            closePopup={getAllFranchisorCohorts}
          />
        )}
        <Loader IsLoading={isLoading} />
        <CohortsTable
          CohortList={CohortList}
          sideBarfilters={filters}
          setSidebarFilters={setSidebarFilters}
          refreshLocationData={refreshLocationData}
          dataCount={dataCount}
          setShowScreen={setShowScreen}
          showScreen={showScreen}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          handleBulkAdd={handleBulkAdd}
          getAllFranchisorCohorts={getAllFranchisorCohorts}
          btnLoading={btnLoading}
          selectedCohortList={selectedCohortList}
          setSelectedCohortList={setSelectedCohortList}
          open={open}
          setIsLoading={setIsLoading}
          setOpen={setOpen}
        />
  
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => handleClose('Cancel')}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle className="alert-title align-center">
            {isUpdated ? 'Congratulations!' : 'Activate/Deactivate Selected Items'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              className="alert-decription align-center"
            >
              <Box display="flex" justifyContent="center" direction="column">
                {!isUpdated ? (
                  <Typography className="title-text-sub">
                  Are you sure you want to mark Activate/Deactivate,
                  <Typography className="title-text-sub">
                  all the selected items?
                </Typography>
                </Typography>
                ) : (
                  <Typography className="title-text-sub">
                    All the selected cohorts have been updated successfully!
                  </Typography>
                )}
              </Box>
            </DialogContentText>
          </DialogContent>
          <Divider />
          <DialogActions className="alert-button-control">
            <Button
              className="font-17 text-black no-hover"
              onClick={() => handleClose('Discard')}
            >
              {isUpdated ? 'Close' : 'Cancel'}
            </Button>
            {!isUpdated && <Divider className="vertical-divider" />}
            {!isUpdated && (
              <Button
                className="font-17 no-hover"
                onClick={() => handleClose('Save')}
              >
                Confirm
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
  
  export default React.forwardRef(CohortsList);
  