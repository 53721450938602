import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Menu,
  MenuItem,
  FormHelperText,
  Slide,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  DialogTitle
} from '@mui/material';

import { CustomDatePicker } from 'src/components/CustomDatePicker';
import { getLocalData } from '../../../utils';
import Loader from '../../../components/Loader';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Header from 'src/components/Header';
import { ApiService } from '../../../services/ApiService';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const Holiday = (props) => {
  const { setShowScreen } = props;
  //   const [isFiendUpdated, setIsFiendUpdated] = useState(false);
  const locationId = getLocalData('locationId');
  let encodedLocationId = encodeURIComponent(locationId);
  const userDetails = getLocalData('UserDetails');
  const userName = JSON.parse(userDetails);
  const [anchor, setAnchor] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [tableInfo, setTableInfo] = useState([]);
  const [openAddTimeOff, setOpenAddTimeOff] = useState(false);
  const [openAddTimeOffDialog, setOpenAddTimeOffDialog] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [holidayCancelled, setHolidayCancelled] = useState(false);
  const [holidayUpdated, setHolidayUpdated] = useState(false);
  const [holidayId, setHolidayId] = useState('');
  const [open, setOpen] = useState(false);

  /* States for errors */
  const [titleError, setTitleError] = useState('');
  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');

  console.log(startDate);
  console.log(endDate);
  useEffect(() => {
    holidayInfo();
    setEndDateError('');
  }, [holidayUpdated]);

  /* const holidayInfo = () => {
    let url = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/getLocatinHoliday?locationId=${encodedLocationId}`;
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data.data.Items[0])
        console.log(data);
        setTableInfo(data.data.Items);
      });
  }; */

  const holidayInfo = async () => {
    try {
      let data = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/getLocatinHoliday?locationId=${encodedLocationId}`
      );
      if (data.statusCode === 200) {
        console.log(data);
        setTableInfo(data.data.Items);
      }
      console.log(data,"datadata");
    }catch(error){
      console.log(error);
    }
  };

  const clearFields = () => {
    setTitle('');
    setStartDate(null);
    setEndDate(null);
  };

  const handleClose = () => {
    setOpenAddTimeOffDialog(true);
  };

  const closeMenu = () => {
    setAnchor(null);
  };

  const openDialog = (action) => {
    /* let valid = validateForm();
    if (valid) {
      return 0;
    }
    // eslint-disable-next-line no-else-return
    else {
      setOpenAddTimeOff(true);
      setOpenAddTimeOffDialog(true);
    }
    return 1; */
    if (action === 'Cancel Holiday') {
      // Skip validation for "Cancel Holiday" action
      setOpenAddTimeOff(true);
      setOpenAddTimeOffDialog(true);
    } else {
      let valid = validateForm();
      if (valid) {
        return 0;
      }
      setOpenAddTimeOff(true);
      setOpenAddTimeOffDialog(true);
    }
    return 1;
  };

  const closeDialog = () => {
    setOpenAddTimeOff(false);
    setOpenAddTimeOffDialog(false);
  };

  const openMenu = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchor(event.currentTarget);
  };

  const onMenuItemClick = (value, event) => {
    event.preventDefault();
    event.stopPropagation();
    setHolidayCancelled(true);
    // openDialog();
    openDialog('Cancel Holiday'); // Pass 'Cancel Holiday' as the action
    setHolidayId(value);
    setAnchor(null);
  };

  const handleChange = (event) => {
    setTitleError('');
    setTitle(event);
  };
  //   const handleTemplateClose = () => {
  //     setOpen(true);
  //     if (isFiendUpdated) {
  //       setOpen(true);
  //     } else {
  //       setShowScreen(0);
  //       setOpen(false);
  //     }
  //   };

  const handleConfirmClose = (val) => {
    if (val === 'Save') {
      setOpen(false);
    } else if (val === 'Discard') {
      setOpen(false);
      setShowScreen(0);
      //   setIsFiendUpdated(false);
    }
  };

  const handleStartDate = (value) => {
    setStartDateError('');
    setStartDate(value);
  };

  const handleEndDate = (value) => {
    setEndDate(value);
  };

  /* const addHoliday = () => {
    setIsLoading(true);
    const body = JSON.stringify({
      title: title,
      locationId: locationId,
      createdBy: userName.name,
      startDate: startDate,
      endDate: !endDate ? startDate : endDate,
      timeZone: 'America/New_York'
    });
    let url = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/addLocationHolidays`;
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      body: body
    })
      .then((response) => response.json())
      .then((data) => {
        clearFields();
        setHolidayUpdated(!holidayUpdated);
        setIsLoading(false);
        closeDialog();
        console.log(data);
      });
  }; */
  const addHoliday = async () => {
    setIsLoading(true);
    try {
    const body = {
      title: title,
      locationId: locationId,
      createdBy: userName.name,
      startDate: startDate,
      endDate: !endDate ? startDate : endDate,
      timeZone: 'America/New_York'
    };
    // let url = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/addLocationHolidays`;
    const apiResponse = await ApiService.post(`${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/addLocationHolidays`, body)
    if (apiResponse.statusCode === 200) {
      clearFields();
      setHolidayUpdated(!holidayUpdated);
      setIsLoading(false);
      closeDialog();
      console.log(apiResponse);
    } else {
      setIsLoading(false);
    }
  } catch (error) {
    console.log(error);
    setIsLoading(false);
  }
    
  };

  const handleCancelHoliday = (val) => {
    setIsLoading(true);
    if (val === 'Yes') {
      cancelHoliday(holidayId);
      closeDialog();
    } else {
      closeDialog();
      setIsLoading(false);
    }
  };

  /* const cancelHoliday = () => {
    setHolidayCancelled(false);
    const body = JSON.stringify({
      locationId: locationId,
      holidayId: holidayId
    });

    let url = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/cancelLocatinHoliday`;
    fetch(url, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      body: body
    })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        setHolidayUpdated(!holidayUpdated);
        console.log(data);
      });
  }; */

  const cancelHoliday = async () => {
    setHolidayCancelled(false);
    try{
    const body = {
      locationId: locationId,
      holidayId: holidayId
    };

    // let url = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/cancelLocatinHoliday`;
    const apiResponse = await ApiService.delete(`${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/cancelLocatinHoliday`, body)
    if (apiResponse.statusCode === 200) {
      setIsLoading(false);
      setHolidayUpdated(!holidayUpdated);
      console.log(apiResponse);
    } else {
      setIsLoading(false);
    }
  } catch (error) {
    console.log(error);
    setIsLoading(false);
  }
    
  };
  const holidayAlreadyCreated = () => {
    // Check if a holiday with the same start date already exists in the tableInfo array
    return tableInfo.some((data) => data.startDate === startDate);
  };

  const validateForm = () => {
    let hasErrors = false;

  if (!title) {
    setTitleError('Title is required');
    hasErrors = true;
  } else {
    setTitleError('');
  }

  if (!startDate) {
    setStartDateError('Select From Date');
    hasErrors = true;
  } else {
    setStartDateError('');
  }

  if (!hasErrors) {
    // Check if a holiday is already created (you should implement this check)
    if (holidayAlreadyCreated()) {
      setStartDateError('Holiday is already created'); // Set the error message
      hasErrors = true;
    }
  }

  return hasErrors;
  };

  const staffTimeOff = () => {
    console.log('startDate:', startDate);

  if (!startDate) {
    const valid = validateForm();
    console.log('valid:', valid);
    
    if (valid) {
      return 0;
    }
  }

  addHoliday();
  return 1;
  };

  const handleAddHoliday = (val) => {
    if (val === 'Yes') {
      staffTimeOff();
      closeDialog();
    } else {
      closeDialog();
    }
  };

  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  }

  return (
    <>
      <Loader IsLoading={isLoading} />
      <Container maxWidth="100%" className="custom-container">
        {/* <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    sx={{ mt: 2 }}
                >
                    <Grid item>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Box onClick={() => handleTemplateClose()} className="back-arrow-close">
                                <svg
                                    width="27"
                                    height="19"
                                    viewBox="0 0 27 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M26.625 7.90885H5.96042L11.1812 2.67344L9.125 0.617188L0.375 9.36719L9.125 18.1172L11.1812 16.0464L5.96042 10.8255H26.625V7.90885Z"
                                        fill="#003087"
                                    />
                                </svg>
                            </Box>
                            <Typography className="header-blue-32">
                                Holiday
                            </Typography>

                        </Box>

                    </Grid>

                </Grid> */}
        <Header title="Holiday" />
        <Grid container sx={{ mt: 2 }} spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="Title"
              label="TITLE"
              type="text"
              value={title}
              onChange={(e) => handleChange(e.target.value)}
              // error={Boolean(titleError)}
              helperText={titleError}
              className="input-field-styling required-field"
              InputLabelProps={{
                className: 'label-style-create-franchisee required-label'
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} />
          <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
            <CustomDatePicker
              label={<span className="required-label">FROM</span>}
              fieldValue={startDate}
              fullWidth
              disablePast
              name="startDate"
              onChange={handleStartDate}
              error={Boolean(startDateError)}
              className="required-field show"
            />
            {startDateError && (
              <FormHelperText>{startDateError}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
            <CustomDatePicker
              label={<span>TO</span>}
              fieldValue={!endDate ? startDate : endDate}
              name="endDate"
              fullWidth
              disablePast
              onChange={handleEndDate}
              error={Boolean(endDateError)}
              className="show"
            />
            {endDateError && <FormHelperText>{endDateError}</FormHelperText>}
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className="button-style-add-staff field-top-margin-edit-location"
              onClick={openDialog}
              sx={{ marginTop: '20px' }}
            >
              <Typography className="submit-button-text">
                Add Holiday
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Typography className="header-blue-22" sx={{ mt: 2 }}>
          All Holiday
        </Typography>
        <TableContainer className="client-table-container table-border-styling">
          <Table>
            <TableHead>
              <TableRow className="table-header">
                <TableCell>Title</TableCell>
                <TableCell>From</TableCell>
                <TableCell>To</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {tableInfo && tableInfo.length !== 0 ? (
                tableInfo.map((data) => (
                  <TableRow className="background-blue">
                    <TableCell>{data.title}</TableCell>
                    <TableCell>{formatDate(data.startDate.replaceAll('-','/'))}</TableCell>
                    <TableCell>{formatDate(data.endDate.replaceAll('-','/'))}</TableCell>
                    <TableCell>
                      <MoreVertIcon
                        onClick={(e) => openMenu(e)}
                        color="grey"
                        variant="contained"
                        className="three-dots-icon"
                      />
                      <Menu
                        open={Boolean(anchor)}
                        anchorEl={anchor}
                        onClose={closeMenu}
                        keepMounted
                        PaperProps={{
                          style: {
                            maxHeight: 40 * 5,
                            width: '25ch'
                          }
                        }}
                      >
                        <MenuItem
                          className="edit-btn"
                          onClick={(e) => onMenuItemClick(data.sortKey, e)}
                        >
                          Cancel Holiday
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell />
                  <TableCell>No Holiday Has been added</TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>

      {openAddTimeOff && (
        <Dialog
          open={openAddTimeOffDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => handleClose('Cancel')}
          aria-describedby="details-dialog"
          className="dialog-box-right-addTask AddStaff"
          // maxWidth="sm"
        >
          <DialogContent sx={{ backgroundColor: '#FFFFFF ! important' }}>
            <DialogContentText
              id="alert-dialog-slide-description"
              className="alert-decription align-center"
            >
              <Box display="flex" justifyContent="center" direction="column">
                {!holidayCancelled ? (
                  <Typography className="title-text-sub">
                    Are you sure you want to add holiday?
                  </Typography>
                ) : (
                  <Typography className="title-text-sub">
                    Are you sure you want to cancel holiday?
                  </Typography>
                )}
              </Box>
            </DialogContentText>
          </DialogContent>
          <Divider />
          <DialogActions className="alert-button-control">
            <Button
              className="font-17 text-black no-hover"
              onClick={() =>
                !holidayCancelled
                  ? handleAddHoliday('Yes')
                  : handleCancelHoliday('Yes')
              }
            >
              <Typography className="orange-text-dialog">Yes</Typography>
            </Button>
            <Divider className="vertical-divider" />

            <Button
              className="font-17 no-hover"
              onClick={() =>
                !holidayCancelled
                  ? handleAddHoliday('No')
                  : handleCancelHoliday('No')
              }
            >
              <Typography className="blue-text-dialog">No</Typography>
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleConfirmClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          Discard Changes?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                You have unsaved changes, what do you want
                <Typography className="title-text-sub"> to do? </Typography>
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleConfirmClose('Discard')}
          >
            Discard changes
          </Button>
          <Divider className="vertical-divider" />

          <Button
            className="font-17 no-hover"
            onClick={() => handleConfirmClose('Save')}
          >
            Go back to editing
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Holiday;
