import { Helmet } from 'react-helmet-async';
import { Button, Grid, Box, Autocomplete, TextField } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
// import { HeaderContext } from 'src/contexts/HeaderContext';
import Footer from 'src/components/Footer';
import { useNavigate } from 'react-router-dom';
import GlobalData from '../../../global';
import Header from '../../../components/Header';
import Reports from './Report';
import { LocationIcon } from 'src/assets/SVG/SvgIcons';
import { getLocalData } from '../../../utils';
import { ApiService } from 'src/services/ApiService';
import { setLocalData } from 'src/utils/index';
import SwitchLocationPopup from './SwitchLocationPopup';
import { makeStyles } from '@material-ui/core/styles';
import { UserContext } from 'src/contexts/UserContext';

function Home() {
  const navigate = useNavigate();

  const useAutocompleteStyles = makeStyles({
    inputRoot: {
      // borderRadius: 10, // or any other value you prefer
    },
    option: {
      '&:hover': {
        color: 'white'
        // or any other color you prefer
      }
    },
    clearIndicator: {
      color: '#003087', // or any other color you prefer
      backgroundColor: '#F8FBFF',
      '&:hover': {
        backgroundColor: '#F8FBFF'
      }
    }
  });
  // Set the header text
  // const { setHeaderText } = useContext(HeaderContext);
  // setHeaderText('Home');
  useEffect(() => {
    if (
      getLocalData('userRole') === 'Front Desk' ||
      getLocalData('userRole') === 'Trainer'
    ) {
      navigate('/staff-dashboard');
    }
  }, []);
  // Get the title from global data
  const title = GlobalData[0].title;
  const [locationOptions, setLocationOptions] = useState([]);

  const [locationName, setLocationName] = useState('');
  const [toggleLocationIcon, setToggleLocationIcon] = useState(false);

  const [openSwitchLocationPopup, setOpenSwitchLocationPopup] = useState(false);
  const autoCompleteClasses = useAutocompleteStyles();

  // get user role and provide access of that roles
  const franchiseeId = getLocalData('franchiseeId');
  const isFranchisor = getLocalData('isFranchisor');
  const userRole = getLocalData('userRole');
  const userId = getLocalData('userId');
  const locationIdAvailable = getLocalData('locationId');
  const {isNotification, setNotification} = useContext(UserContext)

  useEffect(async () => {
    // check if user is login or not
    let isLogin = sessionStorage.getItem('Login_Sucess');
    if (isLogin !== 'true') {
      navigate('/login');
    }
    // Now call the get locations api
    await getLocationList();
  }, []);

  // method to get the location list
  const getLocationList = async () => {
    let getLocationsUrl = `${process.env.REACT_APP_API_END_POINT}locations/getUserLocations`;
    let data = {
      userId: userId,
      franchiseeId: franchiseeId,
      role: userRole === 'Super Manager' ? 'Manager' : userRole,
      isFranchisor: isFranchisor
    };
    try {
      let response = await ApiService.post(getLocationsUrl, data);
      let Item = response?.data?.Items ?? [];
      // setLocationName(Item[0]?.locationName);
      setLocationOptions(Item);

      // Check if location is available in local storage then do not set the location by default, after location switch and revisit, default going to be set
      if ((!locationIdAvailable || locationIdAvailable === 'undefined') && Item[0]?.locationId) {
        setLocationName(Item[0]?.locationName);
        setLocalData('locationId', Item[0]?.locationId ?? '');
        setLocalData('locationName', Item[0]?.locationName ?? '');
        setLocalData('locationStatus', Item[0]?.status ?? '');
      } else {
        let lname = localStorage.getItem('locationName');
        if (lname) setLocationName(lname);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // method to handle the menu item click

  const onMenuItemClick = (event) => {
    const { locationId, locationName, status } = event.target.value;

    // Set the location name
    if (locationName) {
      setLocationName(locationName);
      // set the location name in local storage
      localStorage.setItem('locationName', locationName);
      localStorage.setItem('locationStatus', status);
    }

    // check if location is avaibale then set into the local storage
    if (locationId) {
      // set the location in local storage
      localStorage.setItem('locationId', locationId);
    }
  };
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Box sx={{ paddingX: '30px' }}>
        <Header title="Home" />
      </Box>
      {/* Now draw the location icon to change the location */}
      {isFranchisor === 'false' && (
        <Grid container spacing={2} xs={12}>
          <Grid item xs={12}>
            <Box className="d-flex justify-content-end mb-5">
              {locationName && (
                <Box className="page-header-text font-20 align-self-center d-flex justify-content-end mr-10 border-orange weight-400">
                  {locationName}
                </Box>
              )}

              {locationOptions &&
                locationOptions.length > 0 &&
                (toggleLocationIcon ? (
                  <Box sx={{ background: 'white' }} mr={1}>
                    <Autocomplete
                      classes={autoCompleteClasses}
                      onChange={(event, newValue) => {
                        if (newValue && newValue) {
                          setNotification(!isNotification);
                          onMenuItemClick({
                            target: { name: 'locationType', value: newValue }
                          });
                          setToggleLocationIcon(false);
                        } else {
                          onMenuItemClick({
                            target: { name: 'locationType', value: '' }
                          });
                          setToggleLocationIcon(false);
                        }
                      }}
                      onBlur={() => {
                        setToggleLocationIcon(false); // This will run when you click outside the Autocomplete component
                      }}
                      className="status-field field-label-css custom-field home_searchbox"
                      options={locationOptions}
                      getOptionLabel={(option) => option?.locationName || ''}
                      sx={{
                        width: '200px',
                        borderRadius: '19px',
                        padding: '0px !important',

                        '& fieldset.MuiOutlinedInput-notchedOutline': {
                          border: '1px solid #e35205 !important'
                        }
                      }}
                      style={{ padding: '0px' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={params?.label || 'Search Location'}
                          InputLabelProps={{className:'home_mt-4'}}
                          sx={{ paddingTop: '3px !important' }}
                        />
                      )}
                    />
                  </Box>
                ) : (
                  <Button
                    disableRipple
                    startIcon={<LocationIcon />}
                    className={`list-btn-link no-hover cursor-pointer p-0`}
                    onClick={() => setToggleLocationIcon(true)}
                  />
                ))}
            </Box>
          </Grid>
        </Grid>
      )}
      {/* End of location icon */}
      {/* Now draw the reports */}
      <Reports />
      {/* Now draw the footer */}
      <Footer />
      {isFranchisor === 'true' && (
        <SwitchLocationPopup
          setOpenSwitchLocationPopup={setOpenSwitchLocationPopup}
          openSwitchLocationPopup={openSwitchLocationPopup}
          locationOptions={locationOptions}
        />
      )}
    </>
  );
}

export default Home;
