import {
  Container,
  Box,
  Button,
  styled,
  Grid,
  Typography,
  Divider,
  Dialog,
  // Avatar,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Table,
  TableBody,
  Avatar,
  Card,
  IconButton,
  useTheme
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/Loader';
import { ArrowLeft, DeleteIcon } from 'src/assets/SVG/SvgIcons'; // DeleteIcon
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';

// import { HeaderContext } from "src/contexts/HeaderContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// FranchiseeDetails page style wrapper
const FranchiseeDetailsWrapper = styled(Container)(
  ({ theme }) => `
        margin-top: ${theme.spacing(2)};
`
);

// FranchiseeDetails page main method
const FranchiseeDetails = ({
  ReUse,
  data,
  getItemForEdit,
  refreshFranchiseeData,
  setShowScreen,
  setselectedOwnerItem
}) => {
  console.log(getItemForEdit);
  const navigate = useNavigate();
  let franchiseeId;
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);
  let [franchiseeDetails, setFranchiseeDetails] = useState({});
  let details = data;
  const [open, setOpen] = React.useState(false);
  // Get the loggedIn user Id
  const userId = sessionStorage.getItem('UserId');
  // show error message
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const theme = useTheme();
  // variable and state for location object
  // const [locationDataId, setLocationDataId] = useState('');
  // const [locationTaxId, setLocationTaxId] = useState('');
  // const [locationTaxGrouping, setLocationTaxGrouping] = useState('');
  // const [locationManager, setLocationManager] = useState('');

  // Method to open the delete dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Method to close the delete dialog
  const handleClose = (val) => {
    if (val === 'Delete') {
      setOpen(false);

      // call the api to delete the franchisee
      deleteFranchisee();
    } else if (val === 'Cancel') {
      setOpen(false);
      franchiseeDetails = {};
      ReUse(false);
    } else {
      franchiseeDetails = {};
      ReUse(false);
    }
    setOpen(false);
  };

  const handleCloseDetails = () => {
    setShowScreen(0);
  };

  // Method to open the edit dialog
  const handleClickEdit = (e) => {
    let crossElm = document.getElementsByClassName('details-remove-icon')[0];
    if (crossElm) crossElm.click();

    let target = e && e.target;
    let lid;
    if (target) lid = target.getAttribute('id');

    // find an element from body
    let cls = 'edit_btn_' + lid;

    getItemForEdit(cls);
    setShowScreen(2);
    // let elm = document.getElementsByClassName(cls)[0];
    // if (elm) elm.click();
    // console.log(elm);
  };

  // useeffect state starts here
  useEffect(() => {
    // call the api to get the franchisee details based on the id
    franchiseeId = details.franchiseeId;

    // call the get franchisee details method
    if (franchiseeId) getFranchiseeDetails(franchiseeId);

    // check if the user is logged in or not
    let isLogin = sessionStorage.getItem('Login_Sucess');
    if (isLogin !== 'true') {
      navigate('/login');
    }
  }, [details, navigate]);

  // Method to delete the franchisee
  const deleteFranchisee = () => {
    setOpen(false);
    let franchiseeId = franchiseeDetails.franchiseeId;
    let encodedFranchiseeId = encodeURIComponent(franchiseeId);

    if (franchiseeId) {
      // Now show the loader
      setIsLoading(true);
      // call the api to delete the location
      let url = `${process.env.REACT_APP_API_END_POINT}franchisees?franchiseeId=${encodedFranchiseeId}&updatedBy=${franchiseeDetails.createdAt}&userId=${userId}`;

      // execute the api
      fetch(`${url}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          // console.log("response", response);
          return response.json();
        })
        .then((data) => {
          if (data.statusCode === 200) {
            setOpen(false);
            refreshFranchiseeData();
            setDeleteSuccess(true);
            // console.log("data", data);
            // navigate to the franchisee list page
            let crossElm = document.getElementsByClassName(
              'details-remove-icon'
            )[0];
            if (crossElm) crossElm.click();
            // Now hide the loader
            setIsLoading(false);
            ReUse(false);
            // Now refresh the franchisee table
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          // set error message
          setShowError(true);
          setErrorMessage(error.message);
          // Now hide the loader
          setIsLoading(false);
        });
    }
  };

  // Method to get the franchisee details
  const getFranchiseeDetails = (franchiseeId) => {
    // show loader
    setIsLoading(true);
    // call the api to get the location details
    let url = `${
      process.env.REACT_APP_FEATURED_API_END_POINT
    }franchisees/getFranchiseDetail?id=${encodeURIComponent(franchiseeId)}`;
    fetch(`${url}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        // console.log("response", response);
        return response.json();
      })
      .then((data) => {
        if (data) {
          // set the location details
          let items = data.data.Items;
          if (items) setFranchiseeDetails(items);
          // Now hide the loader
          setIsLoading(false);

          // setLocationTaxId(franchiseeDetails.locations && franchiseeDetails.locations.taxId);
          // setLocationTaxGrouping(franchiseeDetails.locations && franchiseeDetails.locations.taxGrouping);
          // setLocationManager(franchiseeDetails.locations && franchiseeDetails.locations.managerName);
          // if (franchiseeDetails.locations) mngr.push(franchiseeDetails.locations.managerInfo);

          // console.log("ddd", locationTaxId, locationTaxGrouping, locationManager, mngr, franchiseeDetails.locations.managerInfo);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        // Now hide the loader
        setIsLoading(false);
        // set error message
        setShowError(true);
        setErrorMessage(error.message);
      });
  };

  const onMenuItemClick = (ownerItem, event, value) => {
    console.log(ownerItem, 'ownerItemownerItemownerItem');
    event.preventDefault();
    event.stopPropagation();
    if (value === 'editOwners') {
      setselectedOwnerItem(ownerItem);
      setShowScreen(5);
    }
  };

  // const { setHeaderText } = useContext(HeaderContext);
  // setHeaderText(franchiseeDetails.franchiseeName);

  return (
    <>
      <Loader IsLoading={isLoading} />

      <FranchiseeDetailsWrapper
        maxWidth={'100%'}
        className="location-details-wrapper p-0"
      >
        {/* <Box sx={{display:'flex',justifyContent:'flex-end'}}><Button
                type="button"
                className="next-button pr-0 no-hover" onClick={handleClickEdit}>
                <Typography className="next-button-text">Edit</Typography>
              </Button>
              </Box> */}
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box
                onClick={() => handleCloseDetails()}
                className="back-arrow-close"
              >
                {/* Left arrow icon */}
                <ArrowLeft />
              </Box>

              <Typography className="header-blue-32">
                {franchiseeDetails.franchiseeName}
              </Typography>
            </Box>
          </Grid>
          {/* <Grid item> */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button className="details-remove-icon" onClick={handleClickOpen}>
              <DeleteIcon />
            </Button>
            <Button
              sx={{ mt: 1 }}
              variant="contained"
              id={franchiseeDetails.franchiseeId}
              onClick={handleClickEdit}
              className="btn-primary edit-button"
            >
              Edit
            </Button>
          </Box>
          {/* 
                    </Grid>

                </Grid> */}
        </Grid>

        <Box className="location-details-container">
          {/* <Grid container spacing={2} > */}
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-label">BUSINESS ENTITY NAME</Box>
            </Grid>
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-value">
                {franchiseeDetails.franchiseeName}
              </Box>
            </Grid>
            {/* <Grid item xs={12} sm={1.5} className="location-details-col">
              <Box className="location-details-label">BRAND NAME</Box>
            </Grid>
            <Grid item xs={12} sm={4.5} className="location-details-col">
              <Box className="location-details-value">
                {franchiseeDetails.brandName}
              </Box>
            </Grid> */}
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-label">PHONE NUMBER</Box>
            </Grid>
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-value">
                {franchiseeDetails.phone}
              </Box>
            </Grid>
          </Grid>
          <Grid container mt={3} className="location-details-row">
            {/* <Grid item xs={12} sm={1.5} className="location-details-col">
              <Box className="location-details-label">PHONE NUMBER</Box>
            </Grid>
            <Grid item xs={12} sm={4.5} className="location-details-col">
              <Box className="location-details-value">
                {franchiseeDetails.phone}
              </Box>
            </Grid> */}
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-label">EMAIL</Box>
            </Grid>
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-value">
                {franchiseeDetails.email}
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-label">ADDRESS</Box>
            </Grid>
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-value">
                {franchiseeDetails.addressLine1}
              </Box>
            </Grid>
          </Grid>
          <Grid container mt={3} className="location-details-row">
            {/* <Grid item xs={12} sm={1.5} className="location-details-col">
              <Box className="location-details-label">MANAGER ASSIGNED</Box>
            </Grid> */}
            {/* <Grid item xs={12} sm={4.5} className="location-details-col"> */}
            {/* <Box className="location-details-value"> */}
            {/* <Box className="field-value">
                  {franchiseeDetails?.locations?.managerName}
                </Box> */}
            {/* {(locationManager && locationManager.map((Item) => {
                                    // item = Item.toString();
                                    return (
                                        <Box className="field-value">
                                            {Item}
                                            <br />
                                        </Box>
                                    )
                                })
                                )} */}
            {/* </Box> */}
            {/* </Grid> */}
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-label">FA SIGNING DATE</Box>
            </Grid>
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-value">
                {franchiseeDetails.faSigningDate}
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-label">FA EXPIRATION DATE</Box>
            </Grid>
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-value">
                {franchiseeDetails.faExpirationDate}
              </Box>
            </Grid>
          </Grid>

          {/* <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={1.5} className="location-details-col">
              <Box className="location-details-label">TAX GROUPING</Box>
            </Grid>
            <Grid item xs={12} sm={4.5} className="location-details-col">
              <Box className="location-details-value">
                {franchiseeDetails?.locations?.taxGrouping ? 'Yes' : 'No'}
              </Box>
            </Grid>
            <Grid item xs={12} sm={1.5} className="location-details-col">
              <Box className="location-details-label">TAX ID</Box>
            </Grid>
            <Grid item xs={12} sm={4.5} className="location-details-col">
              <Box className="location-details-value">
                {' '}
                {franchiseeDetails?.locations?.taxId}
              </Box>
            </Grid>
          </Grid> */}
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-label">MUD</Box>
            </Grid>
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-value">
                {franchiseeDetails.mud === true ? 'Yes' : 'No'}
              </Box>
            </Grid>
            {/* <Grid item xs={12} sm={1.5} className="location-details-col">
              <Box className="location-details-label">STATUS</Box>
            </Grid> */}
            {/* <Grid item xs={12} sm={4.5} className="location-details-col"> */}
            {/* <Box className="location-details-value">{(franchiseeDetails.isActive) ? 'Active' : 'Inactive'}</Box> */}
            {/* <Box className="location-details-value">{(franchiseeDetails.status === 1) ? 'Active' : (franchiseeDetails.status === 0) ? 'Inactive' : (franchiseeDetails.status === 0) ? 'Deleted': null}</Box> */}
            {/* <Box className="location-details-value">
                {franchiseeDetails.status === 1 && 'Active'}
              </Box>
              <Box className="location-details-value">
                {franchiseeDetails.status === 0 && 'Inactive'}
              </Box>
              <Box className="location-details-value">
                {franchiseeDetails.status === 2 && 'Deleted'}
              </Box> */}
            {/* </Grid> */}
          </Grid>

          <Grid container className="location-header-container" my={3}>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="page-header title-adjust"
            >
              <span className="location-name text-wra">{'Owner'}</span>
              {/* <span className="location-image">
                <Avatar
                  alt="icon"
                  src={
                    franchiseeDetails.users &&
                    franchiseeDetails.users.profileImage
                      ? franchiseeDetails.users.profileImage
                      : '/static/images/logo/cardboardicon.png'
                  }
                  sx={{ marginLeft: 2 }}
                  className="card-avatar-img"
                />
              </span> */}
            </Grid>
          </Grid>

          {/* <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={1.5} className="location-details-col">
              <Box className="location-details-label">NAME</Box>
            </Grid>
            <Grid item xs={12} sm={4.5} className="location-details-col">
              <Box className="location-details-value">
                {franchiseeDetails.users && franchiseeDetails.users.firstName
                  ? franchiseeDetails.users.firstName +
                    ' ' +
                    franchiseeDetails.users.lastName
                  : null}
              </Box>
            </Grid>
            <Grid item xs={12} sm={1.5} className="location-details-col">
              <Box className="location-details-label">EMAIL</Box>
            </Grid>
            <Grid item xs={12} sm={4.5} className="location-details-col">
              <Box className="location-details-value">
                {franchiseeDetails.users && franchiseeDetails.users.email
                  ? franchiseeDetails.users.email
                  : null}
              </Box>
            </Grid>
          </Grid> */}
          {/* <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={1.5} className="location-details-col">
              <Box className="location-details-label">PHONE NUMBER</Box>
            </Grid>
            <Grid item xs={12} sm={4.5} className="location-details-col">
              <Box className="location-details-value">
                {franchiseeDetails.users && franchiseeDetails.users.phoneNumber
                  ? franchiseeDetails.users.phoneNumber
                  : null}
              </Box>
            </Grid>
            <Grid item xs={12} sm={1.5} className="location-details-col">
              <Box className="location-details-label">WORK PHONE NUMBER</Box>
            </Grid>
            <Grid item xs={12} sm={4.5} className="location-details-col">
              <Box className="location-details-value">
                {franchiseeDetails.users &&
                franchiseeDetails.users.workPhoneNumber
                  ? franchiseeDetails.users.workPhoneNumber
                  : null}
              </Box>
            </Grid>
          </Grid> */}

          {/* <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={1.5} className="location-details-col">
              <Box className="location-details-label">ROLE</Box>
            </Grid>
            <Grid item xs={12} sm={4.5} className="location-details-col">
              <Box className="location-details-value">
                {franchiseeDetails.users && franchiseeDetails.users.roleId
                  ? franchiseeDetails.users.roleId
                  : null}
              </Box>
            </Grid>
            <Grid item xs={12} sm={1.5} className="location-details-col">
              <Box className="location-details-label">ADDRESS</Box>
            </Grid>
            <Grid item xs={12} sm={4.5} className="location-details-col">
              <Box className="location-details-value">
                {franchiseeDetails.users && franchiseeDetails.users.addressLine1
                  ? franchiseeDetails.users.addressLine1
                  : null}
              </Box>
            </Grid>
          </Grid> */}

          {/* <Grid container mt={3} className="location-details-row">
                        <Grid item xs={12} sm={6} className="location-details-col">
                            <Box className="location-details-label">LOCATION IMAGE</Box>
                            <Box className="location-details-value location-image">
                                <Avatar
                                    alt="icon"
                                    src={franchiseeDetails.locationImage || '/static/images/logo/cardboardicon.png'}
                                    sx={{ marginLeft: 2 }}
                                    className='card-avatar-img'
                                />
                            </Box>
                        </Grid>
                    </Grid> */}
          {/* </Grid> */}
          <Grid container spacing={2}>
            <Grid
              item
              sx={{
                width: '100%'
              }}
            >
              <Card
                sx={{
                  padding: '15px',
                  borderRadius: '19px 19px 0px 0px'
                }}
              >
                <TableContainer className="location-table-container">
                  <Table>
                    <TableHead>
                      <TableRow className="table-header">
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Mobile Number</TableCell>
                        <TableCell>Owner Type</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {franchiseeDetails?.owners?.map((owner) => {
                        return (
                          <TableRow
                            hover
                            key={owner?.sortKey}
                            // selected={isLocationItemSelected}
                            className="table-row cursor-pointe"
                            // onClick={(e) => handleViewLocation(locationItem, e)}
                          >
                            <TableCell
                              className="table-td -flex"
                              sx={{ minWidth: '200px' }}
                            >
                              <Avatar
                                alt="icon"
                                src={
                                  owner?.profileImage ||
                                  '/static/images/logo/cardboardicon.png'
                                }
                                sx={{ marginRight: 2, float: 'left' }}
                                className="card-avatar-img align-self-center"
                              />
                              <Typography
                                variant="body1"
                                fontWeight="bold"
                                color="text.primary"
                                sx={{ marginTop: 1 }}
                                gutterBottom
                              >
                                {owner?.firstName}
                              </Typography>
                            </TableCell>
                            <TableCell className="table-td">
                              <Typography>{owner?.email}</Typography>
                            </TableCell>
                            <TableCell className="table-td">
                              <Typography
                                variant="body1"
                                fontWeight="bold"
                                color="text.primary"
                                gutterBottom
                                noWrap
                              >
                                {owner?.phoneNumber}
                              </Typography>
                            </TableCell>

                            <TableCell className="table-td">
                              <Typography
                                variant="body1"
                                fontWeight="bold"
                                color="text.primary"
                                gutterBottom
                                noWrap
                              >
                                {owner?.isPrimaryOwner === true
                                  ? 'Primary Owner'
                                  : 'Secondary Owner'}
                              </Typography>
                            </TableCell>
                            <TableCell className="table-td">
                              <IconButton
                                sx={{
                                  '&:hover': {
                                    background: theme.colors.secondary.lighter
                                  },
                                  color: theme.palette.primary.main,
                                  borderRadius: '50% !important'
                                }}
                                color="inherit"
                                size="medium"
                                borderRadius="50% !important"
                                onClick={(e) =>
                                  onMenuItemClick(owner, e, 'editOwners')
                                }
                              >
                                <EditTwoToneIcon fontSize="medium" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
          </Grid>
          {showError && (
            <Grid container mt={3} className="location-details-row">
              <Box className="error-message">{errorMessage}</Box>
            </Grid>
          )}
        </Box>
      </FranchiseeDetailsWrapper>

      {/* Delete success dialog */}
      <Dialog
        open={deleteSuccess}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setDeleteSuccess(false);
          setShowScreen(0);
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Congratulations!'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Selected franchise has been deactivated successfully!
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => {
              setDeleteSuccess(false);
              setShowScreen(0);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete confirmation dialog */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Deactivate'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Are you sure you want to deactviate this record?
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleClose('Delete')}
          >
            Deactivate
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => handleClose('Cancel')}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FranchiseeDetails;
