/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
import React, { useState, forwardRef, Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { convertUtcToUserTimeEdit } from 'src/utils';
import {
  Typography,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  Grid,
  Radio,
  FormControl,
  RadioGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  Slide,
  FormHelperText
} from '@mui/material';
import Loader from '../Loader';
import {
  CustomTextField,
  CustomSelect,
  CustomButton,
  CustomDatePicker,
  AutoComplete
} from 'src/components/Global';
// import {CustomDatePicker} from '../CustomDatePicker'
import {
  isOutOfTimeAvailability,
  generateTimeOptions,
  getValueFromId,
  isValidTimeFormat,
  isEndTimeGreaterOrEqual,
  formatTimeOrDate,
  convertUTCToNormal,
  convertTimeToUTC,
  getOccurencePayload
} from './Const';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { getLocalData } from 'src/utils';
import { CalenderDialogs } from '.';
import { ApiService } from 'src/services/ApiService';
import ErrorMessage from '../PopupMessage/ErrorMessage';
import SuccessMessage from '../PopupMessage/SuccessMessage';
import { TextField } from '@material-ui/core';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const initialDialogs = {
  state: false,
  isCreated: false,
  type: false,
  edit: false,
  delete: false,
  notify: false,
  outTime: false,
  bookClass: false,
  isDelete: false,
  isEditCustom: false,
  isDeleteCustom: false
};

export function AddSchedule(props) {
  const {
    handleResetStates,
    isScheduleEdit,
    // eslint-disable-next-line no-unused-vars
    setIsScheduleEdit,
    initialScheduleValues,
    newSchedule,
    setNewSchedule,
    dialog,
    setDialog,
    allReqFulFilled,
    setAllReqFulFilled,
    btnLoading,
    setBtnLoading,
    allServiceCategories,
    allTrainers,
    allServices,
    allParents,
    allPets,
    allRooms
  } = props;
  const navigate = useNavigate();
  const BASE_URL = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}`;

  const locationId = getLocalData('locationId');
  const franchiseeId = getLocalData('franchiseeId');
  let userId = getLocalData('userId');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [sucessMessage, setSuccessMessage] = useState('')
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [notifyDialog, setNotifyDialog] = useState(initialDialogs);
  const [notify, setNotify] = useState('all');
  const [creditsAvailable, setCreditsAvailable] = useState(0);
  const [totalCredits, setTotalCredits] = useState(0);
  const [showCredit, setShowCredit] = useState(false);
  const [noOfCredit, setnoOfCredit] = useState([]);
  const [isCreditApplied, setIsCreditApplied] = useState(false);
  const [trainerAvailable, setTrainerAvailable] = useState(true);
  const [DateShouldDiffError, setDateShouldDiffError] = useState('');
  const [TimeShouldDiffError, setTimeShouldDiffError] = useState('');
  const [trainerData, setTrainerData] = useState([]);
  const [roomData, setRoomData] = useState([]);
  const [roomError, setRoomError] = useState(false);
  const [trainerError, setTrainerError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [closeDialog, setCloseDialog] = useState({
    state: false,
    isCreated: false,
    creditApplied: false,
    insufficientCredits: false
  });

  const daysOfWeek = [
    { label: 'M', value: 'mo' },
    { label: 'T', value: 'tu' },
    { label: 'W', value: 'we' },
    { label: 'T', value: 'th' },
    { label: 'F', value: 'fr' },
    { label: 'S', value: 'sa' },
    { label: 'S', value: 'su' }
  ];

  const [isError, setIsError] = useState({
    status: false,
    serviceCategory: false,
    serviceId: false,
    fromDate: false,
    toDate: false,
    fromTime: false,
    toTime: false,
    fromTimeFormat: false,
    toTimeFormat: false,
    trainer: false,
    occurence: false,
    room: false,
    description: false,
    parent: false,
    pets: false,
    intervalDays: false
  });
  const valuesAreDifferent = () => {
    if (typeof newSchedule !== typeof initialScheduleValues) {
      return false;
    }

    if (
      typeof newSchedule !== 'object' ||
      typeof initialScheduleValues !== 'object'
    ) {
      return newSchedule === initialScheduleValues;
    }

    const keys1 = Object.keys(newSchedule);
    const keys2 = Object.keys(initialScheduleValues);
    if (keys1.length !== keys2.length) {
      return false;
    }

    const keys = Object.keys(initialScheduleValues);
    return keys.some(
      (key) => key !== 'type' && initialScheduleValues[key] !== newSchedule[key]
    );
  };
  useEffect(() => {
    const fetchData = async () => {
      if (newSchedule.serviceId) {
        try {
          let data = {
            locationId: locationId,
            serviceIds: [newSchedule.serviceId],
          };
  
          let response = await ApiService.post(
            `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/getTrainerWithRooms`,
            data
          );
  
          let Item =
            response && response.data && response.data.Items
              ? response.data.Items
              : [];
  
          setTrainerData(Item.staffData);
          setRoomData(Item.roomData);
  
          if (Item.staffData.length > 0) {
            setTrainerError(false);
          }
          if (Item.roomData.length > 0) {
            setRoomError(false);
          }
          if (Item.staffData.length <= 0) {
            setTrainerError(true);
          }
          if (Item.roomData.length <= 0) {
            setRoomError(true);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
  
    fetchData();
  }, [newSchedule.serviceId]);
  
  
  
 console.log('dataaaaaaaaaaa=====>',trainerData,roomData,trainerData?.length,trainerError);
  // useEffect(() => {


  //   // Find the trainer with the matching sortKey
  //   allTrainers.forEach((item) => {

  //     if (item?.sortKey === newSchedule?.trainer&&newSchedule.serviceId) {
  //      let foundTrainer = item?.availableServices?item?.availableServices:[];
  //       setTrainerService(foundTrainer);
  //       setTrainerAvailable(true)
  //     }
  //   });

  // }, [newSchedule?.serviceId]);
  useEffect(() => {
    let foundTrainer = null; // Declare foundTrainer outside of the forEach loop

    allTrainers.forEach((item) => {
      if (item?.sortKey === newSchedule?.trainer && newSchedule.serviceId) {
        foundTrainer = item?.availableServices ? item?.availableServices : [];
        setTrainerAvailable(true);
      }
    });

    if (newSchedule.serviceId && Array.isArray(foundTrainer) && foundTrainer.length > 0) {
      const match = foundTrainer.some(service => service.serviceId === newSchedule.serviceId);
      setTrainerAvailable(match);
    } else if (foundTrainer?.length === 0) {
      setTrainerAvailable(false);
    } else {
      setTrainerAvailable(true);
    }
  }, [newSchedule.serviceId]);



  // useEffect(() => {
  //   if(newSchedule.serviceId){
  //     const match = trainerService && trainerService.some(service => service.serviceId === newSchedule.serviceId);
  //     setTrainerAvailable(match)
  //   }
  // }, [newSchedule.trainer]);
  useEffect(() => {
    if (newSchedule.parent && newSchedule.pets && newSchedule.serviceId && newSchedule.serviceCategory) {
      checkCredit()
    }
  }, [newSchedule])

  const checkCredit = async () => {
    let data = {
      clientId: newSchedule.parent,
      locationId: locationId,
      petId: newSchedule.pets,
      categoryId: newSchedule.serviceCategory,
      serviceId: newSchedule.serviceId
    };

    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}checkout/getAllCreditAvailability`,
        data
      );

      // Now, compare the lengths and open the dialog if necessary
      if (response.statusCode && !response.error) {

        setnoOfCredit(response.data.Items)
        setShowCredit(true)
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (noOfCredit.length > 0) {
      setTotalCredits(noOfCredit[0]?.remainingSession)
    } else {
      setTotalCredits(0)
    }
    // setShowCredit(true)
  }, [noOfCredit])
  console.log(noOfCredit, 'totalcredit');
  const handleCloseScreen = () => {
    if (valuesAreDifferent()) {
      setCloseDialog({ ...closeDialog, state: true });
    } else {
      handleResetStates();
    }
  };

  const handleDialog = (value) => {
    if (value === 'Continue') {
      setCloseDialog({
        isCreated: false,
        state: false,
        creditApplied: false,
        insufficientCredits: false
      });
    } else if (value === 'Discard') {
      setTrainerAvailable(true)
      handleResetStates();
    } else if (value === 'Cancel') {
      handleCloseScreen();
    }
  };

  const handleToggleDay = (day) => {
    let selectedDays = [];
    if (newSchedule.intervalDays.includes(day)) {
      selectedDays = newSchedule.intervalDays.filter(
        (selectedDay) => selectedDay !== day
      );
    } else {
      selectedDays = [...newSchedule.intervalDays, day];
    }
    setNewSchedule({ ...newSchedule, intervalDays: selectedDays });
    setIsError({ ...isError, intervalDays: false });
  };

  const handleScheduleInfo = (type, value) => {
    if (type === 'serviceCategory') {
      setNewSchedule({ ...newSchedule, [type]: value, serviceId: '' });
      setIsError({ ...isError, [type]: false, serviceId: false });
    } else if (type === 'intervalValue') {
      if (value > 0) {
        setNewSchedule({ ...newSchedule, [type]: value });
      } else {
        setNewSchedule({ ...newSchedule, [type]: 1 });
      }
      setIsError({ ...isError, [type]: false });
    } else if (type === 'parent') {
      setNewSchedule({ ...newSchedule, [type]: value, pets: '' });
      setIsError({ ...isError, [type]: false, pets: false });
    } else if (type === 'fromDate') {
      setNewSchedule({
        ...newSchedule,
        [type]: formatTimeOrDate('date', value),
        toDate: null
        // ,
        // toTime: ''
      });
      setIsError({ ...isError, [type]: false, toDate: false });
    } else if (type === 'toDate') {
      setNewSchedule({
        ...newSchedule,
        [type]: formatTimeOrDate('date', value)
        // ,
        // toTime: ''
      });
      setDateShouldDiffError('')
      setIsError({ ...isError, [type]: false });
    } else if (type === 'fromTime') {
      setNewSchedule({ ...newSchedule, [type]: value });
      console.log(value, newSchedule.toTime);
      setIsError({ ...isError, [type]: false, fromTimeFormat: false });
    } else if (type === 'toTime') {
      setNewSchedule({ ...newSchedule, [type]: value });
      setIsError({ ...isError, [type]: false, toTimeFormat: false });
    } else if (type === 'occurence') {
      setNewSchedule({ ...newSchedule, [type]: value });
      setIsError({ ...isError, [type]: false, intervalDays: false });
    } else {
      setNewSchedule({ ...newSchedule, [type]: value });
      setIsError({ ...isError, [type]: false });
    }
  };

  function isToDateGreater() {
    let startDate = newSchedule.fromDate;
    let endDate = newSchedule.toDate;

    if (!startDate || !endDate) {
      return true;
    }

    let startYear = '';
    let startMonth = '';
    let startDay = '';
    let endYear = '';
    let endMonth = '';
    let endDay = '';

    // if (isScheduleEdit) {
    //   const startDateParts = startDate.split('-');
    //   const endDateParts = endDate.split('-');

    //   startYear = parseInt(startDateParts[2]);
    //   startMonth = parseInt(startDateParts[1]) - 1;
    //   startDay = parseInt(startDateParts[0]);
    //   endYear = parseInt(endDateParts[2]);
    //   endMonth = parseInt(endDateParts[1]) - 1;
    //   endDay = parseInt(endDateParts[0]);
    // } else {
    //   startYear = startDate.getFullYear();
    //   startMonth = startDate.getMonth();
    //   startDay = startDate.getDate();
    //   endYear = endDate.getFullYear();
    //   endMonth = endDate.getMonth();
    //   endDay = endDate.getDate();
    // }

    const startDateParts = startDate.split('-');
    const endDateParts = endDate.split('-');

    startYear = parseInt(startDateParts[2]);
    startMonth = parseInt(startDateParts[1]) - 1;
    startDay = parseInt(startDateParts[0]);
    endYear = parseInt(endDateParts[2]);
    endMonth = parseInt(endDateParts[1]) - 1;
    endDay = parseInt(endDateParts[0]);

    if (endYear > startYear) {
      return true;
      // eslint-disable-next-line no-else-return
    } else if (endYear === startYear) {
      if (endMonth > startMonth) {
        return true;
        // eslint-disable-next-line no-else-return    
      } else if (endMonth === startMonth) {
        if (endDay > startDay) {
          return true;
        }
      }
    }
    return false;
  }

  function checkForEmptyValues() {
    let hasError = false;
    const updatedErrors = {};

    // Always check for empty values
    const alwaysCheck = [
      'serviceCategory',
      'serviceId',
      'fromDate',
      'fromTime',
      'toDate',
      'toTime',
      'trainer',
      'occurence',
      'room'
      // 'description'
    ];
    // eslint-disable-next-line no-restricted-syntax
    for (const key of alwaysCheck) {
      const value = newSchedule[key];
      console.log('KeyValue---->',key,value);
      const isEmpty =
        value === '' ||
        value == null ||
        (Array.isArray(value) && value.length === 0);
      updatedErrors[key] = isEmpty;

      if (isEmpty) {
        hasError = true;
      }
    }

    if (newSchedule.type === 'appointment') {
      const parentIsEmpty = newSchedule?.parent === '';
      const petIsEmpty = newSchedule?.pets === '';
      updatedErrors['parent'] = parentIsEmpty;
      updatedErrors['pets'] = petIsEmpty;

      if (parentIsEmpty || petIsEmpty) {
        hasError = true;
      }
    }

    if (newSchedule.fromTime && !isValidTimeFormat(newSchedule?.fromTime)) {
      updatedErrors['fromTime'] = true;
      updatedErrors['fromTimeFormat'] = true;
      hasError = true;
    }

    if (newSchedule.toTime && !isValidTimeFormat(newSchedule?.toTime)) {
      updatedErrors['toTime'] = true;
      updatedErrors['toTimeFormat'] = true;
      hasError = true;
    }

    if (
      newSchedule?.fromTime &&
      newSchedule?.toTime &&
      !isToDateGreater() &&
      isValidTimeFormat(newSchedule?.fromTime) &&
      isValidTimeFormat(newSchedule?.toTime)
    ) {
      if (!isEndTimeGreaterOrEqual(newSchedule?.fromTime, newSchedule?.toTime)) {
        updatedErrors['toTime'] = true;
        hasError = true;
      }
    }
    setIsError(updatedErrors);
    return hasError;
  }

  useEffect(() => {
    let credits = getValueFromId(
      newSchedule.parent,
      allParents,
      'sortKey',
      'credit'
    );
    if (isCreditApplied) {
      setCreditsAvailable(credits - 1);
    } else {
      setCreditsAvailable(credits || 0);
    }
  }, [newSchedule.parent, isCreditApplied]);

  const handleAddSchedule = (e, type) => {
    e.preventDefault();
    console.log(fromDateToDateValidation() + '&& !' + checkForEmptyValues());
    if (fromDateToDateValidation() && !checkForEmptyValues()) {
      if (type === 'credits') {
        // applying credits and deducting one credit from total
        let noOfCredits = getValueFromId(
          newSchedule.parent,
          allParents,
          'sortKey',
          'credit'
        );
        if (noOfCredits >= 1) {
          setCloseDialog({
            ...closeDialog,
            state: true,
            creditApplied: true,
            insufficientCredits: false
          });
          setIsCreditApplied(true);
        } else {
          setCloseDialog({
            ...closeDialog,
            state: true,
            creditApplied: true,
            insufficientCredits: true
          });
        }
      } else {
        let trainerArry = getValueFromId(
          newSchedule.trainer,
          trainerData,
          'sortKey',
          'availability'
        );
        // checking trainer availablility
        if (
          isOutOfTimeAvailability(
            trainerArry,
            newSchedule.fromTime,
            newSchedule.toTime
          )
        ) {
          setDialog({ ...dialog, state: true, outTime: true });
          setAllReqFulFilled(false);
        } else {
          handleFinalSubmission();
        }
      }
    } else {
      // setShowErrorMessage(true);
      // setErrorMessage('Please fill all the required fields');

    }
  };
  useEffect(() => {
    if (allReqFulFilled) {
      handleFinalSubmission();
    }
  }, [allReqFulFilled]);

  function fromDateToDateValidation() {
    const fromTime = parseTime(newSchedule?.fromTime);
    const toTime   = parseTime(newSchedule?.toTime);
    console.log( newSchedule?.occurence);

    if (fromTime >= toTime) {
      setTimeShouldDiffError('To time must be greater than From time.');
      return false;
      // eslint-disable-next-line no-else-return
    } else if (!isScheduleEdit && newSchedule?.fromDate !== newSchedule?.toDate &&
      newSchedule?.occurence === 'noRepeat') {
      setDateShouldDiffError('From date and To date should be same for never occurrence.')
      setTimeShouldDiffError('');
      return false;
    } else if (!isScheduleEdit && newSchedule?.occurence === 'daily' || newSchedule?.occurence === 'weekly') {
      if (newSchedule?.fromDate === newSchedule?.toDate) {
        setDateShouldDiffError('To date should be greater than from Date')
        return false;
        // eslint-disable-next-line no-else-return
      } else {
        setTimeShouldDiffError('');
        setDateShouldDiffError('');
        return true;
      }
    }
    else {
      setDateShouldDiffError('');
      setTimeShouldDiffError('');
      return true
    }
  }
  function parseTime(timeString) {
    const [hour, minute] = timeString.split(' ')[0].split(':');
    const isPM = timeString.split(' ')[1] === 'PM';
    let hours = parseInt(hour, 10);

    if (isPM && hours !== 12) {
      hours += 12;
    } else if (!isPM && hours === 12) {
      hours = 0;
    }
    return new Date(1970, 0, 1, hours, parseInt(minute, 10));
  }

  useEffect(() => {
    try {
      let FromDate = convertUtcToUserTimeEdit(newSchedule?.fromDate)
      console.log(FromDate, 'FromDateFromDate');
      let ToDate = convertUtcToUserTimeEdit(newSchedule?.toDate)
      if (isScheduleEdit) setNewSchedule({ ...newSchedule, fromTime: FromDate?.formattedTime, toTime: ToDate?.formattedTime, fromDate: FromDate?.formattedDate, toDate: ToDate?.formattedDate });
    } catch (error) {
      console.log('error',error);
    }
  }, []);


  const handleFinalSubmission = () => {
    // setBtnLoading((prev) => {
    //   let toggleId;
    //   if (prev['add_schedule']) {
    //     toggleId = { ['add_schedule']: false };
    //   } else {
    //     toggleId = { ['add_schedule']: true };
    //   }
    //   return { ...toggleId };
    // });
    if (isScheduleEdit) {
      setDialog({ ...dialog, state: true, edit: true });
    } else {
      createSchedule();
    }
  };

  // changing isBlock object -> IsBlock in trainer availability array
  function updateIsBlockProperty(objArray) {
    return objArray?.map((obj) => {
      const { isBlock, ...rest } = obj;
      // return { ...rest, IsBlock: isBlock };
      return { ...rest, isBlock: isBlock };
    });
  }
  console.log(newSchedule, 'datadatadatadatadata');
  const maxlist = allServices.filter((item) => {
    return item.sortKey === newSchedule.serviceId;
  });


  // =================Create Appointment =======================
  const createSchedule = async () => {
    setIsLoading(true)
    let categoryName = getValueFromId(
      newSchedule.serviceCategory,
      allServiceCategories,
      'sortKey',
      'name'
    );
    let serviceName = getValueFromId(
      newSchedule.serviceId,
      allServices,
      'sortKey',
      'name'
    );

    let trainerFistName = getValueFromId(
      newSchedule.trainer,
      trainerData,
      'sortKey',
      'firstName'
    );
    let trainerLastName = getValueFromId(
      newSchedule.trainer,
      trainerData,
      'sortKey',
      'lastName'
    );
    let trainerName = `${trainerFistName} ${trainerLastName}`;
    let trainerImage = getValueFromId(
      newSchedule.trainer,
      trainerData,
      'sortKey',
      'profileImage'
    );

    let trainerAvailability = updateIsBlockProperty(
      getValueFromId(
        newSchedule.trainer,
        trainerData,
        'sortKey',
        'availableSchedules'
      )
    );
    console.log(noOfCredit, 'noOfCredit');
    const data = {
      locationId: locationId,
      franchiseeId: franchiseeId,
      scheduleType: newSchedule.type,
      categoryName: categoryName,
      categoryId: newSchedule.serviceCategory,
      serviceName: serviceName,
      serviceId: newSchedule.serviceId,
      // classInterval: 1,
      // startTime: newSchedule.fromTime,
      //  endTime: newSchedule.toTime,
      description: newSchedule.description,
      trainerId: newSchedule.trainer,
      trainerName: trainerName,
      trainerProfileImage: trainerImage,
      trainerAvailability: trainerAvailability ?? [],
      maximumCapacity: maxlist[0]?.maxCapacity,
      maximumWaitngListCapacity: maxlist[0]?.maxWaitlist,
      room: {
        name: newSchedule.room,
        roomLimit: 5
      },
      repeat: newSchedule.occurence,
      occurrence: getOccurencePayload(newSchedule)
    };

    if (isScheduleEdit) {
      data.updatedBy = userId;
    } else {
      data.createdBy = userId;
    }

    if (newSchedule.type === 'appointment' && totalCredits > 0 && !newSchedule.payLater) {

      data.clientDetails = [{
        payLater: newSchedule.payLater,
        clientParentId: newSchedule.parent,
        clientChildId: newSchedule.pets,
        purchaseId: noOfCredit[0]?.sortKey,
        creditAvailable: creditsAvailable || 0,
        clientParentName: getValueFromId(
          newSchedule.parent,
          allParents,
          'sortKey',
          'firstName'
        ),
        clientEmailId: getValueFromId(
          newSchedule.parent,
          allParents,
          'sortKey',
          'email'
        ),

        clientChildName: getValueFromId(
          newSchedule.pets,
          allPets,
          'sortKey',
          'firstName'
        ),
        clientChildProfile: getValueFromId(
          newSchedule.pets,
          allPets,
          'sortKey',
          'profileImage'
        ),
        clientChildBirthDate: getValueFromId(
          newSchedule.pets,
          allPets,
          'sortKey',
          'birthDate'
        )
      }];
    } else {
      data.clientDetails = [{
        payLater: newSchedule.payLater,
        clientParentId: newSchedule.parent,
        clientChildId: newSchedule.pets,
        creditAvailable: creditsAvailable || 0,
        clientParentName: getValueFromId(
          newSchedule.parent,
          allParents,
          'sortKey',
          'firstName'
        ),
        clientChildName: getValueFromId(
          newSchedule.pets,
          allPets,
          'sortKey',
          'firstName'
        ),
        clientChildProfile: getValueFromId(
          newSchedule.pets,
          allPets,
          'sortKey',
          'profileImage'
        ),
        clientChildBirthDate: getValueFromId(
          newSchedule.pets,
          allPets,
          'sortKey',
          'birthDate'
        ),
        clientEmailId: getValueFromId(
          newSchedule.parent,
          allParents,
          'sortKey',
          'email'
        ),
        // purchaseId:noOfCredit?.sortKey
      }];
    }

    // if(totalCredits>0){
    //   alert(noOfCredit[0]?.sortKey)
    //   alert(totalCredits)
    //   data.clientDetails.push({

    //     purchaseId:noOfCredit?.sortKey
    //   });
    // }

    if (
      newSchedule.type === 'enrollment' ||
      newSchedule.payLater || totalCredits > 0 ||
      (!newSchedule.payLater && isCreditApplied)
    ) {
      try {
        let response = await ApiService.post(
          `${BASE_URL}schedule/addAppointment`,
          data
        );
        if (response.data.statusCode === 400) {
          setShowErrorMessage(true);
          setErrorMessage(response.data?.message || 'Something went wrong, Try again!');
          setIsLoading(false)
        }
        else if (!response.data?.error && response.statusCode === 200) {
          setIsLoading(false)
          // setShowSuccessMessage(true)
          // setSuccessMessage(response.data.message);
          console.log("yo man ")
          setNotify('all')
          setCloseDialog({
            isCreated: true,
            state: true
          });
        } else {
          setShowErrorMessage(true);
          setErrorMessage(response.data?.message || 'Something went wrong, Try again!');
          setIsLoading(false)
          // toast.error(
          //   response.data?.message ||
          //   'Something went wrong with create appoinment'
          // );
        }

        setBtnLoading(false);
      } catch (error) {
        console.error('error', error);
        setIsLoading(false)
        setBtnLoading(false);
        setShowErrorMessage(true);
        setErrorMessage('Something went wrong, Try again!');
        // toast.error('Something went wrong, Try again!');
      }
    } else {
      navigate('/checkout');
    }
  };

  console.log(dialog, 'dialog2')
  const updateSchedule = async () => {
    let categoryName = getValueFromId(
      newSchedule.serviceCategory,
      allServiceCategories,
      'sortKey',
      'name'
    );
    let serviceName = getValueFromId(
      newSchedule.serviceId,
      allServices,
      'sortKey',
      'name'
    );

    let trainerFistName = getValueFromId(
      newSchedule.trainer,
      trainerData,
      'sortKey',
      'firstName'
    );
    let trainerLastName = getValueFromId(
      newSchedule.trainer,
      trainerData,
      'sortKey',
      'lastName'
    );
    let trainerName = `${trainerFistName} ${trainerLastName}`;

    let trainerImage = getValueFromId(
      newSchedule.trainer,
      trainerData,
      'sortKey',
      'profileImage'
    );

    let trainerAvailability = updateIsBlockProperty(
      getValueFromId(
        newSchedule.trainer,
        trainerData,
        'sortKey',
        'availableSchedules'
      )
    );

    // unComment that above one when when 'trainerAvailability' is in api
    // let trainerAvailability = [
    //   {
    //     startTime: '10:00',
    //     endTime: '11:30 ',
    //     isBlock: true
    //   },
    //   {
    //     startTime: '16:00',
    //     endTime: '18:00',
    //     isBlock: true
    //   }
    // ];
    let fromTime = convertTimeToUTC(newSchedule.fromTime)
    let toTime = convertTimeToUTC(newSchedule.toTime)
    let setFromtime = convertUTCToNormal(fromTime)
    let setEndtime = convertUTCToNormal(toTime)
    console.log(setFromtime, 'setFromtime');
    const data = {
      // createdBy: 'John Doe',
      locationId: locationId,
      scheduleType: newSchedule.type,
      categoryName: categoryName,
      categoryId: newSchedule.serviceCategory,
      serviceName: serviceName,
      serviceId: newSchedule.serviceId,
      classInterval: 1,
      startTime: setFromtime,
      endTime: setEndtime,
      description: newSchedule.description,
      trainerId: newSchedule.trainer,
      trainerName: trainerName,
      trainerProfileImage: trainerImage,
      trainerAvailability: trainerAvailability?.map((data) => {
        if (newSchedule?.editTypeValue === 'Reschedule') {
          return { ...data, isBlock: true };
          // eslint-disable-next-line no-else-return
        } else {
          return { ...data, isBlock: false };
        }
      }),
      room: {
        name: newSchedule.room,
        roomLimit: 6
      },
      // repeat: newSchedule.occurence,
      // occurrence: getOccurencePayload(newSchedule),
      trainerSlots: 30
    };

    if (isScheduleEdit) {
      // data.updatedBy= 'John Doe'
    } else {
      data.createdBy = userId;
    }

    if (newSchedule.type === 'appointment') {
      data.clientDetails = [{

        payLater: newSchedule.payLater,
        clientParentId: newSchedule.parent,
        clientChildId: newSchedule.pets,
        creditAvailable: creditsAvailable || 0,
        clientParentName: getValueFromId(
          newSchedule.parent,
          allParents,
          'sortKey',
          'firstName'
        ),
        clientChildName: getValueFromId(
          newSchedule.pets,
          allPets,
          'sortKey',
          'firstName'
        ),
        clientChildProfile: getValueFromId(
          newSchedule.pets,
          allPets,
          'sortKey',
          'profileImage'
        ),
        clientChildBirthDate: getValueFromId(
          newSchedule.pets,
          allPets,
          'sortKey',
          'birthDate'
        )
      }];
    }

    const encodedOccurenceId = encodeURIComponent(newSchedule.id);
    if (newSchedule.payLater || newSchedule.type === 'enrollment') {
      try {
        let response = await ApiService.put(
          `${BASE_URL}schedule/updateAppointment?occurrenceId=${encodedOccurenceId}`,
          data
        );
        if (!response.data?.error || !response?.error) {
          setShowSuccessMessage(true)
          setSuccessMessage(response?.message);
          setCloseDialog({
            isCreated: true,
            state: true
          });
        } else {
          setShowErrorMessage(true);
          setErrorMessage(response.data?.message ||
            'Something went wrong with update appoinment');
        }

        setBtnLoading(false);
      } catch (error) {
        console.error(error);
        setBtnLoading(false);
        setShowErrorMessage(true);
        setErrorMessage('Something went wrong, Try again!');
      }
    } else {
      navigate('/checkout');
    }
  };
  useEffect(() => {
    if (isScheduleEdit) {
      getEnrollmentDetail()
    }
  }, []);

  const getEnrollmentDetail = async () => {
    let data = {
      occurrenceId: newSchedule.appointmentId,
      locationId: locationId,
    };
    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/getAppointmentDetails`,
        data
      );
      let Item = response && response?.data;
      console.log(Item, 'maxWaiting');
      // setWaitingNumber(Item.maximumCapacity);
      // Now, compare the lengths and open the dialog if necessary
      //       if(allAttendies?.length === maxWaiting ||allAttendies?.length > maxWaiting){
      // // setMaxWaitList(true)
      //       }else if (allAttendies?.length === waiting || allAttendies?.length > waiting) {
      //         // setOpenDialog(true);

      //       } else {
      //         // // setOpenDialog(false); 
      //         // setMaxWaitList(false)// Close the dialog if it was previously open
      //       }
      // setWaitingNumber(waiting);
    } catch (error) {
      console.log(error);
    }
  };
  const sendNotification = async () => {
    if (!notify.value) {
      setShowErrorMessage(true);
      setErrorMessage('Plz select option first');
      return;
    }

    const tranierEmail = props.trainerData.map((data) => data?.email);
    const clientEmail = props.allParents.map((data) => data?.email);
    const allEmails = [...tranierEmail, ...clientEmail];
    let payload = {
      toEmail:
        notify.value === 'trainer'
          ? tranierEmail
          : notify.value === 'client'
            ? clientEmail
            : allEmails,
      fromEmail: process.env.REACT_APP_NOTIFYFROMEMAIL
    };
    try {
      let response = await ApiService.post(
        `${BASE_URL}checkout/sendNotification`,
        payload
      );

      if (response?.statusCode === 200) {
        updateSchedule();
        // toast.success(response?.message);
        setNotifyDialog({ ...notifyDialog, state: false, notify: false });
      } else {
        setShowErrorMessage(true);
        setErrorMessage(response?.message || 'Something went Wrong');
      }
    } catch (error) {
      setShowErrorMessage(true);
      setErrorMessage('Something went Wrong');
    }
  };

  const handleCloseTypeModal = (type) => {
    if (type === 'skip') {
      setNotifyDialog({ ...notifyDialog, state: false, notify: false });
      updateSchedule();
    } else if (type === 'notify' || isScheduleEdit) {
      sendNotification();
      updateSchedule();
    }
  };
  const closePopup = () => {
    setShowSuccessMessage(false);
  };
  function replaceHyphensWithSlashes(inputString) {
    if (inputString && inputString.includes('-')) {
      return inputString.replaceAll('-', '/');
    }
    return null;
  }

  return (
    <><Loader IsLoading={isLoading} /><div className="service-container">

      {showErrorMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowErrorMessage} />
      )}
      {showSucessMessage && (
        <SuccessMessage message={sucessMessage} setShowMessage={setSuccessMessage} closePopup={closePopup} />
      )}
      <Box
        sx={{ width: '100%' }}
        paddingBottom="20px"
        className="Main-container common-form-container"
      >
        <Box className="custom-form">
          <Grid mt={1} marginBottom="8px">
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    textAlign: 'left',
                    alignItems: 'center',
                    gap: '8px',
                    marginTop: '10px'
                  }}
                >
                  <KeyboardBackspaceIcon
                    color="primary"
                    sx={{
                      width: '30px',
                      height: '30px',
                      cursor: 'pointer'
                    }}
                    onClick={handleCloseScreen} />
                  <h1
                    className="title primary-color "
                    style={{ textTransform: 'capitalize' }}
                  >
                    {`${isScheduleEdit ? 'Edit' : 'New'} ${newSchedule.type === 'appointment' ? 'Appointment' : "Enrollment schedule"}`}
                  </h1>
                </Box>
              </Grid>

              {!isScheduleEdit && newSchedule.type === 'appointment' ? (
                <Grid item>
                  {newSchedule.payLater || totalCredits > 0 ? (
                    <CustomButton
                      variant="contained"
                      loading={btnLoading['add_schedule']}
                      onClick={handleAddSchedule}
                      disabled={roomError||trainerError}
                    >
                      Book Now
                    </CustomButton>
                  ) : (
                    <Box sx={{ display: 'flex', gap: '12px' }}>
                      {/* <CustomButton
                      variant="contained"
                      loading={btnLoading['apply_credit']}
                      disabled={btnLoading['add_schedule'] || isCreditApplied}
                      onClick={(e) => handleAddSchedule(e, 'credits')}
                    >
                      Apply Credit
                    </CustomButton> */}

                      <CustomButton
                        variant="outline"
                        loading={btnLoading['add_schedule']}
                        onClick={handleAddSchedule}
                        disabled={roomError||trainerError}>
                        Buy Credit
                      </CustomButton>
                    </Box>
                  )}
                </Grid>
              ) : (
                <Grid item>
                  <CustomButton
                    variant="contained"
                    loading={btnLoading['add_schedule']}
                    disabled={roomError||trainerError}
                    onClick={handleAddSchedule}
                  >
                    {isScheduleEdit ? 'Update' : 'Save'}
                  </CustomButton>
                </Grid>
              )}
            </Grid>
          </Grid>

          {/* {newSchedule.type == 'appointment' && (
      <Grid container spacing={3} columns={12} mt={0.5} maxWidth={768}>
        <Grid
          item
          xs={12}
          paddingTop={
            newSchedule.type == 'appointment' && '10px !important'
          }
        >
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: 30,
                  color: '#003087'
                },
                '& .MuiFormControlLabel-label': {
                  color: '#000000',
                  letterSpacing: '0.3px',
                  fontWeight: 500
                },
                '& .MuiFormControlLabel-root': {
                  marginRight: '40px'
                }
              }}
            >
              <FormControlLabel
                value="checkIn"
                control={<Radio />}
                label="Check In"
                checked={newSchedule.appointmentStatus == 'checkIn'}
                onChange={() =>
                  setNewSchedule({
                    ...newSchedule,
                    appointmentStatus: 'checkIn'
                  })
                }
              />

              <FormControlLabel
                value="arrivedLate"
                control={<Radio />}
                label="Arrived Late"
                checked={newSchedule.appointmentStatus == 'arrivedLate'}
                onChange={() =>
                  setNewSchedule({
                    ...newSchedule,
                    appointmentStatus: 'arrivedLate'
                  })
                }
              />

              <FormControlLabel
                value="earlyCancel"
                control={<Radio />}
                label="Early Cancel"
                disabled={!isScheduleEdit}
                checked={newSchedule.appointmentStatus == 'earlyCancel'}
                onChange={() =>
                  setNewSchedule({
                    ...newSchedule,
                    appointmentStatus: 'earlyCancel'
                  })
                }
              />

              <FormControlLabel
                value="lateCancel"
                control={<Radio />}
                label="Late Cancel"
                disabled={!isScheduleEdit}
                checked={newSchedule.appointmentStatus == 'lateCancel'}
                onChange={() =>
                  setNewSchedule({
                    ...newSchedule,
                    appointmentStatus: 'lateCancel'
                  })
                }
              />

              <FormControlLabel
                value="absent"
                control={<Radio />}
                label="Absent"
                disabled={!isScheduleEdit}
                checked={newSchedule.appointmentStatus == 'absent'}
                onChange={() =>
                  setNewSchedule({
                    ...newSchedule,
                    appointmentStatus: 'absent'
                  })
                }
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    )} */}

          {newSchedule.type === 'appointment' && isScheduleEdit && (
            <Grid container spacing={3} columns={12} mt={0.5} maxWidth={768}>
              <Grid item xs={12} paddingTop={'0px !important'}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: 30,
                        color: '#003087'
                      },
                      '& .MuiFormControlLabel-label': {
                        color: '#000000',
                        letterSpacing: '0.3px',
                        fontWeight: 500
                      },
                      '& .MuiFormControlLabel-root': {
                        marginRight: '40px'
                      }
                    }}
                  >
                    <FormControlLabel
                      value="unAvailable"
                      control={<Radio />}
                      label="Mark as unavailable"
                      checked={newSchedule.editTypeValue === 'unAvailable'}
                      onChange={() => setNewSchedule({
                        ...newSchedule,
                        editTypeValue: 'unAvailable'
                      })} />
                    <FormControlLabel
                      value="Reschedule"
                      control={<Radio />}
                      label="Reschedule All Clients"
                      checked={newSchedule.editTypeValue === 'Reschedule'}
                      onChange={() => setNewSchedule({
                        ...newSchedule,
                        editTypeValue: 'Reschedule'
                      })} />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          )}

          <Grid
            container
            maxWidth={768}
            spacing={3}
            columns={12}
            mt={newSchedule.type === 'appointment' ? 0 : 1}
          >
            <Grid
              item
              xs={12}
              sm={6}
              paddingTop={newSchedule.type === 'appointment' && '16px !important'}
            >
              <CustomSelect
                fullWidth
                name="serviceCategory"
                value={newSchedule.serviceCategory}
                onChange={handleScheduleInfo}
                label="SERVICE CATEGORY *"
                disabled={newSchedule.editTypeValue !== 'Reschedule'}
                error={isError.serviceCategory}
                helperText={isError.serviceCategory && 'Service category is required'}
                options={allServiceCategories?.map((item) => ({
                  label: item.name,
                  value: item.sortKey
                }))} />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              paddingTop={newSchedule.type === 'appointment' && '10px !important'}
            >
              <CustomSelect
                fullWidth
                name="serviceId"
                value={newSchedule.serviceId}
                onChange={handleScheduleInfo}
                label="SERVICE NAME *"
                disabled={!newSchedule.serviceCategory ||
                  newSchedule.editTypeValue !== 'Reschedule'}
                error={isError.serviceId}
                helperText={isError.serviceId && 'Service name is required'}
                options={allServices?.map((item) => ({
                  label: item.name,
                  value: item.sortKey
                }))} />
            </Grid>
          </Grid>

          {newSchedule.type === 'appointment' && (
            <Grid
              container
              maxWidth={768}
              spacing={3}
              columns={12}
              mt={newSchedule.type === 'appointment' ? 0 : 1}
            >
              <Grid item xs={12} sm={6}>
                <CustomSelect
                  fullWidth
                  name="parent"
                  value={newSchedule.parent}
                  onChange={handleScheduleInfo}
                  label="SELECT CLIENT *"
                  disabled={newSchedule.editTypeValue !== 'Reschedule' || isScheduleEdit}
                  error={isError.parent}
                  helperText={isError.parent && 'Parent is required'}
                  options={allParents?.map((item) => ({
                    label: `${item.firstName}`,
                    value: item.sortKey
                  }))} />
              </Grid>

              <Grid item xs={12} sm={6}>
                <CustomSelect
                  fullWidth
                  name="pets"
                  value={newSchedule.pets}
                  onChange={handleScheduleInfo}
                  label="SELECT DOG *"
                  disabled={!newSchedule.parent ||
                    newSchedule.editTypeValue !== 'Reschedule' || isScheduleEdit}
                  error={isError.pets}
                  helperText={isError.pets && 'Pet is required'}
                  options={allPets?.map((item) => ({
                    label: `${item.firstName}`,
                    value: item.sortKey
                  }))} />
              </Grid>
            </Grid>
          )}

          <Grid container maxWidth={768} spacing={3} columns={12} mt={1}>
            <Grid item xs={12} sm={6}>
              <CustomSelect
                fullWidth
                name="trainer"
                value={newSchedule.trainer}
                onChange={handleScheduleInfo}
                label="TRAINER *"
                disabled={newSchedule.editTypeValue !== 'Reschedule'}
                error={isError.trainer}
                helperText={isError.trainer && 'Trainer is required' || trainerError && "Trainer is not available for this service."}
                options={trainerData?.map((item) => ({
                  label: `${item.firstName || ''} ${item.lastName || ''}`,
                  value: item.sortKey
                }))} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomSelect
                fullWidth
                name="room"
                value={newSchedule.room}
                onChange={handleScheduleInfo}
                label="ROOM *"
                disabled={newSchedule.editTypeValue !== 'Reschedule'}
                error={isError.room}
                helperText={isError.room && 'Room is required'||roomError&&'Room is not available for this service'}
                options={roomData?.map((item) => ({
                  label: item.name,
                  value: item.name
                }))} />
            </Grid>
          </Grid>

          <Grid container maxWidth={768} spacing={3} columns={12} mt={1}>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <CustomDatePicker
                    label="Date *"
                    name="fromDate"
                    fullWidth
                    disablePast
                    inputFormat="MM/dd/yyyy"
                    disabled={newSchedule.editTypeValue !== 'Reschedule' || isScheduleEdit}
                    value={replaceHyphensWithSlashes(newSchedule?.fromDate)}
                    onChange={handleScheduleInfo}
                    error={isError.fromDate}
                    helperText={isError.fromDate &&
                      (newSchedule.fromDate > newSchedule.toDate
                        ? 'From date must be smaller than To date'
                        : 'From date is required')} />
                </Grid>

                {/* <Grid item xs={4}>
                  <AutoComplete
                    fullWidth
                    freeSolo
                    disableClearable
                    label="Time *"
                    name="fromTime"
                    disabled={newSchedule.editTypeValue !== 'Reschedule'}
                    options={generateTimeOptions()}
                    value={newSchedule.fromTime}
                    onChange={handleScheduleInfo}
                    error={isError.fromTime || isError.fromTimeFormat}
                    helperText={isError.fromTime &&
                      (isError.fromTimeFormat
                        ? 'Plese enter correct format e.g (12:00 AM/PM)'
                        : 'Time is required')} />
                </Grid> */}
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Grid container spacing={1}>
                {/* <Grid item xs={8}>
                  <CustomDatePicker
                    label="TO *"
                    name="toDate"
                    fullWidth
                    disablePast
                    disabled={newSchedule.fromDate == null ||
                      newSchedule.editTypeValue !== 'Reschedule' || isScheduleEdit}
                    // minDate={newSchedule.fromDate && newSchedule.fromDate}
                    inputFormat="MM/dd/yyyy"
                    value={replaceHyphensWithSlashes(newSchedule?.toDate)}
                    onChange={handleScheduleInfo}
                    error={isError.toDate}
                    helperText={isError.toDate ?
                      (!isToDateGreater()
                        ? 'To date must be greater than From date'
                        : 'To date is required') : ''} />
                </Grid> */}
                <Grid item xs={6}>
                {/* <TextField
                          fullWidth
                          name="fromTime"
                          autocomplete="Time"
                          label="Start Time *"
                          type="time"
                          disabled={newSchedule.editTypeValue !== 'Reschedule'}
                          // defaultValue={startTime}
                          value={newSchedule?.fromTime}
                          onChange={handleScheduleInfo}
                          error={isError.fromTime || isError.fromTimeFormat}
                          helperText={isError.fromTime &&
                            (isError.fromTimeFormat
                              ? 'Plese enter correct format e.g (12:00 AM/PM)'
                              : 'Time is required')}
                          className={`input-field-styling ${
                            pricingInfo.timeRange ? 'required-field' : ''
                          }}`}
                          InputLabelProps={{
                            className: `label-style-create-franchisee ${
                              pricingInfo.timeRange ? 'required-label' : ''
                            }`,
                            shrink: true
                          }}
                        /> */}
                  <AutoComplete
                    fullWidth
                    freeSolo
                    disableClearable
                    label="Start Time *"
                    name="fromTime"
                    disabled={newSchedule.editTypeValue !== 'Reschedule'}
                    options={generateTimeOptions()}
                    value={newSchedule?.fromTime}
                    onChange={handleScheduleInfo}
                    error={isError.fromTime || isError.fromTimeFormat}
                    helperText={isError.fromTime &&
                      (isError.fromTimeFormat
                        ? 'Plese enter correct format e.g (12:00 AM/PM)'
                        : 'Time is required')} />
                </Grid>
                <Grid item xs={6}>
                  <AutoComplete
                    fullWidth
                    freeSolo
                    label="End Time *"
                    name="toTime"
                    value={newSchedule.toTime}
                    onChange={handleScheduleInfo}
                    options={generateTimeOptions()}
                    // compareForDisable={
                    //   !isToDateGreater() && newSchedule.fromTime
                    // }
                    disabled={!newSchedule?.fromDate ||
                      !newSchedule?.toDate ||
                      !newSchedule?.fromTime ||
                      newSchedule?.editTypeValue !== 'Reschedule'}
                    disableClearable
                    error={isError.toTime || isError.toTimeFormat}
                    helperText={isError.toTime &&
                      newSchedule.toTime === '' ? 'Time is required' : TimeShouldDiffError} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container maxWidth={768} spacing={3} columns={12} mt={1}>
            <Grid item xs={12} sm={6}>
              <CustomSelect
                fullWidth
                name="occurence"
                value={newSchedule.occurence}
                onChange={handleScheduleInfo}
                disabled={newSchedule.editTypeValue !== 'Reschedule' || isScheduleEdit}
                label="OCCURRENCE"
                error={isError.occurence}
                helperText={isError.occurence && 'Occurrence is required'}
                options={newSchedule.type === 'appointment' ?
                  [
                    {
                      label: 'Once',
                      value: 'noRepeat'
                    },
                    {
                      label: 'Daily',
                      value: 'daily'
                    },
                    {
                      label: 'Weekly',
                      value: 'weekly'
                    }
                  ] :
                  [
                    {
                      label: 'Once',
                      value: 'noRepeat'
                    },
                    {
                      label: 'Daily',
                      value: 'daily'
                    },
                    {
                      label: 'Weekly',
                      value: 'weekly'
                    }
                  ]} />
            </Grid>
            {newSchedule.occurence !== 'noRepeat' ? (
              <Grid item xs={12} sm={6}>
                <CustomDatePicker
                  label="End Date"
                  name="toDate"
                  fullWidth
                  disablePast
                  disabled={newSchedule?.fromDate == null ||
                    newSchedule.editTypeValue !== 'Reschedule' || isScheduleEdit}
                    value={replaceHyphensWithSlashes(newSchedule?.toDate)}
                  // minDate={newSchedule?.fromDate && newSchedule?.fromDate}
                  inputFormat="MM/dd/yyyy"
                  onChange={handleScheduleInfo}
                  error={isError.toDate}
                  helperText={isError.toDate && DateShouldDiffError ?
                    (!isToDateGreater()
                      ? 'To date must be greater than From date'
                      : 'To date is required') : DateShouldDiffError} />
                      
              </Grid>
            ) : null}
            {/* <Grid item xs={12} sm={6}>
              <CustomSelect
                fullWidth
                name="room"
                value={newSchedule.room}
                onChange={handleScheduleInfo}
                label="ROOM *"
                disabled={newSchedule.editTypeValue !== 'Reschedule'}
                error={isError.room}
                helperText={isError.room && 'Room is required'}
                options={allRooms?.map((item) => ({
                  label: item.name,
                  value: item.name
                }))} />
            </Grid> */}
          </Grid>

          {newSchedule.occurence !== 'noRepeat' ? (
            <Grid container maxWidth={768} spacing={3} columns={12} mt={1}>
              {/* <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={newSchedule.occurence === 'monthly' ? 6 : 6}>
                    <CustomTextField
                      fullWidth
                      type="number"
                      min="1"
                      name="intervalValue"
                      disabled={newSchedule.editTypeValue !== 'Reschedule' || isScheduleEdit}
                      value={newSchedule.intervalValue}
                      onChange={handleScheduleInfo}
                      label="Interval" />
                  </Grid> */}

              {/* Adding empty Grid item for spacing */}
              {/* <Grid item xs={6}>
                    <Grid container spacing={1}> */}
              {/* <Grid item xs={6}>
                        <CustomSelect
                          fullWidth
                          name="endLabel"
                          value={newSchedule.endLabel || 'until'} // Preselect 'until' if no value is set
                          onChange={handleScheduleInfo}
                          label="End"
                          disabled={newSchedule.editTypeValue !== 'Reschedule' || isScheduleEdit}
                          options={[
                            { label: 'Until', value: 'until' },
                            // Add other options if needed
                          ]} />
                      </Grid> */}

              {/* <Grid item xs={6}>
                        <CustomDatePicker
                          label="End Date"
                          name="toDate"
                          fullWidth
                          disablePast
                          disabled={newSchedule.fromDate == null ||
                            newSchedule.editTypeValue !== 'Reschedule' || isScheduleEdit}
                          minDate={newSchedule.fromDate && newSchedule.fromDate}
                          inputFormat="MM/dd/yyyy"
                          value={newSchedule.toDate}
                          onChange={handleScheduleInfo}
                          error={isError.toDate}
                          helperText={isError.toDate && DateShouldDiffError ?
                            (!isToDateGreater()
                              ? 'To date must be greater than From date'
                              : 'To date is required') : DateShouldDiffError} />
                      </Grid> */}
              {/* </Grid>
                  </Grid> */}
              {/* Adding empty Grid item for spacing */}


              {/* {newSchedule.occurence == 'monthly' ? (
              <Grid item xs={8}>
                <CustomSelect
                  fullWidth
                  multiple
                  name="intervalMonths"
                  value={newSchedule.intervalMonths}
                  onChange={handleScheduleInfo}
                  label="Every"
                  disabled={newSchedule.editTypeValue !== 'Reschedule'}
                  options={[
                    { label: 'January', value: 1 },
                    { label: 'Feburary', value: 2 },
                    { label: 'March', value: 3 },
                    { label: 'April', value: 4 },
                    { label: 'May', value: 5 },
                    { label: 'June', value: 6 },
                    { label: 'July', value: 7 },
                    { label: 'August', value: 8 },
                    { label: 'September', value: 9 },
                    { label: 'October', value: 10 },
                    { label: ' November', value: 11 },
                    { label: 'December', value: 12 }
                  ]}
                />
              </Grid>
            ) : null} */}
              {/* </Grid>
              </Grid> */}

              <Grid item xs={12} sm={12}>
                {newSchedule.occurence !== 'daily' && (
                  <div className="days-of-week-container">
                    {daysOfWeek.map(({ label, value }) => (
                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                      <div
                        key={value}
                        className={`day-circle vv-${value} ${newSchedule.occurence === 'everyWeek'
                          ? value === 'su'
                            ? ''
                            : 'selected'
                          : newSchedule.intervalDays.includes(value)
                            ? 'selected'
                            : ''}`}
                        onClick={() => newSchedule.occurence !== 'everyWeek' &&
                          handleToggleDay(value)}
                      >
                        {label}
                      </div>
                    ))}
                  </div>
                )}
                <span className="helper-error">
                  {isError.intervalDays && (
                    <FormHelperText>Select atleast one day</FormHelperText>
                  )}
                </span>
              </Grid>
            </Grid>
          ) : null}

          <Grid container maxWidth={768} spacing={3} columns={12} mt={newSchedule.occurence === 'daily' ? -2 : 0}>
            <Grid item xs={12}>
              <CustomTextField
                fullWidth
                multiline
                rows="6"
                type="text"
                name="description"
                disabled={newSchedule.editTypeValue !== 'Reschedule'}
                value={newSchedule.description}
                onChange={handleScheduleInfo}
                label="DESCRIPTION"
              // error={isError.description}
              // helperText={isError.description && 'Description is required'} 
              />
            </Grid>
          </Grid>

          {newSchedule.type === 'enrollment' && isScheduleEdit && (
            <Grid container spacing={3} columns={12} mt={0.5} maxWidth={768}>
              <Grid item xs={12}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: 30,
                        color: '#003087'
                      },
                      '& .MuiFormControlLabel-label': {
                        color: '#000000',
                        letterSpacing: '0.3px',
                        fontWeight: 500
                      },
                      '& .MuiFormControlLabel-root': {
                        marginRight: '40px'
                      }
                    }}
                  >
                    <FormControlLabel
                      value="unAvailable"
                      control={<Radio />}
                      label="Mark as unavailable"
                      checked={newSchedule.editTypeValue === 'unAvailable'}
                      onChange={() => setNewSchedule({
                        ...newSchedule,
                        editTypeValue: 'unAvailable'
                      })} />
                    <FormControlLabel
                      value="Reschedule"
                      control={<Radio />}
                      label="Reschedule All Clients"
                      checked={newSchedule.editTypeValue === 'Reschedule'}
                      onChange={() => setNewSchedule({
                        ...newSchedule,
                        editTypeValue: 'Reschedule'
                      })} />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          )}

          {newSchedule.type === 'appointment' && !isScheduleEdit && (
            // eslint-disable-next-line react/jsx-fragments
            <Fragment>
              <Grid
                container
                spacing={3}
                columns={12}
                mt={0}
                maxWidth={768}
                className="fullWidth-btn"
              >
                <Grid item xs={12} sm={6} paddingTop={'10px !important'}>
                  <FormControlLabel
                    className='checkbox-pay-leter'
                    control={<Checkbox
                      defaultChecked={newSchedule.payLater}
                      color="default"
                      disabled={!trainerAvailable}
                      value={newSchedule.payLater}
                      onChange={(e) => handleScheduleInfo('payLater', e.target.checked)}
                      sx={{
                        color: '#003087',
                        '&.Mui-checked': {
                          color: '#003087'
                        }
                      }} />}
                    label={<span style={{ fontWeight: 'bold', color: 'black' }}>Pay Later</span>} />
                </Grid>

                <Grid
                  item
                  xs={12}

                  sm={6}
                  display="flex"
                  justifyContent="flex-end"
                  paddingRight="16px"
                  paddingTop={'10px !important'}
                >
                  {showCredit && (
                    <Typography variant="body2" color="#003087">
                      {totalCredits > 0
                        ? <Typography variant="body2" fontWeight={800} fontSize={16} color="green">Credit Available: {totalCredits}  </Typography>
                        : <Typography variant="body2" fontWeight={800} fontSize={16} color="red">No credits available for this service </Typography>}
                    </Typography>
                  )}
                </Grid>
              </Grid>

              {/* <Grid container maxWidth={768} spacing={3} columns={12} mt={0}>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                className="underline-btn"
                paddingTop={'10px !important'}
              >
                <Button
                  className="no-hover-btn"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <AddIcon fontSize="small" />
                  Book Another Appointment
                </Button>
              </Grid>
            </Grid> */}
            </Fragment>
          )}
        </Box>
      </Box>

      <Dialog
        open={closeDialog.state}
        TransitionComponent={Transition}

        keepMounted
        onClose={() => handleDialog(closeDialog.creditApplied ? 'Continue' : 'Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        {closeDialog.creditApplied ? null : (
          <DialogTitle className={`alert-title align-center ${closeDialog.isCreated && newSchedule.type === 'appointment' ? 'success-class' : 'default-class'}`}>
            {closeDialog.isCreated ? (newSchedule.type === 'appointment' ? 'Succesfully Booked!' : 'Succesfully Scheduled!') : 'Save Changes'}
          </DialogTitle>
        )}
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!closeDialog.isCreated ? (
                <>
                  {closeDialog.creditApplied ? (
                    <Typography className="title-text-sub">
                      {closeDialog.insufficientCredits
                        ? 'Insufficient Credits'
                        : 'Credits Successfully Applied!'}
                    </Typography>
                  ) : (
                    <Typography className="title-text-sub">
                      You have unsaved changes, Do you want to <br></br>
                      save them?
                    </Typography>
                  )}
                </>
              ) : (
                <Typography className={`${newSchedule.type === 'appointment' ? 'title-text-sub-success' : '  align-center title-text-sub'}`}>
                  {newSchedule.type === 'appointment' ? (
                    isScheduleEdit ? (
                      'Your appointment has been updated successfully!'
                    ) : (
                      'You have scheduled an appointment with us successfully.Make sure you have paid for this service successfully before the appointment date if you want to attend this event.'
                    )
                  ) : (
                    isScheduleEdit ? (
                      'Your enrollment has been updated successfully!'
                    ) : (
                      'Enrollment has been scheduled successfully.'
                    )
                  )}
                </Typography>

              )}

            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          {closeDialog.creditApplied ? (
            <Button
              className="font-17 text-black no-hover"
              onClick={() => handleDialog('Continue')}
            >
              Close
            </Button>
          ) : (
            <>
              <Button
                onClick={() => handleDialog('Discard')}
                className={`font-17 text-black no-hover ${closeDialog.isCreated ? 'close-class' : 'default-class'}`}
              >
                {closeDialog.isCreated ? 'Close' : 'Discard Changes'}
              </Button>

              {!closeDialog.isCreated && (
                <Divider className="vertical-divider" />
              )}
              {!closeDialog.isCreated && (
                <Button
                  className="font-17 no-hover"
                  onClick={() => handleDialog('Continue')}
                >
                  Go back to Editing
                </Button>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>

      <CalenderDialogs
        dialog={notifyDialog}
        setDialog={setNotifyDialog}
        onClose={handleCloseTypeModal}
        notify={notify}
        setNotify={setNotify} />
    </div></>
  );
}