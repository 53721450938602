import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormHelperText,
  Grid,
  Slide,
  Stack,
  TextField,
  CardHeader
} from '@mui/material';
import { Toggle } from 'src/components/Global';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
// import PromotionItemTable from './PromotionItemTable';
import { ApiService } from '../../../services/ApiService';
import { getLocalData } from '../../../utils';
// import { CustomDatePicker } from '../../../components/CustomDatePicker';
import { enterNumberOnly } from '../../../utils';
import Loader from '../../../components/Loader';
import { ActiveStatus, InactiveStatus } from 'src/assets/SVG/SvgIcons';
import InputAdornment from '@mui/material/InputAdornment';
import { PromotionTargetTable } from './PromotionTargetTable';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import { CustomDatePickerForAllBrowser } from 'src/components/CustomDatePickerForAllBrowser';
import moment from 'moment';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

//  showEditPromotionDialog, setShowEditPromotionDialog, setShowEditPromotion,
const EditPromotion = ({
  selectedData,
  setShowScreen,
  getPromotionDetails,
  setErrorMessage,
  setShowMessage
}) => {
  let detailsData = selectedData[0];
  // console.log(detailsData, '---> deatil data');

  let userId = sessionStorage.getItem('UserId');
  const steps = ['Edit Promotion Setup', 'Edit Promotion Target Selection'];
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [openConfirmationPop, setOpenConfirmationPopUp] = useState(false);
  const [promotionName, setPromotionName] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [discount, setDiscount] = useState('');
  const [neverExpires, setNeverExpires] = useState(false);
  const [storesOnly, setStoresOnly] = useState(false);
  const [viaPromoCode, setViaPromoCode] = useState(false);
  const [allItems, setAllItems] = useState(false);
  const [allowDiscount, setAllowDiscount] = useState(false);
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [open, setOpen] = useState(false);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [promoAvailiable, setPromoAvailiable] = useState('');
  const [fieldsFilled, setFieldFilled] = useState(false);
  const [limit, setLimit] = useState('');
  const [promotionError, setPromotionError] = useState('');
  const [promoCodeError, setPromoCodeError] = useState('');
  const [discountError, setDiscountError] = useState('');
  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');
  const [limitError, setLimitError] = useState('');
  const [selectedItems, setSelectedItems] = useState({
    client: null,
    items: [],
    discount: null,
    paymentMethod: null,
    values: [],
    totalAmount: 0.0
  });
  // const [tempSelectedItems, setTempSelectedItems] = useState({
  //     tempValues: [],
  //     tempItems: [],
  // });
  const [useOnline, setUseOnline] = useState(false);

  const locationId = getLocalData('locationId');

  useEffect(() => {
    setActiveStep(0);
    setIsCreated(false);
    setData();
  }, []);

  // Check if unsaved changes are there and loading/closing browser tab
  useEffect(() => {
    if (isFieldUpdated) window.addEventListener('beforeunload', alertUnsaved);
  }, [isFieldUpdated]);

  // Unsaved changes alert
  const alertUnsaved = (e) => {
    e.preventDefault();
    e.returnValue = 'You have unsaved changes, what do you want to do?';
  };

  // Now check if never expires is true then set end date to empty
  useEffect(() => {
    if (neverExpires) {
      setEndDate('');
      setEndDateError('');
      let enddate = document.querySelector('.promo-enddate input');
      if (enddate) enddate.value = '';
    }
  }, [neverExpires]);

  // method to set data
  const setData = () => {
    // console.log(detailsData.neverExpire)
    setPromotionName(detailsData.name);
    setPromoCode(detailsData.promoCode);
    if (detailsData.promoCode) {
      setPromoAvailiable(true);
    }
    setDiscount(parseFloat(detailsData.discount));
    setViaPromoCode(Boolean(detailsData.useOnline));
    setUseOnline(Boolean(detailsData.useOnline));
    // setNeverExpires(Boolean(detailsData.neverExpire));
    setStartDate(detailsData.startDate);
    setEndDate(detailsData.endDate);
    setLimit(detailsData.limit);
    setAllowDiscount(detailsData.allowDiscountOnGiftCardPurchase);
    setAllItems(detailsData.applyToAll);

    // if the selected item is not empty then set the selected items
    if (detailsData.items.length > 0) {
      // Get the ids of the items
      let updatedArray = detailsData.items.map((item) => {
        return item.itemId;
      });
      // set the selected items
      setSelectedItems({
        ...selectedItems,
        items: [...selectedItems.items, ...detailsData.items],
        values: [...selectedItems.values, ...updatedArray].filter(
          (value, index, self) => {
            return self.indexOf(value) === index;
          }
        )
      });
    }
    // if use online is true then set the use online to be true
    if (detailsData?.useOnline) {
      let elm = document.querySelector('.use-online');
      if (elm) elm.click();
    }

    // if never expire is true then set the never expire to be true
    if (detailsData?.neverExpire) {
      let elm = document.querySelector('.never-expire');
      if (elm) elm.click();
    }

    // let serviceItemData = (detailsData.length > 0 && detailsData.items.filter((services) => services.itemType === "Service").map((item) => item.itemValue.id));
    // setServicesValue(serviceItemData);
    // let productItemData = (detailsData.length > 0 && detailsData.items.filter((products) => products.itemType === "Product").map((item) => item.itemValue.id));
    // setProductValue(productItemData);
  };

  const handleToggleInfo = (type, value) => {
    // setInitialValue({ ...initialValue, [type]: value });
    if (type === 'useOnline') {
      setUseOnline(value);
      setFieldFilled(true);
    } else if (type === 'NeverExpiration') {
      setNeverExpires(value);
      setFieldFilled(true);
    }
  };

  const proceedNext = async () => {
    const formattedStartDate = formatDateForComparison(startDate);
    const formattedEndDate = formatDateForComparison(endDate);
    if (!promotionName) {
      setPromotionError('Promotion name is required');
      setFieldFilled(false);
    }
    if (!promoCode) {
      setPromoCodeError('Promo code is required');
      setFieldFilled(false);
    }
    if (!discount) {
      setDiscountError('Discount is required');
      setFieldFilled(false);
    }
    if (!startDate) {
      setStartDateError('Start date is required');
      setFieldFilled(false);
    }
    if (!neverExpires && !endDate) {
      setEndDateError('End date is required');
      setFieldFilled(false);
    } else {
      setEndDateError('');
    }
    if (moment(formattedStartDate).isAfter(moment(formattedEndDate))) {
      setEndDateError('End date should be greater than the start date');
      return;
    }
    console.log('fieldsFilled', fieldsFilled);
    if (fieldsFilled && promotionName && promoCode && discount && startDate) {
      if (neverExpires || endDate) {
        setActiveStep(1);
      }
    }
    // Not needed as promocode field disabled on the Edit as per the logic
    // if (fieldsFilled && promotionName && promoCode && discount && startDate) {
    //     if (neverExpires || endDate) {
    //         // Check promo code availability
    //         let promocode = promoCode && promoCode.toUpperCase();
    //         try {
    //             if (promocode !== detailsData.promoCode) {
    //                 let data = await ApiService.get(`${process.env.REACT_APP_API_END_POINT}promotions/promoCodeValidation?promoCode=${promocode}`)
    //                 if (data.statusCode === 200) {
    //                     setActiveStep(1); // Promo code is available, proceed to the next step
    //                 } else {
    //                     // Handle case where promo code is not available
    //                     setPromoCodeError("Promo code is not available");
    //                 }
    //             }
    //         } catch (error) {
    //             // Handle error while checking promo code availability
    //             console.error(error);
    //             setPromoCodeError("Promo code is not available");
    //         }
    //     }
    // }
  };

  const handleClose = () => {
    if (isFieldUpdated) {
      setOpen(true);
    } else {
      setShowScreen(0);
    }
  };

  const toggleButton = (e) => {
    if (e === 'stores-only') {
      setStoresOnly(!storesOnly);
      setFieldFilled(true);
    }
    if (e === 'promo-code') {
      setViaPromoCode(!viaPromoCode);
      setFieldFilled(true);
    }

    if (e === 'apply-all') {
      setAllItems(!allItems);
      // if (!allProducts) handleAllProductsServiceClear();
      setFieldFilled(true);
    }
    if (e === 'allow-discount') {
      setAllowDiscount(!allowDiscount);
    }
  };

  // console.log(useOnline, 'useonline', neverExpires, "never------");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'Promotion name') {
      if (!value) {
        setPromotionError('Promotion name is required');
        setPromotionName('');
        setIsFieldUpdated(true);
        setFieldFilled(false);
      } else {
        setPromotionError('');
        setPromotionName(value);
        setIsFieldUpdated(true);
        setFieldFilled(true);
      }
    }
    if (name === 'Promo code') {
      let pattern = /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/;
      if (!value) {
        // setPromoAvailiable(false);
        setPromoAvailiable('');
        setPromoCodeError('Promo code is required');
        setPromoCode(value);
        setIsFieldUpdated(true);
        setFieldFilled(false);
      } else if (!pattern.test(value)) {
        // setPromoAvailiable(false);
        setPromoAvailiable('');
        setPromoCodeError('Invalid promo code');
        setPromoCode(value);
        setIsFieldUpdated(true);
      } else {
        setPromoCodeError('');
        // let promoCodeCheck = value;
        setPromoCode(value);
        // checkPromoCode(promoCodeCheck)
        setIsFieldUpdated(true);
        setFieldFilled(true);
        // setPromoAvailiable(true);
      }
    }

    if (name === 'Discount') {
      if (!value) {
        setDiscountError('Discount is required');
        setDiscount(value);
        setIsFieldUpdated(true);
        setFieldFilled(false);
      } else {
        let discountNo = enterNumberOnly(value);
        if (discountNo && discountNo.length <= 2) {
          setDiscountError('');
          setDiscount(discountNo);
          setIsFieldUpdated(true);
          setFieldFilled(true);
        }
      }
    }
    if (name === 'Limit') {
      if (value) {
        let limit = enterNumberOnly(value);
        setLimitError('');
        setLimit(limit);
      } else {
        setLimitError('');
        setLimit('');
      }
    }
  };

  /* Function for end date formatting */
  const formatDateForComparison = (dateString) => {
    // Parse the input date string and format it to 'YYYY-MM-DDTHH:mm:ss'
    return moment(dateString, 'MM-DD-YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss');
  };

  const dateSetStart = (startDate) => {
    if (startDate === '01-01-1970') {
      return;
    }

    // Convert startDate and endDate to a compatible format
    const formattedStartDate = formatDateForComparison(startDate);
    const formattedEndDate = formatDateForComparison(endDate);

    setStartDate(startDate);
    // Check the start date is less than the end date
    if (moment(formattedEndDate).isBefore(moment(formattedStartDate))) {
      setStartDateError('Start date should be less than the end date');
      setFieldFilled(true);
    } else {
      setEndDateError('');
      setStartDateError('');
      setFieldFilled(true);
    }
  };

  const dateSetEnd = (endDate) => {
    if (endDate === '01-01-1970') {
      return;
    }

    // Convert startDate and endDate to a compatible format
    const formattedStartDate = formatDateForComparison(startDate);
    const formattedEndDate = formatDateForComparison(endDate);

    setEndDate(endDate);

    // Check the end date is greater than start date
    if (moment(formattedStartDate).isAfter(moment(formattedEndDate))) {
      setEndDateError('End date should be greater than the start date');

      setFieldFilled(true);
    } else {
      setStartDateError('');
      setEndDateError('');
      setFieldFilled(true);
    }
  };

  //   const dateSetStart = (val) => {
  //     // setStartDate(val);
  //     if (val === null) {
  //       setStartDateError('Start date is required');
  //       setFieldFilled(false);
  //     } else {
  //       setStartDateError('');
  //       setStartDate(val);
  //       setFieldFilled(true);
  //     }
  //   };

  //   const dateSetEnd = (val) => {
  //     if (val === null) {
  //       setEndDateError('End date is required');
  //       setFieldFilled(false);
  //     } else {
  //       setEndDateError('');
  //       setEndDate(val);
  //       setFieldFilled(true);
  //     }
  //     // setEndDate(val)
  //   };
  // not needed as we disablled promo code field
  // const checkPromoCode = async (val) => {
  //     try {
  //         let promoCodeStr = val && val.toUpperCase();
  //         if (promoCodeStr !== detailsData.promoCode) {
  //             let data = await ApiService.get(`${process.env.REACT_APP_API_END_POINT}promotions/promoCodeValidation?promoCode=${promoCodeStr}`)
  //             if (data.statusCode === 200) {
  //                 setPromoAvailiable(true);
  //             }
  //             else {
  //                 setPromoAvailiable(false);
  //             }
  //         }
  //     } catch (error) {
  //         // console.log(error);
  //         setPromoAvailiable(false);
  //     }
  // }

  const addPromotion = (val) => {
    if (!allItems && selectedItems.items.length === 0) {
      // setSelectedItemsError('Select one item at least to proceed');
      setFieldFilled(false);
    } else {
      // setSelectedItemsError('');
      setFieldFilled(true);
    }

    // if condition true the proceed
    //  selectedItems.items.length > 0
    if (fieldsFilled && (selectedItems.items.length > 0 || allItems)) {
    //  promotionItem(val);
    console.log(val)
      setOpenConfirmationPopUp(true);
    }
  };

  const handleConfirmClose = (val) => {
    if (val === 'Yes') {
      // EditBundlesData()
      promotionItem();
      setOpenConfirmationPopUp(false);
    }
    if (val === 'No') {
      setOpenConfirmationPopUp(false);
    }
  };

  const promotionItem = async () => {
    setIsLoading(true);
    let data = {
      locationId: locationId,
      name: promotionName,
      promoCode: promoCode.toUpperCase(),
      discount: discount,
      limit: limit || 0,
      useOnline: useOnline,
      neverExpire: neverExpires,
      allowDiscountOnGiftCardPurchase: allowDiscount,
      applyToAll: allItems,
      startDate: startDate,
      updatedBy: userId,
      items: selectedItems.items || [],
      status: detailsData.status
    };
    if (!neverExpires) data = { ...data, endDate: endDate };
    let promoId = encodeURIComponent(detailsData.sortKey);
    try {
      const apiResponse = await ApiService.put(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}promotions/updatePromoPOP?promoId=${promoId}`,
        data
      );
      if (apiResponse.statusCode === 200) {
        setOpen(true);
        setIsCreated(true);
        setIsLoading(false);
        getPromotionDetails();
      } else {
        setIsLoading(false);
        setShowMessage(true);
        setErrorMessage(apiResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      let msg = error?.message || 'Something went wrong.';
      setShowMessage(true);
      setErrorMessage(msg);
    }
  };

  const handleCloseConfirm = (val) => {
    if (isFieldUpdated) {
      setOpen(true);
      if (val === 'Discard') {
        // setShowEditPromotion(false);
        // setShowEditPromotionDialog(false);
        setShowScreen(0);
      }
      if (val === 'Continue') {
        setOpen(false);
      }
    } else {
      setShowScreen(0);
    }
  };

  useEffect(() => {
    if (
      (selectedItems &&
        selectedItems.items &&
        selectedItems.items.length > 0) ||
      allItems
    ) {
      setFieldFilled(true);
    } else {
      setFieldFilled(false);
    }
  }, [selectedItems && selectedItems.items && selectedItems.items, allItems]);

  // const handleCloseEditPromotion = () => {
  //     setShowScreen(0);
  // }
  // Method to go back on first step
  const stepBack = () => {
    setActiveStep(activeStep - 1);
    // setActiveStep(0);
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      <Grid sx={{ padding: '0 2rem', mt: 1 }}>
        <Box className="Main-container location-details-wrapper promotion-management">
          <Grid
            className="location-header-container"
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Box
                  onClick={() => handleCloseConfirm()}
                  className="back-arrow-close"
                >
                  <ArrowLeft />
                </Box>
                <Box>
                  <Typography className="header-blue-32">
                    <span className="location-name">Edit Promotion</span>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Container maxWidtth="100%" className="p-0">
            <Box
              sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
            >
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                sx={{ width: '100%', background: 'none' }}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel className="step-labeling">{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Container>
          {activeStep === 0 && (
            <>
              {/* promotion Name field block */}
              <Grid container>
                <Grid sm={6} md={6} xs={12}>
                  <TextField
                    fullWidth
                    name="Promotion name"
                    label="PROMOTION NAME"
                    placeholder="PROMOTION NAME"
                    value={promotionName}
                    onChange={handleInputChange}
                    error={Boolean(promotionError)}
                    helperText={promotionError}
                    className="input-field-styling required-field"
                    InputLabelProps={{
                      className: 'label-style-create-franchisee required-label'
                    }}
                    InputProps={{}}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} className="mt-20 spaceBetween">
                {/* Promo code block */}
                <Grid item md={5} sm={5} xs={4} className="pt-0">
                  <Card className="card-block mt-2">
                    <Grid
                      container
                      spacing={2}
                      component="form"
                      className="table-container-styl font-16 text-black p-15"
                    >
                      <Grid item md={7} sm={7} xs={7}>
                        <TextField
                          fullWidth
                          name="Promo code"
                          value={promoCode}
                          label="PROMO CODE"
                          onChange={handleInputChange}
                          error={Boolean(promoCodeError)}
                          helperText={promoCodeError}
                          className="input-field-styling required-field"
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee required-label'
                          }}
                          InputProps={{
                            className: 'promocode-text'
                          }}
                          disabled
                        />
                      </Grid>
                      <Grid
                        item
                        md={5}
                        sm={5}
                        xs={5}
                        className="vertical-middle-text align-self-center"
                      >
                        {/* <Typography className="text-blue weight-600 font-14 vertical-middle-text d-flex justify-content-center">Availiable {promoAvailiable ? <span className="ml-10 mt-5"><ActiveStatus /></span> : <span className="ml-10 mt-5"><InactiveStatus /></span>}</Typography> */}
                        <Typography className="text-blue weight-600 font-14 vertical-middle-text d-flex justify-content-center">
                          {promoAvailiable ? 'Available' : 'Not Available'}{' '}
                          {promoAvailiable ? (
                            <span className="ml-10 mt-5">
                              <ActiveStatus />
                            </span>
                          ) : (
                            <span className="ml-10 mt-5">
                              <InactiveStatus />
                            </span>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item className="">
                        <Typography className="font-13 font-italic text-black invisibl">
                          <b>Note:</b> Promo code must contain both letters and
                          numbers (e.g., 'SAVE20' or 'DISCOUNT50')
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                {/* Discount field block */}
                <Grid item md={3} sm={3} xs={4} className="pt-0">
                  <Card className="card-block mt-2">
                    <Grid
                      container
                      spacing={2}
                      component="form"
                      className="table-container-style p-15"
                    >
                      <Grid item md={12} sm={12} xs={12}>
                        <TextField
                          fullWidth
                          name="Discount"
                          label="DISCOUNT"
                          value={discount}
                          onChange={handleInputChange}
                          error={Boolean(discountError)}
                          helperText={discountError}
                          className="input-field-styling required-field"
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee required-label'
                          }}
                          InputProps={{
                            maxLength: 2,
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                className="postfix-icon"
                              >
                                %
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                      <Grid item className="">
                        <Typography className="font-13 font-italic text-black invisible">
                          <b>Note:</b> Leave it blank to set
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                {/* Limit field block */}
                <Grid item md={4} sm={4} xs={4} className="pt-0">
                  <Card className="card-block mt-2">
                    <Grid
                      container
                      spacing={2}
                      component="form"
                      className="table-container-styl p-15"
                    >
                      <Grid item md={7} sm={7} xs={7}>
                        <TextField
                          fullWidth
                          name="Limit"
                          label="LIMIT"
                          value={limit}
                          onChange={handleInputChange}
                          // error={Boolean(limitError)}
                          helperText={limitError}
                          className="input-field-styling"
                          InputLabelProps={{
                            className: 'label-style-create-franchisee'
                          }}
                          InputProps={{}}
                        />
                      </Grid>
                      <Grid
                        item
                        md={4}
                        sm={4}
                        xs={4}
                        className="vertical-middle-text align-self-center"
                      >
                        <Typography className="text-availiable font-14 vertical-middle-text d-flex justify-content-center">
                          Per Dog
                        </Typography>
                      </Grid>
                      <Grid item className="">
                        <Typography className="font-13 font-italic text-black">
                          <b>Note:</b> Leave it blank to set the promo code as
                          unlimited
                        </Typography>
                        {/* <Typography className="font-13 font-italic">as unlimited</Typography> */}
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
              {/* Promotion Schedule - Never Expired Block */}
              <Card className="card-pb-4 card-block mt-20">
                <Grid
                  container
                  component="form"
                  className="table-container-style p-15"
                >
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography className="field-heading-tex font-20 header-blue-20">
                      Promotion Schedule
                    </Typography>
                  </Grid>
                  <Box className="w-100 gift-card-buttons d-flex">
                    {/* md={5} sm={5} xs={5} */}
                    <Grid
                      item
                      className="vertical-center d-flex align-items-center"
                    >
                      <Typography className="text-field check-box-text mr-25">
                        Set to never Expire
                      </Typography>
                      <Toggle
                        // fullWidth
                        // spaceBetween
                        name="NeverExpiration"
                        // labelPlacement="start"
                        value={neverExpires}
                        onChange={handleToggleInfo}
                        className="never-expire"
                      />
                    </Grid>
                    {/* <Grid item md={1} sm={1} xs={1} className='toggle-btn-block'>
                                            <Toggle
                                                fullWidth
                                                spaceBetween
                                                name="NeverExpiration"
                                                labelPlacement="start"
                                                value={neverExpires}
                                                onChange={handleToggleInfo}
                                                className="never-expire"
                                            />
                                        </Grid> */}
                  </Box>
                  <Grid container spacing={2} md={12} xs={12} sm={12}>
                    <Grid item md={6} xs={6} sm={6}>
                      {/* <CustomDatePickerForAllBrowser
                        onChange={dateSetStart}
                        // fieldValue={startDate.replaceAll('-', '/')}
                        // fieldValue={startDate && startDate?.replaceAll('-', '/')}
                        fieldValue={startDate}
                        label={'Start Date'}
                        minDate={new Date()}
                        required={Boolean(true)}
                        className="show"
                      />
                      {startDateError && (
                        <FormHelperText error>{startDateError}</FormHelperText>
                      )} */}

                      <CustomDatePickerForAllBrowser
                        label={'Start Date'}
                        onChange={dateSetStart}
                        required={Boolean(true)}
                        fieldValue={startDate}
                      />
                      {startDateError && (
                        <FormHelperText error>{startDateError}</FormHelperText>
                      )}
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                      {/* <CustomDatePickerForAllBrowser
                        onChange={dateSetEnd}
                        // fieldValue={endDate.replaceAll('-', '/')}
                        // fieldValue={endDate && endDate?.replaceAll('-', '/')}
                        fieldValue={endDate}
                        label={'End Date'}
                        // minDate shoud be greater than start date
                        // minDate={new Date(startDate)}
                        disabled={neverExpires || !startDate}
                        required={neverExpires ? Boolean(false) : Boolean(true)}
                        className="promo-enddate show"
                      />
                      {!neverExpires && endDateError && (
                        <FormHelperText error>{endDateError}</FormHelperText>
                      )} */}

                      <CustomDatePickerForAllBrowser
                        label={'End Date'}
                        onChange={dateSetEnd}
                     //   minDate={new Date(startDate)}
                        disabled={neverExpires || !startDate}
                        required={neverExpires ? Boolean(false) : Boolean(true)}
                        fieldValue={endDate}
                      />
                      {!neverExpires && endDateError && (
                        <FormHelperText error>{endDateError}</FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Card>

              {/* Promotion Usability - Use Online Block */}
              <Card className="card-pb-4 card-block mt-20">
                <Grid
                  container
                  component="form"
                  className="table-container-style p-15"
                >
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography className="field-heading-tex font-20 header-blue-20">
                      Promotion Usability
                    </Typography>
                  </Grid>
                  {/* md={11} sm={11} xs={11} */}
                  <Grid item className=" vertical-center mt-10">
                    <Typography className="text-fiel check-box-text">
                      Use online
                    </Typography>
                    <Toggle
                      fullWidth
                      spaceBetween
                      name="useOnline"
                      labelPlacement="start"
                      value={useOnline}
                      onChange={handleToggleInfo}
                      className="use-online"
                    />
                  </Grid>
                  {/* <Grid item md={1} sm={1} xs={1} className="mt-10 toggle-btn-block">
                                        <Toggle
                                            fullWidth
                                            spaceBetween
                                            name="useOnline"
                                            labelPlacement="start"
                                            value={useOnline}
                                            onChange={handleToggleInfo}
                                            className="use-online"
                                        />
                                    </Grid> */}
                </Grid>
              </Card>

              {/* Next Button */}
              <Grid container className="mt-20" justifyContent={'right'} pb={3}>
                <Grid item md={4} sm={4} xs={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    className="top-button-style"
                    onClick={() => proceedNext()}
                  >
                    Proceed to Edit Items
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
          {activeStep === 1 && (
            <>
              <Grid
                container
                component="form"
                className="table-container-style p-15"
              >
                <Grid item md={6} sm={6} xs={6}>
                  <Stack direction="row" alignItems="center" gap={6}>
                    <Typography className="check-box-tex text-blue weight-700">
                      Apply to All
                    </Typography>
                    <Toggle
                      value={allItems}
                      name="apply-all"
                      onChange={(e) => toggleButton(e)}
                    />
                  </Stack>
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="right"
                    gap={6}
                  >
                    <Typography className="check-box-tex toggle-btn-block right text-blue weight-700">
                      Allow Discount on Gift Card Purchase
                    </Typography>
                    <Toggle
                      value={allowDiscount}
                      name="allow-discount"
                      onChange={(e) => toggleButton(e)}
                    />
                  </Stack>
                </Grid>
              </Grid>

              <Card
                className="card-pb-40 add-promotion-card"
                sx={{ zIndex: 1, position: 'relative' }}
              >
                <CardHeader
                  title={'Add Items'}
                  className="table-header-name font-20 text-primary"
                />
                {allItems && (
                  <Card
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      height: '100%',
                      width: '100%',
                      // border: '0.4px solid #C5C5C5',
                      // backgroundColor: 'rgba(0, 0, 0, 0.1)',
                      backgroundColor: '#f8fbff',
                      filter: 'blur(100vh)',
                      zIndex: 2
                    }}
                  />
                )}
                {/* {selectedItemsError && (
                                <FormHelperText error>
                                    {selectedItemsError}
                                </FormHelperText>
                            )} */}
                <PromotionTargetTable
                  setSelected={setSelectedItems}
                  selected={selectedItems}
                  // tempSelectedItems={tempSelectedItems}
                  // setTempSelectedItems={setTempSelectedItems}
                />
              </Card>

              <Grid
                container
                component="form"
                justifyContent={'space-between'}
                className="table-container-style mt-20"
                pb={3}
              >
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    variant="outline"
                    type="button"
                    className="top-button-style text-primary"
                    onClick={() => stepBack()}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    className="top-button-style"
                    onClick={() => addPromotion('Save & Publish')}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Grid>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isCreated ? 'Congratulations!' : 'Discard Changes?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isCreated ? (
                <Typography className="title-text-sub">
                  You have unsaved changes. 
                  <Typography className="title-text-sub">What do you want to do?</Typography>
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  Promotion details has been updated successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleCloseConfirm('Discard')}
          >
            {isCreated ? 'Close' : 'Discard Changes'}
          </Button>
          {!isCreated && <Divider className="vertical-divider" />}
          {!isCreated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleCloseConfirm('Continue')}
            >
              Go Back to Editing
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfirmationPop}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleConfirmClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isCreated ? 'Congratulations!' : 'Save Changes?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Please confirm that you want to save your changes?
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleConfirmClose('No')}
          >
           Go Back to Editing
          </Button>
          {!isCreated && <Divider className="vertical-divider" />}

          <Button
            className="font-17 no-hover"
            onClick={() => handleConfirmClose('Yes')}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default EditPromotion;
