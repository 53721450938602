/* eslint-disable no-restricted-syntax */
/* eslint-disable no-useless-computed-key */
/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable spaced-comment */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
import React, { forwardRef, useState, useEffect } from 'react';
import axios from 'axios';
// import { toast } from 'react-toastify';
import {
  Slide,
  Typography,
  Button,
  Box,
  Tabs,
  Tab,
  Grid,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Divider,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stepper,
  Step,
  StepLabel,
  FormHelperText,
  TextField,
  DialogActions
} from '@mui/material';
import {
  CustomSelect,
  CustomTextField,
  CustomButton
} from 'src/components/Global';
// import SuccessMessage from '../PopupMessage/SuccessMessage';
import Loader from '../../../components/Loader';
import { convertTo12HourFormat, getLocalData, setLocalData } from 'src/utils';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import { ToggleWithoutLabelClick } from 'src/components/Global/ToggleWithoutLabelClick';
import { Toggle } from 'src/components/Global';
import { CustomDatePickerForAllBrowser } from 'src/components/CustomDatePickerForAllBrowser';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// get the current date
const currentDate = () => {
  let date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let newmonth;
  let newday;

  // check if month is greater then 9 then don't add 0 before month
  if (month > 9) {
    newmonth = month;
  } else {
    newmonth = `0${month}`;
  }

  // check if day is greater then 9 then don't add 0 before day
  if (day > 9) {
    newday = day;
  } else {
    newday = `0${day}`;
  }

  // This arrangement can be altered based on how we want the date's format to appear.
  let currentDate = `${newmonth}-${newday}-${year}`;
  // setStartDate(currentDate);
  return currentDate;
};

const PricingOptionEdit = ({
  openEditPage,
  setOpenEditPage,
  setIsOpenEdit,
  selectedData,
  getPricingOptionDetail,
  setShowScreen
}) => {
  let detailsData = selectedData && selectedData[0];
  let servicedata = selectedData && selectedData[0].services;
  console.log('Servies ---->', servicedata, selectedData && selectedData[0]);
  let expiresOn = selectedData && selectedData[0].expiresOn;
  const [afterActivation, setAfterActivation] = useState(false);
  const [dateOption, setDateOption] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategoryData] = useState([]);
  const [isCreated, setIsCreated] = useState(false);
  const [open, setOpen] = useState(false);
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);
  // const [activeStep, setActiveStep] = useState(0);
  // const [allCategories, setAllCategories] = useState([]);
  const [allServices, setAllServices] = useState([]);
  const [postServices, setPostServices] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [servicesValue, setServicesValue] = useState([]);
  const userId = sessionStorage.getItem('UserId');
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [pricingInfo, setPricingInfo] = useState({
    // activeStep: 0,
    category: '',
    services: [],
    name: '',
    price: '',
    free: false,
    noOfSessions: '',
    sharable: false,
    restricted: false,
    sellOnline: false,
    // includePromotion: true,
    validThrough: 'startEndDate',
    startDate: null,
    endDate: null,
    expiryDate: null,
    expiresIn: 'Months',
    expiresInValue: '',
    weekDays: false,
    timeRange: false,
    starts: 'Immediately',
    status: ''

    // creditsValidity: {
    //   starts: '',
    //   expires: {
    //     duration: '',
    //     unit: ''
    //   }
    // }
  });
  // get user role and provide access of that roles
  const isFranchisor = getLocalData('isFranchisor');
  const isPrimaryOwner = getLocalData('isPrimaryOwner');
  const locationId = getLocalData('locationId');
  const franchiseeId = getLocalData('franchiseeId');

  const [openConfirmPopUp, setOpenConfirmPopup] = useState(false);
  const [day, setDay] = useState('');
  const [duration, setDuration] = useState('');

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [validThrough, setValidThrough] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [endDateError, setEndDateError] = useState(false);

  const [assignOptions, setAssignOptions] = useState({
    default: true,
    membership: [],
    contract: []
  });
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [startTimeError, setStartTimeError] = useState('');
  const [Timetrue, setTimeTrue] = useState(false);
  const [endTimeTrue, setEndTimeTrue] = useState(false);
  const [endTimeError, setEndTimeError] = useState('');
  const [neverExpire, setNeverExpire] = useState(false);
  const [servicesData, setServicesData] = useState('');

  const [isError, setIsError] = useState({
    category: false,
    services: false,
    name: false,
    price: false,
    noOfSessions: false,
    startDate: false,
    endDate: false,
    expiryDate: false,
    expiresIn: false,
    expiresInValue: false
  });

  let initials = {
    intervalValue: 1,
    intervalDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']
  };
  const [newSchedule, setNewSchedule] = useState(initials);

  const daysOfWeek = [
    { label: 'M', value: 'Monday' },
    { label: 'T', value: 'Tuesday' },
    { label: 'W', value: 'Wednesday' },
    { label: 'T', value: 'Thursday' },
    { label: 'F', value: 'Friday' },
    { label: 'S', value: 'Saturday' },
    { label: 'S', value: 'Sunday' }
  ];

  const clearValues = () => {
    // setActiveStep(0);
    setPricingInfo({
      // activeStep: 0,
      category: '',
      services: [],
      name: '',
      price: '',
      free: false,
      noOfSessions: '',
      sellOnline: false,
      includePromotion: true,
      validThrough: 'startEndDate',
      startDate: null,
      endDate: null,
      expiryDate: null,
      expiresIn: 'Months',
      expiresInValue: '',
      weekDays: false,
      timeRange: false,
      starts: 'Immediately',
      status: ''
      // creditsValidity: {
      //   starts: '',
      //   expires: {
      //     duration: '',
      //     unit: ''
      //   }
      // }
    });

    setNewSchedule(initials);
    setStartTime('');
    setEndTime('');

    setIsError({
      category: false,
      services: false,
      name: false,
      price: false,
      noOfSessions: false,
      startDate: false,
      endDate: false,
      expiryDate: false,
      expiresIn: false,
      expiresInValue: false,
      intervalDays: false
    });

    setAssignOptions({
      default: true,
      membership: [],
      contract: []
    });

    setIsError({ startDate: null, endDate: null });
  };

  const closeInfo = () => {
    setOpen(true);
    setIsCreated(false);
  };

  const handleDialogClose = () => {
    // if (assignOptions.default) {
    //   handleUpdateService();
    // }
    setOpen(true);
    setIsCreated(false);
    // setOpenEditPage(false);
    //  clearValues();
    // handleResetStates();
  };

  useEffect(() => {
    console.log(selectedData);
    getCategories();
    getData();
  }, [selectedData]);
  useEffect(() => {
    setTimeTrue(true);
    setEndTimeTrue(true);
  }, []);

  /* useEffect(() => {
        // set the value of services
        if (servicedata) {
            // let service = servicedata.map((item) => { return `${item.id}` });
            const selectedServices = servicedata.map((item) => {
                const [Id] = item.split('|');
                return Id;
            });
            setServicesValue(servicedata);
            let postService = allServices.filter((services) => {
                return selectedServices.includes(services.sortKey);
            });
            // setServicesValue(service);
            // setPostServices(postService);
        }
    }, [servicedata]);
    */

  /* Function to get data and  fill the form fields */
  console.log(detailsData, 'detailsDatadetailsDatadetailsDatadetailsData');
  console.log(pricingInfo, 'pricingInfopricingInfo');

  const getData = () => {
    let categorySet =
      detailsData && detailsData.categoryId + '|' + detailsData.categoryName;
    // let service = servicedata.map((item) => { return `${item.id}|${item.name}` });
    let service =
      selectedData &&
      servicedata.map((item) => {
        return `${item.id}`;
      });

    // let service = detailsData && detailsData?.services.map((item) => { return `${item.id}` });

    // API data
    // Now set the value of the individual fields using setPriceInfo state
    setPricingInfo({
      category: detailsData?.categoryId,
      services: service,
      name: detailsData?.name,
      price: detailsData?.price,
      free: detailsData?.free,
      noOfSessions: detailsData?.numberOfSessions,
      sharable: detailsData?.sharable,
      restricted: detailsData?.restricted,
      sellOnline: detailsData?.sellOnline,
      // includePromotion: detailsData?.includeInPromotion,
      validThrough: 'Start Date and End Date' || detailsData?.validThrough,
      startDate: detailsData?.validThroughStartDate
        ? detailsData?.validThroughStartDate
        : '',
      endDate: detailsData?.validThroughEndDate
        ? detailsData?.validThroughEndDate
        : '',
      expiryDate: detailsData?.expiryDate,
      expiresIn: detailsData?.creditsValidityExpires?.unit,
      expiresInValue: detailsData?.creditsValidityExpires?.duration,
      weekDays:
        detailsData?.schedulingLimitation?.setWeekDays === undefined || false
          ? false
          : detailsData?.schedulingLimitation?.setWeekDays,
      timeRange: detailsData?.setTimeRange,
      // starts: detailsData?.creditsValidity?.starts,
      starts: detailsData?.creditsValidityStarts,
      creditsValidity: detailsData?.creditsValidityStarts,

      status: detailsData?.status
      // creditsValidity: {
      //   starts: detailsData?.creditsValidity?.starts,
      //   expires: {
      //     duration: detailsData?.creditsValidity?.expires?.duration,
      //     unit: detailsData?.creditsValidity?.expires?.unit
      //   }
      // }
    });

    setServicesValue(service);
    setPostServices(servicedata);

    if (detailsData?.schedulingLimitation?.weekDays === undefined || false) {
      setNewSchedule({
        intervalValue: 1,
        intervalDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']
      });
    } else {
      setNewSchedule({
        intervalValue: 1,
        intervalDays: detailsData?.schedulingLimitation?.weekDays
      });
    }

    // setCategoryId(detailsData?.categoryId);
    // setCategoryName(detailsData?.categoryName);
    // setServices(servicedata);
    // setCategory(categorySet);
    // setServicesValue(service);
    // setName(detailsData?.name);
    // setPrice(detailsData?.price);
    // setSessionNumbers(detailsData?.numberOfSessions);
    // setSellOnline(detailsData?.sellOnline);
    // setIncludesPromotion(detailsData?.includeInPromotion);
    // setPriceVisible(detailsData?.free);
    // setSelectedValue(detailsData?.validThrough);
    // setRadioButton(detailsData?.validThrough);
    // setValidThrough(detailsData?.validThrough);
    // If valid through is never expire then set never expire to true
    if (detailsData?.validThrough === 'Never Expired') {
      setNeverExpire(true);
    }
    // setStatus(detailsData?.status);

    // let startDate = expiryDate && expiryDate.startDate ? expiryDate.startDate : '';
    let startDate = expiresOn && expiresOn.startDate ? expiresOn.startDate : '';
    startDateSet(startDate);
    // let endDate = expiryDate && expiryDate.endDate ? expiryDate.endDate : '';
    let endDate = expiresOn && expiresOn.endDate ? expiresOn.endDate : '';
    setEndDate(endDate);

    console.log(detailsData?.startTime, 'startTimestartTime');

    setStartTime(
      detailsData?.startTime
        ? convert12HourTo24Hour(detailsData?.startTime)
        : ''
    );
    setEndTime(
      detailsData?.endTime ? convert12HourTo24Hour(detailsData?.endTime) : ''
    );

    // let dayFormat = expiryDate && expiryDate.endDate ? expiryDate.endDate : '';
    // let dayFormat = expiresOn && expiresOn.endDate ? expiresOn.endDate : '';
    // let day = '';
    // let month = '';

    // if (dayFormat) {
    //     const dateObj = new Date(dayFormat);
    //     day = dateObj.getDate().toString();
    //     setDay(day);
    //     month = dateObj.toLocaleString('en-US', { month: 'long' });
    //     setDuration(month);
    // }
    let expireDuration =
      expiresOn && expiresOn.expireDuration ? expiresOn.expireDuration : '';
    let key = Object.keys(expireDuration);
    let value = Object.values(expireDuration);
    if (value) setDay(value[0]);
    if (key) setDuration(key);

    getServiceById(detailsData?.categoryId);
  };

  /* Function to set value of start date in date picker */
  const startDateSet = (val) => {
    setStartDate(val);
  };

  /* Function to set value of end date in date picker */
  const endDateSet = (val) => {
    setEndDate(val);
  };

  /* Method to set radio button to be selected based on data  */

  const setRadioButton = (a) => {
    if (a === 'After Activation') {
      setAfterActivation(true);
      setDateOption(false);
    } else if (a === 'Start Date and End Date') {
      setDateOption(true);
      setAfterActivation(false);
    }
  };

  /* Function to get the categories in select drop down */
  const getCategories = () => {
    let encodedLocationId = encodeURIComponent(locationId);
    let url;
    if (isFranchisor === 'false') {
      url = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}pricing-options/getAllServiceCategories?locationId=${encodedLocationId}`;
    } else {
      url = `${process.env.REACT_APP_API_END_POINT}pricing-option/getAllServiceCategories`;
    }
    fetch(`${url}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setCategoryData(data.data.Items);
      });
  };

  /* Function to get the service values in drop down select */

  const getServiceById = (id) => {
    let encodedLocationId = encodeURIComponent(locationId);
    let url;
    if (isFranchisor === 'false') {
      url = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT
        }pricing-options/getSelectedServices?locationId=${encodedLocationId}&categoryId=${encodeURIComponent(
          id
        )}`;
    } else {
      url = `${process.env.REACT_APP_API_END_POINT}pricing-option/getSelectedServices?categoryId=${id}`;
    }
    fetch(`${url}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode && !data.error) {
          setServicesData(data.data.Items);
        }
      });
  };

  const handleToggleDay = (day) => {
    let selectedDays = [];
    if (newSchedule?.intervalDays?.includes(day)) {
      selectedDays = newSchedule.intervalDays.filter(
        (selectedDay) => selectedDay !== day
      );
    } else {
      selectedDays = [...newSchedule.intervalDays, day];
    }
    setNewSchedule({ ...newSchedule, intervalDays: selectedDays });

    console.log(selectedDays, 'selectedDays');
    setIsError({ ...isError, intervalDays: false });
  };

  const handlePricingInfo = (type, value) => {
    if (type === 'price') {
      // const pattern = /^\d{1,4}(\.\d{0,2})?$/;

      const pattern = /^\d{0,4}(\.\d{0,2})?$/;

      if (pattern.test(value)) {
        setPricingInfo({ ...pricingInfo, price: value });
      } else {
        // Handle invalid input, e.g., show an error message

        console.error('Please enter a valid price');
      }
    } else if (type === 'free' && value === true) {
      setPricingInfo({ ...pricingInfo, free: true, price: 0 });
    } else if (type === 'free' && value === false) {
      setPricingInfo({ ...pricingInfo, free: false, price: '' });
    } else if (type === 'Restricted') {
      if (value === true) {
        setPricingInfo({ ...pricingInfo, sellOnline: false, restricted: true });
      } else {
        setPricingInfo({ ...pricingInfo, restricted: false });
      }
    } else if (type === 'Sharable') {
      if (value === true) {
        setPricingInfo({ ...pricingInfo, sharable: true });
      } else {
        setPricingInfo({ ...pricingInfo, sharable: false });
      }
    } else if (type === 'SellOnline') {
      if (value === true) {
        setPricingInfo({ ...pricingInfo, sellOnline: true });
      } else {
        setPricingInfo({ ...pricingInfo, sellOnline: false });
      }
      // if(value === true) {
      // setPricingInfo({ ...pricingInfo, sellOnline: true, restricted: false });
      // } else {
      //   setPricingInfo({ ...pricingInfo, sellOnline: false });
      // }
    } else if (type === 'WeekDays') {
      if (value === true) {
        setPricingInfo({ ...pricingInfo, weekDays: true });
      } else {
        setPricingInfo({ ...pricingInfo, weekDays: false });
      }
    } else if (type === 'TimeRange') {
      setPricingInfo({ ...pricingInfo, timeRange: value });
    } else if (type === 'creditsValidity') {
      setPricingInfo({ ...pricingInfo, starts: value });
    } else if (type === 'category') {
      setPricingInfo({ ...pricingInfo, category: value, services: [] });
    } else if (type === 'services') {
      console.log('services', value);
      const selectedServices = value.map((item) => {
        const [Id] = item.split('|');
        return Id;
      });

      // setServices(selectedServices);
      setServicesValue(value);
      let postService = allServices.filter((services) => {
        return selectedServices.includes(services.sortKey);
      });
      console.log('services----2', value, selectedServices, postService);
      setPostServices(postService);
    } else {
      setPricingInfo({ ...pricingInfo, [type]: value });
    }

    // check if value then set error to false
    if (value) {
      setIsError({ ...isError, [type]: false });
    } else {
      setIsError({ ...isError, [type]: true });
    }
    // setIsError({ ...isError, [type]: false });
  };

  const handleAssignOptions = (type, value) => {
    setAssignOptions({ ...assignOptions, [type]: value });
  };

  const getCreditValue = () => {
    let text = null;
    if (pricingInfo.free) {
      text = 'Price per credit: $0.00';
    } else if (
      !pricingInfo.free &&
      pricingInfo.price > 0 &&
      pricingInfo.noOfSessions > 0
    ) {
      text = `Price per credit: $${(
        Number(pricingInfo.price) / pricingInfo.noOfSessions
      ).toFixed(2)}`;
    }

    return text;
  };

  function checkForEmptyValues() {
    let hasError = false;
    const updatedErrors = {};

    // Always check for empty values
    const alwaysCheck = ['category', 'name', 'noOfSessions'];
    for (const key of alwaysCheck) {
      const value = pricingInfo[key];
      const isEmpty = value === '';
      updatedErrors[key] = isEmpty;

      if (isEmpty) {
        hasError = true;
      }
    }

    // Check additional values based on conditions
    // const { free, validThrough, category } = pricingInfo;
    const { free, category, weekDays, timeRange, starts } = pricingInfo;

    if (!free) {
      const priceIsEmpty = pricingInfo.price === '';
      updatedErrors['price'] = priceIsEmpty;

      if (priceIsEmpty) {
        hasError = true;
      }
    }

    // if week days is true then check for interval days atleast one
    if (weekDays) {
      const intervalDaysIsEmpty = newSchedule?.intervalDays?.length === 0;
      updatedErrors['intervalDays'] = intervalDaysIsEmpty;

      if (intervalDaysIsEmpty) {
        hasError = true;
      }
    }

    // if time range is true then check for start and end time
    if (timeRange) {
      const startTimeIsEmpty = startTime === '';
      const endTimeIsEmpty = endTime === '';
      updatedErrors['startTime'] = startTimeIsEmpty;
      updatedErrors['endTime'] = endTimeIsEmpty;

      if (startTimeIsEmpty || endTimeIsEmpty) {
        hasError = true;
      }
    }

    // if starts is true then check for expires in value
    if (starts === 'On First Class') {
      const expiresInValueIsEmpty = pricingInfo.expiresInValue === '';
      updatedErrors['expiresInValue'] = expiresInValueIsEmpty;

      if (expiresInValueIsEmpty) {
        hasError = true;
      }
    }
    if (endTime < startTime) {
      // {
      // You can handle this situation, such as showing an error message
      //  console.error('End time cannot be earlier than start time');
      setEndTimeError('End time cannot be earlier than start time');
      //  setEndTime(''); // Clear the end time
    } else {
      // setEndTime(newTime);
      setEndTimeError('');
      // addPricingOption();
    }

    // if (validThrough === 'startEndDate') {
    //   const startDateIsEmpty = pricingInfo.startDate === null;
    //   const endDateIsEmpty = pricingInfo.endDate === null;
    //   updatedErrors['startDate'] = startDateIsEmpty;
    //   updatedErrors['endDate'] = endDateIsEmpty;

    //   if (startDateIsEmpty || endDateIsEmpty) {
    //     hasError = true;
    //   }
    // }

    // if (validThrough === 'neverExpire') {
    //   const expiryDateIsEmpty = pricingInfo.expiryDate === null;
    //   updatedErrors['expiryDate'] = expiryDateIsEmpty;

    //   if (expiryDateIsEmpty) {
    //     hasError = true;
    //   }
    // }

    // if (validThrough === 'afterActivation') {
    //   const expiresInIsEmpty = pricingInfo.expiresIn === '';
    //   const expiresInValueIsEmpty = pricingInfo.expiresInValue === '';
    //   updatedErrors['expiresIn'] = expiresInIsEmpty;
    //   updatedErrors['expiresInValue'] = expiresInValueIsEmpty;

    //   if (expiresInIsEmpty || expiresInValueIsEmpty) {
    //     hasError = true;
    //   }
    // }

    // if (category) {
    //   const servicesIsEmpty = servicesValue.length === 0;
    //   updatedErrors['services'] = servicesIsEmpty;

    //   if (servicesIsEmpty) {
    //     hasError = true;
    //   }
    // }

    setIsError(updatedErrors);
    return hasError;
  }

  const getNameById = (id, array) => {
    const matchedObject = array.find((obj) => obj.sortKey === id);
    const name = matchedObject ? matchedObject.name : '';
    return name;
  };

  const convertToObjects = (ids) => {
    return ids.map((id) => ({ id }));
  };

  const encodeValidThrough = () => {
    if (pricingInfo.validThrough == 'afterActivation') {
      return 'After Activation';
    } else if (pricingInfo.validThrough == 'startEndDate') {
      return 'Start Date and End Date';
    } else if (pricingInfo.validThrough == 'neverExpire') {
      return 'Never Expired';
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${month}-${day}-${year}`;
    return formattedDate;
  }

  function calculateAfterActivationDate(value, unit, type) {
    const currentDate = new Date();

    if (type == 'start') {
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      return `${month}-${day}-${year}`;
    }

    if (type == 'end') {
      const futureDate = new Date(currentDate);

      if (unit == 'day') {
        futureDate.setDate(currentDate.getDate() + Number(value));
      } else if (unit == 'month') {
        futureDate.setMonth(currentDate.getMonth() + Number(value));
      }

      const year = futureDate.getFullYear();
      const month = String(futureDate.getMonth() + 1).padStart(2, '0');
      const day = String(futureDate.getDate()).padStart(2, '0');
      return `${month}-${day}-${year}`;
    }

    // Invalid type parameter
    return null;
  }

  const getDateFormat = (type) => {
    let start = '';
    let end = '';

    if (pricingInfo.validThrough == 'startEndDate') {
      start = formatDate(pricingInfo.startDate);
      end = formatDate(pricingInfo.endDate);
    } else if (pricingInfo.validThrough == 'neverExpire') {
      start = formatDate(pricingInfo.expiryDate);
      end = '';
    } else if (pricingInfo.validThrough == 'afterActivation') {
      // start = calculateAfterActivationDate(0, 'day', 'start');
      start = calculateAfterActivationDate(0, 'Day', 'start');

      if (pricingInfo.expiresIn == 'week') {
        end = calculateAfterActivationDate(
          pricingInfo.expiresInValue * 7, // Convert weeks to days
          'day',
          'end'
        );
        // } else if (pricingInfo.expiresIn == 'month') {
      } else if (pricingInfo.expiresIn == 'Months') {
        end = calculateAfterActivationDate(
          pricingInfo.expiresInValue,
          'Months', // Use 'month' directly for months
          'end'
        );
      } else if (pricingInfo.expiresIn == 'year') {
        end = calculateAfterActivationDate(
          pricingInfo.expiresInValue,
          'year', // Use 'year' directly for years
          'end'
        );
      } else {
        // Default to days if the expiresIn value is not recognized
        end = calculateAfterActivationDate(
          pricingInfo.expiresInValue,
          pricingInfo.expiresIn,
          'end'
        );
      }
    }

    if (type == 'start') {
      return start;
    } else if (type == 'end') {
      return end;
    }
  };

  const handleProceed = (e) => {
    e.preventDefault();

    if (!checkForEmptyValues()) {
      if (
        // pricingInfo.validThrough === 'startEndDate' &&
        pricingInfo.startDate > pricingInfo.endDate
      ) {
        console.log('ifsadasdasdd');
        setEndDateError('End date should be greater than the start date');
        setIsError({
          ...isError,
          startDate: true,
          endDate: true
        });
      } else if (endTime < startTime) {
        setEndTimeError('End time cannot be earlier than start time');
        // setEndTime(''); // Clear the end time
      } else {
        // setEndTime(newTime);
        setEndTimeError('');
        setEndDateError('');
        setOpenConfirmPopup(true);
      }

      // }
      //  else {
      //   // editPricing();
      //   setOpenConfirmPopup(true);
      // }
    } else {
      // if timeraange is true then show error
      if (pricingInfo.timeRange) {
        if (startTime === '') {
          setStartTimeError('Start time is required');
        } else {
          setStartTimeError('');
        }

        if (endTime === '') {
          setEndTimeError('End time is required');
        } else {
          setEndTimeError('');
        }
      }
      console.log('Please fill all the required fields');
    }
  };

  // function convertTo12HourFormat(time24Hour) {
  //   const [hours, minutes] = time24Hour.split(':');
  //   let period = 'AM';
  //   let hour = parseInt(hours, 10);

  //   if (hour >= 12) {
  //     period = 'PM';
  //     if (hour > 12) {
  //       hour -= 12;
  //     }
  //   }

  //   // Add leading zero if hour or minute is less than 10
  //   const formattedHour = hour < 10 ? `0${hour}` : hour;
  //   const formattedMinute =
  //     parseInt(minutes, 10) < 10 ? `0${minutes}` : minutes;

  //   return `${formattedHour}:${formattedMinute} ${period}`;
  // }

  console.log(pricingInfo, 'pricingInfopricingInfopricingInfo');

  const editPricing = async () => {
    setIsLoading(true);
    setBtnLoading((prev) => {
      let toggleId;
      if (prev['add_pricing']) {
        toggleId = { ['add_pricing']: false };
      } else {
        toggleId = { ['add_pricing']: true };
      }
      return { ...toggleId };
    });

    let url;
    let sortKey = encodeURIComponent(detailsData?.sortKey);
    let encodedLocationId = encodeURIComponent(locationId);
    if (isFranchisor === 'false') {
      url = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}pricing-options/updatePricingOption?id=${sortKey}&locationId=${encodedLocationId}`;
    } else {
      url = `${process.env.REACT_APP_API_END_POINT}pricing-option/updatePricingOption?id=${sortKey}`;
    }

    let items = [];
    if (postServices.length > 0) {
      let itemServiceObj = postServices.map((serviceData) => {
        return {
          // id: serviceData.serviceId,
          id: serviceData.sortKey || serviceData.id,
          name: serviceData.name
        };
      });
      console.log(itemServiceObj, 'itemServiceObj');
      items.push(...itemServiceObj);
    }

    const data = {
      franchiseeId: franchiseeId,
      name: pricingInfo.name,
      price: pricingInfo.price,
      numberOfSessions: pricingInfo.noOfSessions,
      categoryId: pricingInfo.category,
      categoryName: getNameById(pricingInfo.category, categories),
      services: items,
      // includeInPromotion: pricingInfo.includePromotion,
      sellOnline: pricingInfo.sellOnline,
      free: pricingInfo.free,
      sharable: pricingInfo.sharable,
      restricted: pricingInfo.restricted,
      // validThrough: 'Start Date and End Date' || encodeValidThrough(),
      schedulingLimitation: {
        setWeekDays: pricingInfo.weekDays,
        weekDays: newSchedule.intervalDays
      },
      setTimeRange: pricingInfo.timeRange,
      startTime: convertTo12HourFormat(startTime),
      endTime: convertTo12HourFormat(endTime),
      creditsValidityStarts: pricingInfo.starts,
      // creditsValidityStarts: {
      //     "starts": pricingInfo.starts,
      //     "expires": {
      //         "duration": pricingInfo.expiresInValue,
      //         "unit": pricingInfo.expiresIn
      //     }
      // },
      status: pricingInfo.status,
      updatedBy: userId
    };

    if (pricingInfo.expiresInValue) {
      data.creditsValidityExpires = {
        duration: pricingInfo.expiresInValue,
        unit: pricingInfo.expiresIn
      };
    }

    // user id franchisor then remove location id and franchisee id
    if (isFranchisor === 'true') {
      delete data.locationId;
      delete data.franchiseeId;
    }

    // check if free is true then do not send price
    if (pricingInfo.free) {
      delete data.price;
    }

    // check if time range is false then do not send start and end time
    if (!pricingInfo.timeRange) {
      delete data.startTime;
      delete data.endTime;
    }

    // check if week days is false then do not send week days
    if (!pricingInfo.weekDays) {
      delete data.schedulingLimitation?.weekDays;
    }

    // check if credit validity is false then do not send credit validity
    if (!pricingInfo.starts) {
      delete data.creditsValidity;
    }
    // check if credit validity is true then do not send expires
    if (pricingInfo.expiresInValue === '') {
      // delete data.creditsValidity.expires;
    }
    // data.validThroughStartDate = pricingInfo.startDate
    //   ? formatDate(pricingInfo.startDate)
    //   : currentDate();

    if (pricingInfo.startDate)
      data.validThroughStartDate = pricingInfo?.startDate;
    if (pricingInfo.endDate) data.validThroughEndDate = pricingInfo?.endDate;

    // check if valid through is start end date then send start and end date

    if (encodeValidThrough() === 'Start Date and End Date') {
      // data.startDate = getDateFormat('start');
      // data.endDate = getDateFormat('end');
      // data.expiresOn = {
      //   startDate: getDateFormat('start'),
      //   endDate: getDateFormat('end')
      // };
      // data.expiresOn = getDateFormat('end');
      // data.fromDate = getDateFormat('start');
      // data.toDate = getDateFormat('end')
    }
    if (encodeValidThrough() === 'Never Expired') {
      data.expiresOn = { startDate: getDateFormat('start') };
      // data.fromDate = getDateFormat('start')
    }
    if (encodeValidThrough() === 'After Activation') {
      data.fromDate = getDateFormat('end');
    }
    // if (encodeValidThrough() !== 'Never Expired') {
    //   data.expiresOn = {
    //     startDate: getDateFormat('start'),
    //     endDate: getDateFormat('end')
    //   };

    // }

    try {
      const response = await axios.put(
        // `${process.env.REACT_APP_API_END_POINT}pricing-option/updatePricingOption?id=${sortKey}`,
        url,
        data
      );

      if (!response.data?.error) {
        console.log(response.data?.message);
        // setActiveStep(1);
        setIsLoading(false);
        setDialog(true);
        setOpen(true);
        // setOpenAddPricing(false);

        setIsCreated(true);
        setShowMessage(false);
        // setIsOpenEdit(false);
        // getPricingOptionDetail();
        // setSelectedTab(2)
        // set this pricing option in state if need to be set default
        // let data = {
        //   free: pricingInfo.free,
        //   sortKey: ''
        // };
        // handlePricingSelect(data);
      } else {
        setIsLoading(false);

        setDialog(false);
        // toast.error( response.data?.message || 'Something went wrong with add pricing Option');
        console.log(
          response.data?.message ||
          'Something went wrong with add pricing Option'
        );
        setShowMessage(true);
        setErrorMessage(
          response.data?.message ||
          'Something went wrong with add pricing Option'
        );
      }
      setBtnLoading(false);
    } catch (error) {
      setIsLoading(false);

      console.error(error);
      setBtnLoading(false);
      console.log('Something went wrong, Try again!');
      setShowMessage(true);
      setErrorMessage(error.message || 'Internal Server Error');
    }
  };

  // const fetchCategories = async () => {
  //   try {
  //     const categoriesResponse = await axios.get(
  //       `${process.env.REACT_APP_API_END_POINT}pricing-option/getAllServiceCategories`
  //     );

  //     if (!categoriesResponse?.data?.data?.error) {
  //       setAllCategories(categoriesResponse?.data?.data?.Items);
  //     } else {
  //       console.log( categoriesResponse.message || 'Something went wrong with Pricing categories');
  //     }
  //   } catch (error) {
  //     console.warn('API Error', error);
  //     console.log('Internal Server Error');
  //   }
  // };

  const fetchServices = async (id) => {
    let encodedLocationId = encodeURIComponent(locationId);
    let url;
    if (isFranchisor === 'false') {
      url = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}pricing-options/getSelectedServices?locationId=${encodedLocationId}&categoryId=${id}`;
    } else {
      url = `${process.env.REACT_APP_API_END_POINT}pricing-option/getSelectedServices?categoryId=${id}`;
    }
    try {
      const ServicesResponse = await axios.get(`${url}`);
      if (!ServicesResponse?.data?.data?.error) {
        setAllServices(ServicesResponse?.data?.data?.Items || []);
      } else {
        console.log(
          ServicesResponse.message ||
          'Something went wrong with Pricing Services'
        );
      }
    } catch (error) {
      console.warn('API Error', error);
      console.log('Internal Server Error');
    }
  };

  useEffect(() => {
    //fetch services whenever category option changes
    if (pricingInfo.category !== '') {
      let id = encodeURIComponent(pricingInfo.category);
      fetchServices(id);
    }
  }, [pricingInfo.category]);

  function convert12HourTo24Hour(time12Hour) {
    // Split the time string into hours, minutes, and AM/PM parts
    const timeParts = time12Hour?.split(/[:\s]+/);

    let hours = parseInt(timeParts[0]);
    const minutes = parseInt(timeParts[1]);
    const ampm = timeParts[2].toUpperCase();

    // Check for "PM" and adjust hours if necessary
    if (ampm === 'PM' && hours !== 12) {
      hours += 12;
    } else if (ampm === 'AM' && hours === 12) {
      hours = 0;
    }

    // Convert hours and minutes to a 24-hour format string
    const hours24HourFormat = hours.toString().padStart(2, '0');
    const minutes24HourFormat = minutes.toString().padStart(2, '0');

    // Return the result as a string in the 24-hour format
    return `${hours24HourFormat}:${minutes24HourFormat}`;
  }

  /* Function set time */
  const SetStartTime = (e) => {
    const timeValue = e;
    setStartTime(timeValue);
    //  console.log(timeValue, '--S--->', endTime);
    if (endTime !== '' && timeValue > endTime) {
      setEndTimeError('End time cannot be earlier than start time');
    } else {
      setEndTimeError('');
    }
  };

  /* Function set time */
  const SetEndTime = (e) => {
    const timeValue = e;
    setEndTime(timeValue);
    // Check if the new end time is earlier than the start time
    //  console.log(timeValue, '--E--->', startTime);
    if (timeValue < startTime) {
      setEndTimeError('End time cannot be earlier than start time');
    } else {
      setEndTime(timeValue);
      setEndTimeError('');
    }
  };

  const handleClose = () => {
    if (isFieldUpdated) {
      // setOpen(true);
      setOpenConfirmPopup(true);
    } else {
      setIsOpenEdit(false);
      setOpenEditPage(false);
    }
  };

  const handleCloseConfirm = (val) => {
    if (val === 'Discard') {
      setOpen(false);
      setIsOpenEdit(false);
      setOpenEditPage(false);
      getPricingOptionDetail();
    }
    if (val === 'Continue') {
      setOpen(false);
    }
  };

  const handleConfirmPopUp = (val) => {
    if (val === 'Yes') {
      setOpenConfirmPopup(false);
      editPricing();
      setShowScreen(0);
    }
    if (val === 'No') {
      setOpenConfirmPopup(false);
    }
  };

  const handleStartDateInfo = (startDate) => {
    setPricingInfo({ ...pricingInfo, startDate: startDate });
  };
  const handleEndDateInfo = (endDate) => {
    setPricingInfo({ ...pricingInfo, endDate: endDate });
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}

      <Dialog
        open={openEditPage}
        TransitionComponent={Transition}
        onClose={handleDialogClose}
        aria-describedby="details-dialog"
        className="dialog-box dialog-box-right "
        maxWidth="xs"
      >
        <DialogTitle className="alert-title align-right dialog-header">
          <Box>
            <Button
              className="details-remove-icon p-0 no-hover"
              onClick={closeInfo}
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="13" cy="13" r="13" fill="white" />
                <path
                  d="M17.7785 8.22908C17.7083 8.15877 17.625 8.10298 17.5332 8.06492C17.4415 8.02686 17.3431 8.00726 17.2438 8.00726C17.1444 8.00726 17.0461 8.02686 16.9543 8.06492C16.8625 8.10298 16.7792 8.15877 16.709 8.22908L13 11.9305L9.29097 8.2215C9.22075 8.15127 9.13738 8.09557 9.04563 8.05756C8.95388 8.01956 8.85554 8 8.75623 8C8.65692 8 8.55859 8.01956 8.46683 8.05756C8.37508 8.09557 8.29172 8.15127 8.2215 8.2215C8.15127 8.29172 8.09557 8.37508 8.05756 8.46683C8.01956 8.55859 8 8.65692 8 8.75623C8 8.85554 8.01956 8.95388 8.05756 9.04563C8.09557 9.13738 8.15127 9.22075 8.2215 9.29097L11.9305 13L8.2215 16.709C8.15127 16.7793 8.09557 16.8626 8.05756 16.9544C8.01956 17.0461 8 17.1445 8 17.2438C8 17.3431 8.01956 17.4414 8.05756 17.5332C8.09557 17.6249 8.15127 17.7083 8.2215 17.7785C8.29172 17.8487 8.37508 17.9044 8.46683 17.9424C8.55859 17.9804 8.65692 18 8.75623 18C8.85554 18 8.95388 17.9804 9.04563 17.9424C9.13738 17.9044 9.22075 17.8487 9.29097 17.7785L13 14.0695L16.709 17.7785C16.7793 17.8487 16.8626 17.9044 16.9544 17.9424C17.0461 17.9804 17.1445 18 17.2438 18C17.3431 18 17.4414 17.9804 17.5332 17.9424C17.6249 17.9044 17.7083 17.8487 17.7785 17.7785C17.8487 17.7083 17.9044 17.6249 17.9424 17.5332C17.9804 17.4414 18 17.3431 18 17.2438C18 17.1445 17.9804 17.0461 17.9424 16.9544C17.9044 16.8626 17.8487 16.7793 17.7785 16.709L14.0695 13L17.7785 9.29097C18.0667 9.00274 18.0667 8.51731 17.7785 8.22908Z"
                  fill="#003087"
                />
              </svg>
            </Button>
          </Box>
        </DialogTitle>

        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box>
              {/* <Stepper
                activeStep={activeStep}
                alternativeLabel
                sx={{
                  width: '100%',
                  background: 'transparent',
                  paddingY: '30px'
                }}
                className="custom-stepper"
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper> */}
              <Box sx={{ padding: '1rem 0 0rem 0', float: 'left' }}>
                <Typography className="header-blue-20">
                  Edit Pricing Option
                </Typography>
              </Box>

              <form className="custom-form" onSubmit={(e) => {
                if (!endTimeError) {
                  handleProceed(e)
                }
              }}>
                {/* {activeStep === 0 ? ( */}
                <Grid container spacing={2} columns={12}>
                  <Grid item xs={12}>
                    <CustomSelect
                      fullWidth
                      name="category"
                      required={Boolean(true)}
                      value={pricingInfo.category}
                      onChange={handlePricingInfo}
                      label="CATEGORY"
                      error={isError.category}
                      helperText={isError.category && 'Category is required'}
                      options={categories?.map((item) => ({
                        label: item.name,
                        // value: item.categoryId
                        value: item.sortKey
                      }))}
                      classLabelProps="input-field-dropdown-styling"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <CustomSelect
                      fullWidth
                      multiple
                      name="services"
                      // required={Boolean(true)}
                      // value={pricingInfo.services}
                      value={servicesValue}
                      onChange={handlePricingInfo}
                      label="SERVICES"
                      disabled={pricingInfo.category === ''}
                      // error={isError.servicesValue}
                      // helperText={isError.servicesValue && 'Services is required'}
                      options={allServices?.map((item) => ({
                        label: item.name,
                        value: item.sortKey
                      }))}
                      classLabelProps="input-field-dropdown-styling"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <CustomTextField
                      type="text"
                      name="name"
                      label="NAME"
                      fullWidth
                      required={Boolean(true)}
                      error={isError.name}
                      helperText={isError.name && 'Name is required'}
                      value={pricingInfo.name}
                      onChange={handlePricingInfo}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      type="text"
                      name="price"
                      label="Price"
                      fullWidth
                      //   step="1.00"

                      required={!pricingInfo.free && Boolean(true)}
                      error={!pricingInfo.free && isError.price}
                      helperText={
                        !pricingInfo.free &&
                        isError.price &&
                        'Price is required'
                      }
                      value={pricingInfo.price}
                      //   maxLength='7'

                      //   min={0}

                      onChange={handlePricingInfo}
                      disabled={pricingInfo.free}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display="flex"
                    width="100%"
                    justifyContent="flex-end"
                  >
                    <ToggleWithoutLabelClick
                      name="free"
                      label="Free"
                      labelPlacement="start"
                      value={pricingInfo.free}
                      onChange={handlePricingInfo}
                      className="marginRight: 16px"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <CustomTextField
                      type="number"
                      name="noOfSessions"
                      label="NO OF SESSIONS (CREDIT)"
                      fullWidth
                      maxLength={3}
                      min={1}
                      required={Boolean(true)}
                      error={isError.noOfSessions}
                      helperText={
                        isError.noOfSessions
                          ? 'No of sessions is required'
                          : getCreditValue()
                      }
                      value={pricingInfo.noOfSessions}
                      onChange={handlePricingInfo}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        className="sell-online-txt"
                        style={{
                          fontWeight: '900',
                          fontSize: '16px',
                          marginLeft: '10px'
                        }}
                      >
                        <b>Shareable</b>
                      </Typography>
                      <Box className="bundle-toggle">
                        <Toggle
                          value={pricingInfo.sharable}
                          name="Sharable"
                          onChange={handlePricingInfo}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  {/* <Grid item xs={12} paddingRight="4px">
                    <ToggleWithoutLabelClick
                      fullWidth
                      spaceBetween
                      name="Sharable"
                      label="Shareable"
                      labelPlacement="start"
                      value={pricingInfo.sharable}
                      onChange={handlePricingInfo}
                    />
                  </Grid> */}
                  <Grid item md={12} sm={12} xs={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        className="sell-online-txt"
                        style={{
                          fontWeight: '900',
                          fontSize: '16px',
                          marginLeft: '10px'
                        }}
                      >
                        <b>Restricted</b>
                      </Typography>
                      <Box className="bundle-toggle">
                        <Toggle
                          value={pricingInfo.restricted}
                          name="Restricted"
                          onChange={handlePricingInfo}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  {/* <Grid item xs={12} paddingRight="4px">
                    <ToggleWithoutLabelClick
                      fullWidth
                      spaceBetween
                      name="Restricted"
                      label="Restricted"
                      labelPlacement="start"
                      value={pricingInfo.restricted}
                      onChange={handlePricingInfo}
                    />
                  </Grid> */}
                  {/* <Grid item xs={12} paddingRight="4px">
                    <ToggleWithoutLabelClick
                      fullWidth
                      spaceBetween
                      name="sellOnline"
                      label="Sell Online"
                      labelPlacement="start"
                      // value={pricingInfo.restricted ? false : pricingInfo.sellOnline}
                      value={Boolean(pricingInfo.sellOnline)}
                      disabled={pricingInfo.restricted}
                      onChange={handlePricingInfo}
                    /> */}
                  <Grid item md={12} sm={12} xs={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        className="sell-online-txt"
                        style={{
                          fontWeight: '900',
                          fontSize: '16px',
                          marginLeft: '10px'
                        }}
                      >
                        <b>Sell Online</b>
                      </Typography>
                      <Box className="bundle-toggle">
                        <Toggle
                          value={Boolean(pricingInfo.sellOnline)}
                          checked={pricingInfo?.sellOnline}
                          disabled={pricingInfo.restricted}
                          name="SellOnline"
                          onChange={handlePricingInfo}
                        />
                      </Box>
                    </Box>
                  </Grid>

                  {/* <Grid item xs={12} paddingRight="4px">
                      <Toggle
                        fullWidth
                        spaceBetween
                        name="includePromotion"
                        label="Include in promotion"
                        labelPlacement="start"
                        value={pricingInfo.includePromotion}
                        onChange={handlePricingInfo}
                      />
                    </Grid> */}

                  <Grid item xs={12}>
                    <FormControl className="custom-radio-group">
                      <FormLabel
                        sx={{
                          color: '#000 !important',
                          fontWeight: '600',
                          fontSize: '18px'
                        }}
                      >
                        Valid Through
                      </FormLabel>
                    </FormControl>
                    <Grid item xs={12} mt={2}>
                      <CustomDatePickerForAllBrowser
                        label="Start Date"
                        name="startDate"
                        fullWidth
                        disablePast
                        inputFormat="MM/dd/yyyy"
                        // maxDate={pricingInfo.endDate}
                        fieldValue={pricingInfo.startDate}
                        // value={pricingInfo.startDate}
                        onChange={handleStartDateInfo}
                      // error={isError.startDate}
                      // helperText={
                      //   isError.startDate &&
                      //     pricingInfo.startDate > pricingInfo.endDate
                      //     ? 'Start date must be smaller than End date'
                      //     : 'Start date is required'
                      // }
                      />
                    </Grid>

                    <Grid item xs={12} mt={2}>
                      <CustomDatePickerForAllBrowser
                        label="End Date"
                        name="endDate"
                        fullWidth
                        disablePast
                        // disabled={pricingInfo.startDate == null}
                        // minDate={pricingInfo.startDate && pricingInfo.startDate}
                        inputFormat="MM/dd/yyyy"
                        // fieldValue={pricingInfo.endDate}
                        fieldValue={pricingInfo.endDate}
                        onChange={handleEndDateInfo}
                      // error={isError.endDate}
                      // helperText={
                      //   isError.endDate &&
                      //     pricingInfo.startDate > pricingInfo.endDate
                      //     ? 'End date must be greater than Start date'
                      //     : 'End date is required'
                      // }
                      />
                      {endDateError && (
                        <FormHelperText>{endDateError}</FormHelperText>
                      )}
                    </Grid>

                    {/* <RadioGroup
                          defaultValue="afterActivation"
                          name="radio-buttons-group"
                        >
                          <FormControlLabel
                            value="afterActivation"
                            control={<Radio />}
                            label="After Activation"
                            labelPlacement="start"
                            onChange={() =>
                              handlePricingInfo(
                                'validThrough',
                                'afterActivation'
                              )
                            }
                          />
                          <FormControlLabel
                            value="startEndDate"
                            control={<Radio />}
                            label="Start and End Date"
                            labelPlacement="start"
                            onChange={() =>
                              handlePricingInfo('validThrough', 'startEndDate')
                            }
                          />
                          <FormControlLabel
                            value="neverExpire"
                            control={<Radio />}
                            label="Never Expire"
                            labelPlacement="start"
                            onChange={() =>
                              handlePricingInfo('validThrough', 'neverExpire')
                            }
                          />
                        </RadioGroup> */}
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl className="custom-radio-group">
                      <FormLabel
                        sx={{
                          color: '#000 !important',
                          fontWeight: '600',
                          fontSize: '18px'
                        }}
                      >
                        Scheduling Limitations
                      </FormLabel>
                    </FormControl>
                    <Grid item md={12} sm={12} xs={12}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <Typography
                          className="sell-online-txt"
                          style={{
                            fontWeight: '900',
                            fontSize: '16px',
                            marginLeft: '10px'
                          }}
                        >
                          <b>Set Week Days</b>
                        </Typography>
                        <Box className="bundle-toggle">
                          <Toggle
                            value={pricingInfo.weekDays}
                            name="WeekDays"
                            onChange={handlePricingInfo}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    {/* <Grid item xs={12} paddingRight="4px">
                      <ToggleWithoutLabelClick
                        fullWidth
                        spaceBetween
                        name="WeekDays"
                        label="Set Week Days"
                        labelPlacement="start"
                        value={pricingInfo.weekDays}
                        onChange={handlePricingInfo}
                      />
                    </Grid> */}

                    {pricingInfo?.weekDays && (
                      <Grid item xs={12} sm={12} mt={1} mb={1}>
                        <div className="days-of-week-container">
                          {daysOfWeek.map(({ label, value }) => (
                            <div
                              key={value}
                              role="button"
                              tabIndex={0}
                              className={`day-circle vv-${value} ${newSchedule?.intervalDays?.includes(value)
                                  ? 'selected'
                                  : ''
                                }`}
                              onClick={() => handleToggleDay(value)}
                              onKeyDown={() => handleToggleDay(value)}
                            >
                              {label}
                            </div>
                          ))}
                        </div>
                        <span className="helper-error">
                          {isError.intervalDays && (
                            <FormHelperText>
                              Select atleast one day
                            </FormHelperText>
                          )}
                        </span>
                      </Grid>
                    )}
                    <Grid item md={12} sm={12} xs={12}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <Typography
                          className="sell-online-txt"
                          style={{
                            fontWeight: '900',
                            fontSize: '16px',
                            marginLeft: '10px'
                          }}
                        >
                          <b>Set Time Range</b>
                        </Typography>
                        <Box className="bundle-toggle">
                          <Toggle
                            value={pricingInfo.timeRange}
                            name="TimeRange"
                            onChange={handlePricingInfo}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    {/* <Grid item xs={12} paddingRight="4px">
                      <ToggleWithoutLabelClick
                        fullWidth
                        spaceBetween
                        name="TimeRange"
                        label="Set Time Range"
                        labelPlacement="start"
                        value={pricingInfo.timeRange}
                        onChange={handlePricingInfo}
                      />
                    </Grid> */}
                  </Grid>
                  {pricingInfo.timeRange && (
                    <>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          fullWidth
                          name="StartTime"
                          autocomplete="Time"
                          label="START TIME"
                          type="time"
                          defaultValue={startTime}
                          onChange={(e) => {
                            SetStartTime(e.target.value);
                            setTimeTrue(false);
                          }}
                          error={
                            pricingInfo.timeRange && Boolean(startTimeError)
                          }
                          helperText={pricingInfo.timeRange && startTimeError}
                          className={`input-field-styling ${pricingInfo.timeRange ? 'required-field' : ''
                            }}`}
                          InputLabelProps={{
                            className: `label-style-create-franchisee ${pricingInfo.timeRange ? 'required-label' : ''
                              }`,
                            shrink: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          fullWidth
                          name="EndTime"
                          autocomplete="Time"
                          label="END TIME"
                          type="time"
                          defaultValue={endTime}
                          onChange={(e) => {
                            SetEndTime(e.target.value);
                            setEndTimeTrue(false);
                          }}
                          error={pricingInfo.timeRange && Boolean(endTimeError)}
                          helperText={pricingInfo.timeRange && endTimeError}
                          className={`input-field-styling ${pricingInfo.timeRange ? 'required-field' : ''
                            }}`}
                          InputLabelProps={{
                            className: `label-style-create-franchisee ${pricingInfo.timeRange ? 'required-label' : ''
                              }`,
                            shrink: true
                          }}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12}>
                    <FormControl className="custom-radio-group">
                      <FormLabel
                        sx={{
                          color: '#000 !important',
                          fontWeight: '600',
                          fontSize: '18px'
                        }}
                      >
                        Credits Validity
                      </FormLabel>
                      <Grid
                        item
                        xs={12}
                        paddingRight="4px"
                        className="mt-10 align-left"
                      >
                        <Typography className="header-blue-">Starts</Typography>
                      </Grid>
                      <RadioGroup
                        defaultValue={pricingInfo.starts}
                        name="radio-buttons-group"
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}
                        >
                          <Typography
                            className="header-blue-"
                            style={{
                              fontSize: '15px',
                              marginLeft: '10px'
                            }}
                          >
                            Immediately
                          </Typography>
                          <Box className="bundle-toggle">
                            <FormControlLabel
                              value="Immediately"
                              control={<Radio />}
                              onChange={() =>
                                handlePricingInfo(
                                  'creditsValidity',
                                  'Immediately'
                                )
                              }
                            />
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}
                        >
                          <Typography
                            className="header-blue-"
                            style={{
                              fontSize: '15px',
                              marginLeft: '10px'
                            }}
                          >
                            On First Class
                          </Typography>
                          <Box className="bundle-toggle">
                            <FormControlLabel
                              value="On First Class"
                              control={<Radio />}
                              onChange={() =>
                                handlePricingInfo(
                                  'creditsValidity',
                                  'On First Class'
                                )
                              }
                            />
                          </Box>
                        </Box>
                        {/* <FormControlLabel
                          value="Immediately"
                          control={<Radio />}
                          label="Immediately"
                          labelPlacement="start"
                          onChange={() =>
                            handlePricingInfo('creditsValidity', 'Immediately')
                          }
                        />
                        <FormControlLabel
                          value="On First Class"
                          control={<Radio />}
                          label="On First Class"
                          labelPlacement="start"
                          onChange={() =>
                            handlePricingInfo(
                              'creditsValidity',
                              'On First Class'
                            )
                          }
                        /> */}
                      </RadioGroup>
                    </FormControl>
                    {/* {pricingInfo.validThrough === 'afterActivation' && (
                      <> */}
                    {/* <label className="label-style-create-franchisee">
                        EXPIRES IN
                      </label> */}
                    <Grid item xs={12}>
                      <FormControl className="custom-radio-group">
                        <FormLabel
                          sx={{
                            color: '#000 !important',
                            fontWeight: '600',
                            fontSize: '16px'
                          }}
                        >
                          Expires in
                        </FormLabel>
                      </FormControl>
                    </Grid>
                    <Grid container spacing={2} xs={12} className="mt-10">
                      <Grid item xs={4} sm={4} md={4}>
                        <CustomTextField
                          fullWidth
                          type="number"
                          name="expiresInValue"
                          label="#"
                          maxLength="2"
                          min={1}
                          value={pricingInfo.expiresInValue}
                          onChange={handlePricingInfo}
                          error={
                            pricingInfo.starts === 'On First Class' &&
                            isError.expiresInValue
                          }
                          // helperText={
                          //   isError.expiresInValue &&
                          //   'Expires value is required'
                          // }
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Grid>

                      <Grid item xs={8} sm={8} md={8}>
                        <CustomSelect
                          fullWidth
                          name="expiresIn"
                          value={pricingInfo.expiresIn}
                          onChange={handlePricingInfo}
                          label="MONTHS"
                          // error={isError.expiresIn}
                          // helperText={
                          //   isError.expiresIn && 'Plase select one option'
                          // }
                          options={[
                            { label: 'Months', value: 'Months' },
                            { label: 'Days', value: 'Days' }
                          ]}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Grid>
                    </Grid>
                    {/* </>
                    )} */}
                  </Grid>

                  {/* {pricingInfo.validThrough === 'afterActivation' && (
                      <>
                        <Grid item xs={12}>
                          <CustomTextField
                            fullWidth
                            type="number"
                            name="expiresInValue"
                            label="EXPIRES IN"
                            maxLength="2"
                            value={pricingInfo.expiresInValue}
                            onChange={handlePricingInfo}
                            error={isError.expiresInValue}
                            helperText={
                              isError.expiresInValue && 'Value is required'
                            }
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <CustomSelect
                            fullWidth
                            name="expiresIn"
                            value={pricingInfo.expiresIn}
                            onChange={handlePricingInfo}
                            label="MONTH/DAY"
                            error={isError.expiresIn}
                            helperText={
                              isError.expiresIn && 'Plase select one option'
                            }
                            options={[
                              { label: 'Months', value: 'month' },
                              { label: 'Days', value: 'day' }
                            ]}
                          />
                        </Grid>
                      </>
                    )} */}

                  {/* {pricingInfo.validThrough === 'startEndDate' && (
                      <>
                        <Grid item xs={12}>
                          <CustomDatePicker
                            label="Start Date"
                            name="startDate"
                            fullWidth
                            disablePast
                            inputFormat="MM/dd/yyyy"
                            value={pricingInfo.startDate}
                            onChange={handlePricingInfo}
                            error={isError.startDate}
                            helperText={
                              isError.startDate &&
                              pricingInfo.startDate > pricingInfo.endDate
                                ? 'Start date must be smaller than End date'
                                : 'Start date is required'
                            }
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <CustomDatePicker
                            label="End Date"
                            name="endDate"
                            fullWidth
                            disablePast
                            disabled={pricingInfo.startDate == null}
                            minDate={
                              pricingInfo.startDate && pricingInfo.startDate
                            }
                            inputFormat="MM/dd/yyyy"
                            value={pricingInfo.endDate}
                            onChange={handlePricingInfo}
                            error={isError.endDate}
                            helperText={
                              isError.endDate &&
                              pricingInfo.startDate > pricingInfo.endDate
                                ? 'End date must be greater than Start date'
                                : 'End date is required'
                            }
                          />
                        </Grid>
                      </>
                    )} */}

                  {/* {pricingInfo.validThrough === 'neverExpire' && (
                      <Grid item xs={12}>
                        <CustomDatePicker
                          label="Start Date"
                          name="expiryDate"
                          fullWidth
                          disablePast
                          inputFormat="MM/dd/yyyy"
                          value={pricingInfo.expiryDate}
                          onChange={handlePricingInfo}
                          error={isError.expiryDate}
                          helperText={
                            isError.expiryDate && 'Start date is required'
                          }
                        />
                      </Grid>
                    )} */}

                  <Grid item xs={12} className="fullwidth-btn">
                    <CustomButton
                      variant="contained"
                      fullWidth
                      // loading={btnLoading['add_pricing']}
                      onClick={() => {
                        if (!endTimeError) {
                          document.getElementById('pricing-save').click()
                        }
                      }
                      }
                    >
                      Update
                    </CustomButton>
                    <button hidden type="submit" id="pricing-save">
                      update
                    </button>
                  </Grid>
                </Grid>
                {/* ) : (
                  <Grid Grid container spacing={2} columns={12}>
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      width="100%"
                      justifyContent="flex-start"
                      marginLeft="16px"
                    >
                      <Toggle
                        name="default"
                        label="Set Default Pricing"
                        labelPlacement="end"
                        value={assignOptions.default}
                        onChange={handleAssignOptions}
                        className="marginRight: 16px"
                      />
                    </Grid>
                    <FormHelperText
                      sx={{
                        marginLeft: '100px',
                        marginTop: '-5px',
                        color: '#6d6b6b',
                        fontSize: '13px',
                        letterSpacing: '0.4px'
                      }}
                    >
                      This applies Pricing Option to Clients with no Membership
                      or Contract
                    </FormHelperText>

                    <Grid item xs={12} marginTop={2}>
                      <CustomSelect
                        fullWidth
                        multiple
                        name="membership"
                        value={assignOptions.membership}
                        onChange={handleAssignOptions}
                        label="ASSIGN TO MEMBERSHIP (OPTIONAL)"
                        options={[
                          { label: 'option 1', value: '1' },
                          { label: 'option 2', value: '2' },
                          { label: 'option 3', value: '3' },
                          { label: 'option 4', value: '4' }
                        ]}
                      />
                    </Grid>

                    <Grid item xs={12} marginTop={1}>
                      <CustomSelect
                        fullWidth
                        multiple
                        name="contract"
                        value={assignOptions.contract}
                        onChange={handleAssignOptions}
                        label="ASSIGN TO CONTRACT (OPTIONAL)"
                        options={[
                          { label: 'option 1', value: '1' },
                          { label: 'option 2', value: '2' },
                          { label: 'option 3', value: '3' },
                          { label: 'option 4', value: '4' }
                        ]}
                      />
                    </Grid>

                    <Grid item xs={12} className="fullwidth-btn" marginTop={2}>
                      <CustomButton
                        variant="contained"
                        fullWidth
                        loading={btnLoading['update_service']}
                        onClick={handleDialogClose}
                      >
                        Save & Close
                      </CustomButton>
                    </Grid>
                  </Grid>
                )} */}
              </form>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
      </Dialog>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        // keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isCreated ? 'Congratulations!' : 'Discard Changes?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isCreated ? (
                <Typography className="title-text-sub">
                  You have unsaved changes. 
                  <Typography className="title-text-sub">What do you want to do?</Typography>
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  Pricing option details has been updated successfully!
                 </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleCloseConfirm('Discard')}
          >
            {isCreated ? 'Close' : 'Discard Changes'}
          </Button>
          {!isCreated && <Divider className="vertical-divider" />}
          {!isCreated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleCloseConfirm('Continue')}
            >
              Go Back to Editing
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfirmPopUp}
        TransitionComponent={Transition}
        //  keepMounted
        onClose={() => handleConfirmPopUp('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          Save Changes
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Please confirm that you want to save your changes?
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleConfirmPopUp('No')}
          >
           Go Back to Editing
          </Button>
          {!isCreated && <Divider className="vertical-divider" />}
          {!isCreated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleConfirmPopUp('Yes')}
            >
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PricingOptionEdit;
