/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { CustomButton, CustomSelect } from 'src/components/Global';
import {
  Box,
  // FormControl,
  // InputLabel,
  Card,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  // Select,
  MenuItem,
  Typography,
  useTheme,
  CardHeader,
  TextField,
  Avatar,
  Grid,
  // Dialog,
  // DialogContent,
  // DialogContentText,
  // DialogTitle,
  // Slide,
  Menu,
  Container,
  Checkbox
} from '@mui/material';

// import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SearchIcon from '@mui/icons-material/Search';
// import { useNavigate } from 'react-router-dom';
import Filters from './Filters';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
// import AccessTimeIcon from '@mui/icons-material/AccessTime';
// import ListIcon from '@mui/icons-material/List';
import PersonIcon from '@mui/icons-material/Person';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import './Staff.css';
import './Stafflist.css';
import ClockIn from './clockIn';
import EditClock from './EditClock';
import StaffDetails from './StaffDetails';
import StaffProfile from './StaffProfile/index';
import AllActiveTasks from './AllActiveTasks';
import { getLocalData } from '../../../utils';
import AddTask from './AddTask/AddTask';
import { ActiveStatus, InactiveStatus } from 'src/assets/SVG/SvgIcons';
import Availibility from './Availability';
import EditStaff from './EditStaff';
// import StaffService from './StaffService';

// refreshFranchiseeData
// Main methdo to draw the franchisee table
const DrawStaffTable = ({
  staffLists,
  addBtnClicked,
  sideBarfilters,
  setSidebarFilters,
  allTask,
  refreshStaffData,
  refreshTaskData,
  showScreen,
  setShowScreen,
  selectedStatus,
  setSelectedStatus,
  handleBulkAdd,
  btnLoading,
  selectedStaffLists,
  setSelectedStaffLists,
  getStaff
}) => {
  /* Function to redirect on to the other route */
  // const navigate = useNavigate();
  // const classes = useStyles();
  // const [selectedStaffLists] = useState([]);
  const containerRef = React.useRef(null);
  // const [selectedFranchiseeItem, setSelectedFranchiseeItem] = useState([]);
  const [displayFilterForm, setdisplayFilterForm] = useState(false);

  // const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [franchiseecity, setFranchiseecity] = useState([]);
  const [openClock, setOpenClock] = React.useState(false);
  const [editClock, setEditClock] = React.useState(false);
  const [clockIn, setClockIn] = React.useState(false);
  //  const [staffItemDetails, setStaffItemDetails] = React.useState([]);
  const [staffId, setStaffId] = React.useState('');
  const [getStaffItem, setGetStaffItem] = React.useState([]);
  const [anchor, setAnchor] = useState(null);
  const [clockType, setClockType] = useState('');
  // get the logged in user id
  const loggedInUserId = sessionStorage.getItem('UserId');
  const [openStaffDetails, setOpenStaffDetails] = React.useState(false);
  const [staffProfile, setStaffProfile] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  //  const [staffId, setStaffId] = useState("");
  const [openAddTask, setOpenAddTask] = useState(false);
  const [openAddTaskDialog, setopenAddTaskDialog] = useState(false);
  const [taskApiUpdate, setTaskApiUpdate] = useState(false)

  /* Pagination variables */
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // console.log(openEdit, clockIn, staffProfile);

  // get user role and provide access of that roles
  const isFranchisor = getLocalData('isFranchisor');
  const isPrimaryOwner = getLocalData('isPrimaryOwner');
  const locationId = getLocalData('locationId');
  // console.log(isFranchisor, locationId, isPrimaryOwner);

  const [staffDetail, setStaffDetail] = useState(null);
  const [availabilityType, setAvailabilityType] = useState('');

  const openMenu = (staffItem, event) => {
    // setStaffId(staffItem.staffId);
    event.preventDefault();
    event.stopPropagation();
    setGetStaffItem(staffItem);

    setAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchor(null);
  };

  const onMenuItemClick = (staffItem, event, value) => {
    event.preventDefault();
    event.stopPropagation();

    setAnchor(null);

    if (value === 'availability') {
      setShowScreen(4);
      getStaffDetailById();
    }
    if (value === 'clockIn') {
      setClockIn(true);
      setClockType('ClockIn');
      setOpenClock(true);
      // getStaffDetails(staffId);
      setOpenClock(true);
    } else if (value === 'clockOut') {
      setClockIn(true);
      setClockType('ClockOut');
      setOpenClock(true);
      // getStaffDetails(staffItem.staffId);
    }
  };

  // const getStaffDetails = (staffId) => {
  //   let encodestaffId = encodeURIComponent(staffId);
  //   fetch(
  //     `${process.env.REACT_APP_API_END_POINT}staff/getStaffProfileDetails?id=${encodestaffId}`,
  //     {
  //       method: 'GET',
  //       headers: {
  //         Accept: 'application/json',
  //         'content-Type': 'application/json',
  //         'Access-Control-Allow-Origin': '*'
  //       }
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.statusCode && !data.error) {
  //         setStaffItemDetails(data.data.Items);
  //       }
  //     });
  // };

  // Method to close the clock dialog
  // const handleClockClose = (val) => {
  //   setOpenClock(false);
  //   if (val === 'clockIn') {
  //     setOpen(true);
  //   }
  // };

  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));

  const classes = useStyles();
  const getFilterSize = () => {
    return displayFilterForm ? 300 : 0;
  };

  // const [filters, setFilters] = useState({
  //   franchiseeName: null
  // });

  if (addBtnClicked) {
    // set edit mode true
    setOpenEdit(true);
    handleClickOpen();
  }

  const handleSearchTextChange = (event) => {
    const searchText = event.target.value.toLowerCase();
    setSearchText(searchText);
    setPage(0); // Reset page when search text changes
  };

  // Below logic for convert same staff multiple rows as per the Roles for manage clock in and out.
  // console.log('VV Data Length------>', staffLists);
  let resultData = [];
  staffLists.forEach((item) => {
    if (item?.roles?.length > 0) {
      item?.roles?.forEach((data) => {
        let obj = { ...item };

        let clockTime = item?.clockTime.filter(
          (clockItem) => clockItem.role === data.role
        );

        obj.roles = [data];

        obj.clockTime = [...clockTime];

        resultData.push(obj);
      });
    } else {
      resultData.push(item);
    }
  });
  staffLists = [...resultData];
  // console.log('VV Data Length------>', resultData);

  const filteredStaffLists = staffLists.filter((item) => {
    return Object.values(item).some((value) =>
      value ? value.toString()?.toLowerCase()?.includes(searchText) : ''
    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };

  const paginatedStaffLists = filteredStaffLists.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const showFilter = () => {
    if (franchiseecity.length === 0) getCities();
    if (roleList.length === 0) getUserRoles();
    if (locationList.length === 0) getLocations();
    setdisplayFilterForm(!displayFilterForm);
    // console.log(displayFilterForm, 'displayFilterForm-------');
  };

  // const [isExpanded] = useState(false);
  // const [arrowDirection] = useState('down');
  const theme = useTheme();
  // Method to open the dialog
  const handleClickOpen = () => {
    // setOpen(true);
  };

  // Method to close the dialog
  // const handleClose = (val) => {
  //   if (val === 'Delete') {
  //     // call the api to delete the location
  //     // deleteLocation();
  //   }
  //   // trigger the parent method to refresh the data
  //   // refreshFranchiseeData();
  //   setOpen(false);
  // };

  const handleEditStaff = (staffItem, e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(staffItem);
    setGetStaffItem(staffItem);
    setShowScreen(5);
  };

  // View Staff details
  const handleViewStaff = (staffItem, e) => {
    // e.preventDefault();
    e.stopPropagation();
    if (
      e.target.classList.contains('MuiBackdrop-root') ||
      e.target.nodeName.toLowerCase() === 'ul' ||
      e.target.nodeName.toLowerCase() === 'li' ||
      e.target.nodeName.toLowerCase() === 'input'
    ) {
      return;
    }

    setGetStaffItem(staffItem);
    setOpenStaffDetails(true);
    setStaffId(staffId);
  };

  const handleViewStaffProfile = (staffItem, e) => {
    e.preventDefault();
    e.stopPropagation();
    setGetStaffItem(staffItem);
    // setOpenStaffDetails(true);
    // if (e && e.target && e.target.nodeName !== 'INPUT') {
    setStaffProfile(true);
    handleClickOpen();
    setStaffId(staffItem.sortKey);
    setShowScreen(3);
    // console.log(staffId, 'staffId-------');
    // }
  };

  // Methos to get the cities
  const getCities = async () => {
    fetch(`${process.env.REACT_APP_API_END_POINT}locations/getAllCities`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode && !data.error) {
          let Item = data.data.Items;
          setFranchiseecity(Item);
        }
      });
  };

  // Get roles method to get the list of roles to display in the filter

  const getUserRoles = async () => {
    // Now show the loader
    // setIsLoading(true);

    fetch(
      `${process.env.REACT_APP_API_END_POINT}users/getFranchiseeUserRoles`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode && !data.error) {
          // Set the list of location
          setRoleList(data.data.Items);
          // Now hide the loader
          // setIsLoading(false);
        }
      });
  };

  // Methos to get the location list
  const getLocations = async () => {
    let getLocationsUrl = `${process.env.REACT_APP_API_END_POINT}locations?limit=1000`;
    let encodedLocationId = encodeURIComponent(locationId);

    if (isFranchisor === 'false' && isPrimaryOwner === 'true' && locationId) {
      getLocationsUrl = `${process.env.REACT_APP_API_END_POINT}locations?limit=1000&locationId=${encodedLocationId}`;
    } else if (
      isFranchisor === 'false' &&
      isPrimaryOwner === 'false' &&
      locationId
    ) {
      getLocationsUrl = `${process.env.REACT_APP_API_END_POINT}locations?limit=1000&locationId=${encodedLocationId}`;
    }
    // fetch(`${process.env.REACT_APP_API_END_POINT}locations?limit=500`, {
    fetch(`${getLocationsUrl}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode && !data.error) {
          let Item = data.data.Items;
          setLocationList(Item);
        }
      });
  };

  // Methos to get the location list
  const getStaffDetailById = async () => {
    let encodedLocationId = encodeURIComponent(locationId);
    let encodedStaffId = encodeURIComponent(getStaffItem.sortKey);
    let Url = `${process.env.REACT_APP_API_END_POINT}staff/getStaffProfileDetails?locationId=${encodedLocationId}&staffId=${encodedStaffId}`;

    fetch(`${Url}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode && !data.error) {
          let Item = data?.data?.Items;
          setStaffDetail(Item[0]);
          setAvailabilityType(Item[0]?.availableSchedules[1]?.availabilityType);
        }
      });
  };

  // Method to open the task modal
  const openTaskModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowScreen(2);
    // setShowScreen()
    // set view mode true
    setStaffProfile(false);
    // handleClickOpen();
  };

  const handleClockEdit = (staffItem, e) => {
    e.preventDefault();
    e.stopPropagation();
    setEditClock(true);
    setGetStaffItem(staffItem);
  };
  // console.log(getStaffItem, 'staffItemstaffItemstaffItem');
  const handleOpenAddTask = () => {
    setOpenAddTask(true);
    setopenAddTaskDialog(true);
  };

  // Add new staff
  // const addStaff = () => {
  //   setShowScreen(1);
  //   // handleClickOpen();
  // };

  const handleSelectAllStaffItems = (event) => {
    setSelectedStaffLists(
      event.target.checked
        ? staffLists.map((staffItem) => staffItem.sortKey)
        : []
    );
  };

  const handleSelectOneStaffItem = (event, staffItemId) => {
    event.stopPropagation();
    // event.preventDefault();
    if (!selectedStaffLists.includes(staffItemId)) {
      setSelectedStaffLists((prevSelected) => [...prevSelected, staffItemId]);
    } else {
      setSelectedStaffLists((prevSelected) =>
        prevSelected.filter((id) => id !== staffItemId)
      );
    }
  };
  const selectedSomeStaffItems =
    selectedStaffLists.length > 0 &&
    selectedStaffLists.length < staffLists.length;
  const selectedAllStaffItems = selectedStaffLists.length === staffLists.length;

  // console.log(selectedSomeLocationItems, "selectedAllLocationItems", selectedAllLocationItems);
  // console.log("selectedLocationLists", selectedStaffLists);

  // HTML rendering started

  // let modifiedData = {
  //   scheduleData: [
  //     {
  //       Id: 1,
  //       Subject: 'Explosion of Betelgeuse Star',
  //       Location: 'Space Center USA',
  //       StartTime: '2023-08-31T04:00:00.000Z',
  //       EndTime: '2023-08-31T05:30:00.000Z',
  //       CategoryColor: '#1aaa55'
  //     },
  //     {
  //       Id: 2,
  //       Subject: 'Thule Air Crash Report',
  //       Location: 'Newyork City',
  //       StartTime: '2023-08-31T06:30:00.000Z',
  //       EndTime: '2023-08-31T08:30:00.000Z',
  //       CategoryColor: '#357cd2'
  //     },
  //     {
  //       Id: 3,
  //       Subject: 'Blue Moon Eclipse',
  //       Location: 'Space Center USA',
  //       StartTime: '2023-08-31T04:00:00.000Z',
  //       EndTime: '2023-08-31T05:30:00.000Z',
  //       CategoryColor: '#7fa900'
  //     },
  //     {
  //       Id: 4,
  //       Subject: 'Meteor Showers in 2021',
  //       Location: 'Space Center USA',
  //       StartTime: '2023-08-31T07:30:00.000Z',
  //       EndTime: '2023-08-31T09:00:00.000Z',
  //       CategoryColor: '#ea7a57'
  //     }
  //   ]
  // };

  // Initialize an array to store the modified scheduleData

  console.log(staffDetail, 'staffDetail');
  let modifiedData = {
    scheduleData: []
  };

  const getDay = (day, startTimeParts, endTimeParts) => {
    let date;
    switch (day) {
      case 'Sunday':
        date = '10/01/2023';
        break;
      case 'Monday':
        date = '10/02/2023';
        break;
      case 'Tuesday':
        date = '10/03/2023';
        break;
      case 'Wednesday':
        date = '10/04/2023';
        break;
      case 'Thursday':
        date = '10/05/2023';
        break;
      case 'Friday':
        date = '10/06/2023';
        break;
      default:
        date = '10/07/2023';
        break;
    }
    let startDate = new Date(
      `${date} ${startTimeParts[0]} ${startTimeParts[1]}`
    );
    let endDate = new Date(`${date} ${endTimeParts[0]} ${endTimeParts[1]}`);
    return { startDate: startDate, endDate: endDate };
  };

  staffDetail?.availableSchedules[0]?.availableSchedules?.forEach(
    (schedule, index) => {
      // let startTimeParts = schedule?.startTime?.split(' ');
      let isHoliday = availabilityType === 'holiday' ? '#FF0000' : '#1aaa55';
      // let isAvailable = availabilityType === 'holiday';

      // let endTimeParts = schedule?.endTime?.split(' ');
      // let dateObj = getDay(schedule?.day, startTimeParts, endTimeParts);
      modifiedData.scheduleData.push({
        Id: index + 1,
        Subject: schedule?.day,
        // Subject: [
        //   !isAvailable
        //     ? `Available on ${schedule?.day}`
        //     : `On holiday on ${schedule?.day}`
        // ],
        // Location: schedule?.locationName,
        // StartTime: dateObj.startDate.toISOString(),
        StartTime: schedule?.startTime,
        EndTime:schedule?.endTime,
        // EndTime: dateObj.endDate.toISOString(),
        CategoryColor: isHoliday
      // let startTimeParts = schedule?.startTime?.split(' ');
      // let isHoliday = availabilityType === 'holiday' ? '#FF0000' : '#1aaa55';
      // let isAvailable = availabilityType === 'holiday';

      // let endTimeParts = schedule?.endTime?.split(' ');
      // let dateObj = getDay(schedule?.day, startTimeParts, endTimeParts);
      // modifiedData.scheduleData.push({
      //   Id: index + 1,
      //   Subject: [
      //     !isAvailable
      //       ? `Available on ${schedule?.day}`
      //       : `On holiday on ${schedule?.day}`
      //   ],
      //   // Location: schedule?.locationName,
      //   StartTime: dateObj.startDate.toISOString(),
      //   EndTime: dateObj.endDate.toISOString(),
      //   CategoryColor: isHoliday
      });
    }
  );
console.log(staffDetail,'staffDetailstaffDetail');
  return (
    <>
      {(() => {
        switch (showScreen) {
          case 2:
            return (
              <>
                <AllActiveTasks
                  setShowScreen={setShowScreen}
                  refreshTaskData={refreshTaskData}
                  AllTasks={allTask}
                  openAddTask={openAddTask}
                  setOpenAddTask={setOpenAddTask}
                  openAddTaskDialog={openAddTaskDialog}
                  setopenAddTaskDialog={setopenAddTaskDialog}
                />
              </>
            );
          case 3:
            return (
              <>
                <Box justifyContent="center" direction="column">
                  <StaffProfile
                    data={staffId}
                    setShowScreen={setShowScreen}
                    handleOpenAddTask={handleOpenAddTask}
                    taskApiUpdate={taskApiUpdate}
                    onMenuItemClick={onMenuItemClick}
                  />
                </Box>
              </>
            );

          case 4:
            return (
              <Box padding={4}>
                {/* <StaffScheduler
                      showScreen={showScreen}
                      setShowScreen={setShowScreen}
                      refreshData={closeAddClient}
                      Transition={Transition}
                    /> */}
                <Availibility
                  dataSource={modifiedData}
                  setShowScreen={setShowScreen}
                  staffItem={getStaffItem}
                  getStaffDetailById={getStaffDetailById}
                />
              </Box>
            );

          case 5:
            return (
              <Box justifyContent="center" direction="column">
                <EditStaff
                  staffItem={getStaffItem}
                  getStaffDetailById={getStaffDetailById}
                  setShowScreen={setShowScreen}
                  getStaff={getStaff}
                />
              </Box>
            );
            // case 6:
            // return (
            //   <Box justifyContent="center" direction="column">
            //     <StaffService
            //       staffItem={getStaffItem}
            //       getStaffDetailById={getStaffDetailById}
            //       setShowScreen={setShowScreen}
            //       getStaff={getStaff}
            //     />
            //   </Box>
            // );
          default:
            return (
              <>
                <Grid sx={{ padding: '0 2rem' }}>
                  <Container
                    maxWidth="100%"
                    sx={{ mt: 1 }}
                    className="main-container relative"
                  >
                    <Grid
                      container
                      justifyContent="end"
                      alignItems="center"
                      className="add-btn-container"
                    >
                      {/* <Grid item>
                      <Button
                        sx={{ mt: { xs: 0, md: 0 } }}
                        variant="contained"
                        className="btn-primary"
                        onClick={addStaff}
                      >
                        Add New
                      </Button>
                    </Grid> */}
                      {/* <Grid item>
            <Button
              sx={{
                mt: { xs: 0, md: 0 },
                border: '2px solid #003087 !important',
                color: '#003087 !important',
                padding: '6px 20px !important'
              }}
              variant="outlined"
              className="btn-primary"
              disabled
            >
              Manage
            </Button>
          </Grid> */}
                    </Grid>
                  </Container>
                  <Grid>
                    <Card
                      sx={{
                        padding: '10px',

                        mb: 2
                      }}
                    >
                      <Card sx={{ padding: '10px', mb: 1 }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: 20,
                              fontWeight: 600,
                              color: '#003087'
                            }}
                          >
                            All Active Tasks{' '}
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              letterSpacing: '0.135em',
                              cursor: 'pointer'
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: 10,
                                fontWeight: 700,
                                color: '#003087',
                                textDecoration: 'underline',
                                marginRight: '10px'
                              }}
                              onClick={handleOpenAddTask}
                            >
                              +Add New Task
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: 10,
                                fontWeight: 600,
                                color: '#003087',
                                textDecoration: 'underline'
                              }}
                              onClick={(e) => openTaskModal(e)}
                            >
                              View All
                            </Typography>
                          </Box>
                        </Box>
                      </Card>
                      <Card sx={{ overflowY: 'auto', maxHeight: '300px' }}>
                        {/* Start Now */}
                        {(allTask.length > 0 &&
                          allTask.map((taskItem, index) => {
                            return (
                              index <= 5 && (
                                <Grid
                                  xs={12}
                                  container
                                  sx={{
                                    p: 1,
                                    border: ' 0.4px solid #E7EFF9',
                                    borderRadius: '5px',
                                    backgroundColor: '#FFFFFF'
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={1}
                                    container
                                    sx={{
                                      flex: 1,
                                      justifyContent: 'center',
                                      alignItems: 'flex-start',
                                      marginTop: '3px'
                                    }}
                                  >
                                    {/* <ListIcon
                sx={{
                  fontSize: 25,
                  color: '#003087',
                  border: '2px solid #003087',
                  borderRadius: '30px'
                }}
              /> */}
                                    <div>
                                      <img
                                        src="/static/images/logo/list.png"
                                        alt="TaskIcon"
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item xs={10}>
                                    <Typography
                                      sx={{
                                        fontWeight: 700,
                                        pb: 1
                                      }}
                                    >
                                      <span className="task-name mr-10">
                                        {taskItem.title}{' '}
                                      </span>
                                      {/* <span className='clock-container'><AccessTimeIcon className='clock-icon'
                      sx={{ fontSize: 15, color: '#003087', mr: 1 }}
                    /></span> */}
                                      <span className="mr-10 text-blue">
                                        {taskItem.dueDate}
                                      </span>
                                      {/* <span className="text-blue">11:00 AM</span> */}
                                      <span>{taskItem.dueTime}</span>
                                    </Typography>

                                    <Typography
                                      sx={{ color: '#A8A4A4' }}
                                      className="word-break pr-10 align-justify"
                                    >
                                      {taskItem.description}
                                    </Typography>
                                  </Grid>

                                  {/* <Divider /> */}
                                  <Grid
                                    item
                                    xs={1}
                                    container
                                    sx={{
                                      flex: 1,
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      borderLeft: {
                                        xs: 0,
                                        md: '1px solid rgba(0, 48, 135, 0.1)'
                                      }
                                    }}
                                  >
                                    <Typography>
                                      {(taskItem &&
                                        taskItem?.assignedTo &&
                                        taskItem?.assignedTo?.length > 0 &&
                                        taskItem?.assignedTo[0]?.id) ===
                                        loggedInUserId
                                        ? 'Personal'
                                        : 'Staff'}
                                    </Typography>
                                    <PersonIcon
                                      sx={{
                                        fontSize: '15px !important',
                                        color:'#003087',
                                        ml: 1
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              )
                            );
                          })) ||
                          'No Task Found'}
                      </Card>
                      {/* End Now */}
                    </Card>
                  </Grid>
                  <Grid xs={12}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-end"
                    >
                      <Grid
                        item
                        sx={{
                          marginTop: '10px',
                          marginBottom: '20px'
                        }}
                      >
                        <Button
                          onClick={showFilter}
                          ref={containerRef}
                          variant={displayFilterForm ? 'contained' : 'outlined'}
                        >
                          Filter (All)
                          <KeyboardArrowDownRoundedIcon
                            className={clsx(
                              !displayFilterForm && classes.closeY,
                              displayFilterForm && classes.openY
                            )}
                          />
                        </Button>
                      </Grid>

                      <Grid
                        xs={12}
                        sm={8}
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          marginTop: '10px',
                          marginBottom: '20px'
                        }}
                        className="top-filter-cont"
                      >
                        {/* <Box width={170} mr={2}>
              <FormControl fullWidth variant="outlined">
                <InputLabel className="status-field-lbl">Staff View</InputLabel>
                <Select
                  // value={filters.name}
                  // value={"Staff View"}
                  defaultValue={'Location: (All)'}
                  onChange={handleStatusChange}
                  autoWidth
                  className="status-field field-label-css"
                >
                  <MenuItem disabled value="Location: (All)">
                    <em className="drodown_menu">Location: (All)</em>
                  </MenuItem>
                  <MenuItem value={'Location 1'}>Location 1</MenuItem>
                  <MenuItem value={'Location 2'}>Location 2</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box width={150} mr={2}>
              <FormControl fullWidth variant="outlined">
                <InputLabel className="status-field-lbl">Staff View</InputLabel>
                <Select
                  // value={filters.name}
                  // value={"Staff View"}
                  defaultValue={'Staff View'}
                  onChange={handleStatusChange}
                  autoWidth
                  className="status-field field-label-css"
                >
                  
                  <MenuItem disabled value="Staff View">
                    <em className="drodown_menu">Staff View</em>
                  </MenuItem>
                  <MenuItem value={'Staff 1'}>Staff 1</MenuItem>
                  <MenuItem value={'Staff 2'}>Staff 2</MenuItem>
                </Select>
              </FormControl>
            </Box> */}
                        {/* <Box width={150} mr={2}>
              <FormControl fullWidth variant="outlined">
                <InputLabel className="status-field-lbl">Roles</InputLabel>
                <Select
                  // value={filters.name}
                  defaultValue={'Roles'}
                  // defaultValue={"Roles"}
                  onChange={handleStatusChange}
                  autoWidth
                  className="status-field field-label-css"
                >
                 
                  <MenuItem disabled value="Roles">
                    <em className="drodown_menu">Roles</em>
                  </MenuItem>
                  <MenuItem value={'Owner'}>Owner</MenuItem>
                  <MenuItem value={'Admin'}>Admin</MenuItem>
                  <MenuItem value={'Manager'}>Manager</MenuItem>
                  <MenuItem value={'Location Manager'}>
                    Location Manager
                  </MenuItem>
                  <MenuItem value={'Super Admin'}>Super Admin</MenuItem>
                </Select>
              </FormControl>
            </Box> */}
                        {/* Search box on table */}
                        <TextField
                          type="search"
                          className="Search-field"
                          label="Search"
                          InputProps={{
                            style: {},
                            endAdornment: <SearchIcon />
                          }}
                          onChange={handleSearchTextChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    {displayFilterForm === true ? (
                      // <Slide
                      //   direction="right"
                      //   in={displayFilterForm}
                      //   timeout={{ enter: 1200, exit: 10 }}
                      //   container={containerRef.current}
                      // >
                      <Grid item width={getFilterSize()}>
                        <Filters
                          filters={sideBarfilters}
                          setFilters={setSidebarFilters}
                          getRoleList={roleList}
                          getLocationList={locationList}
                          getCities={franchiseecity}
                        />
                      </Grid>
                    ) : // </Slide>
                      null}

                    <Grid
                      item
                      sx={{
                        width: `calc(100% - ${getFilterSize()}px)})`
                      }}
                    >
                      <Card
                        sx={{
                          padding: '15px',
                          borderRadius: '19px 19px 0px 0px'
                        }}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid
                            item
                            xs={12}
                            sm={5}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              gap: '12px'
                            }}
                          >
                            <CardHeader
                              // action={
                              // }
                              title="All Staff"
                              className="table-header-name"
                              sx={{ fontSize: '20px', color: '#003087' }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={7}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                              gap: '12px'
                            }}
                            className="membership-status-container status-field-ddl"
                          >
                            <CustomSelect
                              fullWidth
                              name="selectedStatus"
                              disabled={!selectedStaffLists?.length}
                              value={selectedStatus}
                              onChange={(type, value) =>
                                setSelectedStatus(value)
                              }
                              label="Select Status"
                              options={[
                                {
                                  label: 'Activate',
                                  value: 1
                                },
                                {
                                  label: 'Deactivate',
                                  value: 0
                                }
                              ]}
                            />
                            <CustomButton
                              variant="contained"
                              disabled={
                                selectedStatus === '' ||
                                !selectedStaffLists?.length
                              }
                              onClick={handleBulkAdd}
                              loading={btnLoading}
                            >
                              Update
                            </CustomButton>
                          </Grid>
                        </Grid>

                        {/* <Divider /> */}
                        <TableContainer className="staff-table-container">
                          <Table>
                            <TableHead>
                              <TableRow className="table-header">
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    color="primary"
                                    checked={selectedAllStaffItems}
                                    indeterminate={selectedSomeStaffItems}
                                    onChange={handleSelectAllStaffItems}
                                  />
                                </TableCell>
                                <TableCell>Staff Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Location</TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell>Clock In/Out</TableCell>
                                <TableCell className="align-center">
                                  Status
                                </TableCell>
                                <TableCell />
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {paginatedStaffLists?.map((staffItem, index) => {
                                const isStaffItemSelected =
                                  selectedStaffLists.includes(
                                    staffItem.sortKey
                                  );
                                return (
                                  <TableRow
                                    hover
                                    // key={staffItem.sortKey}
                                    key={index}
                                    selected={isStaffItemSelected}
                                    className="table-row cursor-pointer"
                                    onClick={(e) =>
                                      handleViewStaff(staffItem, e)
                                    }
                                  >
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        color="primary"
                                        checked={isStaffItemSelected}
                                        onChange={(event) =>
                                          handleSelectOneStaffItem(
                                            event,
                                            staffItem.sortKey
                                          )
                                        }
                                        value={isStaffItemSelected}
                                      />
                                    </TableCell>
                                    <TableCell
                                      className="table-td -flex"
                                      sx={{ minWidth: '150px' }}
                                      onClick={(e) =>
                                        handleViewStaffProfile(staffItem, e)
                                      }
                                    >
                                      <Grid container>
                                        <Grid item xs={6}>
                                          {/* Icon of the staff */}
                                          <Avatar
                                            alt="icon"
                                            src={
                                              staffItem.profileImage
                                            }
                                            sx={{
                                              marginRight: 2,
                                              float: 'left'
                                            }}
                                            className="card-avatar-img align-self-center"
                                          />
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            sx={{ marginTop: 1 }}
                                            gutterBottom
                                          >
                                            {staffItem.firstName +
                                              ' ' +
                                              staffItem.lastName}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </TableCell>
                                    <TableCell className="table-td">
                                      <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                        color="text.primary"
                                        gutterBottom
                                        noWrap
                                      >
                                        {staffItem.email}
                                      </Typography>
                                    </TableCell>
                                    <TableCell className="table-td">
                                      <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                        color="text.primary"
                                        gutterBottom
                                        noWrap
                                      >
                                        {staffItem.locationName}
                                      </Typography>
                                    </TableCell>
                                    <TableCell className="table-td">
                                      <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                        color="text.primary"
                                        gutterBottom
                                        noWrap
                                      >
                                        {staffItem.roles &&
                                          staffItem.roles.length > 0 &&
                                          staffItem.roles[0].role
                                          ? staffItem.roles[0].role
                                          : null}
                                      </Typography>
                                    </TableCell>
                                    <TableCell className="table-td">
                                      {staffItem.clockTime.length > 0 ? (
                                        <>
                                          <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="flex-start"
                                          >
                                            <Grid item>
                                              <Typography
                                                variant="body1"
                                                className="clock-time"
                                                gutterBottom
                                                noWrap
                                              >
                                                {
                                                  staffItem.clockTime[0]
                                                    .clockInTime
                                                }
                                                {staffItem?.clockTime[0]
                                                  ?.clockOutTime
                                                  ? ','
                                                  : ''}
                                              </Typography>

                                              <Typography
                                                variant="body1"
                                                className="clock-time"
                                                gutterBottom
                                                noWrap
                                              >
                                                {
                                                  staffItem?.clockTime[0]
                                                    ?.clockOutTime
                                                }
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                          <Typography
                                            variant="body1"
                                            gutterBottom
                                            noWrap
                                            sx={{
                                              textDecoration: 'underline'
                                            }}
                                            onClick={(e) =>
                                              handleClockEdit(staffItem, e)
                                            }
                                          >
                                            Quick Edit
                                          </Typography>
                                        </>
                                      ) : (
                                        <img
                                          alt="icon"
                                          src="/static/images/logo/clockicon.png"
                                          className="card-avatar-img"
                                        />
                                      )}
                                      {/* <AccessTimeIcon
                            sx={{ fontSize: 15, color: '#003087', mr: 1 }}
                          /> */}
                                    </TableCell>
                                    <TableCell className="table-td">
                                      <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                        color="text.primary"
                                        gutterBottom
                                        noWrap
                                        className="td-center"
                                      >
                                        {staffItem.status === 1 ? (
                                          // Active Status icon
                                          <ActiveStatus />
                                        ) : (
                                          // Inactive Status icon
                                          <InactiveStatus />
                                        )}
                                      </Typography>
                                    </TableCell>
                                    <TableCell className="table-td">
                                      <IconButton
                                        sx={{
                                          '&:hover': {
                                            background:
                                              theme.colors.primary.lighter
                                          },
                                          color: theme.palette.primary.main
                                        }}
                                        color="inherit"
                                        size="small"
                                        className="edit-btn"
                                        onClick={(e) =>
                                          handleEditStaff(staffItem, e)
                                        }
                                      >
                                        Edit
                                      </IconButton>

                                      <MoreVertIcon
                                        onClick={(e) => openMenu(staffItem, e)}
                                        color="grey"
                                        variant="contained"
                                        className="three-dots-icon"
                                      />

                                      <Menu
                                        open={Boolean(anchor)}
                                        anchorEl={anchor}
                                        onClose={closeMenu}
                                        keepMounted
                                        PaperProps={{
                                          style: {
                                            maxHeight: 40 * 5,
                                            width: '30ch'
                                          }
                                        }}
                                      >
                                        <MenuItem
                                          onClick={(e) =>
                                            onMenuItemClick(
                                              staffItem,
                                              e,
                                              'clockIn'
                                            )
                                          }
                                                                                  >
                                          Clock In
                                        </MenuItem>
                                        <MenuItem
                                          onClick={(e) =>
                                            onMenuItemClick(
                                              staffItem,
                                              e,
                                              'clockOut'
                                            )
                                          }
                                        >
                                          Clock Out
                                        </MenuItem>
                                        <MenuItem disabled>Duplicate</MenuItem>
                                        <MenuItem
                                          onClick={(e) =>
                                            onMenuItemClick(
                                              staffItem,
                                              e,
                                              'availability'
                                            )
                                          }
                                        >
                                          Add Service and Availability
                                        </MenuItem>
                                        <MenuItem disabled>Delete</MenuItem>
                                      </Menu>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Box p={2}>
                          {/* Pagination */}
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={filteredStaffLists.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </Box>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            );
        }
      })()}

      {/* View Location dialog */}
      {/* {!staffProfile && (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => handleClose('Cancel')}
          aria-describedby="details-dialog"
          className="dialog-box dialog-box-right franchisee not"
          maxWidth="lg"
        >
          <DialogTitle className="alert-title align-right dialog-heade"> */}
      {/* <Box className='float-left'>{"All Active Tasks"}</Box> */}
      {/* <Box className=" align-right"> */}
      {/* remove icon */}
      {/* <Button
                className="details-remove-icon p-0 no-hover"
                onClick={handleClose}
              >
                <svg
                  width="30"
                  height="31"
                  viewBox="0 0 30 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 27.2695C8.385 27.2695 3 21.8845 3 15.2695C3 8.65453 8.385 3.26953 15 3.26953C21.615 3.26953 27 8.65453 27 15.2695C27 21.8845 21.615 27.2695 15 27.2695ZM15 0.269531C6.705 0.269531 0 6.97453 0 15.2695C0 23.5645 6.705 30.2695 15 30.2695C23.295 30.2695 30 23.5645 30 15.2695C30 6.97453 23.295 0.269531 15 0.269531ZM18.885 9.26953L15 13.1545L11.115 9.26953L9 11.3845L12.885 15.2695L9 19.1545L11.115 21.2695L15 17.3845L18.885 21.2695L21 19.1545L17.115 15.2695L21 11.3845L18.885 9.26953Z"
                    fill="#E64646"
                  />
                </svg>
              </Button>
            </Box> */}
      {/* </DialogTitle>
          <DialogContent sx={{ overflow: 'hidden' }}>
            <DialogContentText
              id="alert-dialog-slide-description"
              className="alert-decription"
            > */}

      {/* </DialogContentText>
          </DialogContent>
        </Dialog>
      )} */}
      <Box justifyContent="center" direction="column">
        {openClock && (
          <ClockIn
            getStaffItem={getStaffItem}
            clockType={clockType}
            openClock={openClock}
            setOpenClock={setOpenClock}
            refreshStaffData={refreshStaffData}
          />
        )}
      </Box>

      <Box justifyContent="center" direction="column">
        {editClock && (
          <EditClock
            getStaffItem={getStaffItem}
            editClock={editClock}
            setEditClock={setEditClock}
            refreshStaffData={refreshStaffData}
          />
        )}
      </Box>

      <Box justifyContent="center" direction="column">
        {openStaffDetails && (
          <StaffDetails
            getStaffItem={getStaffItem}
            openStaffDetails={openStaffDetails}
            setOpenStaffDetails={setOpenStaffDetails}
            refreshStaffData={refreshStaffData}
          />
        )}
      </Box>

      <Box>
        {openAddTask && (
          <AddTask
            staffId={staffId}
            showScreen={showScreen}
            openAddTaskDialog={openAddTaskDialog}
            setopenAddTaskDialog={setopenAddTaskDialog}
            setOpenAddTask={setOpenAddTask}
            refreshTaskData={refreshTaskData}
            taskApiUpdate={taskApiUpdate}
            setTaskApiUpdate={setTaskApiUpdate}
          />
        )}
      </Box>

      {/* Profile details here */}
      {/* {staffProfile && (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => handleClose('Cancel')}
          aria-describedby="details-dialog"
          className="dialog-box dialog-box-right"
          maxWidth="lg"
        >
          <DialogTitle className="alert-title align-right dialog-header test">
            <Box> */}
      {/* remove icon */}
      {/* <Button
                className="details-remove-icon p-0 no-hover"
                onClick={handleClose}
              >
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="13" cy="13" r="13" fill="white" />
                  <path
                    d="M17.7785 8.22908C17.7083 8.15877 17.625 8.10298 17.5332 8.06492C17.4415 8.02686 17.3431 8.00726 17.2438 8.00726C17.1444 8.00726 17.0461 8.02686 16.9543 8.06492C16.8625 8.10298 16.7792 8.15877 16.709 8.22908L13 11.9305L9.29097 8.2215C9.22075 8.15127 9.13738 8.09557 9.04563 8.05756C8.95388 8.01956 8.85554 8 8.75623 8C8.65692 8 8.55859 8.01956 8.46683 8.05756C8.37508 8.09557 8.29172 8.15127 8.2215 8.2215C8.15127 8.29172 8.09557 8.37508 8.05756 8.46683C8.01956 8.55859 8 8.65692 8 8.75623C8 8.85554 8.01956 8.95388 8.05756 9.04563C8.09557 9.13738 8.15127 9.22075 8.2215 9.29097L11.9305 13L8.2215 16.709C8.15127 16.7793 8.09557 16.8626 8.05756 16.9544C8.01956 17.0461 8 17.1445 8 17.2438C8 17.3431 8.01956 17.4414 8.05756 17.5332C8.09557 17.6249 8.15127 17.7083 8.2215 17.7785C8.29172 17.8487 8.37508 17.9044 8.46683 17.9424C8.55859 17.9804 8.65692 18 8.75623 18C8.85554 18 8.95388 17.9804 9.04563 17.9424C9.13738 17.9044 9.22075 17.8487 9.29097 17.7785L13 14.0695L16.709 17.7785C16.7793 17.8487 16.8626 17.9044 16.9544 17.9424C17.0461 17.9804 17.1445 18 17.2438 18C17.3431 18 17.4414 17.9804 17.5332 17.9424C17.6249 17.9044 17.7083 17.8487 17.7785 17.7785C17.8487 17.7083 17.9044 17.6249 17.9424 17.5332C17.9804 17.4414 18 17.3431 18 17.2438C18 17.1445 17.9804 17.0461 17.9424 16.9544C17.9044 16.8626 17.8487 16.7793 17.7785 16.709L14.0695 13L17.7785 9.29097C18.0667 9.00274 18.0667 8.51731 17.7785 8.22908Z"
                    fill="#003087"
                  />
                </svg>
              </Button>
            </Box>
          </DialogTitle>
          <DialogContent className='bg-white'>
            <DialogContentText
              id="alert-dialog-slide-description"
              className="alert-decription bg-white"
            >
              
      //       </DialogContentText>
      //     </DialogContent>
      //   </Dialog>
      // )}

      
      </Box>

      {/* <Dialog
        open={openClock}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClockClose('Cancel')}
        aria-describedby="details-dialog"
        className="dialog-box dialog-box-right"
        maxWidth="sm"
      >
        <DialogTitle className="alert-title align-right dialog-header">
          <Box>
        
            <Button
              className="clock-remove-icon p-0 no-hover"
              onClick={handleClockClose}
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="13" cy="13" r="13" fill="white" />
                <path
                  d="M17.7785 8.22908C17.7083 8.15877 17.625 8.10298 17.5332 8.06492C17.4415 8.02686 17.3431 8.00726 17.2438 8.00726C17.1444 8.00726 17.0461 8.02686 16.9543 8.06492C16.8625 8.10298 16.7792 8.15877 16.709 8.22908L13 11.9305L9.29097 8.2215C9.22075 8.15127 9.13738 8.09557 9.04563 8.05756C8.95388 8.01956 8.85554 8 8.75623 8C8.65692 8 8.55859 8.01956 8.46683 8.05756C8.37508 8.09557 8.29172 8.15127 8.2215 8.2215C8.15127 8.29172 8.09557 8.37508 8.05756 8.46683C8.01956 8.55859 8 8.65692 8 8.75623C8 8.85554 8.01956 8.95388 8.05756 9.04563C8.09557 9.13738 8.15127 9.22075 8.2215 9.29097L11.9305 13L8.2215 16.709C8.15127 16.7793 8.09557 16.8626 8.05756 16.9544C8.01956 17.0461 8 17.1445 8 17.2438C8 17.3431 8.01956 17.4414 8.05756 17.5332C8.09557 17.6249 8.15127 17.7083 8.2215 17.7785C8.29172 17.8487 8.37508 17.9044 8.46683 17.9424C8.55859 17.9804 8.65692 18 8.75623 18C8.85554 18 8.95388 17.9804 9.04563 17.9424C9.13738 17.9044 9.22075 17.8487 9.29097 17.7785L13 14.0695L16.709 17.7785C16.7793 17.8487 16.8626 17.9044 16.9544 17.9424C17.0461 17.9804 17.1445 18 17.2438 18C17.3431 18 17.4414 17.9804 17.5332 17.9424C17.6249 17.9044 17.7083 17.8487 17.7785 17.7785C17.8487 17.7083 17.9044 17.6249 17.9424 17.5332C17.9804 17.4414 18 17.3431 18 17.2438C18 17.1445 17.9804 17.0461 17.9424 16.9544C17.9044 16.8626 17.8487 16.7793 17.7785 16.709L14.0695 13L17.7785 9.29097C18.0667 9.00274 18.0667 8.51731 17.7785 8.22908Z"
                  fill="#003087"
                />
              </svg>
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription"
          >
            {clockIn ? (
              <Box className="clock-in">
                <ClockIn
                  // staffDetails={staffItemDetails}
                  getStaffItem={getStaffItem}
                  clockType={clockType}
                />
              </Box>
            ) : (
              <Box className="clock-in">
                <Typography variant="h5" className="clock-out-title">
                  Clock Out
                </Typography>
              </Box>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog> */}
    </>
  );
};

DrawStaffTable.propTypes = {
  staffLists: PropTypes.array.isRequired
};

DrawStaffTable.defaultProps = {
  staffLists: []
};

export default DrawStaffTable;
