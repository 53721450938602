import React from 'react';
import {
  Button,
  Container,
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardHeader,
  CardActions
} from '@mui/material';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/Header';
import CallMadeIcon from '@mui/icons-material/CallMade';

const PosDashboard = () => {
  let navigate = useNavigate();
  const buttons = [
    {
      label: 'Check Out'
      // onClick: handleButtonClick,
    },
    {
      label: 'Featured'
    }
  ];

  return (
    <>
      <Container maxWidth="100%" sx={{ pb: 2 }}>
        <Header title="POS" buttons={buttons} />

        {/* <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          gap="16px"
        >
          <Grid item>
             <Typography>POS</Typography>
          </Grid>
          <Grid item>
            <CustomButton
              text="Add New"
              variant="contained"
            // onClick={() => handleOpenScreen(2)}
            >
              Check Out
            </CustomButton>
          </Grid>
          <Grid item>
            <CustomButton variant="outlined">
              Featured
            </CustomButton>
          </Grid>
        </Grid> */}
        <Card
          sx={{ backgroundColor: '#32B2AC', marginTop: '15px' }}
          className="pos-title-card"
        >
          <Grid container>
            <Grid item md={1} sm={1} className="image-alignment-style">
              <img src="static/images/logo/posLogo.png" alt="logo" />
            </Grid>
            <Grid item md={10} sm={10}>
              <p className="pos-title">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex
              </p>
            </Grid>
            <Grid item md={1} sm={1}>
              <p>
                {' '}
                <a
                  className="pos-title"
                  style={{ textDecoration: 'underline' }}
                >
                  {' '}
                  View Details
                </a>
              </p>
            </Grid>
          </Grid>
        </Card>
        <Grid container sx={{ marginTop: '10px' }} spacing={2}>
          <Grid item xs={12} sm={8} md={8}>
            <Card
              className="pos-card"
              sx={{
                backgroundColor: '#F8FBFF',
                border: '0.4px solid #C5C5C5',
                pb: 1
              }}
            >
              <CardHeader
                className="font-18 drodown_menu"
                title="SALES IN PAST 7 DAYS"
                action={<MoreVertIcon />}
              >
                {/* <Typography className="card-header">Sales in Past 7 Days</Typography> */}
              </CardHeader>
              <Container>
                <CardMedia>
                  <Typography className="pos-text-size-74">111</Typography>
                  <Box sx={{ textAlign: 'center' }}>
                    <img
                      src="static/images/logo/progressBar.png"
                      alt="progress"
                      style={{ maxWidth: '100%' }}
                    />
                  </Box>
                </CardMedia>
              </Container>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Card
              className="pos-card"
              sx={{
                backgroundColor: '#F8FBFF',
                border: '0.4px solid #C5C5C5',
                pb: 1
              }}
            >
              <CardHeader
                className="font-18 drodown_menu"
                title="UNPAID SERVICES"
                action={<CallMadeIcon className="icon-call" />}
              >
                {/* <Typography className="card-header">Sales in Past 7 Days</Typography> */}
              </CardHeader>
              <CardContent sx={{ textAlign: 'center' }}>
                <Typography className="pos-text-size-74">111</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container sx={{ marginTop: '10px' }} spacing={2}>
          <Grid item xs={12} sm={4} md={4}>
            <Card
              className="main-card-container"
              sx={{
                backgroundColor: '#F8FBFF',
                border: '0.4px solid #C5C5C5',
                pb: 1
              }}
            >
              <CardHeader
                title="Bundles"
                action={<CallMadeIcon className="icon-call" />}
              >
                {/* <Typography className="card-header">Sales in Past 7 Days</Typography> */}
              </CardHeader>
              <CardContent className="pos-card-sub">
                <Typography className="word-wrapper">
                  <p style={{ textAlign: 'justify' }}>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                  </p>
                </Typography>
              </CardContent>
              <CardActions
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Box>
                  <Typography className="font-18 weight-700 pos-text-styling pos-color-green">
                    $ 42K
                  </Typography>
                  <Typography className="font-12 weight-400 pos-text-styling pos-color-black">
                    Active
                  </Typography>
                </Box>

                <Button
                  className="button-style-pos"
                  variant="contained"
                  onClick={() => {
                    navigate('/bundles-management');
                  }}
                >
                  <Typography className="font-12 weight-600 pos-text-styling">
                    ADD NEW BUNDLE
                  </Typography>
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Card
              className="main-card-container"
              sx={{
                backgroundColor: '#F8FBFF',
                border: '0.4px solid #C5C5C5',
                pb: 1
              }}
            >
              <CardHeader
                title="Promotions"
                action={<CallMadeIcon className="icon-call" />}
              >
                {/* <Typography className="card-header">Sales in Past 7 Days</Typography> */}
              </CardHeader>
              <CardContent className="pos-card-sub">
                <Typography className="word-wrapper">
                  <p>
                    Sed dictum condimentum turpis at pharetra. Suspendisse vitae
                    consectetur nunc. In consequat ultricies sapien, sed
                    volutpat nibh lacinia quis. Orci varius natoque
                  </p>
                </Typography>
              </CardContent>
              <CardActions
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Box>
                  <Typography className="font-18 weight-700 pos-text-styling pos-color-green">
                    24
                  </Typography>
                  <Typography className="font-12 weight-400 pos-text-styling pos-color-black">
                    New members
                  </Typography>
                </Box>
                <Button
                  className="button-style-pos"
                  variant="contained"
                  onClick={() => {
                    navigate('/promotion-management');
                  }}
                >
                  <Typography className="font-12 weight-600 pos-text-styling">
                    ADD NEW PROMOTION
                  </Typography>
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Card
              className="main-card-container "
              sx={{
                backgroundColor: '#F8FBFF',
                border: '0.4px solid #C5C5C5',
                pb: 1
              }}
            >
              <CardHeader
                title="Memberships"
                action={<CallMadeIcon className="icon-call" />}
              >
                {/* <Typography className="card-header">Sales in Past 7 Days</Typography> */}
              </CardHeader>
              <CardContent className="pos-card-sub">
                <Typography className="word-wrapper">
                  <p>
                    Sed malesuada, lorem eu pellentesque dignissim, augue enim
                    aliquet orci, sit amet vehicula quam augue vitae nisl. Nunc
                    malesuada fringilla magna, nec
                  </p>
                </Typography>
              </CardContent>
              <CardActions
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Box>
                  <Typography className="font-18 weight-700 pos-text-styling pos-color-green">
                    12
                  </Typography>
                  <Typography className="font-12 weight-400 pos-text-styling pos-color-black">
                    Sales
                  </Typography>
                </Box>
                <Button
                  className="button-style-pos"
                  variant="contained"
                  onClick={() => {
                    navigate('/memberships');
                  }}
                >
                  <Typography className="font-12 weight-600 pos-text-styling">
                    ENROLL MEMEBER{' '}
                  </Typography>
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Card
              className="main-card-container "
              sx={{
                backgroundColor: '#F8FBFF',
                border: '0.4px solid #C5C5C5',
                pb: 1
              }}
            >
              <CardHeader
                title="Gift Cards"
                action={<CallMadeIcon className="icon-call" />}
              >
                {/* <Typography className="card-header">Sales in Past 7 Days</Typography> */}
              </CardHeader>
              <CardContent className="pos-card-sub">
                <Typography className="word-wrapper">
                  <p>
                    Sed malesuada, lorem eu pellentesque dignissim, augue enim
                    aliquet orci, sit amet vehicula quam augue vitae nisl. Nunc
                    malesuada fringilla magna, nec
                  </p>
                </Typography>
              </CardContent>
              <CardActions
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Box>
                  <Typography className="font-18 weight-700 pos-text-styling pos-color-green">
                    43
                  </Typography>
                  <Typography className="font-12 weight-400 pos-text-styling pos-color-black">
                    Gift Cards Sold
                  </Typography>
                </Box>
                <Button
                  className="button-style-pos"
                  variant="contained"
                  onClick={() => {
                    navigate('/gift-cards');
                  }}
                >
                  <Typography className="font-12 weight-600 pos-text-styling">
                    ADD NEW GIFT CARD
                  </Typography>
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Card
              className="main-card-container "
              sx={{
                backgroundColor: '#F8FBFF',
                border: '0.4px solid #C5C5C5',
                pb: 1
              }}
            >
              <CardHeader
                title="Contracts"
                action={<CallMadeIcon className="icon-call" />}
              >
                {/* <Typography className="card-header">Sales in Past 7 Days</Typography> */}
              </CardHeader>
              <CardContent className="pos-card-sub">
                <Typography className="word-wrapper">
                  <p>
                    Sed malesuada, lorem eu pellentesque dignissim, augue enim
                    aliquet orci, sit amet vehicula quam augue vitae nisl. Nunc
                    malesuada fringilla magna, nec
                  </p>
                </Typography>
              </CardContent>
              <CardActions
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Box>
                  <Typography className="font-18 weight-700 pos-text-styling pos-color-green">
                    113
                  </Typography>
                  <Typography className="font-12 weight-400 pos-text-styling pos-color-black">
                    Active Contracts
                  </Typography>
                </Box>
                <Button
                  className="button-style-pos"
                  variant="contained"
                  onClick={() => {
                    navigate('/contract-management');
                  }}
                >
                  <Typography className="font-12 weight-600 pos-text-styling">
                    ADD NEW CONTRACTS
                  </Typography>
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Card
              className="main-card-container"
              sx={{
                backgroundColor: '#F8FBFF',
                border: '0.4px solid #C5C5C5',
                pb: 1
              }}
            >
              <CardHeader
                title="Pricing Options"
                action={<CallMadeIcon className="icon-call" />}
              >
                {/* <Typography className="card-header">Sales in Past 7 Days</Typography> */}
              </CardHeader>
              <CardContent className="pos-card-sub">
                <Typography className="word-wrapper">
                  <p>
                    Sed malesuada, lorem eu pellentesque dignissim, augue enim
                    aliquet orci, sit amet vehicula quam augue vitae nisl. Nunc
                    malesuada fringilla magna, nec
                  </p>
                </Typography>
              </CardContent>
              <CardActions
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Box>
                  <Typography className="font-18 weight-700 pos-text-styling pos-color-green">
                    113
                  </Typography>
                  <Typography className="font-12 weight-400 pos-text-styling pos-color-black">
                    Active Contracts
                  </Typography>
                </Box>
                <Button
                  className="button-style-pos"
                  variant="contained"
                  onClick={() => {
                    navigate('/pricing-options');
                  }}
                >
                  <Typography className="font-12 weight-600 pos-text-styling">
                    ADD NEW PRICING OPTION
                  </Typography>
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Card
              className="main-card-container "
              sx={{
                backgroundColor: '#F8FBFF',
                border: '0.4px solid #C5C5C5',
                pb: 1
              }}
            >
              <CardHeader
                title="Featured Items"
                action={<CallMadeIcon className="icon-call" />}
              >
                {/* <Typography className="card-header">Sales in Past 7 Days</Typography> */}
              </CardHeader>
              <CardContent className="pos-card-sub">
                <Typography className="word-wrapper">
                  <p>
                    Sed malesuada, lorem eu pellentesque dignissim, augue enim
                    aliquet orci, sit amet vehicula quam augue vitae nisl. Nunc
                    malesuada fringilla magna, nec
                  </p>
                </Typography>
              </CardContent>
              <CardActions
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Box>
                  <Typography className="font-18 weight-700 pos-text-styling pos-color-green">
                    113
                  </Typography>
                  <Typography className="font-12 weight-400 pos-text-styling pos-color-black">
                    Active Contracts
                  </Typography>
                </Box>
                <Button
                  className="button-style-pos"
                  variant="contained"
                  onClick={() => {
                    navigate('/featured-items');
                  }}
                >
                  <Typography className="font-12 weight-600 pos-text-styling">
                    ADD NEW FEATURED ITEM
                  </Typography>
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} sm={4} md={4}>
            <Card
              className="main-card-container "
              sx={{
                backgroundColor: '#F8FBFF',
                border: '0.4px solid #C5C5C5',
                pb: 1
              }}
            >
              <CardHeader
                title="Sales History"
                action={<CallMadeIcon className="icon-call" />}
                onClick={() => navigate('/sales-history')}
              >
                {/* <Typography className="card-header">Sales in Past 7 Days</Typography> */}
              </CardHeader>
              <CardContent className="pos-card-sub">
                <Typography className="word-wrapper">
                  <p>
                    Sed malesuada, lorem eu pellentesque dignissim, augue enim
                    aliquet orci, sit amet vehicula quam augue vitae nisl. Nunc
                    malesuada fringilla magna, nec
                  </p>
                </Typography>
              </CardContent>
              <CardActions
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Box>
                  <Typography className="font-18 weight-700 pos-text-styling pos-color-green">
                    1243
                  </Typography>
                  <Typography className="font-12 weight-400 pos-text-styling pos-color-black">
                    Purchase
                  </Typography>
                </Box>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default PosDashboard;
