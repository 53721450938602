import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
    Box, Button,
    Typography,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Slide
} from '@mui/material';
import axios from 'axios';
import AddPromotion from './AddPromotion';
import EditPromotion from './EditPromotion';
import { ApiService } from '../../../../services/ApiService';
import { CustomTable } from '../../../../components/CustomTable';
import { PromotionsFilter } from '../../../../components/Filters/PromotionsFilter';
import PromotionDetails from './PromotionDetails';
import { filtersQuery } from '../../../../utils/index';
import { ActiveStatus, InactiveStatus } from 'src/assets/SVG/SvgIcons';
import ErrorMessage from '../../../../components/PopupMessage/ErrorMessage';
import Loader from '../../../../components/Loader';
import Header from '../../../../components/Header';
import GlobalData from '../../../../global';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const FrachisorPromotion = () => {
    const [showScreen, setShowScreen] = useState(0);
    const [data, setData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [open, setOpen] = React.useState(false);
    // check updated or saved
    const [isUpdated, setIsUpdated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedLists, setSelectedLists] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [btnLoading, setBtnLoading] = useState(false);
    const userId = sessionStorage.getItem('UserId');
    // const locationId = setFullLocationId(getLocalData('locationId'));
    const [loading, setLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    // Get the title from global data
    const title = GlobalData[0].title;

    console.log(isLoading);

    let tableDropdown = { isPromotions: false };
    let [filters, setFilters] = useState({
        status: 1,
        discount: '',
        isDraft: ''
    });

    const getPromotionDetails = async () => {
        setLoading(true);
        try {
            let queryString = await filtersQuery(filters);
            let filtersParams = queryString ? `?${queryString}` : '';
            // const formattedLocationid = encodeURIComponent(locationId);
            // let data = await ApiService.get(`${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}promotions?locationId=${formattedLocationid}${filtersParams}`)
            let data = await ApiService.get(`${process.env.REACT_APP_API_END_POINT}promotions${filtersParams}`)
            let Item = data && data.data && data.data.Items ? data.data.Items : [];
            setLoading(false);
            console.log("Items ---->", Item);
            const result = Item.map(obj => {
                const {
                    partitionKey,
                    sortKey,
                    name,
                    discount,
                    limit,
                    status
                } = obj;

                return {
                    partitionKey,
                    sortKey,
                    name: name ?? '',
                    discount: discount + '%' ?? '',
                    // status: status ?? '',
                    limit: limit ?? '',
                    status: status === 1 ? <ActiveStatus /> : <InactiveStatus />
                }

            })

            setTableData(result);
            setData(Item);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }

    }

    const handleOnClick = (record) => {
        let arr = [record[1]]
        let filterRecord = data?.filter(i => arr.includes(i.sortKey));
        setSelectedData(filterRecord);
        console.log("record", record);
        if (record[2] === 'Detail') {
            setShowScreen(1)
        } else if (record[2] === 'Type') {
            if (record.length > 1 && record[1] && record[1] === 'Draft') {
                console.log(record[1]);
                setFilters((prevState) => ({ ...prevState, isDraft: 'true' }));
            }
            if (record.length > 1 && record[1] && record[1] === 'All') {
                setFilters((prevState) => ({ ...prevState, isDraft: '' }));
            }

        }
        if (record[2] === 'Edit') {
            setShowScreen(3);
        }
    }

    useEffect(() => {
        getPromotionDetails();
        // setShowScreen(2)
    }, [filters]);

    const handleButtonClick = () => {
        setShowScreen(2);
    }

    // method to handle bulk update
    const handleBulkAdd = async () => {
        console.log('handleBulkAdd');
        setIsUpdated(false);
        setOpen(true);
    };

    // Method to handle bulk update status final confirm
    const confirmBulkUpdate = async () => {
        setOpen(false);
        setBtnLoading(true);
        // show loader
        setIsLoading(true);
        const data = {
            status: selectedStatus,
            updatedBy: userId,
            promoIds: selectedLists
        };

        try {
            // const encodedLocationId = encodeURIComponent(locationId);
            const response = await axios.put(
                `${process.env.REACT_APP_API_END_POINT}promotions/updateBulkStatus`,
                data
            );

            if (!response.data?.error) {
                // toast.success(response.data?.message || 'Promotion status updated');
                setSelectedStatus('');
                setSelectedLists([]);
                setIsUpdated(true);
                setOpen(true);
            } else {
                setShowMessage(true);
                setErrorMessage(response.data?.message ||
                    'Something went wrong with update promotion status')

            }
            // show loader
            setIsLoading(false);
            setBtnLoading(false);
        } catch (error) {
            console.error(error);
            setBtnLoading(false);
            // show loader
            setIsLoading(false);
            setShowMessage(true);
            setErrorMessage('Something went wrong, Try again!')
            // toast.error('Something went wrong, Try again!');
        }
    };

    // Method to handle close of dialog
    const handleClose = (val) => {
        if (val === 'Save') {
            setOpen(false);
            confirmBulkUpdate();
        } else if (val === 'Discard') {
            setOpen(false);
            if (isUpdated) {
                getPromotionDetails();
                setIsUpdated(false);
            }
        }
    };
    const buttons = [
        {
            label: 'Add New',
            onClick: handleButtonClick,
            theam: true
        },
    ]
    return (
        <>
            <Helmet>
                <title>Promotion Management | {title}</title>
            </Helmet>
            <Loader IsLoading={loading} />
            {showMessage &&
                <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
            }
            {(() => {
                switch (showScreen) {
                    case 1:
                        return (
                            <PromotionDetails selectedData={selectedData}
                                setShowScreen={setShowScreen}
                                getPromotionDetails={getPromotionDetails}
                            />
                        )
                    case 2:
                        return (
                            <AddPromotion
                                setShowScreen={setShowScreen}
                                getPromotionDetails={getPromotionDetails}
                                setShowMessage={setShowMessage}
                                setErrorMessage={setErrorMessage}
                            />
                        )
                    case 3:
                        return (
                            <EditPromotion
                                selectedData={selectedData}
                                setShowScreen={setShowScreen}
                                getPromotionDetails={getPromotionDetails}
                                setShowMessage={setShowMessage}
                                setErrorMessage={setErrorMessage}
                            />
                        )
                    default:

                        return (
                            // <Container maxWidth="100%">
                            <Box>
                                <Box sx={{ paddingX: "30px" }} >
                                    {showScreen === 0 && <Header title="Promotion Management" buttons={buttons} />}
                                </Box>
                                <CustomTable data={tableData} handleOnClick={handleOnClick} options={tableDropdown}
                                    filterCompoenent={<PromotionsFilter setFilters={setFilters} filters={filters} />} label="All Promotions"
                                    selectedLists={selectedLists}
                                    setSelectedLists={setSelectedLists}
                                    open={open}
                                    setOpen={setOpen}
                                    selectedStatus={selectedStatus}
                                    setSelectedStatus={setSelectedStatus}
                                    handleBulkAdd={handleBulkAdd}
                                    btnLoading={btnLoading} />
                                {/* </Container> */}
                            </Box>
                        )
                }
            }
            )()}

            {/* Status Update Dialog confirmation */}
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => handleClose('Cancel')}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle className="alert-title align-center">
                    {isUpdated ? 'Congratulations!' : 'Activate/Deactivate Selected Items'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-slide-description"
                        className="alert-decription align-center"
                    >
                        <Box display="flex" justifyContent="center" direction="column">
                            {!isUpdated ? (
                               <Typography className="title-text-sub">
                               Are you sure you want to mark Activate/Deactivate,
                               <Typography className="title-text-sub">
                               all the selected items?
                             </Typography>
                             </Typography>
                            ) : (
                                <Typography className="title-text-sub">
                                    All the selected promotions have been updated successfully!
                                </Typography>
                            )}
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <Divider />
                <DialogActions className="alert-button-control">
                    <Button
                        className="font-17 text-black no-hover"
                        onClick={() => handleClose('Discard')}
                    >
                        {isUpdated ? 'Close' : 'Cancel'}
                    </Button>
                    {!isUpdated && <Divider className="vertical-divider" />}
                    {!isUpdated && (
                        <Button
                            className="font-17 no-hover"
                            onClick={() => handleClose('Save')}
                        >
                            Confirm
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </>
    )

}
export default FrachisorPromotion;