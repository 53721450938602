import React, { useEffect, useState } from 'react';
import {
  Box,
  Container
} from '@mui/material';
import ErrorMessage from '../../../components/PopupMessage/ErrorMessage';
import SuccessMessage from '../../../components/PopupMessage/SuccessMessage';
import Loader from '../../../components/Loader';
import {
  AddSchedule,
  CalenderDialogs,
  BookClassDialog,
  Calender,
  ServiceDetails,
  AllOccurences,
  convertUTCToNormal,
  convertTimeToUTC,
} from 'src/components/Schedules';
import { getLocalData, formatDateTimeCalender } from 'src/utils';
import { ApiService } from 'src/services/ApiService';
import Header from '../../../components/Header';
import AllRooms from '../../../components/Schedules/AllRooms';

const Schedules = () => {

  const BASE_URL = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}`;

  const locationId = getLocalData('locationId');
  let userId = getLocalData('userId');
  const encodedLocationId = encodeURIComponent(locationId);

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [showScreen, setShowScreen] = useState(1);
  const [isInitialState, setIsInitialState] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [tableLoading, setTableLoading] = useState(false);
  const [allServiceCategories, setAllServiceCategories] = useState([]);
  const [allServices, setAllServices] = useState([]);
  const [allRooms, setAllRooms] = useState([]);
  const [allTrainers, setAllTrainers] = useState([]);
  const [allParents, setAllParents] = useState([]);
  const [allPets, setAllPets] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [allAttendies, setAllAttendies] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [sucessMessage, setSuccessMessage] = useState('')
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [allReqFulFilled, setAllReqFulFilled] = useState(false);
  const [deleteType, setDeleteType] = useState('occurrence');
  const [editType, setEditType] = useState('occurrence');
  const [isLoading, setIsLoading] = useState(false);

  const [notify, setNotify] = useState({
    isNotify: true,
    value: 'client'
  });

  let initials = {
    id: '',
    appointmentId: '',
    type: 'appointment',
    editTypeValue: 'Reschedule',
    appointmentStatus: '',
    serviceCategory: '',
    serviceId: '',
    serviceName: '',
    fromDate: new Date(),
    fromTime: '',
    toDate: new Date(),
    toTime: '',
    trainer: '',
    trainerName: '',
    trainerAvailability: '',
    occurence: 'noRepeat',
    intervalValue: 1,
    intervalDays: ['mo', 'tu', 'we', 'th', 'fr'],
    intervalMonths: [],
    room: '',
    description: '',
    payLater: false,
    parent: '',
    pets: ''
  };
  const [newSchedule, setNewSchedule] = useState(initials);
  const [bookClass, setBookClass] = useState(initials);
  const [initialScheduleValues, setInitialScheduleValues] = useState(initials);
  const [initialBookClass, setInitialBookClass] = useState(initials);
  const [isScheduleEdit, setIsScheduleEdit] = useState(false);
  const [isBookClassEdit, setIsBookClassEdit] = useState(false);

  let initialDialogs = {
    state: false,
    isCreated: false,
    type: false,
    edit: false,
    delete: false,
    notify: false,
    outTime: false,
    bookClass: false,
    isDelete: false,
    isEditCustom: false,
    isDeleteCustom: false,
    notifyUpdate: false
  };

  const [dialog, setDialog] = useState(initialDialogs);

  const handleCloseTypeModal = (type) => {
    if (type === 'type') {
      handleOpenScreen(2);
    } else if (type === 'skip') {
      setNotify({ ...notify, isNotify: false });
      setDialog({ ...dialog, state: false, notify: false });

      if (dialog.isDelete) {
        handleDeleteAppointment();
      } else {
        // updateSchedule();
      }
    } else if (type === 'notify') {
      sendNotification();
      if (dialog.isDelete) {
        if (showScreen === 4) {
          handleDeleteAppointment('list');
        } else {
          handleDeleteAppointment();
        }
      } else {
        // if (dialog.notifyUpdate) {
        //   // updateSchedule();
        // } else {
        handleCancelAppointment();
        // }
      }
    } else if (type === 'delete') {
      // handling delete and cancel appointment
      if (dialog.isDelete) {
        if (dialog.isDeleteCustom) {
          setDialog({ ...dialog, state: false, notify: false });
          handleDeleteAppointment('list');
        } else {
          handleDeleteAppointment();
        }
      } else if (dialog.isEditCustom) {
        setDialog({ ...dialog, state: false, notify: false });
        handleCancelAppointment('list');
      } else {
        handleCancelAppointment();
      }
    } else if (type === 'customEdit') {
      setDialog({ ...dialog, state: false, edit: false });
      // console.log(dialog, 'dialog');
      handleCancelAppointment('customEdit');
    } else if (type === 'customDelete') {
      setDialog({ ...dialog, state: false, delete: false, isDelete: true });
      handleDeleteAppointment('customDelete');
    } else if (type === 'bookClass') {
      setDialog({ ...dialog, bookClass: false });
      handleClearStates('bookClass');
    } else if (type === 'cancel') {
      setNewSchedule({ ...newSchedule, editTypeValue: 'Reschedule' });
    } else if (type === 'outTime') {
      if (showScreen === 1) {
        setDialog({ ...dialog, state: true, outTime: false, type: true });
      } else if (showScreen === 2) {
        setDialog({ ...dialog, state: false, outTime: false });
        setAllReqFulFilled(true);
      }
    }

    if (type === 'cancel' || type === 'type') {
      closeDialog('add');
    }
  };

  const closeDialog = (value) => {
    setDialog(initialDialogs);

    setDeleteType('occurrence');
    setEditType('occurrence');
    setNotify({ isNotify: true, value: 'all' });

    if (value !== 'add') {
      setNewSchedule({ ...newSchedule, type: 'appointment' });
    }
  };
  function updateIsBlockProperty(objArray) {
    return objArray?.map((obj) => {
      const { isBlock, ...rest } = obj;
      // return { ...rest, IsBlock: isBlock };
      return { ...rest, isBlock: isBlock };
    });
  }

  const updateSchedule = async () => {
    // alert('hey index')
    let categoryName = getValueFromId(
      newSchedule.serviceCategory,
      allServiceCategories,
      'sortKey',
      'name'
    );
    let serviceName = getValueFromId(
      newSchedule.serviceId,
      allServices,
      'sortKey',
      'name'
    );

    let trainerName = getValueFromId(
      newSchedule.trainer,
      allTrainers,
      'sortKey',
      'firstName'
    );

    let trainerImage = getValueFromId(
      newSchedule.trainer,
      allTrainers,
      'sortKey',
      'profileImage'
    );

    let trainerAvailability = updateIsBlockProperty(
      getValueFromId(
        newSchedule.trainer,
        allTrainers,
        'sortKey',
        'availableSchedules'
      )
    );
    let fromTime = convertTimeToUTC(newSchedule.fromTime)
    let toTime = convertTimeToUTC(newSchedule.toTime)
    let setFromtime = convertUTCToNormal(fromTime)
    let setEndtime = convertUTCToNormal(toTime)
    const data = {
      // createdBy: 'John Doe',
      locationId: locationId,
      scheduleType: newSchedule.type,
      categoryName: categoryName,
      categoryId: newSchedule.serviceCategory,
      serviceName: serviceName,
      serviceId: newSchedule.serviceId,
      startTime: setFromtime,
      endTime: setEndtime,
      description: newSchedule.description,
      trainerId: newSchedule.trainer,
      trainerName: trainerName,
      trainerProfileImage: trainerImage,
      trainerSlots: 30,

      trainerAvailability: trainerAvailability?.map((data) => {
        if (newSchedule?.editTypeValue === 'Reschedule') {
          return { ...data };
        }
        return { ...data };
      }),
      room: {
        name: newSchedule.room,
        roomLimit: 6
      }
      // repeat: newSchedule.occurence,
      // occurrence: getOccurencePayload(newSchedule),
      // trainerSlots: 30
    };

    if (isScheduleEdit) {
      // data.updatedBy= 'John Doe'
    } else {
      data.createdBy = userId;
    }

    if (newSchedule.type === 'appointment') {
      data.clientDetails = [
        {
          payLater: newSchedule.payLater,
          clientParentId: newSchedule.parent,
          clientChildId: newSchedule.pets,
          creditAvailable: 0,
          clientParentName: getValueFromId(
            newSchedule.parent,
            allParents,
            'sortKey',
            'firstName'
          ),
          clientChildName: getValueFromId(
            newSchedule.pets,
            allPets,
            'sortKey',
            'firstName'
          ),
          clientChildProfile: getValueFromId(
            newSchedule.pets,
            allPets,
            'sortKey',
            'profileImage'
          ),
          clientChildBirthDate: getValueFromId(
            newSchedule.pets,
            allPets,
            'sortKey',
            'birthDate'
          ),
          clientEmailId: getValueFromId(
            newSchedule.parent,
            allParents,
            'sortKey',
            'email'
          )
        }
      ];
    }

    if (
      newSchedule.payLater ||
      newSchedule.type === 'enrollment' ||
      newSchedule.type === 'appointment'
    ) {
      let encodedOccurenceId;
      let occurenceType; // Define the variable outside the conditions
      if (editType === 'occurrence') {
        encodedOccurenceId = encodeURIComponent(newSchedule.id);
        occurenceType = 'occurrence';
      } else {
        encodedOccurenceId = encodeURIComponent(newSchedule.appointmentId);
        occurenceType = 'series';
      }
      try {
        const response = await ApiService.put(
          `${BASE_URL}schedule/updateAppointment?occurrenceId=${encodedOccurenceId}&type=${occurenceType}`,
          data
        );

        if (!response.data?.error || !response?.error) {
          setShowSuccessMessage(true)
          setSuccessMessage(response?.message);
          // toast.success(response.data?.message);
          handleResetStates();
          // setCloseDialog({
          //   isCreated: true,
          //   state: true
          // });
        } else {
          setShowErrorMessage(true)
          setErrorMessage('Something went wrong with update appoinment')
          // toast.error(
          //   response.data?.message ||
          //     'Something went wrong with update appoinment'
          // );
        }

        setBtnLoading(false);
      } catch (error) {
        console.error(error);
        setBtnLoading(false);
        setShowErrorMessage(true)
        setErrorMessage('Something went wrong with update appoinment')
        // toast.error('Something went wrong, Try again!');
      }
    }
    // else {
    //   navigate('/checkout', { state: { type: 'appointment', items: [data] } });
    // }
  };
  //  console.log(editType,'editTypeeditType');
  const handleOpenModal = (args, type, key) => {
    // console.log('args cal', args);
    const detail = args?.data ? args?.data : args;
    if (type === 'data') {
      openDetail(detail, key);
      // console.log(detail, 'detail');
    } else if (type === 'invalid') {
      setDialog({ ...dialog, state: true, outTime: true });
    } else {
      setDialog({ ...dialog, state: true, type: true });
    }

    if (detail && (type === 'invalid' || type === 'valid')) {
      // setting time and date accordng to selected cell
      setNewSchedule({
        ...newSchedule,
        fromDate: formatDateTimeCalender(detail.StartTime, 'date'),
        fromTime: formatDateTimeCalender(detail.StartTime, 'time'),
        toDate: formatDateTimeCalender(detail.EndTime, 'date'),
        toTime: formatDateTimeCalender(detail.EndTime, 'time'),
        trainer: detail.trainerId
      });
    }
  };

  const openDetail = (data, key) => {
    const numericToAbbrev = {
      0: 'mo',
      1: 'tu',
      2: 'we',
      3: 'th',
      4: 'fr',
      5: 'sa',
      6: 'su'
    };
    const intervalDaysAbbrev = data.occurrence?.byweekday?.map(
      (item) => numericToAbbrev[item.weekday]
    );

    setNewSchedule({
      ...newSchedule,
      id: data?.sortKey,
      appointmentId: data?.appointmentId,
      type: data.scheduleType,
      serviceCategory: data.categoryId,
      serviceId: data.serviceId,
      serviceName: data.serviceName,
      fromDate: data.fromDate,
      toDate: data.toDate,
      fromTime: data.startTime,
      toTime: data.endTime,
      trainer: data.trainerId,
      trainerName: data.trainerName,
      trainerAvailability: data?.trainerAvailability ?? [],
      editTypeValue: data && data?.trainerAvailability && data?.trainerAvailability.length > 0 && data?.trainerAvailability[0].isBlock
        ? 'Reschedule'
        : 'unAvailable',
      room: data.room?.name,
      description: data.description,

      payLater: data.clientDetails[0]?.payLater || false,
      parent: data.clientDetails[0]?.clientParentId || '',
      pets: data.clientDetails[0]?.clientChildId || '',

      occurence: data.repeat,
      intervalValue: data.occurrence?.interval,
      intervalDays: intervalDaysAbbrev,
      intervalMonths: []
    });

    if (data.scheduleType === 'appointment') {
      setAllAttendies(data.clientDetails);
    }

    if (
      data.scheduleType === 'enrollment' &&
      data.clientDetails &&
      data.clientDetails?.length > 0
    ) {
      setAllAttendies(data.clientDetails);

      let bookClassData = {
        ...bookClass,
        id: data.sortKey,
        fromDate: data.fromDate,
        toDate: data.toDate,
        fromTime: data.startTime,
        toTime: data.endTime,

        occurence: data.repeat,
        intervalValue: data.occurrence?.interval,
        intervalDays: data.occurrence?.byweekday
      };

      setBookClass(bookClassData);
      setInitialBookClass(bookClassData);
    }
    if (!key) {
      handleOpenScreen(3);
    }
    setSelectedRows([]);
  };

  const openEditSchedule = () => {
    setIsScheduleEdit(true);
    setSelectedRows([]);
    setInitialScheduleValues(newSchedule);
    handleOpenScreen(2);
  };

  useEffect(() => {
    if (showScreen === 1 && isInitialState) {
      setIsInitialState(false);
    }
  }, [showScreen, isInitialState]);

  const handleOpenScreen = (value) => {
    setShowScreen(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleResetStates = () => {
    setIsInitialState(true);
    setAllReqFulFilled(false);
    setShowScreen(1);
    setIsScheduleEdit(false);
    setIsBookClassEdit(false);
    closeDialog();
    handleClearStates();
    setAllAttendies([]);
    setSelectedRows([]);

    // call fetch api for getting latest data of services
    fetchData();
  };

  const handleClearStates = (type) => {
    console.log(type, 'typetype');
    if (type === 'bookClass') {
      setBookClass(initials);
      setInitialBookClass(initials);
      setIsBookClassEdit(false);
    } else if (type === 'newSchedule') {
      setNewSchedule(initials);
      setInitialScheduleValues(initials);
    } else {
      setBookClass(initials);
      setNewSchedule(initials);
      setInitialBookClass(initials);
      setInitialScheduleValues(initials);
    }
  };
  // console.log(allAttendies,'gfdddddddddddd');
  // eslint-disable-next-line no-unused-vars
  function getValueFromId(id, array, matchValue, returnValue, extra) {
    const matchedObject = array.find((obj) => obj[matchValue] === id);
    return matchedObject ? matchedObject[returnValue] : null;
  }

  function formatTimeOrDate(type, value) {
    if (type === 'date') {
      // Convert the date string to a Date object
      const dateObj = new Date(value);
      // Get the month, date, and year components
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const date = String(dateObj.getDate()).padStart(2, '0');
      const year = dateObj.getFullYear();
      // Format the date as "MM-DD-YYYY"
      const formattedDate = `${month}-${date}-${year}`;
      return formattedDate;
      // eslint-disable-next-line no-else-return
    } else if (type === 'time') {
      // Split the time string into hours, minutes, and seconds     
      const [hours, minutes] = value.split(':');
      // Convert hours to 12-hour format
      const formattedHours = String(hours % 12 || 12).padStart(2, '0');
      // Determine whether it's AM or PM
      const period = hours < 12 ? 'AM' : 'PM';
      // Format the time as "HH:MM AM/PM"
      const formattedTime = `${formattedHours}:${minutes} ${period}`;
      return formattedTime;
    }
    return '';
  }
  //   ===============call api here=============

  const fetchData = async () => {
    try {
      const [allRoomsResponse, allTrainersResponse, allClientsResponse] =
        await Promise.all([
          ApiService.get(
            `${BASE_URL}schedule/getAllRooms?locationId=${encodedLocationId}`
          ),
          ApiService.get(
            `${BASE_URL}schedule/getAllTrainers?locationId=${encodedLocationId}`
          ),
          ApiService.get(
            `${BASE_URL}schedule/getAllClients?locationId=${encodedLocationId}`
          )
        ]);

      if (!allRoomsResponse?.data?.data?.error) {
        const modifiedData = allRoomsResponse?.data?.Items?.map((item) => ({
          ...item,
          id: item.sortKey
        }));
        setAllRooms(modifiedData);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(allRoomsResponse.message || 'Something went wrong with Rooms list');
      }

      if (!allTrainersResponse?.data?.data?.error) {
        const modifiedData = allTrainersResponse?.data?.Items?.map((item) => ({
          ...item,
          id: item.sortKey
        }));
        setAllTrainers(modifiedData);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(allTrainersResponse.message || 'Something went wrong with Trainers list');
      }

      if (!allClientsResponse?.data?.data?.error) {
        const modifiedData = allClientsResponse?.data?.Items?.map((item) => ({
          ...item,
          id: item.sortKey
        }));
        setAllParents(modifiedData);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(allClientsResponse.message || 'Something went wrong with Clients list');
      }
      setLoading(false);
      setTableLoading(false);
    } catch (error) {
      // alert(error)
      console.warn('API Error', error);
      setLoading(false);
      setTableLoading(false);
      setShowErrorMessage(true)
      setErrorMessage('Internal Server Error')
      // toast.error('Internal Server Error');
    }
  };
  console.log(newSchedule, 'dialog14233434')
  const sendNotification = async () => {
    if (!notify.value) {
      setShowErrorMessage(true)
      setErrorMessage('Plz select option first')
      // toast.error('Plz select option first');
      return;
    }

    let payload = {
      locationId: locationId,
      sendTo: notify.value,
      appointmentId: newSchedule.appointmentId,
      occurrenceType: newSchedule.type,
      type: editType
    }
    if (editType === 'series') {
      payload.occurrenceId = newSchedule.id
    }

    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/sendNotification`,
        payload
      );

      if (response?.statusCode === 200) {
        if (dialog.isDelete) {
          handleDeleteAppointment();
        } else {
          updateSchedule();
        } if (dialog.isDelete) {
          if (showScreen === 4) {
            handleDeleteAppointment('list');
          } else {
            handleDeleteAppointment();
          }
        } else if (dialog.notifyUpdate) {
          updateSchedule();
        } else {
          handleCancelAppointment();
        }

      } else {
        setShowErrorMessage(true)

        setErrorMessage(response?.message || 'Something went Wrong in notification send')
        // toast.error(response?.message || 'Something went Wrong');
      }
    } catch (error) {
      setShowErrorMessage(true)
      setErrorMessage('Something went Wrong in notification send')
      // toast.error(error?.message || 'Something went Wrong');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getServiceCategories = async () => {
    try {
      const response = await ApiService.get(
        `${BASE_URL}schedule/getAllServiceCategories?locationId=${encodedLocationId}&serviceType=${newSchedule.type}`
      );
      if (!response.data?.error) {
        setAllServiceCategories(response?.data?.Items);
      } else {
        setShowErrorMessage(true)
        setErrorMessage(response?.data.message || 'Something went wrong with get service categories')
        // toast.error(
        //   response.data?.message ||
        //     'Something went wrong with get service categories'
        // );
      }
    } catch (error) {
      console.error(error);
      setShowErrorMessage(true)
      setErrorMessage('Something went wrong, Try again!')
      // toast.error('Something went wrong, Try again!');
    }
  };

  const getServicesBasedOnCategory = async () => {
    let categoryId = encodeURIComponent(newSchedule.serviceCategory);

    try {
      const response = await ApiService.get(
        `${BASE_URL}schedule/getAllServices?locationId=${encodedLocationId}&categoryId=${categoryId}`
      );

      if (!response.data?.error) {
        setAllServices(response.data?.Items);
      } else {
        setShowErrorMessage(true)
        setErrorMessage(response?.data.message || 'Something went wrong with getting all services')
        // toast.error(
        //   response.data?.message ||
        //     'Something went wrong with getting all services'
        // );
      }
    } catch (error) {
      console.error(error);
      setShowErrorMessage(true)
      setErrorMessage('Something went wrong, Try again!')
      // toast.error('Something went wrong, Try again!');
    }
  };

  const getPetsBasedOnClient = async () => {
    let clientId = encodeURIComponent(newSchedule.parent);

    try {
      const response = await ApiService.get(
        `${BASE_URL}schedule/getAllPets?clientId=${clientId}`
      );

      if (!response.data?.error) {
        setAllPets(response.data?.Items);
      } else {
        setShowErrorMessage(true)
        setErrorMessage(response?.data?.message || 'Something went wrong with getting Pets lis')
        // toast.error(
        //   response.data?.message ||
        //     'Something went wrong with getting Pets list'
        // );
      }
    } catch (error) {
      console.error(error);
      setShowErrorMessage(true)
      setErrorMessage('Something went wrong, Try again!')
      // toast.error('Something went wrong, Try again!');
    }
  };

  useEffect(() => {
    getServiceCategories();
  }, [newSchedule.type]);

  useEffect(() => {
    if (newSchedule.serviceCategory) {
      getServicesBasedOnCategory();
    }
  }, [newSchedule.serviceCategory]);

  useEffect(() => {
    if (newSchedule.parent) {
      getPetsBasedOnClient();
    }
  }, [newSchedule.parent]);

  // =================Cancel/Delete Appointment =======================
  // useEffect(() => {
  //   if (newSchedule.editTypeValue == 'unAvailable') {
  //     setDialog({ ...dialog, state: true, edit: true });
  //   }
  // }, [newSchedule.editTypeValue]);

  const handleCancelAppointment = (type) => {
    if (type === 'customEdit') {
      handleOpenScreen(4);
    } else if (type === 'list') {
      cancelAppointment(editType, selectedRows);
    } else if (editType !== 'custom') {
      cancelAppointment(editType, newSchedule.id);
    }
  };
  // console.log(editType, 'editTypeeditType');
  const handleDeleteAppointment = (type) => {
    if (type === 'customDelete') {
      handleOpenScreen(4);
    } else if (type === 'list') {
      cancelAppointment(deleteType, selectedRows);
    } else if (deleteType !== 'custom') {
      cancelAppointment(deleteType, newSchedule.id);
    }
  };

  const cancelAppointment = async (type, occurrence) => {
    const data = {
      locationId: locationId,
      type: type
    };

    if (type === 'custom') {
      data.occurrenceIds = occurrence;
    } else {
      data.occurrenceId = occurrence;
      data.fromDate = formatTimeOrDate('date', newSchedule.fromDate);
    }

    setBtnLoading((prev) => {
      let toggleId;
      if (prev['cancel_occurence']) {
        // eslint-disable-next-line no-useless-computed-key
        toggleId = { ['cancel_occurence']: false };
      } else {
        // eslint-disable-next-line no-useless-computed-key
        toggleId = { ['cancel_occurence']: true };
      }
      return { ...toggleId };
    });

    try {
      // VV - remove as not needed from drontend side
      // const response = await ApiService.post(
      //   `${BASE_URL}schedule/cancelAppointment`,
      //   data
      // );

      // if (!response.data?.error) {
      //   setShowSuccessMessage(true)
      //   setSuccessMessage('Appointment deleted successfully');
      //   // toast.success(response.data?.message);
      //   handleResetStates();
      // } else {
      //   setShowErrorMessage(true)
      //   setErrorMessage(response?.data?.message || 'Something went wrong with cancelling appoinment')
      //   // toast.error(
      //   //   response.data?.message ||
      //   //     'Something went wrong with cancelling appoinment'
      //   // );
      // }

      setBtnLoading(false);
    } catch (error) {
      console.error(error);
      setBtnLoading(false);
      setShowErrorMessage(true)
      setErrorMessage('Something went wrong, Try again!')
      // toast.error('Something went wrong, Try again!');
    }
  };
  // console.log(newSchedule, 'newwwwwwwww');
  const handleChangeStatus = async (statusData) => {
    setIsLoading(true);
    console.log('VV-->',newSchedule);
    try {
      const locData = {
        // locationId: locationId,
        appointmentId: newSchedule.appointmentId
      };
      const allData = { ...statusData, ...locData };

      const response = await ApiService.put(
        `${BASE_URL}schedule/changeStatus`,
        allData
      );

      if (!response.data?.error) {
        setIsLoading(false)
        setLoadData(true);
        openDetail(response?.data?.data?.Attributes);
        handleChangeStatus();

      } else {
        setShowErrorMessage(true)
        setIsLoading(false)
        setIsLoading(false)
        setErrorMessage(response.data?.message || 'Something went wrong with status update') 
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    } 
  };

  // const handleButtonClick = () => {
  //   setShowScreen(5);
  // };

  // const buttons = [
  //   {
  //     label: 'Room Setup',
  //     onClick: handleButtonClick,
  //     theam: true
  //   }
  // ];

  useEffect(() => {
    // console.log('newSchedule', newSchedule);
  }, [newSchedule]);

  useEffect(() => {
    // console.log('bookClass', bookClass);
  }, [bookClass]);

  // useEffect(() => {
  //   // console.log('allAttendies', allAttendies);
  // }, [allAttendies]);
  const closePopup = () => {
    setShowSuccessMessage(false);
  };
  console.log(loadData, 'loadDataloadDataloadData1');
  console.log(showScreen, 'showScreen')
  // console.log( allServiceCategories, 'allServiceCategoriesallServiceCategoriesallServiceCategoriesallServiceCategories' );
  return (
    <>
      <Box ml={2.5} mb={1}>
        <Loader IsLoading={isLoading} />
        {showScreen === 1 && <Header title="Schedule Management" />}
      </Box>
      {showErrorMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowErrorMessage} />
      )}
      {showSucessMessage && (
        <SuccessMessage message={sucessMessage} setShowMessage={setSuccessMessage} closePopup={closePopup} />
      )}
      <Container maxWidth="100%" className="custom-container">
        {(() => {
          switch (showScreen) {
            case 2:
              return (
                <Box justifyContent="center" direction="column">
                  <AddSchedule
                    handleResetStates={handleResetStates}
                    btnLoading={btnLoading}
                    setBtnLoading={setBtnLoading}
                    isScheduleEdit={isScheduleEdit}
                    setIsScheduleEdit={setIsScheduleEdit}
                    initialScheduleValues={initialScheduleValues}
                    newSchedule={newSchedule}
                    setNewSchedule={setNewSchedule}
                    dialog={dialog}
                    setDialog={setDialog}
                    allReqFulFilled={allReqFulFilled}
                    setAllReqFulFilled={setAllReqFulFilled}
                    allServiceCategories={allServiceCategories}
                    allTrainers={allTrainers}
                    allServices={allServices}
                    allParents={allParents}
                    allPets={allPets}
                    allRooms={allRooms}
                  />
                </Box>
              );
            case 3:
              return (
                <Box justifyContent="center" direction="column">
                  <ServiceDetails
                    handleResetStates={handleResetStates}
                    openEditSchedule={openEditSchedule}
                    setIsBookClassEdit={setIsBookClassEdit}
                    setInitialBookClass={setInitialBookClass}
                    handleStatus={handleChangeStatus}
                    newSchedule={newSchedule}
                    dialog={dialog}
                    setLoadData={setLoadData}
                    setDialog={setDialog}
                    allPets={allPets}
                    bookClass={bookClass}
                    setBookClass={setBookClass}
                    btnLoading={btnLoading}
                    allAttendies={allAttendies}
                  />
                </Box>
              );
            case 1:
              return (
                <Box justifyContent="center" direction="column">
                  <Calender
                    handleOpenModal={handleOpenModal}
                    dialog={dialog}
                    setDialog={setDialog}
                    loadData={loadData}
                  />
                </Box>
              );
            case 4:
              return (
                <Box justifyContent="center" direction="column">
                  <AllOccurences
                    handleOpenScreen={handleOpenScreen}
                    handleCancelAppointment={handleCancelAppointment}
                    newSchedule={newSchedule}
                    setNewSchedule={setNewSchedule}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    btnLoading={btnLoading}
                    dialog={dialog}
                    setDialog={setDialog}
                  />
                </Box>
              );
            case 5:
              return (
                <Box justifyContent="center" direction="column">
                  <AllRooms
                    // handleOpenScreen={handleOpenScreen}
                    // roomsLists={roomsLists}
                    // getAllRooms={getAllRooms}
                    setShowScreen={setShowScreen}
                  />
                </Box>
              );
            default:

              return (
                <Box justifyContent="center" direction="column">
                  <Calender
                    handleOpenModal={handleOpenModal}
                    dialog={dialog}
                    setDialog={setDialog}
                    loadData={loadData}
                  />
                </Box>
              );
          }
        })()}

        <CalenderDialogs
          dialog={dialog}
          setDialog={setDialog}
          onClose={handleCloseTypeModal}
          newSchedule={newSchedule}
          setNewSchedule={setNewSchedule}
          notify={notify}
          setNotify={setNotify}
          deleteType={deleteType}
          setDeleteType={setDeleteType}
          editType={editType}
          setEditType={setEditType}
        />

        <BookClassDialog
          handleResetStates={handleResetStates}
          dialog={dialog}
          setDialog={setDialog}
          setLoadData={setLoadData}
          loadData={loadData}
          onClose={handleCloseTypeModal}
          bookClass={bookClass}
          setBookClass={setBookClass}
          setNewSchedule={setNewSchedule}
          initialBookClass={initialBookClass}
          newSchedule={newSchedule}
          isBookClassEdit={isBookClassEdit}
          allParents={allParents}
          allPets={allPets}
          allAttendies={allAttendies}
          allRooms={allRooms}
        />
      </Container>
    </>
  );
};

export default Schedules;
