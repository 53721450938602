import React, { useEffect, forwardRef, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide
} from '@mui/material';
import {
  DynamicPopup,
  DynamicTextField
} from '../../../components/GlobalComponents';
import { getLocalData } from 'src/utils';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import { RoomTargetTable } from './RoomTargetTable';
import Loader from 'src/components/Loader';
import { ApiService } from 'src/services/ApiService';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const AddRoom = (props) => {
  const {
    handleClose,
    roomsDetail,

    fetchData: fetchRoomData,
    first,
    setfirst,
    setroomDetails,
    setShowSuccessMessage,
    setSuccessMessage,
    showSucessMessage,
    sucessMessage
  } = props;

  const locationId = getLocalData('locationId');
  const [isLoading, setIsLoading] = useState(false);
  const userId = sessionStorage.getItem('UserId');

  const [roomData, setroomData] = useState('');
  const [roomName, setroomName] = useState('');
  const [description, setDescription] = useState('');
  const [pageType, setPageType] = useState(1);

  const [roomNameError, setroomNameError] = useState(false);
  const [selectedError, setselectedError] = useState(false);

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [isFieldEdited, setIsFieldEdited] = useState(false);
  const [allServices, setAllServices] = useState([]);
  const [dialog, setDialog] = useState({
    state: false,
    isCreated: false
  });
  const [selectedItems, setSelectedItems] = useState({
    items: [],
    values: []
  });
  const handleResetStates = () => {
    setDialog({
      state: false,
      isCreated: false
    });

    setroomNameError(false);
  };

  const handleContractInfo = (type, value) => {
    // Set to true if any field is edited
    setIsFieldEdited(true);
    if (type === 'roomName') {
      if (value) {
        setroomName(value);
        setroomNameError(false);
      } else {
        setroomName('');
        setroomNameError(true);
      }
    } else if (type === 'description') {
      setDescription(value);
    }
  };

  function findObjectsByIds(tableData, idsToFind) {
    return idsToFind
      .map((sortKey) => {
        const foundObject = tableData?.find(
          (item) => item?.sortKey === sortKey
        );
        if (foundObject) {
          return {
            serviceId: foundObject?.sortKey,
            serviceName: foundObject?.name
          };
        }
        return null;
      })
      .filter(Boolean);
  }

  console.log(selectedItems, 'selectedItemsselectedItems');
  // add new room
  const addnewRoom = async () => {
    let item;
    if (selectedItems?.items?.length <= 0) {
      item = findObjectsByIds(allServices, selectedItems?.values);
    } else {
      item = selectedItems?.items;
    }
    if (!roomName) {
      setroomNameError(true);
    } else if (
      (selectedItems?.items && selectedItems?.items.length !== 0) ||
      (roomsDetail && roomsDetail?.length !== 0)
    ) {
      setroomNameError(false);
      setIsLoading(true);
      let params = {
        locationId: locationId,
        name: roomName,
        description: description ?? '',
        // createdBy: userId,
        forAllServices: false,
        services: item ?? []
      };
      try {
        let response;
        if (roomsDetail) {
          params.updatedBy = userId;
          let roomId = encodeURIComponent(roomsDetail[1]);
          response = await ApiService.put(
            `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}rooms?id=${roomId}`,
            params
          );
        } else {
          params.createdBy = userId;
          response = await ApiService.post(
            `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}rooms`,
            params
          );
        }
        const responseData = response;
        if (responseData.statusCode === 200) {
          fetchRoomData();
          handleClose();
          setIsLoading(false);
          setTimeout(() => {
            setShowSuccessMessage(true);
            setSuccessMessage(responseData.message);
            setfirst(false);
          }, 500);
        } else {
          setIsLoading(false);
          setShowErrorMessage(true);
          setErrorMessage(responseData?.message);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        setShowErrorMessage(true);
        setErrorMessage('Something went wrong, Try again!');
      }
    }

    if (
      selectedItems?.items &&
      selectedItems?.items?.length <= 0 &&
      !roomsDetail
    )
      setselectedError(true);
  };

  const handleCloseScreen = (type) => {
    if (type) {
      handleClose();
    } else {
      handleResetStates();
    }
  };

  const handleDialog = (value) => {
    if (value === 'Continue') {
      setDialog({
        isCreated: false,
        state: false
      });
      // handleClose();
    } else if (value === 'Discard') {
      handleResetStates();
      handleClose();
    } else if (value === 'Cancel') {
      handleCloseScreen();
    }
    handleClose();
  };

  const handleAddService = (e) => {
    e.preventDefault();
  };

  // Methdo to handle the click event for back arrow
  const handleCLick = () => {
    // If the value is confirm then navigate to the list page
    if (isFieldEdited) setConfirmDialog(true);
    else if (first) {
      setroomDetails('');
      setfirst(false);
      handleClose();
    } else {
      handleResetStates();
      handleClose();
    }
  };

  // Method to trigger when close the popup
  const closePopup = () => {
    setShowSuccessMessage(false);
  };

  // Handler to handle the dialog
  const handleConfirmationDialog = (value) => {
    // If the value is confirm then navigate to the list page
    setConfirmDialog(false);

    if (value === 'Discard') {
      if (first) {
        setroomDetails('');
        setfirst(false);
        handleClose();
      } else {
        handleResetStates();
        handleClose();
      }
    }
  };

  useEffect(() => {
    setroomName('');
    setDescription('');
    setPageType(2);
  }, []);

  useEffect(() => {
    if (selectedItems?.items && selectedItems?.items?.length <= 0) {
      // setselectedError(true);
    } else {
      setselectedError(false);
    }
  }, [selectedItems]);

  /* below is used for Edit */
  useEffect(() => {
    if (roomsDetail) {
      setIsFieldEdited(true);
      getDetails();
      getAllServices();
    }
  }, []);

  useEffect(() => {
    setData(roomData);
  }, [roomData]);

  const getDetails = async () => {
    // To show loader
    setIsLoading(true);
    let id = encodeURIComponent(roomsDetail[1]);
    let encodedLocationId = encodeURIComponent(locationId);
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}rooms/getRoomDetails?locationId=${encodedLocationId}&id=${id}`
      );
      if (response.statusCode === 200) {
        setroomData(response?.data?.Items[0]);
        setIsLoading(false);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(response.message);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('error-->', error);
      setIsLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong');
    }
  };

  const setData = (item) => {
    if (item) {
      setroomName(item?.name);
      setDescription(item?.description);
      setSelectedItems({
        items: item?.services,
        values: item?.services?.map((item) => item.sortKey)
      });
      console.log(item);
    }
  };

  const getAllServices = async () => {
    // Show loader

    let encodedLocationId = encodeURIComponent(locationId);
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}services/getAllServices?locationId=${encodedLocationId}`
      );
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      if (response?.statusCode === 200) {
        setAllServices(Item);
        // Hide loader
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      // Hide loader
      setIsLoading(false);
    }
  };
  console.log(selectedItems, 'selectedItemsselectedItems');

  return (
    <>
      <Loader IsLoading={isLoading} />
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}

      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setShowSuccessMessage}
          closePopup={closePopup}
        />
      )}
      <div className="contractMain mt-3">
        <Box
          sx={{ width: '100%' }}
          paddingBottom="20px"
          className="Main-container common-form-container"
        >
          <form className="custom-form" onSubmit={handleAddService}>
            <Grid mt={1} marginBottom="10px">
              <div className="contrHeader">
                {/* <h1
                  className="headHeading"
                  style={{ width: '100% !important' }}
                >
                  {contractsDetail ? 'Edit Contract' : 'Add a new contract'}
                </h1> */}

                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  gap="16px"
                >
                  <Grid item>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Box
                        onClick={() => {
                          handleCLick();
                        }}
                        className="back-arrow-close"
                      >
                        <ArrowLeft />
                      </Box>
                      <Typography className="header-blue-32">
                        {roomsDetail ? 'Edit Room' : 'Add Room'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography variant="h1" color="primary" />
                    <Grid item>
                      <Button
                        variant="contained"
                        type="submit"
                        onClick={() => {
                          addnewRoom();
                        }}
                      >
                        {roomsDetail ? 'Update' : 'Save'}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <div>
              <Grid container spacing={2} columns={12} mt={1}>
                <Grid item xs={12} sm={6}>
                  <DynamicTextField
                    type="text"
                    name="roomName"
                    label="ROOM NAME"
                    helperText={roomNameError && 'Room name is required'}
                    shrink={Boolean(true)}
                    fullWidth
                    value={roomName}
                    onChange={handleContractInfo}
                    className="required-field"
                    InputLabelClass="required-label"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} columns={12} mt={1}>
                <Grid item xs={12} sm={6}>
                  <DynamicTextField
                    fullWidth
                    label="DESCRIPTION"
                    multiline
                    rows="6"
                    type="text"
                    name="description"
                    value={description}
                    onChange={handleContractInfo}
                  />
                </Grid>
              </Grid>
            </div>
          </form>
        </Box>
        <div>
          <Box className="location-details-container">
            <Grid container mt={4} className="location-details-row">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className="location-details-col"
              >
                <Typography className="field-heading-text font-24">
                  Select Services
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {/* Show error message if no item is selected */}
          {selectedError && (
            <div className="error-message">
              Please select at least one service
            </div>
          )}
          <RoomTargetTable
            setSelected={setSelectedItems}
            selected={selectedItems}
            pageType={pageType}
            setIsFieldEdited={setIsFieldEdited}
          />
        </div>
      </div>
      <DynamicPopup
        handleDialog={handleDialog}
        open={dialog.state}
        created={dialog.isCreated}
      />
      {/* Confirmation dialog */}
      <Dialog
        open={confirmDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleConfirmationDialog('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          Save Changes
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                You have unsaved changes, Do you want to
                <Typography className="title-text-sub"> save them?</Typography>
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleConfirmationDialog('Discard')}
          >
            Discard Changes
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 no-hover"
            onClick={() => handleConfirmationDialog('Continue')}
          >
            Go back to Editing
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default AddRoom;
