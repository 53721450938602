import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardHeader,
  CardActions
} from '@mui/material';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useNavigate } from 'react-router-dom';
import CallMadeIcon from '@mui/icons-material/CallMade';
import Header from 'src/components/Header';
import { setFullLocationId, getLocalData } from 'src/utils';

const InventoryManagementDashboard = () => {
  let navigate = useNavigate();
  const BASE_URL = `${process.env.REACT_APP_INVENTRY_API_END_POINT}`;
  const locationId = setFullLocationId(getLocalData('locationId'));
  const [PurchaseOrder, setPurchaseOrder] = useState([]);
  const [productsListed, setProductsListed] = useState([]);
  console.log(PurchaseOrder, productsListed);

  useEffect(() => {
    fetchPurchaseOrder();
  }, []);

  const fetchPurchaseOrder = async () => {
    try {
      let encodedLocationId = encodeURIComponent(locationId);
      const response = await fetch(
        `${BASE_URL}purchase-orders/pendingPurchaseOrder?locationId=${encodedLocationId}`
      );
      if (response.ok) {
        const data = await response.json();
        const countData = data?.data?.Count;
        setPurchaseOrder(countData);
      } else {
        throw new Error('Request failed');
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchProductslisted();
  }, []);

  const fetchProductslisted = async () => {
    try {
      let encodedLocationId = encodeURIComponent(locationId);
      const response = await fetch(
        `${BASE_URL}inventory?locationId=${encodedLocationId}&status=1`
      );
      if (response.ok) {
        const data = await response.json();
        const countData = data?.data?.Count;
        setProductsListed(countData);
      } else {
        throw new Error('Request failed');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Container maxWidth="100%" sx={{ pb: 2 }}>
        <Header title="Inventory Management" />

        {/* <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          gap="16px"
        >
          <Grid item>
             <Typography>POS</Typography>
          </Grid>
          <Grid item>
            <CustomButton
              text="Add New"
              variant="contained"
            // onClick={() => handleOpenScreen(2)}
            >
              Check Out
            </CustomButton>
          </Grid>
          <Grid item>
            <CustomButton variant="outlined">
              Featured
            </CustomButton>
          </Grid>
        </Grid> */}
        <Card
          sx={{ backgroundColor: '#32B2AC', marginTop: '15px' }}
          className="pos-title-card"
        >
          <Grid container spacing={0.3}>
            <Grid item md={1} sm={1} className="image-alignment-style">
              <img src="static/images/logo/posLogo.png" alt="logo" />
            </Grid>
            <Grid item md={10} sm={10}>
              <p className="pos-title">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex
              </p>
            </Grid>
            <Grid item md={1} sm={1}>
              <p>
                {' '}
                <a
                  className="pos-title"
                  style={{ textDecoration: 'underline' }}
                >
                  {' '}
                  View Details
                </a>
              </p>
            </Grid>
          </Grid>
        </Card>
        <Grid container sx={{ marginTop: '10px' }} spacing={2}>
          <Grid item xs={12} sm={8} md={8}>
            <Card
              className="pos-card"
              sx={{
                backgroundColor: '#F8FBFF',
                border: '0.4px solid #C5C5C5',
                pb: 1
              }}
            >
              <CardHeader
                className="font-18 drodown_menu"
                title="PRODUCT SALES IN PAST 7 DAYS"
                action={<MoreVertIcon />}
              >
                {/* <Typography className="card-header">Sales in Past 7 Days</Typography> */}
              </CardHeader>
              <Container>
                <CardMedia>
                  <Typography className="pos-text-size-74">$ 14.6K</Typography>
                  <Box sx={{ textAlign: 'center' }}>
                    <img
                      src="static/images/logo/progressBar.png"
                      alt="progress"
                      style={{ maxWidth: '100%' }}
                    />
                  </Box>
                </CardMedia>
              </Container>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
          >
            <Card
              className="pos-inventory-card"
              sx={{
                backgroundColor: '#F8FBFF',
                border: '0.4px solid #C5C5C5',
                pb: 1
              }}
            >
              <CardHeader
                className="font-18 drodown_menu"
                title="TOTAL PROFIT"
                action={<CallMadeIcon className="icon-call" />}
              >
                {/* <Typography className="card-header">Sales in Past 7 Days</Typography> */}
              </CardHeader>
              <CardContent sx={{ textAlign: 'left' }}>
                <Typography className="pos-inventory-text-size-74">
                  $8.2K
                </Typography>
              </CardContent>
            </Card>
            <Card
              className="pos-inventory-card"
              sx={{
                backgroundColor: '#F8FBFF',
                border: '0.4px solid #C5C5C5',
                pb: 1
              }}
            >
              <CardHeader
                className="font-18 drodown_menu"
                title="TOTAL INVENTORY RECEIVED"
                action={<CallMadeIcon className="icon-call" />}
              >
                {/* <Typography className="card-header">Sales in Past 7 Days</Typography> */}
              </CardHeader>
              <CardContent sx={{ textAlign: 'left' }}>
                <Typography className="pos-inventory-text-size-74">
                  $62K
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container sx={{ marginTop: '10px' }} spacing={2}>
          {/* <Grid item xs={12} sm={8} md={8}>
            <Card
              className="main-card-container"
              sx={{
                backgroundColor: '#F8FBFF',
                border: '0.4px solid #C5C5C5',
                pb: 1
              }}
            >
              <CardHeader
                title="Products"
                action={<CallMadeIcon className="icon-call" />}
              >
                <Typography className="card-header">
                  Sales in Past 7 Days
                </Typography>
              </CardHeader>
              <CardContent
                sx={{ height: '102px', width: '100%' }}
                className="pos-card-sub"
              >
                <Typography className="word-wrapper">
                  <p style={{ textAlign: 'justify' }}>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                  </p>
                </Typography>
              </CardContent>
              <CardActions
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div style={{ display: 'flex' }}>
                  <Box flex={1} marginRight={2} style={{ width: '140px' }}>
                    <Typography className="font-18 weight-700 pos-text-styling pos-color-green">
                      {productsListed}
                    </Typography>
                    <Typography className="font-12 weight-400 pos-text-styling pos-color-black">
                      Products Listed
                    </Typography>
                  </Box>
                  <Box flex={1}>
                    <Typography className="font-18 weight-700 pos-text-styling pos-color-green">
                      48
                    </Typography>
                    <Typography className="font-12 weight-400 pos-text-styling pos-color-black">
                      Near Expiry
                    </Typography>
                  </Box>
                </div>

                <Button
                  className="button-style-pos"
                  variant="contained"
                  onClick={() => {
                    navigate('/franchise-products');
                  }}
                >
                  <Typography className="font-12 weight-600 pos-text-styling">
                    ADD NEW PRODUCTS
                  </Typography>
                </Button>
                <Button
                  className="button-style-pos"
                  sx={{ marginLeft: '5px' }}
                  variant="contained"
                  onClick={() => {
                    navigate('/franchise-products');
                  }}
                >
                  <Typography className="font-12 weight-600 pos-text-styling">
                    MANAGE PRODUCTS
                  </Typography>
                </Button>
              </CardActions>
            </Card>
          </Grid> */}
          {/* <Grid item xs={12} sm={4} md={4}>
            <Card
              className="main-card-container"
              sx={{
                backgroundColor: '#F8FBFF',
                border: '0.4px solid #C5C5C5',
                pb: 1
              }}
            >
              <CardHeader
                title="Purchase Orders"
                action={<CallMadeIcon className="icon-call" />}
              >
                <Typography className="card-header">
                  Sales in Past 7 Days
                </Typography>
              </CardHeader>
              <CardContent className="pos-card-sub">
                <Typography className="word-wrapper">
                  <p style={{ textAlign: 'justify' }}>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                  </p>
                </Typography>
              </CardContent>
              <CardActions
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Box>
                  <Typography className="font-18 weight-700 pos-text-styling pos-color-green">
                    {PurchaseOrder}
                  </Typography>
                  <Typography className="font-12 weight-400 pos-text-styling pos-color-black">
                    Pending Orders
                  </Typography>
                </Box>

                <Button
                  className="button-style-pos"
                  variant="contained"
                  onClick={() => {
                    navigate('/purchase-order');
                  }}
                >
                  <Typography className="font-12 weight-600 pos-text-styling">
                    CREATE NEW ORDER
                  </Typography>
                </Button>
              </CardActions>
            </Card>
          </Grid> */}
          <Grid item xs={12} sm={4} md={4}>
            <Card
              className="main-card-container pos-card-container"
              sx={{
                backgroundColor: '#F8FBFF',
                border: '0.4px solid #C5C5C5',
               // pb: 1
              }}
            >
              <CardHeader
                title="Inventory Adjustment"
                action={<CallMadeIcon className="icon-call" />}
              >
                {/* <Typography className="card-header">Sales in Past 7 Days</Typography> */}
              </CardHeader>
              <CardContent className="pos-card-sub pos-card-content">
                <Typography className="word-wrapper">
                  <p>
                    Sed dictum condimentum turpis at pharetra. Suspendisse vitae
                    consectetur nunc. In consequat ultricies sapien, sed
                    volutpat nibh lacinia quis. Orci varius natoque
                  </p>
                </Typography>
              </CardContent>
              <CardActions
                sx={{ display: 'flex', justifyContent: 'end', padding: '16px'}}
                className='pos-card-action absolute'
              >
                {/* <Box>
                  <Typography className="font-18 weight-700 pos-text-styling pos-color-green">
                    24
                  </Typography>
                  <Typography className="font-12 weight-400 pos-text-styling pos-color-black">
                    New members
                  </Typography> 
                </Box> */}
                <Button
                  className="button-style-pos"
                  variant="contained"
                  onClick={() => {
                    navigate('/inventory-adjustment');
                  }}
                >
                  <Typography className="font-12 weight-600 pos-text-styling">
                    ADJUST
                  </Typography>
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Card
              className="main-card-container pos-card-container"
              sx={{
                backgroundColor: '#F8FBFF',
                border: '0.4px solid #C5C5C5',
               // pb: 1
              }}
            >
              <CardHeader
                title="Transfer Inventory"
                action={<CallMadeIcon className="icon-call" />}
              >
                {/* <Typography className="card-header">Sales in Past 7 Days</Typography> */}
              </CardHeader>
              <CardContent className="pos-card-sub pos-card-content">
                <Typography className="word-wrapper">
                  <p>
                    Sed malesuada, lorem eu pellentesque dignissim, augue enim
                    aliquet orci, sit amet vehicula quam augue vitae nisl. Nunc
                    malesuada fringilla magna, nec
                  </p>
                </Typography>
              </CardContent>
              <CardActions
                sx={{ display: 'flex', justifyContent: 'end', padding: '16px' }}
                className='pos-card-action absolute'
              >
                {/* <Box>
                  <Typography className="font-18 weight-700 pos-text-styling pos-color-green">
                    62
                  </Typography>
                  <Typography className="font-12 weight-400 pos-text-styling pos-color-black">
                    Branch Transfer
                  </Typography>
                </Box> */}
                <Button
                  className="button-style-pos"
                  variant="contained"
                  onClick={() => {
                    navigate('/inventory-transfer');
                  }}
                >
                  <Typography className="font-12 weight-600 pos-text-styling">
                    TRANSFER
                  </Typography>
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Card
              className="main-card-container pos-card-container "
              sx={{
                backgroundColor: '#F8FBFF',
                border: '0.4px solid #C5C5C5',
                // pb: 1
              }}
            >
              <CardHeader
                title="Transfer History"
                action={<CallMadeIcon className="icon-call" />}
              >
                {/* <Typography className="card-header">Sales in Past 7 Days</Typography> */}
              </CardHeader>
              <CardContent className="pos-card-sub pos-card-content">
                <Typography className="word-wrapper">
                  <p>
                    Sed malesuada, lorem eu pellentesque dignissim, augue enim
                    aliquet orci, sit amet vehicula quam augue vitae nisl. Nunc
                    malesuada fringilla magna, nec
                  </p>
                </Typography>
              </CardContent>
              <CardActions
                sx={{ display: 'flex', justifyContent: 'end', padding: '16px' }}
                className='pos-card-action absolute'
              >
                {/* <Box>
                  <Typography className="font-18 weight-700 pos-text-styling pos-color-green">
                    43
                  </Typography>
                  <Typography className="font-12 weight-400 pos-text-styling pos-color-black">
                    Gift Cards Sold
                  </Typography>
                </Box> */}
                <Button
                  className="button-style-pos"
                  variant="contained"
                  onClick={() => {
                    navigate('/transfer-history');
                  }}
                >
                  <Typography className="font-12 weight-600 pos-text-styling">
                    HISTORY
                  </Typography>
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default InventoryManagementDashboard;
