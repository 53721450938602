/* eslint-disable spaced-comment */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
// import {
//   Inject,
//   ScheduleComponent,
//   ViewDirective,
//   ViewsDirective,
//   Week
// } from '@syncfusion/ej2-react-schedule';
import './style.css';
import Header from 'src/components/Header';
import { Card, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import { SetOperationHoursModal } from './SetOperationHoursModal';

const OperationHoursCalender = ({
  data,
  currentDate,
  setModalOpen,
  getAllOperatingHours
}) => {
  const theme = useTheme();
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [isEdit, setIsEdit] = useState(false)
  const [openEdit, setEditOpen] = useState(false)
  const [editData, setEditData] = useState([])

  useEffect(() => {
    setSelectedRowsData(data[0]?.operatingHours)
  }, [data])
  const handleEditoperatingHour = (item) => {
    // e.preventDefault();
    setEditData(item)
    setIsEdit(true)
    setEditOpen(true)
  };

  return (
    <><Grid pr={3}>
      <Card
        sx={{
          padding: '15px',
          borderRadius: '19px 19px 0px 0px'
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          ml={2}

        >
          <Grid item>
            {/* <CardHeader title="All Services" className="table-header" /> */}
            {/* <h2 className="table-heading">All Services</h2> */}
            <Typography className="table-heade header-blue-20">
              {' '}
              All Operating Hours
            </Typography>
          </Grid>
        </Grid>
        <TableContainer className="location-table-container">
          <Table>
            <TableHead>
              <TableRow className="table-header">
                <TableCell>Day</TableCell>
                <TableCell>Start Time</TableCell>
                <TableCell>End Time</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {selectedRowsData?.map((row, index) => {
                // const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    key={index}
                    role="checkbox"
                    tabIndex={-1}
                    className="table-row cursor-pointer"
                  >
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        sx={{ marginTop: 1 }}
                        gutterBottom
                        noWrap
                      >
                        {row.day}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {row.startTime}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {row.endTime}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <IconButton
                        sx={{
                          '&:hover': {
                            background: theme.colors.primary.lighter
                          },
                          color: theme.palette.primary.main
                        }}
                        color="inherit"
                        size="small"
                        className="edit-btn"
                        id={'edit_btn_' + index}
                        onClick={(e) =>
                          handleEditoperatingHour(row, e)
                        }
                      >
                        Edit
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        {/* <Box p={2}>

                      <TablePagination
                          rowsPerPageOptions={[5, 10, 20]}
                          component="div"
                          count={filteredData.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage} />
                  </Box> */}
      </Card>
    </Grid>
      {isEdit && (
        <SetOperationHoursModal
          getAllOperatingHours={getAllOperatingHours}
          setModalOpen={setModalOpen}
          open={openEdit}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          data={editData}
          setOpen={setEditOpen}
        />
      )}
    </>

  )
  // const [modalOpen, setModalOpen] = useState(false);
  // const [startFromTime, setStartFromTime] = useState('');
  // const [startFromEndTime, setFromEndTime] = useState('');

  // const [startTime, setStartTime] = useState('08:00');
  // const [endTime, setEndTime] = useState('20:00');
  // const [workHours, setWorkHours] = useState({
  //   highlight: true,
  //   start: startTime,
  //   end: endTime
  // });

  // let scheduleObj = useRef(null);
  // const onPopupOpen = (args) => {
  //   const detail = args?.data ? args?.data : args;

  //   const startDateTimeString = detail.StartTime;

  //   const originalStartDate = new Date(startDateTimeString);

  //   const formattedStartTime = originalStartDate
  //     .toLocaleTimeString([], {
  //       hour: '2-digit',
  //       minute: '2-digit',
  //       hour12: true
  //     })
  //     .toUpperCase();

  //   setStartFromTime(formattedStartTime);

  //   const endDateTimeString = detail.EndTime;

  //   const originalEndDate = new Date(endDateTimeString);

  //   const formattedEndTime = originalEndDate
  //     .toLocaleTimeString([], {
  //       hour: '2-digit',
  //       minute: '2-digit',
  //       hour12: true
  //     })
  //     .toUpperCase();

  //   setFromEndTime(formattedEndTime);

  //   // handleOpenModal();
  //   setModalOpen(true);
  //   if (
  //     args.type === 'QuickInfo' ||
  //     args.type === 'Editor' ||
  //     args.target.classList.contains('e-selected-cell') ||
  //     (args.target && args.target.classList.contains('e-work-cells'))
  //   ) {
  //     args.cancel = true;
  //   }
  // };

  // const onRenderCell = (args) => {
  //   if (
  //     (args.element.classList.contains('e-work-cells') &&
  //       args.element.classList.length === 1) ||
  //     (args.element.classList.contains('e-work-cells') &&
  //       args.element.classList.contains('e-alternate-cells') &&
  //       args.element.classList.length === 2)
  //   ) {
  //     args.element.style.backgroundColor = '#ffff';
  //   }
  // };
  // return (
  //   <div className="schedule-control-section ">
  //     <div className="col-lg-12 control-section">
  //       <div className="control-wrapper control-wrapper-operatingHour">
  //         <ScheduleComponent
  //           showTimeIndicator
  //           allowSwiping={false}
  //           workHours={workHours}
  //           ref={(schedule) => (scheduleObj = schedule)}
  //           cssClass="customStyling"
  //           width="100%"
  //           selectedDate={currentDate}
  //           currentView={'Week'}
  //           eventSettings={{
  //             dataSource: data || []
  //           }}
  //           popupOpen={onPopupOpen}
  //           renderCell={onRenderCell}
  //           height="850px"
  //         >
  //           <ViewsDirective>
  //             <ViewDirective option="Week" allowVirtualScrolling={false}/>
  //           </ViewsDirective>
  //           <Inject services={[Week]} />
  //         </ScheduleComponent>
  //       </div>
  //     </div>
  //     <SetOperationHoursModal
  //       getAllOperatingHours={getAllOperatingHours}
  //       open={modalOpen}
  //       setModalOpen={setModalOpen}
  //       startFromTime={startFromTime}
  //       startFromEndTime={startFromEndTime}
  //       setFromEndTime={setFromEndTime}
  //       setStartFromTime={setStartFromTime}
  //     />
  //   </div>
  // );
};
export default OperationHoursCalender;
