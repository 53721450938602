/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import {
  Box,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect } from 'react';
import {
  AccountNumberIcon,
  ArrowLeft,
  DateOrderIcon,
  ListStatusIcon,
  SupplierIcon
} from '../../assets/SVG/SvgIcons';
import { Accordian } from './index';
import { CustomButton } from '../Global';
import { enterNumber } from '../../utils/index';
// import InputAdornment from '@mui/material/InputAdornment';

export const Summary = (props) => {
  const {
    allSuppliers,
    orderDetails,
    selectedRowsData,
    quantity,
    handleResetStates,
    subTotal,
    btnLoading,
    postData,
    purchaseStatus,
    handleOrderSummary,
    postReviseData,
    postReceiveData,
    handleOpenScreen,
    selectedRows,
    setSelectedRows,
    editOrder,
    unitCost,
    shippingCost,
    setShippingCost
  } = props;

  const [finalSubTotal, setFinalSubTotal] = React.useState(subTotal);
  const [productItems, setProductItems] = React.useState([]);
  const [itemSubTotal, setItemSubTotal] = React.useState(subTotal);
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  };

  useEffect(() => {
    if (purchaseStatus == 'receive') {
      setProductItems(selectedRowsData);
    } else if (
      purchaseStatus == 'summary' &&
      (orderDetails?.orderStatus == 'send' ||
        orderDetails?.orderStatus == 'draft' ||
        orderDetails?.orderStatus == 'closed')
    ) {
      setProductItems(orderDetails?.items);
    } else {
      setProductItems(selectedRowsData);
    }
  }, [orderDetails]);

  // useEffect(() => {
  //   if(orderDetails.shippingCost === 0) setShippingCost(orderDetails.shippingCost);
  // }, [orderDetails.shippingCost]);

  // Close the dialog and scroll top
  const handleDialogClose = (type) => {
    if (type === 'adjustment') {
      handleOpenScreen(2);
    } else {
      handleResetStates();
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Find the suplier with the given id
  function findSupplierName(id) {
    const foundSupplier = allSuppliers?.find(
      (supplier) => supplier.sortKey == id
    );

    if (foundSupplier) {
      // return foundSupplier.supplierName;
      return foundSupplier.businessName;
    }

    return ''; // Return an empty string if no supplier with the given id is found
  }

  // Formate the date
  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US');

    return formattedDate;
  }

  // Now handle the order save on submit btn
  const handleSaveOrder = () => {
    // SetEditOrder to edit when user click on edit button of the purchase table
    if (editOrder == 'edit') {
      postReviseData('Arrived');
    } else if (purchaseStatus == 'create') {
      postData('Arrived');
    } else if (purchaseStatus == 'revise') {
      postReviseData('Arrived');
    } else if (purchaseStatus == 'receive') {
      postReceiveData();
    }
  };

  // console.log('editOrder', editOrder, purchaseStatus);
  const isSelected = (id) => selectedRows.indexOf(id) !== -1;

  /* Function to set the values of shipping cost input fields */

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'ShippingCost') {
      if (value === '') {
        setShippingCost('');
        setFinalSubTotal(subTotal);
      } else {
        // setShippingCost(value);
        let formattedCost = enterNumber(value);
        // formattedCost = formattedCost.toFixed(2);
        setShippingCost(formattedCost);

        // check if cost is available then add into subtotal field
        if (formattedCost) {
          let total = Number(subTotal) + Number(formattedCost);
          total = total.toFixed(2);
          setFinalSubTotal(total);
        }
        // else {
        //   let total = Number(subTotal) - Number(shippingCost);
        //   props.setSubTotal(total);
        // }
      }
    }
  };

  // console.log(shippingCost, 'shippingCost', subTotal);

  useEffect(() => {
    // check if cost is available then add into subtotal field
    if (subTotal > 0 && (shippingCost > 0 || orderDetails.shippingCost > 0)) {
      let total = Number(subTotal) + Number(shippingCost || orderDetails.shippingCost);
      total = total?.toFixed(2);
      setFinalSubTotal(total);
    } else if (orderDetails.subTotal && orderDetails.shippingCost) {
      let total = Number(orderDetails.subTotal) + Number(orderDetails.shippingCost);
      total = total?.toFixed(2);
      setFinalSubTotal(total);
    } else {
      // let subtotalval = subTotal?.toFixed(2);
      setFinalSubTotal(subTotal);
    }

    if (subTotal > 0) {
      setItemSubTotal(subTotal);
    } else if (orderDetails.subTotal > 0) {
      setItemSubTotal(orderDetails.subTotal);
    } else {
      setItemSubTotal(subTotal);
    }
    console.log('iside---', shippingCost);
    // if shipping cost is greater than 0 then get it from orderdetails
    if (shippingCost > 0) {
      setShippingCost(shippingCost);
    } else if (orderDetails.shippingCost > 0) {
      setShippingCost(orderDetails.shippingCost);
    } else {
      setShippingCost(shippingCost);
    }
  }, [shippingCost, subTotal, orderDetails]);
  // console.log('orderDetails---', orderDetails, shippingCost, purchaseStatus);

// Method to formate the number  
  const numberFormate = (num) => {
    const formatted = Number(num).toLocaleString('en', options);
    // console.log(formatted);
    return formatted;
  }

  return (
    <div className="service-container order-container">
      <Box
        sx={{ width: '100%' }}
        paddingBottom="20px"
        className="Main-container common-form-container"
      >
        <Stack direction="column" className="container" marginTop="12px">
          {purchaseStatus == 'create' ? (
            <Box
              variant="variant2"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '15px'
              }}
              className="detail-title font-myriad"
            >
              <Box onClick={handleDialogClose} className="back-arrow-cloe">
                <ArrowLeft />
              </Box>

              <Typography className="header-blue-32">Order Summary</Typography>
            </Box>
          ) : (
            <>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                marginBottom="10px"
              >
                <Grid item>
                  <Typography
                    variant="variant2"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '15px',
                      cursor: 'pointer'
                    }}
                    className="detail-title font-myriad"
                  >
                    <Box
                      onClick={handleDialogClose}
                      className="back-arrow-clse"
                    >
                      <ArrowLeft />
                    </Box>

                    <Typography
                      className="header-blue-24"
                      sx={{ color: '#000 ! important' }}
                    >
                      {`Purchase Order  ${orderDetails.number}`}
                    </Typography>
                  </Typography>
                </Grid>

                {purchaseStatus == 'summary' &&
                  orderDetails.orderStatus != 'closed' &&
                  orderDetails.orderStatus != 'Draft' &&
                  orderDetails.orderStatus != 'draft' ? (
                  <Grid
                    item
                    className="fullWidth purchase-summary-label summary-page-button"
                  >
                    <CustomButton
                      text="Add New"
                      variant="contained"
                      onClick={(e) => handleOrderSummary('receive', e)}
                    >
                      Receive Purchase Order
                    </CustomButton>
                  </Grid>
                ) : null}
              </Grid>

              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography
                    variant="variant2"
                    sx={{
                      fontSize: '24px',
                      textAlign: 'left',
                      textTransform: 'capitalize'
                    }}
                    className="detail-title font-myriad"
                  >
                    {orderDetails.title}
                  </Typography>
                </Grid>

                {/* {purchaseStatus == 'summary' &&
                orderDetails.orderStatus != 'closed' &&
                orderDetails.orderStatus != 'Draft' &&
                orderDetails.orderStatus != 'draft' ? (
                  <Grid
                    item
                    className="fullWidth purchase-summary-label summary-page-button"
                  >
                    <CustomButton variant="outlined">Print Labels</CustomButton>
                  </Grid>
                ) : null} */}
              </Grid>
            </>
          )}

          <Table>
            <TableBody>
              <TableRow>
                <TableCell />
              </TableRow>

              {purchaseStatus != 'create' ? (
                <>
                  <TableRow>
                    <TableCell>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box display="flex" alignItems="center" gap={4}>
                          <ListStatusIcon />
                          <h3 className="accordian-header">Status</h3>
                        </Box>
                        <Typography
                          className="accordian-header"
                          textTransform={'capitalize'}
                        >
                          {orderDetails.orderStatus}
                        </Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box display="flex" alignItems="center" gap={4}>
                          <DateOrderIcon />
                          <h3 className="accordian-header">Date Order</h3>
                        </Box>
                        <Typography className="accordian-header">
                          {formatDate(orderDetails.dateCreated)}
                        </Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                </>
              ) : null}

              <TableRow>
                <TableCell>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box display="flex" alignItems="center" gap={4}>
                      <SupplierIcon />
                      <h3 className="accordian-header">Supplier</h3>
                    </Box>
                    <Typography className="accordian-header">
                      {findSupplierName(orderDetails.supplier)}
                    </Typography>
                  </Stack>
                </TableCell>
              </TableRow>

              {purchaseStatus == 'create' ? (
                <>
                  <TableRow>
                    <TableCell>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box display="flex" alignItems="center" gap={4}>
                          <img
                            src="static/images/logo/shipping.png"
                            alt="shipping"
                          />
                          <h3 className="accordian-header">Shipping Address</h3>
                        </Box>
                        <Typography>{orderDetails.address}</Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box display="flex" alignItems="center" gap={4}>
                          <img
                            src="static/images/logo/shippingCost.png"
                            alt="shipping"
                          />

                          <h3 className="accordian-header">Shipping Cost</h3>
                        </Box>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className="input-field-cont d-flex"
                        >
                          <span className="doller-sign font-20 mr-10 align-self-center">
                            $
                          </span>
                          <TextField
                            fullWidth
                            name="ShippingCost"
                            autocomplete="Shipping Cost"
                            // label="Shipping Cost"
                            value={shippingCost}
                            onChange={handleInputChange}
                            className="input-field-stylin"
                            InputLabelProps={{
                              className: 'label-style-create-franchisee'
                            }}
                            inputProps={{
                              // endAdornment: (
                              //   <InputAdornment position="end">$</InputAdornment>
                              // ),
                              maxLength: 6
                            }}
                          />
                        </Grid>
                      </Stack>
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                <TableRow>
                  <TableCell>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box display="flex" alignItems="center" gap={4}>
                        <AccountNumberIcon />
                        <h3 className="accordian-header">Account Number</h3>
                      </Box>
                      <Typography>xxxx-xxxx-xxxx-xxxx</Typography>
                    </Stack>
                  </TableCell>
                </TableRow>
              )}

              <TableRow>
                <Accordian
                  className="font-myriad"
                  label={'Notes'}
                  data={orderDetails.notes}
                />
              </TableRow>
            </TableBody>
          </Table>
        </Stack>

        <Stack direction="column" className="container" marginTop="24px">
          <Typography
            variant="variant2"
            sx={{
              fontSize: '24px',
              textAlign: 'left',
              marginBottom: '8px'
            }}
            className="detail-title font-myriad"
          >
            Order Items
          </Typography>

          <TableContainer className="location-table-container common-table-container">
            <Table>
              <TableHead className="common-header">
                <TableRow>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Unit Cost</TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {selectedRowsData?.map((item, index) => { */}
                {productItems?.map((item, index) => {
                  return (
                    <>
                      <TableRow
                        role="checkbox"
                        tabIndex={-1}
                        id={index}
                        key={item.sortKey}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell>{item.fName || item?.itemName}</TableCell>
                        <TableCell>
                          {quantity[item.sortKey] || item?.quantity}
                        </TableCell>
                        <TableCell>
                          {(unitCost[item.sortKey] &&
                            '$' + unitCost[item.sortKey]) ||
                            (item?.price && '$' + item.price) ||
                            (item?.cost && '$' + item.cost)}
                        </TableCell>
                        <TableCell>
                          {(unitCost[item.sortKey] &&
                            '$' +
                            numberFormate(unitCost[item.sortKey] *
                            quantity[item.sortKey])) ||
                            (item?.price &&
                              '$' + numberFormate(item.price * quantity[item.sortKey])) ||
                            (item?.amount && '$' + numberFormate(item.amount))}
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>

        <Stack direction="column" className="container" marginTop="24px">
          <Typography
            variant="variant2"
            sx={{ fontSize: '24px', textAlign: 'left' }}
            className="detail-title font-myriad"
          >
            Cost
          </Typography>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell />
              </TableRow>

              <TableRow>
                <TableCell>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box display="flex" alignItems="center">
                      <h3 className="accordian-header">SUBTOTAL</h3>
                    </Box>
                    <Typography className="accordian-header">
                      {/* ${subTotal > 0 ? subTotal : orderDetails.subTotal} */}
                      ${numberFormate(itemSubTotal || orderDetails.subTotal || subTotal)}
                    </Typography>
                  </Stack>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box display="flex" alignItems="center">
                      <h3 className="accordian-header">SHIPPING</h3>
                    </Box>
                    <Typography className="accordian-header">
                      ${numberFormate(shippingCost || orderDetails.shippingCost || 0)}
                    </Typography>
                  </Stack>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box display="flex" alignItems="center">
                      <h3
                        className="accordian-header"
                        style={{ fontWeight: '600', color: '#000' }}
                      >
                        TOTAL
                      </h3>
                    </Box>
                    <Typography
                      className="accordian-header"
                      sx={{ fontWeight: '600', color: '#000' }}
                    >
                      ${numberFormate(finalSubTotal || orderDetails.total)}
                    </Typography>
                  </Stack>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>

        {purchaseStatus == 'summary' ? (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            gap={2}
            className="summary-page-button"
            marginTop={5}
          >
            {orderDetails.orderStatus == 'draft' ||
              orderDetails.orderStatus == 'Draft' ? (
              <CustomButton
                variant="outlined"
                disabled={btnLoading}
                onClick={(e) => handleOrderSummary('revise', e)}
              >
                Revise Order
              </CustomButton>
            ) : (
              ''
            )}
            <CustomButton
              variant="contained"
              loading={btnLoading}
              onClick={(e) => handleOrderSummary('duplicate', e)}
            >
              Duplicate Purchase Order
            </CustomButton>
          </Stack>
        ) : (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            className="summary-page-button"
            gap={2}
            marginTop={5}
          >
            <CustomButton
              variant="outlined"
              disabled={btnLoading}
              onClick={() => handleDialogClose('adjustment')}
            >
              Revise Order
            </CustomButton>

            <CustomButton
              variant="contained"
              loading={btnLoading}
              onClick={handleSaveOrder}
            >
              {purchaseStatus == 'create' ? 'Save & Share' : 'Save'}
            </CustomButton>
          </Stack>
        )}
      </Box>
    </div>
  );
};
