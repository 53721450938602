/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-useless-computed-key */
/* eslint-disable spaced-comment */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
/* eslint-disable react/self-closing-comp */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  Select,
  Slide,
  TextField,
  Typography
} from '@mui/material';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MenuItem from '@mui/material/MenuItem';
import { ApiService } from '../../../services/ApiService';
import { dateFormat, getLocalData } from '../../../utils';
import Loader from '../../../components/Loader';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export function AddTimeOffModal({
  open,
  handleClose,
  trainers,
  getAllTrainers,
  fromTime,
  endTime,
  fromDate,
  endDate,
  selectedTrainerData,
  setendTime,
  setfromTime,
  setendDate,
  setFromDate,
  setselectedTrainerData
}) {
  const locationId = getLocalData('locationId');
  const [formData, setFormData] = useState({
    trainer: null,
    startDate: null,
    sTime: null,
    endDate: null,
    eTime: null
  });

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    trainer: '',
    startDate: '',
    sTime: '',
    endDate: '',
    eTime: ''
  });
  const clearForm = () => {
    setFormData({
      trainer: null,
      startDate: null,
      sTime: null,
      endDate: null,
      eTime: null
    });

    setendTime('');
    setfromTime('');
    setendDate('');
    setFromDate('');
    setselectedTrainerData('');

    setErrors({
      trainer: '',
      startDate: '',
      sTime: '',
      endDate: '',
      eTime: ''
    });
  };
  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    // Validation for trainer field (example: not empty)
    if (!formData.trainer) {
      newErrors.trainer = 'Trainer is required';
      isValid = false;
    }

    // Validation for startDate field (example: not empty)
    if (!formData.startDate) {
      newErrors.startDate = 'Start date is required';
      isValid = false;
    }

    // Validation for sTime field (example: not empty)
    if (!formData.sTime) {
      newErrors.sTime = 'Start time is required';
      isValid = false;
    }

    // Validation for endDate field (example: not empty)
    if (!formData.endDate) {
      newErrors.endDate = 'End date is required';
      isValid = false;
    }

    // Validation for eTime field (example: not empty)
    if (!formData.eTime) {
      newErrors.eTime = 'End time is required';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  console.log(formData, 'formData');
  const handleSave = async () => {
    if (validateForm()) {
      setIsLoading(true);
      let payload = {
        locationId: locationId,
        staffId: formData.trainer,
        availabilityType: 'unavailable',
        // VV - as per the API changes we changes payload
        // startDate: dateFormat(formData.startDate),
        // endDate: dateFormat(formData.endDate),
        startTime: `${dateFormat(formData.startDate)}, ${formData.sTime}`,
        endTime: `${dateFormat(formData.endDate)}, ${formData.eTime}`,
        createdBy: 'Alice Smith'
      };
      console.log(formData, 'formData');
      try {
        let response = await ApiService.post(
          `${process.env.REACT_APP_API_END_POINT}staff/addStaffTimeOff`,
          payload
        );
  
        if (response.statusCode === 200) {
          getAllTrainers();
          setIsLoading(false);
          handleClose();
        }
      }
      catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    }
  };
  const timeSlots = [
    { value: '12:00 AM', label: '12:00 AM' },
    { value: '12:30 AM', label: '12:30 AM' },
    { value: '01:00 AM', label: '01:00 AM' },
    { value: '01:30 AM', label: '01:30 AM' },
    { value: '02:00 AM', label: '02:00 AM' },
    { value: '02:30 AM', label: '02:30 AM' },
    { value: '03:00 AM', label: '03:00 AM' },
    { value: '03:30 AM', label: '03:30 AM' },
    { value: '04:00 AM', label: '04:00 AM' },
    { value: '04:30 AM', label: '04:30 AM' },
    { value: '05:00 AM', label: '05:00 AM' },
    { value: '05:30 AM', label: '05:30 AM' },
    { value: '06:00 AM', label: '06:00 AM' },
    { value: '06:30 AM', label: '06:30 AM' },
    { value: '07:00 AM', label: '07:00 AM' },
    { value: '07:30 AM', label: '07:30 AM' },
    { value: '08:00 AM', label: '08:00 AM' },
    { value: '08:30 AM', label: '08:30 AM' },
    { value: '09:00 AM', label: '09:00 AM' },
    { value: '09:30 AM', label: '09:30 AM' },
    { value: '10:00 AM', label: '10:00 AM' },
    { value: '10:30 AM', label: '10:30 AM' },
    { value: '11:00 AM', label: '11:00 AM' },
    { value: '11:30 AM', label: '11:30 AM' },
    { value: '12:00 PM', label: '12:00 PM' },
    { value: '12:30 PM', label: '12:30 PM' },
    { value: '01:00 PM', label: '01:00 PM' },
    { value: '01:30 PM', label: '01:30 PM' },
    { value: '02:00 PM', label: '02:00 PM' },
    { value: '02:30 PM', label: '02:30 PM' },
    { value: '03:00 PM', label: '03:00 PM' },
    { value: '03:30 PM', label: '03:30 PM' },
    { value: '04:00 PM', label: '04:00 PM' },
    { value: '04:30 PM', label: '04:30 PM' },
    { value: '05:00 PM', label: '05:00 PM' },
    { value: '05:30 PM', label: '05:30 PM' },
    { value: '06:00 PM', label: '06:00 PM' },
    { value: '06:30 PM', label: '06:30 PM' },
    { value: '07:00 PM', label: '07:00 PM' },
    { value: '07:30 PM', label: '07:30 PM' },
    { value: '08:00 PM', label: '08:00 PM' },
    { value: '08:30 PM', label: '08:30 PM' },
    { value: '09:00 PM', label: '09:00 PM' },
    { value: '09:30 PM', label: '09:30 PM' },
    { value: '10:00 PM', label: '10:00 PM' },
    { value: '10:30 PM', label: '10:30 PM' },
    { value: '11:00 PM', label: '11:00 PM' },
    { value: '11:30 PM', label: '11:30 PM' }
  ];
  console.log('errors.startDate:', errors.startDate);

  useEffect(() => {
    setFormData({
      trainer: selectedTrainerData,
      startDate: fromDate,
      sTime: fromTime,
      endDate: endDate,
      eTime: endTime
    });
  }, [fromTime, endTime]);

  return (
    <>
      <Loader IsLoading={isLoading} />
      <Dialog
        className="enrollClassDialog"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description "
      >
        <DialogContent className="custom-scroll -team-container-white-box">
          <Box
            sx={{ width: '100%' }}
            paddingBottom="2
            0px"
            className="Main-container common-form-container"
          >
            <Box className="custom-form">
              <Grid mt={0} marginBottom="8px">
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  gap={'15px'}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      width: '100%'
                    }}
                    mb={2}
                  >
                    <Typography className={'-dialogPopupHeader'}>
                      {' '}
                      Add Time Off
                    </Typography>
                    <CancelOutlinedIcon
                      color="error"
                      sx={{
                        width: '32px',
                        height: '32px',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        clearForm();
                        handleClose();
                      }}
                    />
                  </Box>

                  {/* <h1 className="title"
                      style={{ textTransform: "capitalize", color: "#003087", marginTop: '-50px',marginBottom:'60px', fontSize: "40px" }}>Add Time
                    Off</h1> */}

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      gap: '30px'
                    }}
                  >
                    <FormControl
                      pt={2}
                      variant="outlined"
                      fullWidth
                      sx={{
                        width: '47.89%'
                      }}
                    >
                      <InputLabel className="text-field-styling">
                        SELECT TRAINER
                      </InputLabel>
                      <Select
                        value={formData.trainer}
                        onChange={(event) => {
                          return (
                            setFormData({
                              ...formData,
                              trainer: event.target.value
                            }),
                            setErrors({ ...errors, trainer: '' })
                          );
                        }}
                        label="SELECT TRAINER"
                        // error={!!errors.trainer}
                        helperText={errors.trainer}
                        className="status-field field-label-css"
                        IconComponent={KeyboardArrowDownIcon}
                        MenuProps={{
                          PaperProps: { style: { maxHeight: 250 } }
                        }}
                      >
                        {trainers?.length > 0 &&
                          trainers.map((item, index) => (
                            <MenuItem
                              key={index}
                              id={index}
                              value={item.sortKey}
                            >
                              {item?.firstName || ''} {item?.lastName || ''}
                            </MenuItem>
                          ))}
                      </Select>
                      {errors.trainer && (
                        <Typography color="#d13a00">
                          {errors.trainer}
                        </Typography>
                      )}
                    </FormControl>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        gap: '30px'
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          gap: '10px'
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="FROM"
                            name="Date"
                            // error={!!errors.startDate}
                            helperText={errors.startDate}
                            value={formData.startDate}
                            onChange={(value) => {
                              return (
                                setFormData({ ...formData, startDate: value }),
                                setErrors({ ...errors, startDate: '' })
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id="outlined-required"
                                className="text-field-styling"
                                helperText={errors.startDate}
                                // helperText={cardValidThroughError}

                                InputLabelProps={{
                                  className: 'label-style-text-field'
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                        <FormControl
                          pt={2}
                          variant="outlined"
                          fullWidth
                          sx={{
                            width: '50%'
                          }}
                        >
                          <InputLabel className="text-field-styling">
                            Time
                          </InputLabel>
                          <Select
                            value={formData.sTime}
                            onChange={(event) => {
                              return (
                                setFormData({
                                  ...formData,
                                  sTime: event.target.value
                                }),
                                setErrors({ ...errors, sTime: '' })
                              );
                            }}
                            label="Time"
                            // error={!!errors.sTime}
                            helperText={errors.sTime}
                            className="status-field field-label-css"
                            IconComponent={KeyboardArrowDownIcon}
                            MenuProps={{
                              PaperProps: { style: { maxHeight: 300 } }
                            }}
                          >
                            {timeSlots?.length > 0 &&
                              timeSlots.map((item, index) => (
                                <MenuItem
                                  key={index}
                                  id={index}
                                  value={item.value}
                                >
                                  {item.label}
                                </MenuItem>
                              ))}
                          </Select>
                          {errors.sTime && (
                            <Typography color="#d13a00">
                              {errors.sTime}
                            </Typography>
                          )}
                        </FormControl>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          gap: '10px'
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="TO"
                            name="Date"
                            error={!!errors.endDate}
                            helperText={errors.endDate}
                            value={formData.endDate}
                            onChange={(value) => {
                              return (
                                setFormData({ ...formData, endDate: value }),
                                setErrors({ ...errors, endDate: '' })
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id="outlined-required"
                                className="text-field-styling"
                                // helperText={cardValidThroughError}
                                helperText={errors.endDate}
                                InputLabelProps={{
                                  className: 'label-style-text-field'
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>

                        <FormControl
                          pt={2}
                          variant="outlined"
                          fullWidth
                          sx={{
                            width: '50%'
                          }}
                        >
                          <InputLabel className="text-field-styling">
                            Time
                          </InputLabel>
                          <Select
                            value={formData.eTime}
                            onChange={(event) => {
                              return (
                                setFormData({
                                  ...formData,
                                  eTime: event.target.value
                                }),
                                setErrors({ ...errors, eTime: '' })
                              );
                            }}
                            label="Time"
                            // error={!!errors.eTime}
                            helperText={errors.eTime}
                            className="status-field field-label-css"
                            IconComponent={KeyboardArrowDownIcon}
                            MenuProps={{
                              PaperProps: { style: { maxHeight: 300 } }
                            }}
                          >
                            {timeSlots?.length > 0 &&
                              timeSlots.map((item, index) => (
                                <MenuItem
                                  key={index}
                                  id={index}
                                  value={item.value}
                                >
                                  {item.label}
                                </MenuItem>
                              ))}
                          </Select>
                          {errors.eTime && (
                            <Typography color="#d13a00">
                              {errors.eTime}
                            </Typography>
                          )}
                        </FormControl>
                      </Box>
                    </Box>
                    <Button
                      variant="contained"
                      className="top-button-style"
                      sx={{ padding: '15px' }}
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
