import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { getLocalData } from '../../../utils';
import GlobalData from 'src/global';
import { Helmet } from 'react-helmet-async';
import { ApiService } from '../../../services/ApiService';

import SalesDetail from './SalesDetail';
import { filtersQuery } from '../../../utils/index';
import '../GiftCards/style.css';
import './style.css';

import SearchIcon from '@mui/icons-material/Search';
import { ReturnSuccess } from './ReturnSuccess';
import { ReturnFailed } from './ReturnFailed';
import { useNavigate, useParams } from 'react-router-dom';
import { SalesHistoryFilter } from 'src/components/Filters/SalesHistoryFilters';
import { SalesHistoryTable } from './SalesHistoryTable';
import moment from 'moment';

const SalesHistoryMain = () => {
  const navigate = useNavigate();
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  };
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  let [filters, setFilters] = useState({
    startDate: '',
    petId: '',
    paymentMethod: '',
    endDate: ''
  });
  const [selectedClient, setSelectedClient] = useState(null);
  const [selected, setSelected] = useState(null);
  const [showScreen, setShowScreen] = useState(0);
  const [clientList, setClientList] = useState([]);
  const [selectedOrderId, setSelectedOrderId] = useState('');

  const locationId = getLocalData('locationId');
  const { id } = useParams();

  useEffect(()=> {
    sessionStorage.removeItem("paramKey")
  },[])

  useEffect(()=> {
    if(clientList.length){
      const newClient = clientList?.filter((item)=> item?.sortKey === `#CLIENT#${id}`)
      searchClients("", newClient[0])
    }
  },[clientList])

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      return (event.returnValue = 'Are you sure you want to leave this page?');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  let elm = document.getElementsByClassName('page-header-text')[0];
  if (elm) elm.innerHTML = '';
  const title = GlobalData[0].title;

  const dateFormate = (date) => {
    // let utc = moment(date).format('MMMM Do YYYY, h:mm:ss A');
    let utc;
    utc = date ? moment(date).format('MM-DD-YYYY') : '';
    return utc;
    // let dateFor = date && new Date(date);
    // // let utc = dateFor.toUTCString();
    // let finaldate = dateFor.toLocaleString();
    // return finaldate;
  };

  const numberFormate = (num) => {
    let value = num && num / 100;
    const formatted = Number(value).toLocaleString('en', options);
    // console.log(formatted);
    return formatted;
  };

  console.log(numberFormate)

  const getSalesHistory = async (ClientId) => {
    let queryString = filtersQuery(filters);
    let filtersParams = queryString ? `&${queryString}` : '';
    let encodedLocationId = encodeURIComponent(locationId);

    let encodedClientId = encodeURIComponent(ClientId);

    let response = await ApiService.get(
      `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}checkout/getOrderHistory?locationId=${encodedLocationId}${filtersParams}&clientId=${encodedClientId}`
    );
    let Item =
      response && response.data && response.data.Items
        ? response.data.Items
        : [];
    console.log(Item, 'result1111');
    const result = Item.map((obj) => {
      const {
        sortKey,
        sqaureOrderState,
        orderDate,
        cardNumber,
        paymentSourceType,
        totalSquareDiscountAmountInDollar,
        petName,
        petId,
        dateOfPurchase,
        totalSquareAmountWithTaxInDollar
      } = obj;

      return {
        partitionKey: petId,
        sortKey,
        Date: orderDate
          ? dateFormate(orderDate)
          : dateOfPurchase && dateFormate(dateOfPurchase),
        'Payment Status': sqaureOrderState || '-',
        'Payment Method': paymentSourceType || '-',
        'Card Number': cardNumber?.substring(cardNumber.length - 4) || '-',
        Dog: petName || '-',
        'Total Amount':
          (totalSquareAmountWithTaxInDollar && '$' + totalSquareAmountWithTaxInDollar) || '$0.00',
        Discount: (totalSquareDiscountAmountInDollar && totalSquareDiscountAmountInDollar + '%') || 0
      };
    });

    // setData(Item);

    setTableData(result);
    setLoading(false);
  };
  const getAllClients = async () => {
    let encodedLocationId = encodeURIComponent(locationId);

    let response = await ApiService.get(
      `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}client/getAllClients?locationId=${encodedLocationId}`
    );
    let Item =
      response && response.data && response.data.Items
        ? response.data.Items
        : [];
    console.log(Item, 'result');
    setClientList(Item);
  };

  const searchClients = async (event, newValue) => {
    setSelectedClient(newValue);
    if (newValue) {
      let response = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}client/getClientsBySearch?searchText=${newValue.firstName}`
      );
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      // setClientList(Item);
      console.log(Item, 'Item');
      getSalesHistory(newValue.sortKey);
    }
  };

  useEffect(() => {
    if (filters && selectedClient) {
      getSalesHistory(selectedClient.sortKey);
    }
  }, [filters]);
  useEffect(() => {
    getAllClients();
  }, []);

  const handleOnClick = (data) => {
    if (data[2] === 'Detail') {
      const list = [...tableData];
      const selectedItem = list.filter((item) => item.sortKey === data[1]);
      setSelectedOrderId(data[1]);
      setSelected(selectedItem[0]);
      setShowScreen(1);
    }
  };

  return (
    <>
      {(() => {
        switch (showScreen) {
          case 1:
            return (
              <SalesDetail
                detail={selected}
                setActive={setShowScreen}
                selectedOrderId={selectedOrderId}
              />
            );
          case 2:
            return <ReturnFailed setActive={setShowScreen} />;
          case 3:
            return <ReturnSuccess setActive={setShowScreen} />;
          default:
            return (
              <>
                <Helmet>
                  <title>Sales History | {title}</title>
                </Helmet>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    marginLeft: ' 40px',
                    justifyContent: 'start',
                    flexDirection: 'column',
                    gap: '20px'
                  }}
                >
                  <Typography className="page-title" variant="h1">
                    <Box
                      className="back-arrow-close"
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '15px'
                      }}
                      onClick={() => navigate('/staff-dashboard')}
                    >
                      <svg
                        width="27"
                        height="19"
                        viewBox="0 0 27 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M26.625 7.90885H5.96042L11.1812 2.67344L9.125 0.617188L0.375 9.36719L9.125 18.1172L11.1812 16.0464L5.96042 10.8255H26.625V7.90885Z"
                          fill="#003087"
                        />
                      </svg>
                      Sales History
                    </Box>
                  </Typography>
                  <Box className="clientSearch searchBar" mt={2}>
                    <Autocomplete
                      style={{
                        width: '400px'
                      }}
                      value={selectedClient}
                      onChange={searchClients}
                      className="status-field field-label-css"
                      options={clientList ?? []}
                      
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.sortKey}>
                            {option.firstName} {option.lastName}
                          </li>
                        );
                      }}
                      getOptionLabel={(option) => option?.firstName }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: selectedClient === null && (
                              <SearchIcon />
                            ),
                            type: 'search'
                          }}
                          // onChange={(event) => searchClients(event, event.target.value)}
                          label={'Search Client'}
                          variant="outlined"
                        />
                      )}
                    />
                  </Box>
                </Box>
                {selectedClient && (
                  <SalesHistoryTable
                    loading={loading}
                    filterCompoenent={
                      <SalesHistoryFilter
                        setFilters={setFilters}
                        filters={filters}
                        selectedClient={selectedClient}
                      />
                    }
                    data={tableData || []}
                    isEdit
                    isFilter
                    isSearch
                    handleOnClick={handleOnClick}
                  />
                )}
              </>
            );
        }
      })()}
    </>
  );
};

export default SalesHistoryMain;
