import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Grid
} from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import clsx from 'clsx';
import { CustomInput } from './CustomInput';
import { Filter } from './Filter';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@mui/x-data-grid';

export const FilterAndSearchComponent = ({
  sideBarFilters,
  setSidebarFilters,
  title,
  dataList,
  columns,
  loading,
  isCheckBox,
  setSelected,
  selected,
  filterOptions,
  handleChangeFilter,
  IsRoomEdit,
  setIsFieldEdited
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [displayFilterForm, setDisplayFilterForm] = useState(false);

  const handleChange = (value) => {
    setSearchQuery(value);
    // let list = [...tableData];
    let list = [...dataList];

    let result = columns?.map((attribute) =>
      list.filter((item) =>
        item[attribute?.field]
          ?.toString()
          ?.toLowerCase()
          .includes(value.toLowerCase())
      )
    );
    const convertedData = [];
    let uniqueData = [];
    result.forEach((item) => {
      if (item.length > 0) {
        convertedData.push(...item);
      }
    });
    convertedData.forEach((item) => {
      if (!uniqueData.includes(item)) {
        uniqueData.push(item);
      }
    });
    if (value !== '') {
      setTableData(uniqueData);
    } else {
      setTableData(dataList);
    }
  };

  useEffect(() => {
    setTableData(dataList);
    setSelected({
      ...selected,
      items: [...selected.items],
      values: [...selected.values]
    });
    setSelectedRows(selected?.values);
  }, [dataList]);

  useEffect(() => {
    setSelected({
      ...selected,
      items: [...selected.items],
      values: [...selected.values]
    });
    setSelectedRows(selected?.values);
  }, [tableData]);

  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));

  const classes = useStyles();
  const getFilterSize = () => {
    return displayFilterForm ? 300 : 0;
  };

  const showFilter = () => {
    setDisplayFilterForm(!displayFilterForm);
    handleChangeFilter();
  };
  const getRowId = (row) => row?.sortKey;

  useEffect(() => {
    // If it is in edit mode then set the selected rows
    console.log(IsRoomEdit, selected);
    if (selected?.values?.length > 0 && !IsRoomEdit) {
      setSelectedRows(selected?.values);
    }
  }, [selected]);

  // Working code
  useEffect(() => {
    if (selectedRows?.length > 0) setSelectedRows(selectedRows);
    if (selected?.values?.length > 0 && selectedRows?.length === 0) {
      setSelectedRows(selected?.values);
    }

    let myArrayFiltered = filterArray(selected?.items, selectedRows);
    let filterPOData = myArrayFiltered.map((item) => {
      return {
        serviceName: item?.name ?? item?.serviceName, // itemId
        serviceId: item?.serviceId ?? item?.sortKey
      };
    });

    setSelected({
      ...selected,
      items: filterPOData,
      values: selectedRows
    });
  }, [selected?.values, selectedRows]);

  function filterArray(array, filter) {
    let myArrayFiltered = [];
    for (let i = 0; i < array.length; i++) {
      for (let j = 0; j < filter.length; j++) {
        if (array[i].serviceId === filter[j]) {
          myArrayFiltered.push(array[i]);
        }
      }
    }
    return myArrayFiltered;
  }

  const handleCheckBoxChange = (newRowSelectionModel) => {
    // set if field is edited
    setIsFieldEdited(true);
    setSelectedRows(newRowSelectionModel);
    const filteredResults = tableData.filter((item) =>
      newRowSelectionModel.includes(item.serviceId)
    );
    let updatedArray = filteredResults;
    updatedArray = filteredResults.map((item) => {
      return {
        serviceName: item?.name ?? item?.serviceName, // itemId
        serviceId: item?.serviceId ?? item?.sortKey
      };
    });

    setSelected({
      ...selected,
      items: [...updatedArray].filter((item, index, self) => {
        return (
          self.findIndex((obj) => obj.serviceId === item.serviceId) === index
        );
      }),
      values: [...newRowSelectionModel].filter((value, index, self) => {
        return self.indexOf(value) === index;
      })
    });
    setSelectedRows(newRowSelectionModel);
  };
  const clearFilters = () => {
    setSidebarFilters({
      typeOfService: null,
      status: 1,
      categoryName: []
    });
  };
  return (
    <>
      <Grid>
        {sideBarFilters && (
          <Grid
            xs={12}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Grid
              item
              sx={{
                // marginTop: '50px',
                marginBottom: '20px'
              }}
            >
              <Button
                className="filterBtn"
                onClick={showFilter}
                variant={displayFilterForm ? 'contained' : 'outlined'}
              >
                Filters
                <KeyboardArrowDownRoundedIcon
                  className={clsx(
                    !displayFilterForm && classes.closeY,
                    displayFilterForm && classes.openY
                  )}
                />
              </Button>
            </Grid>

            <Grid
              xs={12}
              sm={6}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '10px',
                marginBottom: '20px'
              }}
              className="searchBar"
            >
              <CustomInput
                label={'Search'}
                value={searchQuery}
                setSearchQuery={handleChange}
              />
            </Grid>
          </Grid>
        )}
        <Grid
          className="cartFilter cartTable promotion-cart-table no-width"
          container
          spacing={2}
        >
          {displayFilterForm === true ? (
            <Grid item width={getFilterSize()}>
              <Filter
                filters={sideBarFilters}
                filterOptions={filterOptions}
                setFilters={setSidebarFilters}
                clearFilters={clearFilters}
              />
            </Grid>
          ) : null}
          <Grid
            item
            sx={{
              width: `calc(100% - ${getFilterSize()}px)})`
            }}
          >
            {loading ? (
              <div className="loader">
                <CircularProgress />
              </div>
            ) : (
              <Grid
                item
                sx={{
                  width: '100%'
                }}
              >
                <Card
                  sx={{ padding: '15px', borderRadius: '19px 19px 0px 0px' }}
                >
                  <CardHeader
                    title={title}
                    className="table-header-name"
                    sx={{ fontSize: '20px', color: '#003087' }}
                  />
                  <DataGrid
                    className="custom-table col-4 editable "
                    rows={tableData}
                    columns={columns}
                    loading={!tableData}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 10 }
                      }
                    }}
                    disableColumnMenu
                    getRowId={getRowId}
                    disableRowSelectionOnClick
                    columnBuffer={0}
                    checkboxSelection={isCheckBox}
                    pageSizeOptions={[5, 10, 50]}
                    onRowSelectionModelChange={handleCheckBoxChange}
                    // rowSelectionModel={selected?.values || selectedRows}
                    rowSelectionModel={selectedRows}
                    localeText={{
                      noRowsLabel: 'No pricing options are available'
                    }}
                  />
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
