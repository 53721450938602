import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormHelperText,
  Grid,
  Slide,
  Stack,
  TextField,
  CardHeader
} from '@mui/material';
import { Toggle } from 'src/components/Global';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { ApiService } from '../../../../services/ApiService';
import { enterNumberOnly } from '../../../../utils';
import Loader from '../../../../components/Loader';
import { ActiveStatus, InactiveStatus } from 'src/assets/SVG/SvgIcons';
import InputAdornment from '@mui/material/InputAdornment';
import { PromotionTargetTable } from './PromotionTargetTable';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import { CustomDatePickerForAllBrowser } from 'src/components/CustomDatePickerForAllBrowser';
import moment from 'moment';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
// showAddPromotionDialog, setShowAddPromotionDialog, setShowAddPromotion
const AddPromotion = ({
  setShowScreen,
  getPromotionDetails,
  setErrorMessage,
  setShowMessage
}) => {
  let userId = sessionStorage.getItem('UserId');
  const steps = ['Promotion Setup', 'Promotion Target Selection'];
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [promotionName, setPromotionName] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [discount, setDiscount] = useState('');
  const [neverExpires, setNeverExpires] = useState(true);
  const [viaPromoCode, setViaPromoCode] = useState(false);
  const [allItems, setAllItems] = useState(false);
  const [allowDiscount, setAllowDiscount] = useState(false);
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [open, setOpen] = useState(false);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [promoAvailiable, setPromoAvailiable] = useState('');
  const [fieldsFilled, setFieldFilled] = useState(false);
  const [limit, setLimit] = useState('');
  const [promotionError, setPromotionError] = useState('');
  const [promoCodeError, setPromoCodeError] = useState('');
  const [discountError, setDiscountError] = useState('');
  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');
  const [limitError, setLimitError] = useState('');
  // const [selectedItemsError, setSelectedItemsError] = useState('');
  const [selectedItems, setSelectedItems] = useState({
    items: [],
    values: []
  });
  // const [tempSelectedItems, setTempSelectedItems] = useState({
  //   tempValues: [],
  //   tempItems: []
  // });
  const [useOnline, setUseOnline] = useState(false);

  // const locationId = getLocalData('locationId');

  useEffect(() => {
    setActiveStep(0);
    setIsCreated(false);
  }, []);

  // Check if unsaved changes are there and loading/closing browser tab
  useEffect(() => {
    if (isFieldUpdated) window.addEventListener('beforeunload', alertUnsaved);
  }, [isFieldUpdated]);

  // Unsaved changes alert
  const alertUnsaved = (e) => {
    e.preventDefault();
    e.returnValue = 'You have unsaved changes, what do you want to do?';
  };

  // Now check if never expires is true then set end date to empty
  useEffect(() => {
    if (neverExpires) {
      setEndDate('');
      setEndDateError('');
      let enddate = document.querySelector('.promo-enddate input');
      if (enddate) enddate.value = '';
      console.log(neverExpires, endDate, 'only edit');
    }
  }, [neverExpires]);

  const proceedNext = async () => {
    let valid = true; // A flag to track if all validations pass
    // Validate promotion name
    const formattedStartDate = formatDateForComparison(startDate);
    const formattedEndDate = formatDateForComparison(endDate);
    if (!promotionName) {
      setPromotionError('Promotion name is required');
      valid = false; // Set the flag to false if the promotionName is invalid
    }
    // else if (!/^[A-Za-z ]+$/.test(promotionName)) {
    //   setPromotionError('Promotion name should only contain letters');
    //   valid = false; // Set the flag to false if the promotionName is invalid
    // }

    if (moment(formattedStartDate).isAfter(moment(formattedEndDate))) {
      setEndDateError('End date should be greater than the start date');
      return;
    }

    if (!promoCode) {
      setPromoCodeError('Promo code is required');
      setFieldFilled(false);
    }
    if (!discount) {
      setDiscountError('Discount is required');
      setFieldFilled(false);
    }
    if (!startDate) {
      setStartDateError('Start date is required');
      setFieldFilled(false);
    }
    if (!neverExpires && !endDate) {
      setEndDateError('End date is required');
      setFieldFilled(false);
    } else {
      setEndDateError('');
    }

    // Check if promo code is available

    if (!promoCode) {
      setPromoCodeError('Promo code is required');

      valid = false; // Set the flag to false if promoCode is missing
    } else {
      // Check promo code availability

      let promocode = promoCode && promoCode.toUpperCase();

      try {
        let data = await ApiService.get(
          `${process.env.REACT_APP_API_END_POINT}promotions/promoCodeValidation?promoCode=${promocode}`
        );

        if (data.statusCode !== 200) {
          // Handle case where promo code is not available

          setPromoCodeError('Promo code is not available');

          valid = false; // Set the flag to false if promo code is not available
        }
      } catch (error) {
        // Handle error while checking promo code availability

        console.error(error);

        setPromoCodeError('Promo code is not available');

        valid = false; // Set the flag to false if an error occurs
      }
    }

    // Check if all required fields are filled

    if (!promoCode || !discount || !startDate || (!neverExpires && !endDate)) {
      setFieldFilled(false);

      valid = false; // Set the flag to false if any required fields are missing
    } else {
      setFieldFilled(true);
    }

    if (valid) {
      setActiveStep(1); // Proceed to the next step only if all validations pass
    }
  };

  const handleClose = () => {
    if (isFieldUpdated) {
      setOpen(true);
    } else {
      setShowScreen(0);
    }
  };

  const toggleButton = (e) => {
    if (e === 'Promotion-expiration') {
      setNeverExpires(!neverExpires);
      setFieldFilled(true);
    }

    if (e === 'promo-code') {
      setViaPromoCode(!viaPromoCode);
      setFieldFilled(true);
    }

    if (e === 'apply-all') {
      setAllItems(!allItems);
    }
    if (e === 'allow-discount') {
      setAllowDiscount(!allowDiscount);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === 'Promotion name') {
      if (!value) {
        setPromotionError('Promotion name is required');
        setPromotionName('');
        setIsFieldUpdated(true);
        setFieldFilled(false);
        // } else if (!/^[A-Za-z ]+$/.test(value)) {
        //   setPromotionError('Promotion name should only contain letters');
        //   setPromotionName(value);
        //   setIsFieldUpdated(true);
        //   setFieldFilled(false);
      } else {
        setPromotionError('');
        setPromotionName(value);
        setIsFieldUpdated(true);
        setFieldFilled(true);
      }
    }
    if (name === 'Promo code') {
      let pattern = /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/;
      if (!value) {
        // setPromoAvailiable(false);
        setPromoAvailiable('');
        setPromoCodeError('Promo code is required');
        setPromoCode(value);
        setIsFieldUpdated(true);
        setFieldFilled(false);
      } else if (!pattern.test(value)) {
        // setPromoAvailiable(false);
        setPromoAvailiable('');
        setPromoCodeError('Invalid promo code');
        setPromoCode(value);
        setIsFieldUpdated(true);
      } else {
        setPromoCodeError('');
        let promoCodeCheck = value;
        setPromoCode(promoCodeCheck);
        checkPromoCode(promoCodeCheck);
        setIsFieldUpdated(true);
        setFieldFilled(true);
        // setPromoAvailiable(true);
      }
    }

    if (name === 'Discount') {
      if (!value) {
        setDiscountError('Discount is required');
        setDiscount(value);
        setIsFieldUpdated(true);
        setFieldFilled(false);
      } else {
        let discountNo = enterNumberOnly(value);
        if (discountNo && discountNo.length <= 2) {
          setDiscountError('');
          setDiscount(discountNo);
          setIsFieldUpdated(true);
          setFieldFilled(true);
        }
      }
    }
    if (name === 'Limit') {
      if (value) {
        let limit = enterNumberOnly(value);
        setLimitError('');
        setLimit(limit);
      } else {
        setLimitError('');
        setLimit('');
      }
    }
  };

  // const dateSetStart = (val) => {
  //   // setStartDate(val);
  //   if (val === null) {
  //     setStartDateError('Start date is required');
  //     setFieldFilled(false);
  //   } else {
  //     setStartDateError('');
  //     setStartDate(val);
  //     setFieldFilled(true);
  //   }
  // };

  // const dateSetEnd = (val) => {
  //   if (val === null) {
  //     setEndDateError('End date is required');
  //     setFieldFilled(false);
  //   } else {
  //     setEndDateError('');
  //     setEndDate(val);
  //     setFieldFilled(true);
  //   }
  //   // setEndDate(val)
  // };

  /* Function for end date formatting */
  const formatDateForComparison = (dateString) => {
    // Parse the input date string and format it to 'YYYY-MM-DDTHH:mm:ss'
    return moment(dateString, 'MM-DD-YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss');
  };

  const dateSetStart = (startDate) => {
    if (startDate === '01-01-1970') {
      return;
    }

    // Convert startDate and endDate to a compatible format
    const formattedStartDate = formatDateForComparison(startDate);
    const formattedEndDate = formatDateForComparison(endDate);

    setStartDate(startDate);

    // Check the start date is less than the end date
    if (moment(formattedEndDate).isBefore(moment(formattedStartDate))) {
      setStartDateError('Start date should be less than the end date');
      setFieldFilled(true);
    } else {
      setEndDateError('');
      setStartDateError('');
      setFieldFilled(true);
    }
  };

  const dateSetEnd = (endDate) => {
    if (endDate === '01-01-1970') {
      return;
    }

    // Convert startDate and endDate to a compatible format
    const formattedStartDate = formatDateForComparison(startDate);
    const formattedEndDate = formatDateForComparison(endDate);

    setEndDate(endDate);

    // Check the end date is greater than start date
    if (moment(formattedStartDate).isAfter(moment(formattedEndDate))) {
      setEndDateError('End date should be greater than the start date');
      setFieldFilled(true);
    } else {
      setStartDateError('');
      setEndDateError('');
      setFieldFilled(true);
    }
  };

  const checkPromoCode = async (val) => {
    try {
      let promocode = val && val.toUpperCase();
      let data = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}promotions/promoCodeValidation?promoCode=${promocode}`
      );
      if (data.statusCode === 200) {
        setPromoAvailiable(true);
      } else if (!promoCodeError) {
        setPromoAvailiable(false);
      }
      console.log(data);
    } catch (error) {
      console.log(error);
      if (!promoCodeError) setPromoAvailiable(false);
    }
  };

  const addPromotion = (val) => {
    if (!allItems && selectedItems.items.length === 0) {
      // setSelectedItemsError('Select one item at least to proceed');
      setFieldFilled(false);
    } else {
      // setSelectedItemsError('');
      setFieldFilled(true);
    }

    // if condition true the proceed
    if (fieldsFilled && (selectedItems.items.length > 0 || allItems)) {
      promotionItem(val);
    }
  };

  const promotionItem = async () => {
    setIsLoading(true);
    try {
      let data = {
        // locationId: locationId,
        name: promotionName,
        promoCode: promoCode.toUpperCase(),
        discount: discount,
        limit: limit || 0,
        useOnline: useOnline,
        neverExpire: neverExpires,
        allowDiscountOnGiftCardPurchase: allowDiscount,
        applyToAll: allItems,
        startDate: startDate,
        createdBy: `${userId}`,
        items: selectedItems.items || [],
        status: 1
      };
      if (!neverExpires) data = { ...data, endDate: endDate };
      const apiResponse = await ApiService.post(
        // `${process.env.REACT_APP_API_END_POINT}promotions`,
        `${process.env.REACT_APP_API_END_POINT}promotions/createPromoPOP`,
        data
      );
      if (apiResponse.statusCode === 200) {
        setOpen(true);
        setIsCreated(true);
        setIsLoading(false);
        getPromotionDetails();
      } else {
        setIsLoading(false);
        let msg = apiResponse?.message || 'Something went wrong.';
        setShowMessage(true);
        setErrorMessage(msg);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      let msg = error?.message || 'Something went wrong.';
      setShowMessage(true);
      setErrorMessage(msg);
    }
  };

  const handleCloseConfirm = (val) => {
    if (isFieldUpdated) {
      setOpen(true);
      if (val === 'Discard') {
        // setShowAddPromotion(false);
        // setShowAddPromotionDialog(false);
        setShowScreen(0);
      }
      if (val === 'Continue') {
        setOpen(false);
      }
    } else {
      setShowScreen(0);
    }
  };

  useEffect(() => {
    if (selectedItems.items.length > 0) {
      setFieldFilled(true);
    } else {
      setFieldFilled(false);
    }
  }, [selectedItems.items]);

  const handleToggleInfo = (type, value) => {
    setUseOnline(value);
  };

  // Method to go back on first step
  const stepBack = () => {
    setActiveStep(activeStep - 1);
    // setActiveStep(0);
  };

  return (
    <>
      <Loader IsLoading={isLoading} />

      <Grid sx={{ padding: '0 2rem', mt: 1 }}>
        <Box className="Main-container location-details-wrapper promotion-management">
          <Grid
            className="location-header-container"
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Box
                  onClick={() => handleCloseConfirm()}
                  className="back-arrow-close"
                >
                  <ArrowLeft />
                </Box>
                <Box>
                  <Typography className="header-blue-32">
                    <span className="location-name">Add Promotion</span>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Container maxWidtth="100%" className="p-0">
            <Box
              sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
            >
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                sx={{ width: '100%', background: 'none' }}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel className="step-labeling">{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Container>
          {activeStep === 0 && (
            <>
              {/* promotion Name field block */}
              <Grid container>
                <Grid sm={6} md={6} xs={12}>
                  <TextField
                    fullWidth
                    name="Promotion name"
                    label="PROMOTION NAME"
                    placeholder="PROMOTION NAME"
                    value={promotionName}
                    onChange={handleInputChange}
                    error={Boolean(promotionError)}
                    helperText={promotionError}
                    className="input-field-styling required-field"
                    InputLabelProps={{
                      className: 'label-style-create-franchisee required-label'
                    }}
                    InputProps={{}}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} className="mt-20 spaceBetween">
                {/* Promo code block */}
                <Grid item md={5} sm={5} xs={4} className="pt-0">
                  <Card className="card-block mt-2">
                    <Grid
                      container
                      spacing={2}
                      component="form"
                      className="table-container-styl font-16 text-black p-15"
                    >
                      <Grid item md={7} sm={7} xs={7}>
                        <TextField
                          fullWidth
                          name="Promo code"
                          value={promoCode}
                          label="PROMO CODE"
                          onChange={handleInputChange}
                          error={Boolean(promoCodeError)}
                          helperText={promoCodeError}
                          className="input-field-styling required-field"
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee required-label'
                          }}
                          InputProps={{
                            className: 'promocode-text'
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        md={5}
                        sm={5}
                        xs={5}
                        className="vertical-middle-text align-self-center"
                      >
                        {/* <Typography className="text-blue weight-600 font-14 vertical-middle-text d-flex justify-content-center">{promoAvailiable ? 'Available' : 'Not Available'} {promoAvailiable ? <span className="ml-10 mt-5"><ActiveStatus /></span> : <span className="ml-10 mt-5"><InactiveStatus /></span>}</Typography> */}
                        {promoAvailiable === true ? (
                          <>
                            Available{' '}
                            <span className="ml-10 mt-5">
                              <ActiveStatus />
                            </span>
                          </>
                        ) : promoAvailiable === false ? (
                          <>
                            Not Available{' '}
                            <span className="ml-10 mt-5">
                              <InactiveStatus />
                            </span>
                          </>
                        ) : null}
                      </Grid>
                      <Grid item className="">
                        <Typography className="font-13 font-italic text-black invisibl">
                          <b>Note:</b> Promo code must contain both letters and
                          numbers (e.g., 'SAVE20' or 'DISCOUNT50')
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                {/* Discount field block */}
                <Grid item md={3} sm={3} xs={4} className="pt-0">
                  <Card className="card-block mt-2">
                    <Grid
                      container
                      spacing={2}
                      component="form"
                      className="table-container-style p-15"
                    >
                      <Grid item md={12} sm={12} xs={12}>
                        <TextField
                          fullWidth
                          name="Discount"
                          label="DISCOUNT"
                          value={discount}
                          onChange={handleInputChange}
                          error={Boolean(discountError)}
                          helperText={discountError}
                          className="input-field-styling required-field"
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee required-label'
                          }}
                          InputProps={{
                            maxLength: 2,
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                className="postfix-icon"
                              >
                                %
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                      <Grid item className="">
                        <Typography className="font-13 font-italic text-black invisible">
                          <b>Note:</b> Leave it blank to set
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                {/* Limit field block */}
                <Grid item md={4} sm={4} xs={4} className="pt-0">
                  <Card className="card-block mt-2">
                    <Grid
                      container
                      spacing={2}
                      component="form"
                      className="table-container-styl p-15"
                    >
                      <Grid item md={7} sm={7} xs={7}>
                        <TextField
                          fullWidth
                          name="Limit"
                          label="LIMIT"
                          value={limit}
                          onChange={handleInputChange}
                          // error={Boolean(limitError)}
                          helperText={limitError}
                          className="input-field-styling"
                          InputLabelProps={{
                            className: 'label-style-create-franchisee'
                          }}
                          InputProps={{}}
                        />
                      </Grid>
                      <Grid
                        item
                        md={4}
                        sm={4}
                        xs={4}
                        className="vertical-middle-text align-self-center"
                      >
                        <Typography className="text-availiable font-14 vertical-middle-text d-flex justify-content-center">
                          Per Dog
                        </Typography>
                      </Grid>
                      <Grid item className="">
                        <Typography className="font-13 font-italic text-black">
                          <b>Note:</b> Leave it blank to set the promo code as
                          unlimited
                        </Typography>
                        {/* <Typography className="font-13 font-italic">as unlimited</Typography> */}
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
              {/* Promotion Schedule - Never Expired Block */}
              <Card className="card-pb-4 card-block mt-20">
                <Grid
                  container
                  component="form"
                  className="table-container-style p-15"
                >
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography className="field-heading-tex font-20 header-blue-20">
                      Promotion Schedule
                    </Typography>
                  </Grid>
                  <Box className="w-100 gift-card-buttons d-flex">
                    <Grid
                      item
                      // md={5}
                      // sm={5}
                      // xs={5}
                      className="vertical-center d-flex align-items-center"
                    >
                      <Typography className="text-field check-box-text mr-25">
                        Set to never Expire
                      </Typography>
                      <Toggle
                        name="Promotion-expiration"
                        value={neverExpires}
                        onChange={(e) => toggleButton(e)}
                      />
                    </Grid>
                    {/* <Grid item md={1} sm={1} xs={1} className="toggle-btn-block">
                      <Toggle
                        name="Promotion-expiration"
                        value={neverExpires}
                        onChange={(e) => toggleButton(e)}
                      />
                    </Grid> */}
                  </Box>
                  <Grid container spacing={2} md={12} xs={12} sm={12}>
                    <Grid item md={6} xs={6} sm={6}>
                      {/* <CustomDatePicker
                        onChange={dateSetStart}
                        fieldValue={startDate}
                        label={'Start Date'}
                        minDate={new Date()}
                        required={Boolean(true)}
                        className="show"
                      />
                      {startDateError && (
                        <FormHelperText error>{startDateError}</FormHelperText>
                      )} */}
                      <CustomDatePickerForAllBrowser
                        label={'Start Date'}
                        onChange={dateSetStart}
                        required={Boolean(true)}
                        fieldValue={startDate}
                      //  minDate={new Date()}
                      />
                      {startDateError && (
                        <FormHelperText error>{startDateError}</FormHelperText>
                      )}
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                      {/* <CustomDatePicker
                        onChange={dateSetEnd}
                        fieldValue={endDate}
                        label={'End Date'}
                        // minDate shoud be greater than start date
                        minDate={new Date(startDate)}
                        disabled={neverExpires || !startDate}
                        required={neverExpires ? Boolean(false) : Boolean(true)}
                        className="promo-enddate show"
                      />
                      {!neverExpires && endDateError && (
                        <FormHelperText error>{endDateError}</FormHelperText>
                      )} */}
                      <CustomDatePickerForAllBrowser
                        label={'End Date'}
                        onChange={dateSetEnd}
                      //  minDate={new Date(startDate)}
                        disabled={neverExpires || !startDate}
                        required={neverExpires ? Boolean(false) : Boolean(true)}
                        fieldValue={endDate}
                      />
                      {!neverExpires && endDateError && (
                        <FormHelperText error>{endDateError}</FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Card>

              {/* Promotion Usability - Use Online Block */}
              <Card className="card-pb-4 card-block mt-20">
                <Grid
                  container
                  component="form"
                  className="table-container-style p-15"
                >
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography className="field-heading-tex font-20 header-blue-20">
                      Promotion Usability
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    // md={11}
                    // sm={11}
                    // xs={11}
                    className=" vertical-center mt-10"
                  >
                    <Typography className="text-fiel check-box-text">
                      Use online
                    </Typography>
                    <Toggle
                      fullWidth
                      spaceBetween
                      name="useOnline"
                      labelPlacement="start"
                      value={useOnline}
                      onChange={handleToggleInfo}
                      className="use-online"
                    />
                  </Grid>
                  {/* <Grid
                    item
                    md={1}
                    sm={1}
                    xs={1}
                    className="mt-10 toggle-btn-block"
                  >
                    <Toggle
                      fullWidth
                      spaceBetween
                      name="useOnline"
                      labelPlacement="start"
                      value={useOnline}
                      onChange={handleToggleInfo}
                      className="use-online"
                    />
                  </Grid> */}
                </Grid>
              </Card>

              {/* Next Button */}
              <Grid container className="mt-20" justifyContent={'right'} pb={3}>
                <Grid item md={4} sm={4} xs={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    type="button"
                    className="top-button-style"
                    onClick={() => proceedNext()}
                  >
                    Proceed to Add Items
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
          {activeStep === 1 && (
            <>
              <Grid
                container
                component="form"
                className="table-container-style p-15"
              >
                <Grid item md={6} sm={6} xs={6}>
                  <Stack direction="row" alignItems="center" gap={6}>
                    <Typography className="check-box-tex text-blue weight-700">
                      Apply to All
                    </Typography>
                    <Toggle
                      value={allItems}
                      name="apply-all"
                      onChange={(e) => toggleButton(e)}
                    />
                  </Stack>
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="right"
                    gap={6}
                  >
                    <Typography className="check-box-tex toggle-btn-block right text-blue weight-700">
                      Allow Discount on Gift Card Purchase
                    </Typography>
                    <Toggle
                      value={allowDiscount}
                      name="allow-discount"
                      onChange={(e) => toggleButton(e)}
                    />
                  </Stack>
                </Grid>
              </Grid>

              <Card
                className="card-pb-40 add-promotion-card"
                sx={{ zIndex: 1, position: 'relative' }}
              >
                <CardHeader
                  title={'Add Items'}
                  className="table-header-name font-20 text-primary"
                />
                {allItems && (
                  <Card
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      height: '100%',
                      width: '100%',
                      // border: '0.4px solid #C5C5C5',
                      // backgroundColor: 'rgba(0, 0, 0, 0.1)',
                      backgroundColor: '#f8fbff',
                      filter: 'blur(100vh)',
                      zIndex: 2
                    }}
                  />
                )}
                {/* {selectedItemsError && (
                                <FormHelperText error>
                                    {selectedItemsError}
                                </FormHelperText>
                            )} */}
                <PromotionTargetTable
                  setSelected={setSelectedItems}
                  selected={selectedItems}
                  // tempSelectedItems={tempSelectedItems}
                  // setTempSelectedItems={setTempSelectedItems}
                />
              </Card>

              <Grid
                container
                component="form"
                justifyContent={'space-between'}
                className="table-container-style mt-20"
                pb={3}
              >
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    variant="outline"
                    type="button"
                    className="top-button-style text-primary"
                    onClick={() => stepBack()}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    type="button"
                    className="top-button-style"
                    onClick={() => addPromotion('Save & Publish')}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Grid>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isCreated ? 'Congratulations!' : 'Discard Changes?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isCreated ? (
                <Typography className="title-text-sub">
                  You have unsaved changes. 
                  <Typography className="title-text-sub">What do you want to do?</Typography>
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  Promotion has been created successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleCloseConfirm('Discard')}
          >
            {isCreated ? 'Close' : 'Discard Changes'}
          </Button>
          {!isCreated && <Divider className="vertical-divider" />}
          {!isCreated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleCloseConfirm('Continue')}
            >
              Go Back to Editing
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
export default AddPromotion;
