import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Slide,
  Stack,
  Typography,
  FormHelperText
} from '@mui/material';
import { Toggle } from 'src/components/Global';
import { enterNumberOnly } from '../../../utils/index';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { CustomDatePicker } from 'src/components/CustomDatePicker';
import {
  getLocalData,
  CalenderdateFormat
} from '../../../utils';
import { ApiService } from '../../../services/ApiService';
// import { toast } from 'react-toastify';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import Loader from '../../../components/Loader';
import moment from 'moment';
import { CustomDatePickerForAllBrowser } from 'src/components/CustomDatePickerForAllBrowser';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const AddPricing = ({
  openAddPricingModal,
  setOpenAddPricingModal,
  setOpenAddPricing,
  selectedItem
}) => {
  const locationId = getLocalData('locationId');
  let userId = sessionStorage.getItem('UserId');
  const currentDate = new Date(); // Today
  const throughDate = selectedItem?.validThrough ? moment(selectedItem?.validThrough, "MM-DD-YYYY") : null;
  const formattedThroughDate = throughDate ? throughDate?.format("MM/DD/YY") : null;
  const [open, setOpen] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [cardName, setCardName] = useState(
    formattedThroughDate || ''
  );
  const [cardValue, setCardValue] = useState(
    selectedItem?.giftCardPriceValue || ''
  );
  const [priceValue, setPriceValue] = useState(selectedItem?.price || '');
  const [dateValue, setDateValue] = useState(
    selectedItem?.validThrough || null
  );
  const [includeSale, setIncludeSale] = useState(selectedItem ? Boolean(selectedItem.isIncludeInSales) : true);
  // const [includeSale, setIncludeSale] = useState(
  //   Boolean(selectedItem?.isIncludeInSales) || false
  // );
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [cardNameError, setCardNameError] = useState('');
  const [cardValueError, setCardValueError] = useState('');
  const [cardPriceError, setCardPriceError] = useState('');
  const [cardValidThroughError, setCardValidThroughError] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  // useEffect(() => {

  // })

  const handleClosemodal= () =>{
    setShowConfirmationDialog(true);
   // setOpenAddPricingModal(false);
  }


  const handleCancelConfirmation = (type) => {
    // Close the confirmation dialog
    if (type === 'Discard'){
      
      setShowConfirmationDialog(false);
      setOpenAddPricingModal(false);
      setOpen(false);
      setOpenAddPricing(false);
    }else {
      setOpen(false);
    }
   
  };

  const handleConfirmClose = () => {
    // Close both the main dialog and the confirmation dialog
    setShowConfirmationDialog(false);
    setOpen(false); // Close the main dialog
    // You can also reset or perform any other necessary actions here
  };

  const handleClose = () => {
    if (isFieldUpdated) {
      setOpen(true);
    } else if (selectedItem !== null) {
      setOpen(true);
    } else {
      setOpenAddPricingModal(false);
      setOpenAddPricing(false);
    }
  };

  const handleClosedialog = (val) => {
     if (val === 'message-close') {
      setOpenMessageDialog(false);
       setOpenAddPricingModal(false);
      setOpenAddPricing(false);
    }
  };
  // console.log(cardValue, "card valuer eafdsfasdf")

  const toggleButton = (name, value) => {
    setIncludeSale(value);
    setPriceValue(cardValue);
    setDateValue(null);
    setCardPriceError('');
    setCardValidThroughError('');
    
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'name') {
      setCardName(value);
      setIsFieldUpdated(true);
      setCardNameError('');
    }
    if (name === 'value') {
      let formattedValue = enterNumberOnly(value);
      setCardValue(formattedValue);
      setCardValueError('');
      setIsFieldUpdated(true);
    }

    if (name === 'price') {
      let formattedValue = enterNumberOnly(value);
      setPriceValue(formattedValue);
      setCardPriceError('');
      setIsFieldUpdated(true);
    }
  };

  const addNewGift = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(selectedItem, 'selectedItem');
    if (selectedItem) {
      console.log(selectedItem, 'selectedItemselectedItem');
      // UpdateGiftCardPricing('Update');
      setOpen(true);
    } else {
      if (!cardName) {
        setCardNameError('Card name is required');
      }
      if (!cardValue) {
        setCardValueError('Card value is required');
      }

      if (includeSale) {
        console.log(includeSale, 'includeSale');
        if (!dateValue) {
          setCardValidThroughError('Date is required');
        }
        if (!priceValue) {
          setCardPriceError('Card price is required');
        }
      }

      // if name and value is not empty then add new gift card
      if (cardName && cardValue) {
        // check if include in sale is true then check for price and date
        if (includeSale) {
          if (dateValue && priceValue) {
          //  setOpen(true);
          UpdateGiftCardPricing();
          }
        } else {
        //  setOpen(true);
        UpdateGiftCardPricing();
        }
      }
      console.log('created');
      // UpdateGiftCardPricing();
    }
  };

  const handleCloseConfirm = (val) => {
    if (val === 'Discard') {
      setOpen(false);
    //  setOpenAddPricingModal(false);
    //  setOpenAddPricing(false);
    }
    if (val === 'Continue') {
      if (selectedItem) {
        console.log('Updated');
        UpdateGiftCardPricing('Update');
      } 
      setOpen(false);
    }
  };

  const checkValidations = () => {
    if (cardName === '') {
      setCardNameError('Card Name is Required');
      return false;
    }
    if (cardValue === '') {
      setCardValueError('Card Value is Required');
      return false;
    }

    if (includeSale) {
      console.log(includeSale, 'checkValidations');
      if (priceValue === '') {
        setCardPriceError('Card Price is Required');
        return false;
      }
    }

    return true;
  };

  const UpdateGiftCardPricing = async (type) => {
    setIsLoading(true);
    let encodedLocationId = encodeURIComponent(locationId);
    let priceId = encodeURIComponent(selectedItem?.sortKey);
    let payload = {
      giftCardPriceName: cardName,
      giftCardPriceValue: cardValue,
      isIncludeInSales: includeSale,
      // validThrough: CalenderdateFormat(dateValue) || '',
      // price: priceValue || 0,
      // isActive: 1,
      // isDeleted: false,
      updatedBy: userId
    };
    if (includeSale === true) {
      payload.price = priceValue || 0;
      payload.validThrough = CalenderdateFormat(dateValue) || ''
    }
    try {
      if (checkValidations()) {
        let response;
        if (type === 'Update') {
          response = await ApiService.put(
            `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}gift-cards/updateGiftCardPrice?locationId=${encodedLocationId}&id=${priceId}`,
            payload
          );
        } else {
          payload.partitionKey = locationId;
          payload.createdBy = userId;
          delete payload['updatedBy'];
          response = await ApiService.post(
            `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}gift-cards/addNewGiftCardPrice`,
            payload
          );
        }

        if (response.statusCode === 200) {
          setIsLoading(false);
          setIsCreated(true);
          setOpenMessageDialog(true);
        }
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e, 'error');
      setShowErrorMessage(true);
      setErrorMessage(e?.message || 'Something went wrong')
      // toast.error(e?.message || 'Something went wrong');
    }
  };

  // 
  const setDate = (date) => {
    const parsedDate = moment(date, "MM-DD-YYYY"); 
    const formattedDate = parsedDate.format("MM/DD/YY");
    if (formattedDate === '01/01/1970') {
      return;
    }
    setDateValue(formattedDate);
    setCardValidThroughError('');
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      {showErrorMessage &&
        <ErrorMessage message={errorMessage} setShowMessage={setShowErrorMessage} />
      }
      <Dialog
        open={openAddPricingModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClosemodal('Cancel')}
        aria-describedby="details-dialog"
        className="dialog-box dialog-box-right client bg-white"
        maxWidth="sm"
      >
        <DialogTitle className="alert-title align-right dialog-header">
          <Box>
            <Button
              className="details-remove-icon p-0 no-hover"
              onClick={handleClosemodal}
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="13" cy="13" r="13" fill="white" />
                <path
                  d="M17.7785 8.22908C17.7083 8.15877 17.625 8.10298 17.5332 8.06492C17.4415 8.02686 17.3431 8.00726 17.2438 8.00726C17.1444 8.00726 17.0461 8.02686 16.9543 8.06492C16.8625 8.10298 16.7792 8.15877 16.709 8.22908L13 11.9305L9.29097 8.2215C9.22075 8.15127 9.13738 8.09557 9.04563 8.05756C8.95388 8.01956 8.85554 8 8.75623 8C8.65692 8 8.55859 8.01956 8.46683 8.05756C8.37508 8.09557 8.29172 8.15127 8.2215 8.2215C8.15127 8.29172 8.09557 8.37508 8.05756 8.46683C8.01956 8.55859 8 8.65692 8 8.75623C8 8.85554 8.01956 8.95388 8.05756 9.04563C8.09557 9.13738 8.15127 9.22075 8.2215 9.29097L11.9305 13L8.2215 16.709C8.15127 16.7793 8.09557 16.8626 8.05756 16.9544C8.01956 17.0461 8 17.1445 8 17.2438C8 17.3431 8.01956 17.4414 8.05756 17.5332C8.09557 17.6249 8.15127 17.7083 8.2215 17.7785C8.29172 17.8487 8.37508 17.9044 8.46683 17.9424C8.55859 17.9804 8.65692 18 8.75623 18C8.85554 18 8.95388 17.9804 9.04563 17.9424C9.13738 17.9044 9.22075 17.8487 9.29097 17.7785L13 14.0695L16.709 17.7785C16.7793 17.8487 16.8626 17.9044 16.9544 17.9424C17.0461 17.9804 17.1445 18 17.2438 18C17.3431 18 17.4414 17.9804 17.5332 17.9424C17.6249 17.9044 17.7083 17.8487 17.7785 17.7785C17.8487 17.7083 17.9044 17.6249 17.9424 17.5332C17.9804 17.4414 18 17.3431 18 17.2438C18 17.1445 17.9804 17.0461 17.9424 16.9544C17.9044 16.8626 17.8487 16.7793 17.7785 16.709L14.0695 13L17.7785 9.29097C18.0667 9.00274 18.0667 8.51731 17.7785 8.22908Z"
                  fill="#003087"
                />
              </svg>
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Container maxWidth="100%" sx={{ paddingTop: '10px' }}>
              <Grid
                container
                component="form"
                onSubmit={(e) => addNewGift(e)}
                spacing={3}
                className="table-container-style"
              >
                <Grid item md={12} sm={12} xs={12}>
                  <Typography className="page-title" variant="h3">
                    {selectedItem ? 'Edit' : 'New'} Gift Card Pricing
                  </Typography>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    name="name"
                    fullWidth
                    id="outlined-required"
                    label="PRICING NAME"
                    className="text-field-styling required-field"
                    onChange={handleInputChange}
                    error={cardNameError}
                    helperText={cardNameError}
                    value={cardName}
                    InputLabelProps={{
                      className: 'label-style-text-field required-label'
                    }}
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    name="value"
                    fullWidth
                    id="outlined-required"
                    label="CARD VALUE"
                    className="text-field-styling required-field"
                    onChange={handleInputChange}
                    error={cardValueError}
                    helperText={cardValueError}
                    value={cardValue}
                    InputLabelProps={{
                      className: 'label-style-text-field required-label'
                    }}
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    name="price"
                    id="outlined-required"
                    label="PRICE"
                    className={`text-field-styling ${includeSale ? 'required-field' : ''}`}
                    onChange={handleInputChange}
                    error={cardPriceError}
                    helperText={cardPriceError}
                    value={priceValue}
                    InputLabelProps={{
                      className: `label-style-text-field ${includeSale ? 'required-label' : ''}`
                    }}
                    disabled={!includeSale}
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography className="add-pricing-box-text">
                      Include in Sale
                    </Typography>
                    <Toggle
                      className="toggle-button"
                      value={includeSale}
                      onChange={toggleButton}
                    />
                  </Stack>
                </Grid>

                {includeSale ? (
                  <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  className="form-datepicker-container"
                >
                  <CustomDatePickerForAllBrowser
                    label="Valid Through"
                    onChange={setDate}
                    minDate={currentDate}
                    fieldValue={dateValue}
                    required={includeSale}
                  />
                  {cardValidThroughError && (
                    <FormHelperText>{cardValidThroughError}</FormHelperText>
                  )}
                </Grid>
                  // <Grid item md={12} sm={12} xs={12}>
                  //   <Typography className="font-weight-600" variant="h4">
                  //     Valid Through
                  //   </Typography>
                  //   <LocalizationProvider dateAdapter={AdapterDayjs}>
                  //     <DatePicker
                  //       label="DATE"
                  //       name="Date"
                  //       value={dateValue}
                  //       // onChange={(newValue) => setDateValue(newValue)}
                  //       onChange={(newValue) => setDate(newValue)}
                  //       renderInput={(params) => (
                  //         <TextField
                  //           {...params}
                  //           fullWidth
                  //           id="outlined-required"
                  //           className={`text-field-styling ${includeSale ? 'required-field' : ''}`}
                  //           helperText={cardValidThroughError}
                  //           sx={{ marginTop: '20px' }}
                  //           InputLabelProps={{
                  //             className: `label-style-text-field ${includeSale ? 'required-label' : ''}`
                  //           }}
                  //         />
                  //       )}
                  //     />
                  //   </LocalizationProvider>
                  // </Grid>
                ) : null}
                <Grid item md={12} sm={12} xs={12}>
                  <Button className="submit-button" fullWidth type="submit">
                    {selectedItem ? 'Update' : 'Save'}
                  </Button>
                </Grid>
              </Grid>
              {/* <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                <Typography>Save</Typography>
                            </Box> */}
            </Container>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        open={showConfirmationDialog}
        onClose={handleCancelConfirmation}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center" >Discard Changes?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" className="alert-decription align-center">
          <Typography className="title-text-sub">
          You have unsaved changes. 
          </Typography>
          <Typography className="title-text-sub">What do you want to do?</Typography>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button onClick={() => handleCancelConfirmation('Discard')}className="font-17 text-black no-hover">
            Discard Changes
          </Button>
          <Divider className="vertical-divider" />
          <Button onClick={handleConfirmClose} className="font-17 no-hover">
            Go Back to Editing
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isCreated ? 'Congratulations!' : 'Save Changes?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              
                <Typography className="title-text-sub">
                 Please confirm that you want to save your changes?
                </Typography>
             
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleCloseConfirm('Discard')}
          >
            {isCreated ? 'Close' : 'Go Back to Editing'}
          </Button>
          {!isCreated && <Divider className="vertical-divider" />}
          {!isCreated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleCloseConfirm('Continue')}
            >
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
          open={openMessageDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => handleClosedialog('message-close')}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle className="alert-title align-center">
          {'Congratulations!'}
         </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              className="alert-decription align-center"
            >
              <Box display="flex" justifyContent="center" direction="column">
                <Typography className="title-text-sub">
                Gift card pricing {selectedItem ? 'details has been updated' : 'has been created'}{' '}
                  successfully!
                </Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
          <Divider />
          <DialogActions className="alert-button-control">
            <Button
              className="font-17 no-hover"
              onClick={() => handleClosedialog('message-close')}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
    </>
  );
};

export default AddPricing;
