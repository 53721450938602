import React, { useEffect, useState } from 'react';
// Get the loader
import Loader from '../../../components/Loader';
// import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import { ApiService } from 'src/services/ApiService';

const Reports = () => {
  // get the report url
  const [reportUrl, setReportUrl] = React.useState('');
  // const [showMessage, setShowMessage] = useState(false);
  // const [errorMessage, setErrorMessage] = useState('');
  // check loader is true or false
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getReport();
  }, []);

  // Get the report method
  const getReport = async () => {
    try {
      // Now show the loader
      setIsLoading(true);

      const response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}franchisees/report`
      );

      //   if (response.ok) {
      // const data = await response.json();
      if (response.Status === 200) {
        // Set the list of teams
        let url = response.EmbedUrl;
        console.log(url);
        setReportUrl(url);
      } else {
        // setShowMessage(true);
        // setErrorMessage('Something went wrong, Try again!');
        // Handle other status codes or errors here
      }
      //   } else {
      //     // Handle non-200 status codes here
      //   }

      // Now hide the loader
      setIsLoading(false);
    } catch (error) {
      // Handle any exceptions or network errors here
      console.error('An error occurred:', error);
      // Now hide the loader
      setIsLoading(false);
      // setShowMessage(true);
      // setErrorMessage('Internal Server Error');
    }
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      {/* {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )} */}
      <div className="container align-center">
        {/* <iframe title="Reports" width="96%" height="960" src="https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/395188757585/dashboards/a9ab0bc0-4018-4918-b5d5-6d08b44fe427?directory_alias=zr" /> */}
        <iframe title="Reports" width="96%" height="960" src={reportUrl} />
      </div>
    </>
  );
};

export default Reports;
