/* eslint-disable no-unused-vars */
import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Typography,
  FormHelperText,
  Card,
  TableContainer,
  useTheme,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TablePagination,
  DialogActions,
  Divider
} from '@mui/material';
import { Box } from '@mui/system';
import { AutoComplete } from 'src/components/Global';
import { generateTimeOptions, isEndTimeGreaterOrEqual } from 'src/components/Schedules';
import { ApiService } from 'src/services/ApiService';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import { getLocalData, convertFrom12To24Format } from 'src/utils';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import Loader from 'src/components/Loader';
import StaffService from './StaffService'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

/**
 * Schedule date header template sample
 */

const dayOptions = [
  { id: 1, label: 'Monday', value: 'Monday' },
  { id: 2, label: 'Tuesday', value: 'Tuesday' },
  { id: 3, label: 'Wednesday', value: 'Wednesday' },
  { id: 4, label: 'Thursday', value: 'Thursday' },
  { id: 5, label: 'Friday', value: 'Friday' },
  { id: 6, label: 'Saturday', value: 'Saturday' },
  { id: 7, label: 'Sunday', value: 'Sunday' }
];
const Availibility = ({
  dataSource,
  setShowScreen,
  staffItem,
  getStaffDetailById
}) => {
  const locationId = getLocalData('locationId');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteData, setDeleteData] = useState('');
  const [fromTime, setfromTime] = useState('');
  const [endTime, setendTime] = useState('');
  const [endTimeError, setEndTimeError] = useState("")
  const [selectDayError, setSelectDayError] = useState("")
  const [startFromTimeError, setStartFromTimeError] = useState(false)
  const [toEndTimeError, setToEndTimeError] = useState(false)
  const [editData, setEditData] = useState('')
  const [isEdit, setIsEdit] = useState(false)
  const [open, setOpen] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [day, setday] = useState([]);
  const [daysValue, setDaysValue] = useState([]);
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');
  const [disabledSaveButton, setDisabledSaveButton] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const theme = useTheme();
  const handleScheduleInfo = (type, value) => {
    // setDisabledSaveButton(true)
    if (type === 'fromTime') {
      setfromTime(value);
      if (value === '') {
        setStartFromTimeError(true)
      } else {
        setStartFromTimeError(false)

      }
    } else if (type === 'toTime') {
      setendTime(value);
      if (value === '') {
        setToEndTimeError(true)
      } else {
        setToEndTimeError(false)
      }

    }
  };
  console.log(staffItem, 'staffItem');
  // const handleAssignService=()=>{
  //   setShowScreen(6)
  // }
  const handleEndTimeError = () => {
    if (!isEndTimeGreaterOrEqual(fromTime, endTime) || fromTime === endTime) {
      setEndTimeError("Invalid Time.")
      setDisabledSaveButton(true)
    } else {
      setEndTimeError('')
    }
  }
  console.log(dataSource.scheduleData, 'dataSource');
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };
  const paginatedLocationLists = dataSource?.scheduleData?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  const handleDayChange = (e) => {
    const { name, value } = e.target;
    console.log(value, 'valuevalue');
    if (name === 'day') {
      const selectedDays = value.map((item) => {
        const dayName = item.label;

        return { day: dayName };
      });

      setday(selectedDays);
      setDaysValue(value);
      setSelectDayError(false)
    }
  };



  useEffect(() => {
    if (!day?.length) {
      setDisabledSaveButton(true)
    }
  }, [day])

  useEffect(() => {

    if (day.length > 0 && fromTime && endTime && !isEdit) checkStaffAvailability();

  }, [day, fromTime, endTime]);
  useEffect(() => {
    if (endTime) {
      handleEndTimeError()
    }
  }, [endTime, fromTime])


  const addAvailibility = () => {
    handleOpenModal()
  }
  const handleOpenModal = () => {
    setOpen(true);
  };

  const clearStates = () => {
    setIsEdit(false)
    setday([]);
    setDaysValue([]);
    setfromTime('');
    setendTime('');
    setToEndTimeError(false)
    setStartFromTimeError(false)
    setEndTimeError('')
    setDisabledSaveButton(true);
  };

  const handleDialog = (type) => {
    if (type === 'cancel') {
      setOpen(false);
      setStartFromTimeError(false)
      clearStates();
    }
  };

  const handleClose = () => {
    setDaysValue([])
    setfromTime('')
    setendTime('')
    setShowScreen(0);
    clearStates();
    getStaffDetailById();
  };
  const handleEditAvailibility = (e, row) => {
    setEditData(row)
    setIsEdit(true)

    handleOpenModal()

  }
  const handleDeleteAvailibility = async (row) => {
    setOpenDeleteDialog(true);
    setDeleteData(row)  
  }
  const handleCloseDelete = (val)=>{
if(val==='Delete'){
  handleDelete()
} else if(val==='Cancel'){
  setOpenDeleteDialog(false);
}
}
const handleDelete = async()=>{
  let data = {
    locationId: locationId,
    scheduleIndex: deleteData.Id - 1,
    staffId: staffItem.sortKey,
  };
  try {
    let response = await ApiService.delete(
      `${process.env.REACT_APP_API_END_POINT}staff/deleteStaffAvailability`,
      data
    );
    if (response.statusCode === 200 && !response.error) {
      setIsLoading(false);
      let sucessmsg = response.message;
      clearStates();
      setOpenDeleteDialog(false);
      setSuccessMessage(sucessmsg);
      setShowSuccessMessage(true);
    } else {
      setIsLoading(false);

      let errmsg = response.message;
      clearStates();
      setOpen(false);
      setErrorMessage(errmsg);
      setShowErrorMessage(true);
    }
  } catch (error) {
    console.error('Error:', error);
    let errmsg = error.message;
    setErrorMessage(errmsg);
    setShowErrorMessage(true);
  }
  setSelectDayError(false)
  setStartFromTimeError(false)
  setToEndTimeError(false)
}
  useEffect(() => {
    if (isEdit) {
      setDisabledSaveButton(false);
      const uniqueData = dataSource.scheduleData.find((item) => {
        return item.Id === editData.Id
      })
      const selectedDay = dayOptions.find(item => item.label === uniqueData?.Subject);
      if (selectedDay) {
        setDaysValue([selectedDay]);
      }
      setday([{ day: uniqueData?.Subject }])
      setendTime(uniqueData?.EndTime);
      setfromTime(uniqueData?.StartTime);
    }

  }, [isEdit])
  useEffect(() => {
    if (isEdit) {
      setDisabledSaveButton(false);
    }
  }, [isEdit])
  console.log(dataSource.scheduleData, 'disabledSaveButton');
  const handleAddScheduleData = async () => {
    if (daysValue.length === 0) {
      setSelectDayError(true)
    } else if (!fromTime) {
      setStartFromTimeError(true)
    } else if (!endTime) {
      setToEndTimeError(true)
    } else {
      setIsLoading(false);
      let modifiedData = day.map((d) => {
        return { day: d.day, startTime: fromTime, endTime: endTime };
      });
      let data = {
        locationId: locationId,
        staffId: staffItem.sortKey,
        availableSchedules: modifiedData
      };
      try {
        let response = await ApiService.post(
          `${process.env.REACT_APP_API_END_POINT}staff/addStaffAvailability `,
          data
        );
        if (response.statusCode === 200 && !response.error) {
          setIsLoading(false);
          let sucessmsg = response.message;
          clearStates();

          setOpen(false);
          setSuccessMessage(sucessmsg);
          setShowSuccessMessage(true);
        } else {
          setIsLoading(false);

          let errmsg = response.message;
          clearStates();
          setOpen(false);
          setErrorMessage(errmsg);
          setShowErrorMessage(true);
        }
      } catch (error) {
        console.error('Error:', error);
        let errmsg = error.message;
        setErrorMessage(errmsg);
        setShowErrorMessage(true);
      }
      setSelectDayError(false)
      setStartFromTimeError(false)
      setToEndTimeError(false)
    }
  };
  const handleEditScheduleData = async () => {
    if (daysValue.length === 0) {
      setSelectDayError(true)
    } else if (!fromTime) {
      setStartFromTimeError(true)
    } else if (!endTime) {
      setToEndTimeError(true)
    } else {
      setIsLoading(false);
      let modifiedData = day.map((d) => {
        return { day: d.day, startTime: fromTime, endTime: endTime };
      });
      let data = {
        locationId: locationId,
        scheduleIndex: editData.Id - 1,
        staffId: staffItem.sortKey,
        availableSchedules: modifiedData
      };
      console.log(data, 'datadatadata');
      try {
        let response = await ApiService.put(
          `${process.env.REACT_APP_API_END_POINT}staff/updateStaffAvailability`,
          data
        );
        if (response.statusCode === 200 && !response.error) {
          setIsLoading(false);
          let sucessmsg = response.message;
          clearStates();

          setOpen(false);
          setSuccessMessage(sucessmsg);
          setShowSuccessMessage(true);
        } else {
          setIsLoading(false);

          let errmsg = response.message;
          clearStates();
          setOpen(false);
          setErrorMessage(errmsg);
          setShowErrorMessage(true);
        }
      } catch (error) {
        console.error('Error:', error);
        let errmsg = error.message;
        setErrorMessage(errmsg);
        setShowErrorMessage(true);
      }
      setSelectDayError(false)
      setStartFromTimeError(false)
      setToEndTimeError(false)
    }
  };
  const checkStaffAvailability = async () => {
    let modifiedData = day.map((d) => {
      return { day: d.day, startTime: fromTime, endTime: endTime };
    });

    let data = {
      locationId: locationId,
      staffId: staffItem.sortKey,
      availableSchedules: modifiedData
    };
    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}staff/checkAvailability`,
        data
      );
      if (response.statusCode === 200 && !response.error) {
        console.log(response, 'response');
        setDisabledSaveButton(false);
      } else {
        setDisabledSaveButton(true);
      }
    }
    catch (error) {
      console.error('Error:', error);
      setDisabledSaveButton(true);
      if (day?.length) {
        setShowErrorMessage(true)
        setErrorMessage("Staff is not available for the given time slot")
      }
    }
  };


  return (
    <>
      <Loader IsLoading={isLoading} />
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}
      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setShowSuccessMessage}
          closePopup={() => {
            setShowSuccessMessage(false);
            getStaffDetailById();

            clearStates();
          }}
        />
      )}

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{ paddingBottom: '15px' }}
      >
        <Grid item mt={-1}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Box className="back-arrow-close" onClick={() => handleClose()}>
              <svg
                width="27"
                height="19"
                viewBox="0 0 27 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M26.625 7.90885H5.96042L11.1812 2.67344L9.125 0.617188L0.375 9.36719L9.125 18.1172L11.1812 16.0464L5.96042 10.8255H26.625V7.90885Z"
                  fill="#003087"
                />
              </svg>
            </Box>
            <Typography className="header-blue-32">
              Add Service and Availability
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Button
            sx={{ mt: { xs: 0, md: 0 } }}
            variant="contained"
            className="btn-primary"
            onClick={addAvailibility}
          >
            Add Availability
          </Button>

          {/* <Button
            sx={{
              mt: { xs: 0, md: 0 },
              // border: '2px solid #003087 !important',
              // color: '#003087 !important',
              // padding: '6px 20px !important',
              ml: '10px'
            }}
            variant="contained"
            className="btn-primary"
            // onClick={handleAssignService}
          // disabled
          >
            Assign Services
          </Button> */}
        </Grid>
      </Grid>
      <Grid pr={0} mt={2}>
        <Card
          sx={{
            padding: '15px',
            borderRadius: '19px 19px 0px 0px'
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            ml={2}

          >
            <Grid item>
              {/* <CardHeader title="All Services" className="table-header" /> */}
              {/* <h2 className="table-heading">All Services</h2> */}
              <Typography className="table-heade header-blue-20">
                {' '}
                Staff Availibility
              </Typography>
            </Grid>
          </Grid>
          <TableContainer className="location-table-container">
            <Table>
              <TableHead>
                <TableRow className="table-header">
                  <TableCell>Day</TableCell>
                  <TableCell>Start Time</TableCell>
                  <TableCell>End Time</TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                {paginatedLocationLists?.map((row, index) => {
                  // const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      key={row.Id}
                      role="checkbox"
                      tabIndex={-1}
                      className="table-row cursor-pointer"
                    >
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          sx={{ marginTop: 1 }}
                          gutterBottom
                          noWrap
                        >
                          {row.Subject}
                        </Typography>
                      </TableCell>
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {row.StartTime}
                        </Typography>
                      </TableCell>
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {row.EndTime}
                        </Typography>
                      </TableCell>
                      <TableCell className="table-td">
                        <IconButton
                          sx={{
                            ml:'90%',
                            '&:hover': {
                              background: theme.colors.primary.lighter
                            },
                            color: theme.palette.primary.main
                          }}
                          color="inherit"
                          size="small"
                          className="edit-btn"
                          id={'edit_btn_' + index}
                          onClick={(e) =>
                            handleEditAvailibility(e, row)
                          }
                        >
                          Edit
                        </IconButton>
                      </TableCell>
                      <TableCell className="">
                        <IconButton
                          sx={{
                            '&:hover': {
                              background: theme.colors.primary.lighter
                            },
                            color: theme.palette.primary.main
                          }}
                          color="inherit"
                          size="small"
                          className="edit-btn"
                          id={'edit_btn_' + index}
                          onClick={(e) =>
                            handleDeleteAvailibility(row)
                          }
                        >
                          Delete
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box p={2}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={dataSource?.scheduleData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Card>
      </Grid>
      <StaffService
      setShowScreen={setShowScreen}
        staffItem={staffItem}
      />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleDialog('cancel')}
        aria-describedby="details-dialog"
        className="dialog-box dialog-box-right not "
        maxWidth="sm"
        sx={{ maxHeight: '426px', mt: 25, ml: 2 }}
      >
        <Box>
          <DialogTitle className="dialog-box-right-addTask -team-container-white-box">
            {/* this class is commented for the above code className="alert-title align-right dialog-header" */}
            <Box
              className="dialog-text "
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Typography
                textAlign="center"
                sx={{
                  marginBottom: '10px',
                  fontWeight: 'bold',
                  color: '#003087 !important',
                  fontSize: '30px'
                }}
              >
                <span
                  style={{
                    color: '#003087',
                    fontFamily: 'Myriad Pro',
                    fontWeight: '600px'
                  }}
                >
                  Add Availability
                </span>
              </Typography>
              {/* remove icon */}
              <Button
                className="details-remove-icon task-cross p-0 no-hover"
                onClick={() => handleDialog('cancel')}
              >
                <svg
                  width="30"
                  height="31"
                  viewBox="0 0 30 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 27.2695C8.385 27.2695 3 21.8845 3 15.2695C3 8.65453 8.385 3.26953 15 3.26953C21.615 3.26953 27 8.65453 27 15.2695C27 21.8845 21.615 27.2695 15 27.2695ZM15 0.269531C6.705 0.269531 0 6.97453 0 15.2695C0 23.5645 6.705 30.2695 15 30.2695C23.295 30.2695 30 23.5645 30 15.2695C30 6.97453 23.295 0.269531 15 0.269531ZM18.885 9.26953L15 13.1545L11.115 9.26953L9 11.3845L12.885 15.2695L9 19.1545L11.115 21.2695L15 17.3845L18.885 21.2695L21 19.1545L17.115 15.2695L21 11.3845L18.885 9.26953Z"
                    fill="#E64646"
                  />
                </svg>
              </Button>

            </Box>
          </DialogTitle>
        </Box>
        <DialogContent className="-team-container-white-box">
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription"
          >
            <Grid container spacing={2}>
              <Grid item md={6} sm={6} xs={6}>
                <FormControl
                  fullWidth
                  className="select-drop-down-label-styling required-field"
                >
                  <InputLabel
                    id="demo-simple-select-helper-label"
                    className="input-field-dropdown-styling required-label"
                  >
                    SELECT DAY
                  </InputLabel>
                  <Select
                    multiple
                    name="day"
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label="SELECT DAY"
                    className="input-field-styling"
                    onChange={handleDayChange}
                    disabled={isEdit}
                    value={daysValue}
                  >
                    {dayOptions.length > 0 &&
                      dayOptions.map((item) => (
                        <MenuItem id={item.id} value={item}>
                          {item.label}
                        </MenuItem>
                      ))}
                  </Select>
                  {selectDayError && (
                    <FormHelperText error>{'Please select at least one day.'}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={12} sm={12} xs={12} mb={2}>
                <Grid container spacing={2}>
                  <Grid item md={6} sm={6} xs={6}>
                    <AutoComplete
                      fullWidth
                      className="input-field-dropdown-to-from"
                      freeSolo
                      disableClearable
                      label="FROM"
                      name="fromTime"
                      options={generateTimeOptions()}
                      value={fromTime}
                      onChange={handleScheduleInfo}
                      helperText={startFromTimeError ? '' : ''}

                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={6}>
                    <AutoComplete
                      fullWidth
                      freeSolo
                      className="input-field-dropdown-to-from"
                      disableClearable
                      label="TO"
                      name="toTime"

                      options={generateTimeOptions()}
                      value={endTime}
                      onChange={handleScheduleInfo}
                      error={endTimeError !== ""}

                      helperText={
                        endTimeError && endTimeError
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {!disabledSaveButton && !endTimeError ? (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className="button-style-add-team field-top-margin-edit-location"
                onClick={isEdit ? handleEditScheduleData : handleAddScheduleData}
              >
                <Typography className="next-button-text">Save</Typography>
              </Button>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className="button-style-add-team field-top-margin-edit-location"
                disabled
              >
                <Typography className="next-button-text">Save</Typography>
              </Button>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openDeleteDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Deactivate'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Are you sure you want to delete this record?
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleCloseDelete('Delete')}
          >
            Delete
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => handleCloseDelete('Cancel')}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default Availibility;
