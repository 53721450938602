/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, forwardRef, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  IconButton,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import {
  DynamicPopup,
  // DynamicRightSidebar,
  DynamicContractTerms,
  DynamicSelect,
  DynamicTextField,
  SwitchToggle
} from '../../../components/GlobalComponents';
import { getLocalData, setFullLocationId } from 'src/utils';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import { ArrowLeft, InfoIcon } from 'src/assets/SVG/SvgIcons';
import { ContractTargetTable } from './ContractTargetTable';
import Loader from 'src/components/Loader';
import { ApiService } from 'src/services/ApiService';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const AddNewContract = (props) => {
  const {
    type,
    handleClose,
    contractsDetail,
    fetchData: fetchContractData,
    first,
    setfirst,
    setContractDetails,
    setShowSuccessMessage,
    setSuccessMessage,
    showSucessMessage,
    sucessMessage
  } = props;
  const payText = 'The client gets charged at the payment cycle OR immediately when the credits are exhausted, whichever happens first.';
  const consumText = 'If this feature is activated, the client will be charged immediately upon credit consumption; otherwise, charges will only be applied on the billing or anniversary date.';
  const locationId = setFullLocationId(getLocalData('locationId'));
  const encodedLocationId = encodeURIComponent(locationId);
  const [pageType, setPageType] = useState(type);
  const addRef = useRef(null);
  const API_URL = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}`;
  const [isLoading, setIsLoading] = useState(false);
  const [contractPricing, setContractPricing] = useState({
    // locationId: 'LOC#121231',
    locationId: locationId,
    // contractDuration: ['monthly'],
    contractDuration: 'every month',
    contractDurationError: false,
    contractName: null,
    contractType: '',
    contractTypeError: false,
    selectDayError: false,
    contractNameError: false,
    selectItems: false,
    isActive: true,
    isAutopay: true,
    items: [
      {
        itemId: '',
        contractItemType: ''
      }
    ],
    paymentFrequency: 'string',
    soldOnline: true,
    status: '1',
    createdBy: 'string',
    terms: '',
    termsError: false,
    termsCheckBox: false,
    selectAppointment: false,
    selectEnrollmentsT: false,
    selectEnrollmentsClass: false,
    signatureImage: 'binary photo',
    signatureImageError: false,
    pricingOptionId: '',
    description: '',
    sharable: false,
    autoPay: true,
    uponConsumption: false
  });
  const [pricingDialog, setPricingDialog] = useState(false);
  const [pricingDialogType, setPricingDialogType] = useState('assign');
  const userId = sessionStorage.getItem('UserId');
  // const [showSucessMessage, setShowSuccessMessage] = useState(false);
  // const [sucessMessage, setSuccessMessage] = useState('');
  const [contactNameError, setContactNameError] = useState(false);
  const [contractTypeError, setContractTypeError] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [contractDetail, setContractDetail] = useState(null);
  const [price, setPrice] = useState(0);
  const [isContractDurationAndCycleValid, setIsContractDurationAndCycleValid] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [isFieldEdited, setIsFieldEdited] = useState(false);
  const [dialog, setDialog] = useState({
    state: false,
    isCreated: false
  });
  const [selectedItems, setSelectedItems] = useState({
    client: null,
    items: [],
    discount: null,
    paymentMethod: null,
    values: [],
    totalAmount: 0.0
  });
  const handleResetStates = () => {
    setDialog({
      state: false,
      isCreated: false
    });
    // setSelectedEnrollments([]);
    // setSelectedAppointments([]);
    // setSelectedEnrollmentsType([]);
    setContactNameError(false);
    setContractTypeError(false);
    setContractPricing({
      // locationId: 'LOC#121231'
      locationId: locationId,
      contractDuration: [],
      contractDurationError: false,
      contractName: null,
      contractTypeError: false,
      contractType: [],
      selectDayError: false,
      contractNameError: false,
      selectItems: false,
      isActive: true,
      isAutopay: false,
      items: [
        {
          itemId: '',
          contractItemType: ''

        }
      ],
      paymentFrequency: 'string',
      soldOnline: true,
      status: '1',
      createdBy: 'string',
      terms: '',
      termsError: false,
      termsCheckBox: false,
      termsCheckBoxError: false,
      selectAppointment: false,
      selectEnrollmentsT: false,
      selectEnrollmentsClass: false,
      signatureImage: 'binary photo',
      signatureImageError: false,
      pricingOptionId: '',
      description: '',
      sharable: false,
      autoPay: true,
      uponConsumption: false
    });
  };

  const handleContractInfo = (type, value) => {
    // Set to true if any field is edited
    setIsFieldEdited(true);

    if (type === 'contractType') {
      if (value) {
        setContractTypeError(false);
        setContractPricing({
          ...contractPricing,
          contractTypeError: false,
          [type]: value
        });
      } else {
        setContractTypeError(true);
        setContractPricing({
          ...contractPricing,
          contractTypeError: true,
          [type]: value
        });
      }
    }
    if (type === 'contractName') {
      if (value) {
        setContactNameError(false);
        setContractPricing({
          ...contractPricing,
          contractNameError: false,
          [type]: value
        });
      } else {
        setContactNameError(true);
        setContractPricing({
          ...contractPricing,
          contractNameError: true,
          [type]: value
        });
      }
    }
    if (type === 'contractDuration') {
      setContractPricing({
        ...contractPricing,
        contractDurationError: false,
        [type]: value
      });
    }
    if (type === 'selectDay') {
      setContractPricing({
        ...contractPricing,
        selectDayError: false,
        [type]: value
      });
    }
    if (type === 'terms') {
      setContractPricing({
        ...contractPricing,
        termsError: false,
        [type]: value
      });
    }
    if (type === 'autoPay') {
      setContractPricing({
        ...contractPricing,
        autoPay: value
      });
    }

    if (type === 'uponConsumption') {
      setContractPricing({
        ...contractPricing,
        uponConsumption: value
      });
    }

    if (type === 'sharable') {
      setContractPricing({
        ...contractPricing,
        sharable: value
      });
    }

    if (type === 'description') {
      setContractPricing({
        ...contractPricing,
        description: value
      });
    }
  };

  useEffect(() => {
    // check if the contract duration is every year and contract type is 3 months or 6 months or 9 months then show the error message
    if (contractPricing.contractDuration === 'every year' && (contractPricing.contractType === '3 months' || contractPricing.contractType === '6 months' || contractPricing.contractType === '9 months')) {
      setIsContractDurationAndCycleValid(true);
    } else if (contractPricing.contractDuration === 'every 6 months' && contractPricing.contractType === '3 months') {
      setIsContractDurationAndCycleValid(true);
    } else {
      setIsContractDurationAndCycleValid(false);
    }
  }, [contractPricing.contractDuration, contractPricing.contractType]);

  const addNewContractClick = async () => {
    setContractPricing({
      ...contractPricing,
      contractDurationError: contractPricing.contractDuration.length === 0,
      contractNameError: contractPricing.contractName === null,
      contractTypeError: contractPricing.contractType.length === 0,
      termsError: contractPricing.terms === null
    });

    if (
      contractPricing.contractName !== null &&
      contractPricing.contractType.length !== 0 &&
      selectedItems.items.length !== 0 &&
      !isContractDurationAndCycleValid
    ) {
      setPricingDialog(true);
      setPricingDialogType('terms');
    } else {
      // check the value of name and contract type and items and show the error message
      if (contractPricing.contractName === null) {
        // console.log('BBBB', contractPricing);
        setContactNameError(true);
        setContractPricing({
          ...contractPricing,
          contractNameError: true
        });
      }
      if (contractPricing.contractType.length === 0) {
        setContractTypeError(true);
        setContractPricing({
          ...contractPricing,
          contractTypeError: true
        });
      }
      if (selectedItems.items.length === 0) {
        setContractPricing({
          ...contractPricing,
          selectItems: true
        });
      }

    }
  };

  // image
  const callContractApi = async () => {
    setIsLoading(true);

    let contractId = '';
    let params = {
      locationId: locationId,
      contractType: contractPricing.contractType,
      contractName: contractPricing.contractName,
      autopay: contractPricing.autoPay,
      items: selectedItems.items,
      billingCycle: contractPricing.contractDuration,
      description: contractPricing.description,
      terms: contractPricing.terms,
      price: price || 0.0,
      uponConsumption: contractPricing.uponConsumption,
    };
    // check if the contract detail is present then update the contract
    if (contractsDetail) {
      contractId = encodeURIComponent(contractDetail.sortKey);
      params = {
        // locationId: locationId, 
        ...params,
        status: contractPricing.status,
        updatedBy: userId,
      };
    } else {
      params = {
        // locationId: locationId,
        ...params,
        status: 1,
        createdBy: userId,
      };
    }
    try {
      let response;
      if (contractsDetail) {
        response = await ApiService.put(`${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}contracts/updateContractPOP?contractId=${contractId}`, params);
      } else {
        response = await ApiService.post(`${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}contracts/createContractPOP`, params);
      }
      const responseData = response;
      if (responseData.statusCode === 200) {
        fetchContractData();
        setIsLoading(false);

        setTimeout(() => {
          setShowSuccessMessage(true);
          if(contractsDetail){
            setSuccessMessage('Contract details has been updated successfully!');

          }else{

            setSuccessMessage('Contract has been created successfully!');
          }
        }, 500);

        if (!contractsDetail) {
          setPricingDialog(true);
          setPricingDialogType('assign');
        }
        setContractDetails('');
        setfirst(false);
        handleClose();
      } else {
        setIsLoading(false);
        setShowErrorMessage(true);
        setErrorMessage(responseData?.message);
        // toast.error(responseData?.message);       
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong, Try again!');
      // toast.error('Something went wrong, Try again!');      
    }
  };

  const valuesAreDifferent = () => {
    return Object.entries(contractPricing).some(([key, value]) => {
      // console.log(key);
      if (Array.isArray(value)) {
        return value.length > 0; // Consider non-empty arrays as different
      }
      return value !== ''; // Consider non-empty strings as different      
    });
  };

  const handleCloseScreen = (type) => {
    if (type) {
      handleClose();
    } else if (valuesAreDifferent()) {
      setDialog({ ...dialog, state: true });
    } else {
      handleResetStates();
    }
  };

  const handleDialog = (value) => {
    if (value === 'Continue') {
      setDialog({
        isCreated: false,
        state: false
      });
      // handleClose();
    } else if (value === 'Discard') {
      handleResetStates();
      handleClose();
    } else if (value === 'Cancel') {
      handleCloseScreen();
    }
    handleClose();
  };

  useEffect(() => {
    // fetchData();
    // fetchPricingData();
  }, [pageType]);

  useEffect(() => {
    if (contractsDetail) {
      setPageType('edit');
      getContract();
    }
  }, [contractsDetail]);

  useEffect(() => {
    if (contractDetail) {
      if (contractDetail?.uponConsumption) {
        let elm = document.querySelector('.switchSe.uponConsumption .custom-switch');
        if (elm) elm.click();
      }
      // TODO: To disable the toggle button if value is false
      // if auto-pay is false then set the Auto pay to be false
      if (!contractDetail?.autopay) {
        let elm = document.querySelector('.switchSe.autopay .custom-switch');
        if (elm) elm.click();
      }
            
      setContractPricing({
        ...contractPricing,
        terms: contractDetail.terms,
        contractType: contractDetail.contractType,
        contractName: contractDetail.contractName,
        contractDuration: contractDetail.billingCycle,
        description: contractDetail.description,
        autoPay: contractDetail.autopay,
        status: contractDetail.status,
        uponConsumption: contractDetail.uponConsumption,
      });
      setPrice(contractDetail.price);
      
      // if the selected item is not empty then set the selected items
      if (contractDetail.items.length > 0) {
        // // Get the ids of the items
        let updatedArray = contractDetail.items.map((item) => {
          return item.sortKey;
        });

        // set the selected items
        setSelectedItems({
          ...selectedItems,
          items: [...selectedItems.items, ...contractDetail.items],
          values: [...selectedItems.values, ...updatedArray].filter(
            (value, index, self) => {
              return self.indexOf(value) === index;
            }
          )
        });
      }
    }
    const handleClickOutside = (event) => {
      if (addRef.current && !addRef.current.contains(event.target)) {
        handleCloseScreen();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [contractDetail]);

  const getContract = async () => {
    let id = encodeURIComponent(contractsDetail[0].sortKey);
    // Show loader
    setIsLoading(true);
    try {
      const contractsData = await ApiService.get(
        `${API_URL}contracts/getContractById?locationId=${encodedLocationId}&contractId=${id}`
      );
      if (contractsData.statusCode === 200) {
        setContractDetail(contractsData.data.Items);
        setIsLoading(false);
      } else {
        setShowErrorMessage(true);
        setErrorMessage('Something went wrong');
        // toast.error('Something went wrong');
        setContractDetail([]);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleAddService = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    // check if selecte items length is greater than 0 then set the selectItems to false

    if (selectedItems.items.length > 0) {
      // Set the field edited to true
      // setIsFieldEdited(true);

      setContractPricing({
        ...contractPricing,
        selectItems: false
      });
    }
    const totalAmount =
      selectedItems?.items?.reduce((total, item) => {
        return total + Number(item?.price);
      }, 0) || 0.0;
    //  || Number(selectedItems.totalAmount)
    setSelectedItems({
      ...selectedItems,
      totalAmount: totalAmount
    });
  }, [selectedItems && selectedItems.items.length]);

  // Now updated the total amount into the price field
  useEffect(() => {
    setPrice(selectedItems.totalAmount);
  }, [selectedItems.totalAmount]);

  // Methdo to handle the click event for back arrow
  const handleCLick = () => {
    // If the value is confirm then navigate to the list page
    if (isFieldEdited) setConfirmDialog(true);
    else if (first) {
      setContractDetails('');
      setfirst(false);
      handleClose();
    } else {
      handleResetStates();
      handleClose();
    }
  };

  // Method to trigger when close the popup
  const closePopup = () => {
    setShowSuccessMessage(false);
  };

  // Handler to handle the dialog
  const handleConfirmationDialog = (value) => {
    // If the value is confirm then navigate to the list page
    setConfirmDialog(false);

    if (value === 'Discard') {
      if (first) {
        setContractDetails('');
        setfirst(false);
        handleClose();
      } else {
        handleResetStates();
        handleClose();
      }
    }
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}

      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setShowSuccessMessage}
          closePopup={closePopup}
        />
      )}
      <div className="contractMain mt-3">

        <Box
          sx={{ width: '100%' }}
          paddingBottom="20px"
          className="Main-container common-form-container"
        >
          <form className="custom-form" onSubmit={handleAddService}>
            <Grid mt={1} marginBottom="10px">
              <div className="contrHeader">
                {/* <h1
                  className="headHeading"
                  style={{ width: '100% !important' }}
                >
                  {contractsDetail ? 'Edit Contract' : 'Add a new contract'}
                </h1> */}

                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  gap="16px"
                >
                  <Grid item>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Box
                        onClick={() => {
                          // handleResetStates();
                          // handleClose();
                          handleCLick();
                        }}
                        className="back-arrow-close"
                      >
                        <ArrowLeft />
                      </Box>
                      <Typography className="header-blue-32">
                        {contractsDetail
                          ? 'Edit Contract'
                          : 'Add a new contract'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography variant="h1" color="primary" />
                    <Grid item>
                      <Button
                        variant="contained"
                        type="submit"
                        onClick={() => {
                          // setPricingDialog(true);
                          // setPricingDialogType('terms');
                          addNewContractClick();
                        }}
                      // disabled={!contractPricing.pricingOptionId}
                      >
                        {contractsDetail ? 'Update' : 'Save'}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>

            {(() => {
              switch (pageType) {
                default:
                  return (
                    <div>
                      <Grid container spacing={2} columns={12} mt={1}>
                        <Grid item xs={12} sm={6}>
                          <DynamicTextField
                            type="text"
                            name="contractName"
                            label="CONTRACT NAME"
                            // error={contactNameError}
                            helperText={
                              contactNameError &&
                              'Contract name is required'
                            }
                            shrink={Boolean(true)}
                            fullWidth
                            value={contractPricing.contractName}
                            onChange={handleContractInfo}
                            className='required-field'
                            InputLabelClass='required-label'
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <DynamicSelect
                            fullWidth
                            // helperTextColor="red"                           
                            // error={contractTypeError}
                            helperText={
                              contractTypeError &&
                              'Contract type is required'
                            }
                            name="contractType"
                            value={contractPricing.contractType}
                            onChange={handleContractInfo}
                            label="CONTRACT DURATION"
                            options={[
                              { label: '3 Months', value: '3 months' },
                              { label: '6 Months', value: '6 months' },
                              { label: '9 Months', value: '9 months' },
                              { label: '12 Months', value: '12 months' },
                              { label: 'Ongoing', value: 'Ongoing' }
                            ]}
                            className='required-field'
                            labelClass='required-label'
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} columns={12} mt={1}>
                        <Grid item xs={12} sm={6}>
                          <Grid container spacing={2} columns={12}>
                            <Grid item xs={6} sm={6}>
                              <DynamicTextField
                                type="text"
                                name="price"
                                label="PRICE"
                                disabled={Boolean(true)}
                                shrink
                                fullWidth
                                value={'$ ' + price}
                              />
                            </Grid>
                            <Grid item xs={5} sm={5}>
                              <div className="daySDropdownSe w-10">
                                <DynamicSelect
                                  fullWidth
                                  error={contractPricing.contractDurationError}
                                  helperText={
                                    contractPricing.contractDurationError &&
                                    'Payment cycle is required'
                                  }
                                  required
                                  name="contractDuration"
                                  value={contractPricing.contractDuration}
                                  onChange={handleContractInfo}
                                  label="PAYMENT CYCLE"
                                  options={[
                                    { label: 'Monthly', value: 'every month' },
                                    { label: 'Quarterly', value: 'every quarter' },
                                    { label: 'Every 6 months', value: 'every 6 months' },
                                    { label: 'Every year', value: 'every year' }
                                  ]}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={1} sm={1} className='d-flex' sx={{ paddingLeft: '10px !important' }}>
                              <Box
                                className="align-self-center cursor-pointer" id='info-icon'
                              >
                                {/* left arrow icon */}
                                <Tooltip arrow title={payText} placement="bottom">
                                  <IconButton className='no-hover'>
                                    <InfoIcon className='info-icon' />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Grid>
                            <Grid item xs={8} sm={8}>
                              <div className="switchSe uponConsumption mr-5 d-flex justify-content-en">
                                <SwitchToggle
                                  name="uponConsumption"
                                  label="Upon consumption"
                                  labelPlacement="start"
                                  value={contractPricing.uponConsumption}
                                  onChange={handleContractInfo}
                                  classValue="w-100 autoPay-btn"
                                />
                                {/* left arrow icon */}
                                <Tooltip arrow title={consumText} placement="bottom">
                                  <IconButton className='no-hover'>
                                    <InfoIcon className='info-icon' />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </Grid>
                            <Grid item xs={4} sm={4}>
                              <div className="switchSe autopay mr-5 d-flex justify-content-end">
                                <SwitchToggle
                                  name="autoPay"
                                  label="Auto Pay"
                                  labelPlacement="start"
                                  value={contractPricing.autoPay}
                                  onChange={handleContractInfo}
                                  classValue="w-100 autoPay-btn"
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <DynamicTextField
                            fullWidth
                            label="DESCRIPTION"
                            multiline
                            rows="6"
                            type="text"
                            name="description"
                            value={contractPricing.description}
                            onChange={handleContractInfo}
                          />
                        </Grid>
                      </Grid>

                      {/* <Grid container spacing={-16} columns={12}>
                        <Grid item xs={12} sm={6}>
                          <SwitchToggle
                            name="sharable"
                            label="Sharable"
                            labelPlacement="start"
                            value={contractPricing.sharable}
                            onChange={handleContractInfo}
                            className="w-100"
                          />
                        </Grid>
                      </Grid> */}
                    </div>
                  );
              }
            })()}
            {/* Show error message if payment cycle and duration is not correct */}
            {isContractDurationAndCycleValid && (
              <div className="error-message">{`Payment cycle can not be ${contractPricing?.contractDuration} for a ${contractPricing?.contractType} contract.`}</div>
            )}
          </form>
        </Box>

        {pageType !== 2 ? (
          <div>
            <Box className="location-details-container">
              <Grid container mt={4} className="location-details-row">
                <Grid item xs={12} sm={12} md={12} className="location-details-col">
                  <Typography className='field-heading-text font-24'>Add Contract Item</Typography>
                </Grid>
              </Grid>
            </Box>
            {/* Show error message if no item is selected */}
            {contractPricing.selectItems && (
              <div className="error-message">Please select at least one pricing option</div>
            )}
            <ContractTargetTable
              setSelected={setSelectedItems}
              selected={selectedItems}
              pageType={pageType}
              setIsFieldEdited={setIsFieldEdited}
            />
          </div>
        ) : null}
      </div>
      <DynamicPopup
        handleDialog={handleDialog}
        open={dialog.state}
        created={dialog.isCreated}
      />
      <DynamicContractTerms
        open={pricingDialog}
        setOpen={setPricingDialog}
        handleContractInfo={handleContractInfo}
        type={pricingDialogType}
        contractPricing={contractPricing}
        setContractPricing={setContractPricing}
        callContractApi={callContractApi}
        setPageType={setPageType}
        contractDetails={contractsDetail}
      // placeholder="Write the terms here..."
      />

      {/* Confirmation dialog */}
      <Dialog
        open={confirmDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleConfirmationDialog('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
         Discard Changes?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
              You have unsaved changes
              <Typography className="title-text-sub">
               What do you want to do?
              </Typography>
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleConfirmationDialog('Discard')}
          >
            Discard Changes
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 no-hover"
            onClick={() => handleConfirmationDialog('Continue')}
          >
            Go Back to Editing
          </Button>
        </DialogActions>
      </Dialog>
     
    </>
  );
};
export default AddNewContract;
