import React, { useState ,useEffect } from 'react';
import { Box, Checkbox, Divider, Stack, Typography, Grid } from '@mui/material';
import { DynamicButton, DynamicModal } from '../../../../components/Checkout';
import { getLocalData } from 'src/utils';
import { CustomTable } from '../TableComponent/CustomTable';
import { ApiService } from 'src/services/ApiService';
import Loader from 'src/components/Loader';


export const OrderSummary = ({ selected , handlePrevious, setSuccess, setSelected, handleNext }) => {
  const userId = getLocalData('userId');
  const locationId = getLocalData('locationId');
  const franchiseeId = getLocalData('franchiseeId');

  let BASE_URL = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}`;

  const [checkTerms, setCheckTerms] = useState(false);
  const [successOrder, setSuccessOrder] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false)


  const discount = selected?.totalDiscount || 0.0;
  useEffect(() => {
    // Update the active step value in local storage
    localStorage.setItem('activeStep', '3');
  }, []);

  const isContract = selected?.items?.filter(item => item?.itemType === 'Contract')?.length > 0;

  useEffect(() => {
    if (successOrder) {
      setSuccess(successOrder);
    }
    if (error) {
      setOpen(true);
    }
  }, [successOrder, error]);

  useEffect(()=> {
    calculateOrder()
  },[])

// FUNCTION TO GENERATE RANDOM IDEMPOTENCYKEY

  const generateIdempotencyKey = () => {
    const hexDigits = '0123456789abcdef';
    let uuid = '';
    for (let i = 0; i < 36; i++) {
      if (i === 14) {
        uuid += '4'; // Version 4 UUID
      } else if (i === 19) {
        uuid += hexDigits[(Math.floor(Math.random() * 4) + 8)]; // Variant (8, 9, A, or B)
      } else {
        uuid += hexDigits[Math.floor(Math.random() * 16)];
      }
    }
    return uuid;
  };
  
// FUNCTION TO OPEN THE POS APP FOR PAYMENT
  // const openPosApp = () => {
  //   const idempotencyKey = generateIdempotencyKey();
  //   console.log(idempotencyKey);
  //   console.log(newPaylaoad, "newpayload is consoled here")
  //   try {
  //     const dataParameter = {
  //       amount_money: {
  //         amount: `${newPaylaoad?.amount}`,
  //         currency_code: "USD"
  //       },
  //       location_id: newPaylaoad?.squareLocationId,
  //       customer_id: newPaylaoad?.squareDeveloperCustomerId,
  //       order_id: newPaylaoad?.squareOrderId,
  //       idempotency_key: idempotencyKey,
  //       reference_id: locationId,
  //       cash: {
  //         source: "CASH",
  //         amount: newPaylaoad?.amount,
  //         squareSellerCustomerId: newPaylaoad?.squareSellerCustomerId,
  //         squareDeveloperCustomerId: newPaylaoad?.squareDeveloperCustomerId,
  //         squareLocationId: newPaylaoad?.squareLocationId,
  //         squareOrderId: newPaylaoad?.squareOrderId,
  //         locationId: locationId,
  //         orderId: newPaylaoad?.orderId,
  //         paymentMode: "CASH",
  //         cashDetails: {    
  //             buyerSuppliedMoney: {
  //                 amount: Number(newPaylaoad?.amount),
  //                 currency: "USD"
  //             },
  //             changeBackMoney: {    
  //                 amount: 0,         
  //                 currency: "USD"
  //             }
  //         }
  //     }
  //     };

  //     console.log(dataParameter, "dataParameterdataParameter")
  //     console.log("TestSquare://payment?data=" + encodeURIComponent(JSON.stringify(dataParameter)))
  //     const openApp = window.open("TestSquare://payment?data=" + encodeURIComponent(JSON.stringify(dataParameter)));
  //     if (!openApp) {

  //       console.log("Unable to open the app");
  //     }
  //   } 
  //   catch (error) {
  //     console.log("Unable to open the app");
  //   }
  // }

  const originalString = selected?.client?.clientId
  const updatedString = originalString?.replace('#CLIENT#', '')

  const openPosApp = (newPayload) => {
    const idempotencyKey = generateIdempotencyKey();
    console.log(idempotencyKey);
    console.log(newPayload, "newPayload is consoled here"); // Correct the variable name from newPaylaoad to newPayload

    try {
        const dataParameter = {
            amount_money: {
                amount: `${newPayload?.amount}`,
                currency_code: "USD"
            },
            location_id: newPayload?.squareLocationId,
            customer_id: newPayload?.squareSellerCustomerId,
            order_id: newPayload?.squareOrderId,
            idempotency_key: idempotencyKey,
            reference_id: locationId,
            cash: {
                franchiseeId: franchiseeId,
                clientId: selected?.client?.clientId,
                source: "CASH",
                amount: newPayload?.amount,
                // squareSellerCustomerId: newPayload?.squareSellerCustomerId,
                // squareDeveloperCustomerId: newPayload?.squareDeveloperCustomerId,
                // squareLocationId: newPayload?.squareLocationId,
                squareOrderId: newPayload?.squareOrderId,
                locationId: locationId,
                orderId: newPayload?.orderId,
                paymentMode: "CASH",
                cashDetails: {
                    buyerSuppliedMoney: {
                        amount: Number(newPayload?.amount),
                        currency: "USD"
                    },
                    changeBackMoney: {
                        amount: 0,
                        currency: "USD"
                    }
                }
            },
            callbackUrl: 
              `${window?.location?.origin}/sales-history/${updatedString}`
        };

        console.log(dataParameter, "dataParameterdataParameter");

        const testSquareURL = "TestSquare://payment?data=" + encodeURIComponent(JSON.stringify(dataParameter));

        window.location.href = testSquareURL;

        // Capture the "Cancel" action when the user navigates back from TestSquare
        window.onbeforeunload = function() {
            alert("cancelled")
            // This function will execute when the user clicks "Cancel" or navigates back.
            // You can perform your desired action here.
            console.log("User clicked Cancel or navigated back.");
            // Call your cancel function here if needed
        };

        // Optionally, you can capture the "Open" action when the user returns to your page
        window.onfocus = function() {
          setSuccessOrder(true)
            // This function will execute when the user returns to your page after using TestSquare.
            // You can perform your desired action here.
            console.log("User returned to your page after using TestSquare.");
            // Call your "Open" function here if needed
        };
    } catch (error) {
        console.log("Unable to open the app");
    }
}

//  PAYLOAD FOR PLACE ORDER

const payload = {
  locationId: locationId,
  clientId: selected?.client?.sortKey || "",
  clientName: selected?.client?.firstName || "",
  guestClient: selected?.guestClient || false,
  createdBy: userId || '',
  queueId: selected?.queueId,
  petId: selected?.pet?.sortKey || "",
  petName: selected?.pet?.firstName || "",
  franchiseeId: franchiseeId,
};

const calculateOrder = async () => {
  setLoading(true)
  let newItemsArr = payload?.items?.map?.((item)=> {
    delete item.promoCode
    return item
  })
  let payloadData = {...payload, items: newItemsArr}
  if(payload?.clientId === ""){
    delete payloadData?.clientId
    delete payloadData?.clientName
  }
  if(!selected?.pet){
    delete payloadData?.petId
    delete payloadData?.petName
  }
  // callApi('orderSummary', 'checkout/placeOrder', 'POST', payloadData);
  
  try {
      let response = await ApiService.post(
        `${BASE_URL}checkout/calculateOrder`,payloadData, {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.statusCode === 200) {
        const squareLocationId = response?.data?.squareLocationId
        const taxAmount = response?.data?.totalSquareTaxAmountInDollar
        const totalDiscount = response?.data?.totalDiscount
        const totalAmount = response?.data?.totalSquareAmountWithTaxInDollar

        setSelected({
                    ...selected,
                    discount: totalDiscount,
                    tax: taxAmount,
                    squareLocationId: squareLocationId,
                    totalPrice: totalAmount
                  })
        }
        setLoading(false)    
  } catch (error) {
      console.error(error);
      setLoading(false)
      setSuccessOrder(false);
      setError(error?.data?.message || "Something went wrong");
  }
  
};

// API FOR PLACE ORDER 

  const handleConfirm = async () => {
    setLoading(true)   
    let payloadData = {...payload}
    if(payload.clientId === ""){
      delete payloadData.clientId
      delete payloadData.clientName
    }
    if(!selected?.pet){
      delete payloadData.petId
      delete payloadData.petName
    }
    // callApi('orderSummary', 'checkout/placeOrder', 'POST', payloadData);
    
    try {
        let response = await ApiService.post(
          `${BASE_URL}checkout/placeOrder`,payloadData, {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
  
        if (response.statusCode === 200) {
          const sqaureOrderId = response?.data?.sqaureOrderId
          const squareLocationId = response?.data?.squareLocationId

          const createPayload = {
            source: "CASH",
            amount: Number(response?.data?.sqaureOrderTotalMoney),
            squareSellerCustomerId: response.data.squareSellerCustomerId,
            squareDeveloperCustomerId: response.data.squareCustomerId,
            squareLocationId: response.data.squareLocationId,
            squareOrderId: response.data.sqaureOrderId,
            locationId: locationId,
            orderId: response.data.ZROrderId,
            paymentMode: "CASH",
            cashDetails: {    
                buyerSuppliedMoney: {
                    amount: Number(response.data.sqaureOrderTotalMoney),
                    currency: "USD"
                },
                changeBackMoney: {    
                    amount: 0,         
                    currency: "USD"
                }
            }
          }
          setSelected({
                      ...selected,
                      sqaureOrderId: sqaureOrderId,
                      squareLocationId: squareLocationId
                    })
          openPosApp(createPayload)
          }
          setLoading(false)    
    } catch (error) {
        console.error(error);
        setLoading(false)
        setSuccessOrder(false);
        setError(error?.data?.message || "Order not placed something went wrong");
    }
    
  };
  
  const handleProceed = () =>{
    handleConfirm()
  }

  return (
    <>
      <Loader IsLoading={loading} />
      <Typography
        variant="h1"
        align="center"
        color="primary"
        style={{ marginBottom: '15px' }}
      >
        Order Summary
      </Typography>

      <Stack direction="column" justifyContent="left" alignItems="left">
        <Box sx={{ maxWidth: '40%', margin: '0 auto 60px' }}>
          <Typography
            variant="h4"
            align="left"
            color="primary"
            style={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            Client
          </Typography>
          <Box className="clientItem">
            <Box>
              {selected?.client?.firstName} {selected?.client?.lastName}
            </Box>
          </Box>
        </Box>
        <Box sx={{ alignItems: 'center' }}>
          <Typography variant="h4" color="primary" sx={{fontSize: "20px"}}>
            All Added Items
          </Typography>
          <CustomTable
            data={selected?.items || []}
            isEdit={false}
            isSearch={false}
            //  loading={loading}
          />
        </Box>
      </Stack>
      <Box className="termsFlex">
        {/* <Box className="left">
          <Typography variant="body1">Terms</Typography>
          <Box className="checkoutTermsBox">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
            et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
            Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
            sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
            et dolore magna aliquyam erat, sed diam voluptua Lorem ipsum dolor
            sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
            invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
            At vero eos et accusam et justo duo dolores et ea rebum. Stet clita
            kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
            amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
            diam nonumy eirmod tempor invidunt ut labore et dolore magna
            aliquyam erat, sed diam voluptua Lorem ipsum dolor sit amet,
            consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
            ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero
            eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
            gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua
          </Box>
        </Box> */}
        {isContract && 
          <Box className="right">
            <Box>
              <Checkbox
                label="Accept Terms"
                checked={checkTerms}
                onChange={(e) => setCheckTerms(e.target.checked)}
              />
              <Typography variant="body1">Accept Terms</Typography>
            </Box>
            <Box title="by clicking this box you will find details">
              By purchasing the contracts above, I agree to the terms and conditions of the contract.
            </Box>
          </Box>
        }
      </Box>
      <Grid 
        container 
        item 
        sm={6} md={4} lg={4} 
        // sx={{border: "1px solid #D9D9D9", borderRadius: "19px 19px 0 0"}}
      >
        <Box sx={{ marginTop: '2%', direction: 'column', padding: '10px', width: "100%" }}>
          <Stack direction="row" justifyContent="space-between" margin="22px 0px">
            <Typography variant="h4" color="primary">
              SUBTOTAL
            </Typography>
            <Typography variant="h5">${selected?.total || 0}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" margin="22px 0px">
          <Typography variant="h4" color="primary" sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
              DISCOUNT 
              {/* {discount} */}
              {/* <Typography variant="h4" sx={{color: '#000000 !important'}}>
                % OFF
              </Typography> */}
          </Typography>
            <Typography variant="h5">{discount}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" margin="22px 0px">
            <Typography variant="h4" color="primary">
              TAXES
            </Typography>
            <Typography variant="h5">${selected?.tax || 0.0}</Typography>
          </Stack>
          <Divider />
          <Stack direction="row" justifyContent="space-between" margin="22px 0px">
            <Typography variant="h4" color="primary">
              TOTAL
            </Typography>
            <Typography variant="h5" style={{ color: '#000' }}>
              {/* {discountedAmount || totalAmount} */}
              ${selected?.totalPrice || 0}
            </Typography>
          </Stack>
          <Divider />
        </Box>
      </Grid>
      <Box display="flex" justifyContent="center" sx={{ paddingBottom: "20px"}}>
        <DynamicButton
          title={'Go Back'}
          variant="contained"
          onClick={handlePrevious}
          style={{
            padding: '10px 23px',
            borderRadius: '10px',
            textTransform: 'capitalize',
            maxWidth: 'max-content',
            fontWeight: '600',
            fontSize: '16px',
            marginRight: '10px',
            background: '#fff',
            color: '#003087',
            border: '2px solid #003087'
          }}
        />
        <DynamicButton
          onClick={handleProceed}
          variant={'contained'}
          title={'Confirm'}
          disabled={isContract && !checkTerms}
          style={{
            padding: '10px 23px',
            borderRadius: '10px',
            textTransform: 'capitalize',
            maxWidth: 'max-content',
            fontWeight: '600',
            fontSize: '16px',
            border: '2px solid #003087'
          }}
        />
      </Box>
      <DynamicModal
        className={'checkoutModal transactionModal'}
        open={open}
        handleDialog={() => {
          setOpen(false);
          handleNext();
        }}
        type
        selected={selected}
      />
    </>
  );
};
