import React, { useEffect, useState } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Card, Grid,  
     
} from '@mui/material';


export const CustomTable = ({
    data, 
    handleOnClick = () => { console.log('no event'); },
    selectedLists,
}) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [keys, setKeys] = useState([]);
    const [tableData ,setTableData] = useState([])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset page when rows per page changes
    };

    const paginatedData = tableData?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    const childOnClick = (e, rest) => {
        e.preventDefault();
        e.stopPropagation();
        handleOnClick(rest);
    }
    
    const tableHeader = ['itemType', 'itemName', 'quantity', 'price' ,'membershipDiscount', 'promoCodeDiscount', 'TotalPrice' ]

    useEffect(() => {
        let rows = data?.map((item) => {
            if (item?.discountDetails) {
                const membershipDiscount = 
                    item?.discountDetails?.memberShip?.discountApplied
                    ?   `${item?.discountDetails?.memberShip?.discountApplied} %` 
                    :   0;
                const promoCodeDiscount = 
                    item?.discountDetails?.promoCodes?.discountApplied 
                    ?   `${item?.discountDetails?.promoCodes?.discountApplied} %`
                    : 0;
                const TotalPrice = 
                    item?.discountDetails?.promoCodes?.discountPrice 
                        ? item?.discountDetails?.promoCodes?.discountPrice
                        : item?.discountDetails?.memberShip?.discountPrice
                            ? item?.discountDetails?.memberShip?.discountPrice
                        : 0
              return {
                ...item,
                membershipDiscount,
                promoCodeDiscount,
                TotalPrice
              };
            }
          
            return {
              ...item,
              membershipDiscount: 0,
              promoCodeDiscount: 0,
              TotalPrice: item?.price
            };
          });
          
        
        setTableData(rows)
        let key = rows && rows?.length > 0 && rows[0] ? Object?.keys(rows[0]) : tableHeader;

        key?.sort((a, b) => {
            const indexA = tableHeader.indexOf(a);
            const indexB = tableHeader.indexOf(b);
            if (indexA < indexB) {
              return -1;
            }
            if (indexA > indexB) {
              return 1;
            }
            return 0;
          });

        if (key) setKeys(key);
        // setKeys(['name', 'age', 'profession']);
    }, [data]);



 
    // Handler for select all items

    return (
            <Grid container spacing={2} sx={{ padding: "0 1rem", marginTop: "10px" }}>
                <Grid item sx={{ width: `100%`, paddingLeft: "0!important" }}>
                    <Card sx={{ padding: '15px', borderRadius: '19px 19px 0px 0px',border: "1px solid #D9D9D9"  }}>
                        <TableContainer className="Custom-Table table-container common-table-container">
                            <Table>
                                <TableHead className='common-header'>
                                    <TableRow key={0} >
                                        
                                        {keys?.map((item, indexhead) => {
                                            return(
                                                 tableHeader.includes(item) ? <TableCell key={indexhead} className={indexhead < 2 ? 'h-partitionKey h-sortKey' : ''}>{item}</TableCell> : ''
                                            )
                                        })}
                                        {/* <TableCell /> */}
                                        {/* Add more table headers as needed */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {paginatedData?.map((item, indexpage) => {
                                        const isItemSelected = (selectedLists && selectedLists?.length > 0 && selectedLists?.includes(item?.sortKey));
                                        return (<>
                                            <TableRow key={indexpage} className="table-row cursor-pointer" selected={isItemSelected}>     
                                                {keys?.map((key, indexkey) => {
                                                    return (
                                                        tableHeader.includes(key)
                                                        ?   <TableCell key={indexkey} onClick={(e) => childOnClick(e, [item['partitionKey'], item['sortKey'], 'Detail'])} >
                                                                {typeof item[key] !== 'object' && tableHeader.includes(key) && item[key]} 
                                                            </TableCell> 
                                                        : ''
                                                    )
                                                })}
                                                
                                                {/* Add more table cells as needed */}
                                            </TableRow>
                                        </>)
                                    }
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {/* Pagination */}
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Card>
                </Grid>
            </Grid>
    );
};
