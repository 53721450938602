/* eslint-disable no-unused-vars */
import {
  Grid,
  Box,
  Card,
  Typography,
  Paper,
  Menu,
  MenuItem,
  Button,
  TextField,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Slide,
  FormHelperText,
  Divider,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import React, { useEffect, useState ,useContext} from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Loader from '../../../components/Loader';
import { ApiService } from 'src/services/ApiService';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useNavigate } from 'react-router-dom';
import { dateFormat } from 'src/utils';
import { UserContext } from 'src/contexts/UserContext';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ClockIn = ({
  getStaffItem,
  clockType,
  openClock,
  setOpenClock,
  refreshStaffData
}) => {
  const navigate = useNavigate();
  const [staffName, setStaffName] = useState('');
  const [staffLocationName, setStaffLocationName] = useState('');
  const [staffLocationId, setStaffLocationId] = useState('');
  const [staffRole, setStaffRole] = useState('');
  const [assignedRoles, setAssignedRoles] = useState([]);
  const [StaffClockIn, setStaffClockIn] = useState('');
  const [StaffClockOut, setStaffClockOut] = useState('');
  const [StaffId, setStaffId] = useState('');
  const [anchor, setAnchor] = useState(null);
  const [anchorLocation, setAnchorLocation] = useState(null);
  const [clockInDate, setClockInDate] = useState(new Date());
  const { setIsCheckInOutApi, isCheckInOutApi } = useContext(UserContext);
  const [checkClockInTime, setCheckClockInTime] = useState('');
  const [checkClockOutTime, setCheckClockOutTime] = useState('');
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);
  const [ispopup, setIsPopup] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [dateChange, setDateChange] = useState(true);
  const [apiError, setApiError] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState('');
  const [punchId, setPunchId] = useState('');

  let staff = getStaffItem;
  let userId = sessionStorage.getItem('UserId');
  console.log(clockType, 'clockType----');

  useEffect(() => {
    getStaffDetails(staff);
  }, [staff]);

  const getStaffDetails = (staffDetails) => {
    // if (staff.length) {
    //   setStaffName(staffDetails[0].name);
    //   setStaffLocationName(staffDetails[0].locationName);
    //   setStaffRole(staffDetails[0].roles[0].role);
    //   setStaffClockIn(staffDetails[0].clockIn);
    //   setAssignedRoles(staffDetails[0].roles);
    //   setStaffLocationId(staffDetails[0].locationId);
    //   setStaffId(staffDetails[0].staffId);
    // }
    let name = staffDetails.firstName + ' ' + staffDetails.lastName;
    setStaffName(name);
    setStaffLocationName(staffDetails.locationName);
    setStaffRole(
      (staffDetails?.roles && staffDetails?.roles[0]?.role) ||
      staffDetails?.roleName
    );
    setStaffClockIn(staffDetails?.clockIn);
    setAssignedRoles(staffDetails?.roles);
    setStaffLocationId(getStaffItem?.locationId);
    setStaffId(staffDetails.sortKey);
    // setStaffId(staffDetails.sortKey);
    if (staffDetails && staffDetails.clockTime && staffDetails.clockTime[0]) {
      setCheckClockOutTime(staffDetails.clockTime[0].clockOutTime);
    } else {
      setCheckClockOutTime('');
    }

    if (staffDetails && staffDetails.clockTime && staffDetails.clockTime[0]) {
      setCheckClockInTime(staffDetails.clockTime[0].clockInTime);
    } else {
      setCheckClockInTime('');
    }

    if (staffDetails.clockTime) {
      setPunchId(
        staffDetails?.clockTime &&
        staffDetails?.clockTime[0] &&
        staffDetails?.clockTime[0].punchId
      );
    }
    // setCheckClockInTime(getStaffItem ?.clockTime[0]?.clockInTime.clockOutTime);
    // setCheckClockOutTime(getStaffItem.clockTime[0].clockOutTime);
    console.log(staffDetails, '------------------');

    if (clockType === 'ClockOut') {
      let userRole = (staffDetails?.roles && staffDetails?.roles[0]?.role) || staffDetails?.roleName
      clockInTimeAndDate(
        userRole,
        staffDetails?.locationId,
        staffDetails?.sortKey,
        staffDetails.createdAt
      )
    }


  };

  const clockInTimeAndDate = async (role, locationId, userId, createDate) => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = mm + '-' + dd + '-' + yyyy;
    const payload = {
      "staffId": userId,
      "locationId": locationId,
      "role": role,
      "filter": "month",
      "date": formattedToday
    }


    try {
      const response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}staff/clock-in-out`,
        payload
      );
      if (response.ok) {
        const data = await response.json();
        if (!checkClockOutTime) {
          setCheckClockOutTime(data?.Items[0].clockOutTime);
        } else {
          setCheckClockOutTime('');
        }

        if (!checkClockInTime) {
          setCheckClockInTime(data?.data?.Items[0].clockInTime);

        } else {
          setCheckClockInTime('');
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const openRoleMenu = (event) => {
    setAnchor(event.currentTarget);
  };

  const openLocationMenu = (event) => {
    setAnchorLocation(event.currentTarget);
  };

  const closeRoleMenu = () => {
    setAnchor(null);
  };

  const closeLocationMenu = () => {
    setAnchorLocation(null);
  };

  const changeRole = (event) => {
    console.log(event.target.innerText);
    setStaffRole(event.target.innerText);
  };

  const formatDate = (inputDate) => {
    let date;
    let month;
    let year;

    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();

    date = date.toString().padStart(2, '0');

    month = month.toString().padStart(2, '0');

    return `${month}-${date}-${year}`;
  };

  const convertToAMPM = (time) => {
    const date = new Date(time);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Convert hours to 12-hour format
    const Hours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedHours = Hours < 10 ? `0${Hours}` : Hours;

    // Add leading zeros to minutes if necessary
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    // Determine if it's AM or PM
    const ampm = hours < 12 ? 'AM' : 'PM';

    // Construct the final time string
    const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;

    return formattedTime;
  };

  const addClockIn = () => {
    let checkDate = formatDate(new Date(clockInDate))
    if (!ispopup) setIsLoading(true);
    let currentTime = convertToAMPM(new Date().getTime());
    const body = JSON.stringify({
      staffId: StaffId,
      role: (staff?.roles && staff?.roles[0]?.role) || staff?.roleName,
      staffName: staffName,
      locationId: staffLocationId,
      locationName: staffLocationName,
      clockInDate: formatDate(new Date(clockInDate)),
      clockInTime: currentTime,
      createdBy: userId
      // isActive: true
    });

    setTimeout(() => {
      let checkYear = checkDate[6] + checkDate[7] + checkDate[8] + checkDate[9]
      let getStaffYear = getStaffItem.startDate[6] + getStaffItem.startDate[7] + getStaffItem.startDate[8] + getStaffItem.startDate[9]
      if (checkDate >= getStaffItem.startDate && checkYear >= getStaffYear) {
        setDateError('')
        fetch(`${process.env.REACT_APP_API_END_POINT}staff/clock-in`, {
          method: 'POST',
          headers: {
        Accept: 'application/json',
          'content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
      },
      body: body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode === 200) {
          setCheckClockInTime(currentTime);
          setOpenConfirmation(true);
          activeCheckClockIn();
          setIsLoading(false);
          setIsCheckInOutApi(!isCheckInOutApi)
        } else {
          let errmsg = data.message;
          setApiError(true);
          setIsLoading(false);
          setApiErrorMessage(errmsg);
        }
      })
      .catch((error) => {
        setApiError(true);
        console.error('Error:', error);
        let errmsg = error.message;
        setApiError(true);
        setApiErrorMessage(errmsg);
      });
  } else {
    setIsLoading(false)
  setDateError('please select different date.')
}
    }, 500);
  };

const getPunchTimeByDate = async (date) => {
  let formatedDate = dateFormat(date);
  let payload = {
    "staffId": StaffId,
    "date": formatedDate
  };
  try {
    const response = await ApiService.post(
      `${process.env.REACT_APP_API_END_POINT}staff/getStaffPunchTimeByDate`,
      payload
    );
    if (response?.Items.length > 0) {
      const data = await response;
      setPunchId(data?.Items[0].punchId);
      setCheckClockInTime(data?.Items[0].clockInTime);
      setDateChange(false);
      if (clockType === 'clockOut') {
        activeCheckClockOut();
      }
    } else {
      const data = await response.json();
      let errmsg = data.message;
      setIsLoading(false);
      // setApiError(true);
      setApiErrorMessage(errmsg);
      setDateChange(true);
      setCheckClockInTime('');
      if (clockType === 'clockOut') {
        activeCheckClockOut();
      }
    }
  } catch (error) {
    console.error(error);
    setIsLoading(false);
    setApiErrorMessage('Please add the clock in time.');
    setDateChange(true);
    setCheckClockInTime('');
    if (clockType === 'clockOut') {
      activeCheckClockOut();
    }
  }
};

const addClockOut = () => {
  if (!ispopup) setIsLoading(true);
  let selectedPunchId = encodeURIComponent(punchId);
  let currentTime = convertToAMPM(new Date().getTime());
  const body = JSON.stringify({
    staffId: StaffId,
    clockOutDate: formatDate(new Date(clockInDate)),
    clockOutTime: currentTime,
    updatedBy: userId
  });

  fetch(
    `${process.env.REACT_APP_API_END_POINT}staff/clock-out?punchId=${selectedPunchId}`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      body: body
    }
  )
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      if (data.statusCode === 200) {
        setCheckClockOutTime(currentTime);
        setOpenConfirmation(true);
        activeCheckClockOut();
        setIsLoading(false);
        setIsCheckInOutApi(!isCheckInOutApi)
      } else {
        let errmsg = data.message;
        setIsLoading(false);
        setApiError(true);
        setApiErrorMessage(errmsg);
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      let errmsg = error.message;
      setApiError(true);
      setApiErrorMessage(errmsg);
      setIsLoading(false);
    });
};

const handleDateChnage = (date) => {

  setClockInDate(date);

  if (clockType === 'ClockIn') {
    activeCheckClockIn(true);
    setDateChange(false);
  }
  if (clockType === 'ClockOut') {
    getPunchTimeByDate(date);
  }
};

const activeCheckClockIn = () => {
  if (clockType === 'ClockIn' && checkClockInTime && dateChange) {
    return true;
  }

  if (clockType === 'ClockOut') {
    return true;
  }

  return false;
};

const activeCheckClockOut = () => {
  if (clockType === 'ClockOut' && !checkClockInTime) {
    return true;
  }

  if (clockType === 'ClockOut' && checkClockOutTime && dateChange) {
    return true;
  }

  if (clockType === 'ClockIn') {
    return true;
  }

  return false;
};

const handleClockClose = () => {
  setOpenClock(false);
};

// Method to close the update dialog
const handleConfirmationClose = (val) => {
  setOpenClock(false);
  setOpenConfirmation(false);
  if (refreshStaffData) refreshStaffData();
};

const handleCloseErrorModal = () => {
  setApiError(false);
};
// hook
useEffect(() => {
  let isLogin = sessionStorage.getItem('Login_Sucess');
  if (isLogin !== 'true') {
    navigate('/login');
  }
  if (StaffId) {
    let formatedDate = clockInDate
    getPunchTimeByDate(formatedDate)
  }
}, [StaffId]);
return (
  <>
    <Loader IsLoading={isLoading} />
    <Dialog
      open={openClock}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => handleClockClose('Cancel')}
      aria-describedby="details-dialog"
      className="dialog-box dialog-box-right"
      maxWidth="sm"
    >
      <DialogTitle className="alert-title align-right dialog-header">
        <Box>
          {/* remove icon */}
          <Button
            className="details-remove-icon p-0 no-hover"
            onClick={() => handleClockClose('Close')}
          >
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="13" cy="13" r="13" fill="white" />
              <path
                d="M17.7785 8.22908C17.7083 8.15877 17.625 8.10298 17.5332 8.06492C17.4415 8.02686 17.3431 8.00726 17.2438 8.00726C17.1444 8.00726 17.0461 8.02686 16.9543 8.06492C16.8625 8.10298 16.7792 8.15877 16.709 8.22908L13 11.9305L9.29097 8.2215C9.22075 8.15127 9.13738 8.09557 9.04563 8.05756C8.95388 8.01956 8.85554 8 8.75623 8C8.65692 8 8.55859 8.01956 8.46683 8.05756C8.37508 8.09557 8.29172 8.15127 8.2215 8.2215C8.15127 8.29172 8.09557 8.37508 8.05756 8.46683C8.01956 8.55859 8 8.65692 8 8.75623C8 8.85554 8.01956 8.95388 8.05756 9.04563C8.09557 9.13738 8.15127 9.22075 8.2215 9.29097L11.9305 13L8.2215 16.709C8.15127 16.7793 8.09557 16.8626 8.05756 16.9544C8.01956 17.0461 8 17.1445 8 17.2438C8 17.3431 8.01956 17.4414 8.05756 17.5332C8.09557 17.6249 8.15127 17.7083 8.2215 17.7785C8.29172 17.8487 8.37508 17.9044 8.46683 17.9424C8.55859 17.9804 8.65692 18 8.75623 18C8.85554 18 8.95388 17.9804 9.04563 17.9424C9.13738 17.9044 9.22075 17.8487 9.29097 17.7785L13 14.0695L16.709 17.7785C16.7793 17.8487 16.8626 17.9044 16.9544 17.9424C17.0461 17.9804 17.1445 18 17.2438 18C17.3431 18 17.4414 17.9804 17.5332 17.9424C17.6249 17.9044 17.7083 17.8487 17.7785 17.7785C17.8487 17.7083 17.9044 17.6249 17.9424 17.5332C17.9804 17.4414 18 17.3431 18 17.2438C18 17.1445 17.9804 17.0461 17.9424 16.9544C17.9044 16.8626 17.8487 16.7793 17.7785 16.709L14.0695 13L17.7785 9.29097C18.0667 9.00274 18.0667 8.51731 17.7785 8.22908Z"
                fill="#003087"
              />
            </svg>
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-slide-description"
          className="alert-decription"
        >
          <Box
            className="clock-in-content clock-content"
            sx={{
              padding: '20px'
            }}
          >
            <Grid
              container
              direction="column"
              justifyContent="space-around"
              alignItems="center"
            >
              <Grid
                item
                xs={12}
                sx={{
                  marginTop: '20px'
                }}
              >
                <svg
                  width="46"
                  height="46"
                  viewBox="0 0 46 46"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M43.7881 22.894C43.7881 34.4335 34.4335 43.7881 22.894 43.7881C11.3546 43.7881 2 34.4335 2 22.894C2 11.3546 11.3546 2 22.894 2C34.4335 2 43.7881 11.3546 43.7881 22.894L43.7881 22.894Z"
                    stroke="#003087"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M22.8945 10.8027V22.9937L31.0218 27.0573"
                    stroke="#003087"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  marginTop: '20px'
                }}
              >
                <Paper>
                  <Typography variant="h2" className="text-black">
                    {staffName}
                  </Typography>
                </Paper>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  marginTop: '20px'
                }}
              >
                <Button
                  className="clock-in-button"
                  id="demo-customized-button"
                  // aria-controls={open ? 'demo-customized-menu' : undefined}
                  aria-haspopup="true"
                  // aria-expanded={open ? 'true' : undefined}
                  disableElevation
                  onClick={openRoleMenu}
                  endIcon={
                    <KeyboardArrowDownIcon className="primary-color" />
                  }
                >
                  {staffRole || staff?.roleName}
                </Button>
                {/* 
            <Menu
              open={Boolean(anchor)}
              anchorEl={anchor}
              onClose={closeRoleMenu}
              keepMounted
              // TransitionComponent={Slide}
              PaperProps={{
                style: {
                  maxHeight: 40 * 5,
                  width: '25ch'
                }
              }}
            >
              {assignedRoles.map((assignedRole) => (
                <MenuItem
                  key={assignedRole.role}
                  value={assignedRole.role}
                  onClick={(e) => changeRole(e)}
                >
                  {assignedRole.role}
                </MenuItem>
              ))}
            </Menu> */}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  marginTop: '20px'
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box marginTop={1}>
                    <DatePicker
                      className="clock-date-picker"
                      variant="contained"
                      onChange={handleDateChnage}
                      value={clockInDate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ width: '100%' }}
                          className="clock-datepicker"
                        />
                      )}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                    <p style={{ color: 'red' }}>{dateError} </p>
                  </Box>
                </LocalizationProvider>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  marginTop: '20px'
                }}
              >
                <Button
                  sx={{ mt: { xs: 0, md: 0 }, mr: 2 }}
                  variant="contained"
                  className="btn-primary clock-button"
                  onClick={addClockIn}
                  disabled={activeCheckClockIn()}
                >
                  Clock In
                </Button>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  marginTop: '20px'
                }}
              >
                <Button
                  sx={{ mt: { xs: 0, md: 0 }, mr: 2 }}
                  variant="contained"
                  className="btn-primary clock-button"
                  onClick={addClockOut}
                  disabled={activeCheckClockOut()}
                >
                  Clock Out
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  marginTop: '10px'
                }}
              >
                {!checkClockInTime && clockType === 'ClockOut' && (
                  <FormHelperText className="Mui-error final-error">
                    Please add the clock-in time.
                  </FormHelperText>
                )}
              </Grid>
              <Grid
                container
                direction="column"
                alignItems="flex-end"
                justify="center"
                xs={12}
                className="clock-in-location"
              >
                <Box sx={{}}>
                  <Button
                    className="clock-in-button"
                    id="change-location"
                    // aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    // aria-expanded={open ? 'true' : undefined}
                    disableElevation
                    onClick={openLocationMenu}
                    endIcon={
                      <KeyboardArrowDownIcon className="primary-color" />
                    }
                  >
                    {staffLocationName}
                  </Button>

                  {/* <Menu
                open={Boolean(anchorLocation)}
                anchorEl={anchorLocation}
                onClose={closeLocationMenu}
                keepMounted
                // TransitionComponent={Slide}
                PaperProps={{
                  style: {
                    maxHeight: 40 * 5,
                    width: '25ch'
                  }
                }}
              > */}
                  {/* {assignedRoles.map((assignedRole) => (
                <MenuItem key={assignedRole.role} value={assignedRole.role} onClick={(e) => changeLocation(e)}>
                  {assignedRole.role}
                </MenuItem>
              ))}  */}
                  {/* </Menu> */}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>

    {/* confirmation dialog */}
    <Dialog
      open={openConfirmation}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => handleConfirmationClose('Cancel')}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className="alert-title align-center">
          {'Congratulations!'}
         </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-slide-description"
          className="alert-decription align-center"
        >
          <Box display="flex" justifyContent="center" direction="column">
            <Typography className="title-text-sub">
              {clockType === 'ClockIn'
                ? 'Staff member has successfully clocked in.'
                : 'Staff member has successfully clocked out.'}
            </Typography>
          </Box>
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions className="alert-button-control">
        <Button
          className="font-17 text-black no-hover"
          onClick={() => handleConfirmationClose('Discard')}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>

    <Dialog
      open={apiError}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => handleCloseErrorModal('Cancel')}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className="alert-title align-center">
        {' '}
        {clockType === 'ClockIn' ? 'Clocked In' : 'Clocked Out'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-slide-description"
          className="alert-decription align-center"
          sx={{ minWidth: '300px' }}
        >
          <Box display="flex" justifyContent="center" direction="column">
            <Typography className="title-text-sub">
              {/* Staff is already clocked in for this date. */}
              {apiErrorMessage}
            </Typography>
          </Box>
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions className="alert-button-control">
        <Button
          className="font-17 text-black no-hover"
          onClick={() => handleCloseErrorModal('Discard')}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  </>
);
};

export default ClockIn;
