/* eslint-disable no-unused-vars */
import {
  Grid,
  Box,
  Card,
  Typography,
  Paper,
  Menu,
  MenuItem,
  Button,
  TextField,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Slide,
  FormHelperText,
  Divider
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import React, { useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Loader from '../../../components/Loader';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useNavigate } from 'react-router-dom';
import { dateFormat } from 'src/utils';
import { ApiService } from 'src/services/ApiService';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const EditClock = ({
  getStaffItem,
  editClock,
  setEditClock,
  refreshStaffData,
  disableEditClock,
  refreshGetStaffData
}) => {
  const navigate = useNavigate();
console.log(disableEditClock, "disableEditClock");

  // hook
  useEffect(() => {
    let isLogin = sessionStorage.getItem('Login_Sucess');
    if (isLogin !== 'true') {
      navigate('/login');
    }
  }, []);

  const [staffName, setStaffName] = useState('');
  const [staffLocationName, setStaffLocationName] = useState('');
  // const [staffLocationId, setStaffLocationId] = useState('');
  const [staffRole, setStaffRole] = useState('');
  const [assignedRoles, setAssignedRoles] = useState([]);
  const [StaffClockIn, setStaffClockIn] = useState('');
  const [StaffClockOut, setStaffClockOut] = useState('');
  const [StaffId, setStaffId] = useState('');
  const [anchor, setAnchor] = useState(null);
  const [anchorLocation, setAnchorLocation] = useState(null);
  const [clockInDate, setClockInDate] = useState(new Date());
  // const [clockOutDate, setClockInDate] = useState(new Date());

  const [checkClockInTime, setCheckClockInTime] = useState('');
  const [checkClockOutTime, setCheckClockOutTime] = useState('');
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);
  const [ispopup, setIsPopup] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [format24HoursInTime, setFormat24HoursInTime] = useState('');
  const [format24HoursOutTime, setFormat24HoursOutTime] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [succesMessage, setSuccessMessage] = useState('');
  const [finalApiErr, setFinalApiErr] = useState(false);
  const [finalApiErrMsg, setFinalApiErrMsg] = useState('');
  const [dateChange, setDateChange] = useState(true);
  const [punchId, setPunchId] = useState('');
  const [getStaffId, setGetStaffId] = useState('');

  let staff = getStaffItem;
  let userId = sessionStorage.getItem('UserId');

  useEffect(() => {
    getStaffDetails(staff);
    // getStaff();
  }, [staff]);

  const getStaffDetails = (staffDetails) => {
    console.log(staffDetails, 'StaffIdStaffIdStaffIdStaffId');
    let stffId = staffDetails && staffDetails.sortKey ? staffDetails.sortKey : staffDetails.staffId;

    if (staffDetails.firstName ) {
      let name = (staffDetails.firstName + ' ' + staffDetails.lastName)
      setStaffName(name);
      setStaffLocationName(staffDetails.locationName);
      setStaffRole(staffDetails.roles[0].role);
      setStaffClockIn(staffDetails.clockIn);
      setAssignedRoles(staffDetails.roles);
      setStaffId(stffId);

      if (staffDetails && staffDetails.clockTime && staffDetails.clockTime[0]) {
        setCheckClockOutTime(staffDetails.clockTime[0].clockOutTime);
        setFormat24HoursOutTime(
          convertTo24HourFormat(staffDetails.clockTime[0].clockOutTime)
        );
      } else {
        setCheckClockOutTime('');
        setFormat24HoursOutTime('');
      }

      if (staffDetails && staffDetails.clockTime && staffDetails.clockTime[0]) {
        setCheckClockInTime(staffDetails.clockTime[0].clockInTime);
        setFormat24HoursInTime(
          convertTo24HourFormat(staffDetails.clockTime[0].clockInTime)
        );
      } else {
        setCheckClockInTime('');
        setFormat24HoursInTime('');
      }

      setPunchId(staffDetails.clockTime[0].punchId);
    } else if (staffDetails.staffName){
      setStaffName(staffDetails.staffName);
      setStaffLocationName(staffDetails.locationName);
      setStaffRole(staffDetails.role);
      setGetStaffId(staffDetails.staffId)
      setStaffClockIn(staffDetails.clockIn);
      setAssignedRoles(staffDetails.roles);
      setStaffId(stffId);
      setPunchId(staffDetails.punchId);
      setFormat24HoursInTime(convertTo24HourFormat(staffDetails.clockInTime));
      setFormat24HoursOutTime(convertTo24HourFormat(staffDetails.clockOutTime));
      setCheckClockOutTime(staffDetails.clockOutTime);
      setCheckClockInTime(staffDetails.clockInTime);
      setClockInDate(staffDetails.clockInDate);
      
    }
  };

  const openRoleMenu = (event) => {
    setAnchor(event.currentTarget);
  };

  const openLocationMenu = (event) => {
    setAnchorLocation(event.currentTarget);
  };

  const closeRoleMenu = () => {
    setAnchor(null);
  };

  const closeLocationMenu = () => {
    setAnchorLocation(null);
  };

  const changeRole = (event) => {
    setStaffRole(event.target.innerText);
  };

  const formatDate = (inputDate) => {
    let date;
    let month;
    let year;

    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();

    date = date.toString().padStart(2, '0');

    month = month.toString().padStart(2, '0');

    return `${month}-${date}-${year}`;
  };

  const convertToAMPM = (time) => {
    const date = new Date(time);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Convert hours to 12-hour format
    const Hours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedHours = Hours < 10 ? `0${Hours}` : Hours;

    // Add leading zeros to minutes if necessary
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    // Determine if it's AM or PM
    const ampm = hours < 12 ? 'AM' : 'PM';

    // Construct the final time string
    const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;
    return formattedTime;
  };
  
  const saveTime = () => {
    setFinalApiErr(false);
    setFinalApiErrMsg('');
    if (!ispopup) setIsLoading(true);
    let selectedPunchId = encodeURIComponent(punchId);
    const body = {
      staffId: StaffId,
     
      clockInDate: formatDate(new Date(clockInDate)),
      clockInTime: checkClockInTime,
      updatedBy: userId
    };

    if (checkClockOutTime) {
      body.clockOutDate = formatDate(new Date(clockInDate));
      body.clockOutTime = checkClockOutTime;
    } else {
      body.clockOutDate = '';
      body.clockOutTime = '';
    }
    const requestBody = JSON.stringify(body);

    fetch(
      `${process.env.REACT_APP_API_END_POINT}staff/clock-in-out?punchId=${selectedPunchId}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        body: requestBody
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode === 200) {
          setOpenConfirmation(true);
          setIsLoading(false);
          setSuccessMessage(data.message);
        } else {
          let errmsg = data.message;
          setIsLoading(false);
          setSuccessMessage('');
          if (data.error && errmsg) {
            setFinalApiErr(true);
            setFinalApiErrMsg(errmsg);
          }
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        let errmsg = error.message;
        setIsLoading(false);
        setFinalApiErr(true);
        setFinalApiErrMsg(errmsg);
        setSuccessMessage('');
      });
  };

  const handleDateChange = (date) => {
    setClockInDate(date);
    // setClockOutDate(date);
    setDateChange(false);
    setIsEdit(true);
    getPunchTimeByDate(date);
  };

  // Method to update the time as per the date change
  const getPunchTimeByDate = async (date) => {
    let payload = {
      "staffId": StaffId,
      "date": dateFormat(date)
    };
    try {
      const response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}staff/getStaffPunchTimeByDate`,payload
      );
      if (response.ok) {
        const data = await response.json();
        setPunchId(data?.Items[0].punchId);
        setIsEdit(true);
        if (data?.Items[0].clockOutTime) {
          setCheckClockOutTime(data?.Items[0].clockOutTime);
          setFormat24HoursOutTime(
            convertTo24HourFormat(data?.Items[0].clockOutTime)
          );
        } else {
          setCheckClockOutTime('');
          setFormat24HoursOutTime('');
        }
        if (data?.Items[0].clockInTime) {
          setCheckClockInTime(data?.Items[0].clockInTime);
          setFormat24HoursInTime(
            convertTo24HourFormat(data?.Items[0].clockInTime)
          );
        } else {
          setCheckClockInTime('');
          setFormat24HoursInTime('');
        }
        setFinalApiErr(false);
        setFinalApiErrMsg('');
      } else {
        const data = await response.json();
        let errmsg = data.message;
        setIsLoading(false);
        setFinalApiErr(true);
        setFinalApiErrMsg(errmsg);
        setIsEdit(false);
      }
    } catch (error) {
      console.error(error);
      setIsEdit(false);
    }
  };

  // const getPunchTimeByDate = (date) => {
  //   let StaffIds = encodeURIComponent(StaffId);
  //   let selectedDate = dateFormat(date);
  //   fetch(
  //     `${process.env.REACT_APP_API_END_POINT}staff/getStaffPunchTimeByDate?staffId=${StaffIds}&date=${selectedDate}`,
  //     {
  //       method: 'GET',
  //       headers: {
  //         Accept: 'application/json',
  //         'content-Type': 'application/json',
  //         'Access-Control-Allow-Origin': '*'
  //       },
  //       // body: requestBody
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data) {
  //         setPunchId(data.Items[0].punchId);
  //         // let punchId = encodeURIComponent(data.Items[0].punchId);
  //         // if (staffDetails && staffDetails.clockTime && staffDetails.clockTime[0]) {
  //         //   setCheckClockOutTime(staffDetails.clockTime[0].clockOutTime);
  //         //   setFormat24HoursOutTime(
  //         //     convertTo24HourFormat(staffDetails.clockTime[0].clockOutTime)
  //         //   );
  //         // } else {
  //         //   setCheckClockOutTime('');
  //         //   setFormat24HoursOutTime('');
  //         // }

  //         // if (staffDetails && staffDetails.clockTime && staffDetails.clockTime[0]) {
  //         //   setCheckClockInTime(staffDetails.clockTime[0].clockInTime);
  //         //   setFormat24HoursInTime(
  //         //     convertTo24HourFormat(staffDetails.clockTime[0].clockInTime)
  //         //   );
  //         // } else {
  //         //   setCheckClockInTime('');
  //         //   setFormat24HoursInTime('');
  //         // }

  //       }
  //     })
  //     .catch((error) => {
  //        console.error('Error:', error);
  //       let errmsg = error.message;
  //       setIsLoading(false);
  //       setFinalApiErr(true);
  //       setFinalApiErrMsg(errmsg);
  //       setSuccessMessage('');
  //     });

  // }

  const handleClockClose = () => {
    setEditClock(false);
  };

  // Method to close the update dialog
  const handleConfirmationClose = (val) => {
  
    setEditClock(false);
    setOpenConfirmation(false);
    refreshStaffData();
    refreshGetStaffData();
  };

  const setTimeIn = (time) => {
    setFormat24HoursInTime(time); // Returns time in 24 hours format
    setCheckClockInTime(convertTo12HourFormat(time));
    setIsEdit(true);
  };

  const setTimeOut = (time) => {
    setFormat24HoursOutTime(time);
    setCheckClockOutTime(convertTo12HourFormat(time));
    setIsEdit(true);
  };

  const convertTo12HourFormat = (time24) => {
    // Extract the hour and minutes from the 24-hour time string
    const [hours, minutes] = time24.split(':');

    // Convert the hour part to a number
    let hours12 = parseInt(hours, 10);

    // Determine the AM/PM indicator
    let indicator = 'AM';

    // Adjust the hour and AM/PM indicator
    if (hours12 >= 12) {
      indicator = 'PM';
      if (hours12 > 12) {
        hours12 -= 12;
      }
    }
    // Return the time in 12-hour format as a string
    const formattedTime = `${hours12
      .toString()
      .padStart(2, '0')}:${minutes} ${indicator}`;
    return formattedTime;
  };

  const convertTo24HourFormat = (time12) => {
    if (time12) {
      // Extract the hour, minutes, and AM/PM indicator from the 12-hour time string
      const [time, modifier] = time12.split(' ');
      const [hours, minutes] = time.split(':');

      // Convert the hour part to a number
      let hours24 = parseInt(hours, 10);

      // Adjust the hour value for PM
      if (modifier === 'PM' && hours24 < 12) {
        hours24 += 12;
      }

      // Adjust the hour value for AM (midnight)
      if (modifier === 'AM' && hours24 === 12) {
        hours24 = 0;
      }

      // Return the time in 24-hour format as a string
      const formattedTime = `${hours24.toString().padStart(2, '0')}:${minutes}`;
      return formattedTime;
    }
    return null;
  };

  return (
    <>
      <Loader IsLoading={isLoading} />

      <Dialog
        open={editClock}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClockClose('Cancel')}
        aria-describedby="details-dialog"
        className="dialog-box dialog-box-right"
        maxWidth="sm"
      >
        <DialogTitle className="alert-title align-right dialog-header">
          <Box>
            {/* remove icon */}
            <Button
              className="details-remove-icon p-0 no-hover"
              onClick={() => handleClockClose('Close')}
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="13" cy="13" r="13" fill="white" />
                <path
                  d="M17.7785 8.22908C17.7083 8.15877 17.625 8.10298 17.5332 8.06492C17.4415 8.02686 17.3431 8.00726 17.2438 8.00726C17.1444 8.00726 17.0461 8.02686 16.9543 8.06492C16.8625 8.10298 16.7792 8.15877 16.709 8.22908L13 11.9305L9.29097 8.2215C9.22075 8.15127 9.13738 8.09557 9.04563 8.05756C8.95388 8.01956 8.85554 8 8.75623 8C8.65692 8 8.55859 8.01956 8.46683 8.05756C8.37508 8.09557 8.29172 8.15127 8.2215 8.2215C8.15127 8.29172 8.09557 8.37508 8.05756 8.46683C8.01956 8.55859 8 8.65692 8 8.75623C8 8.85554 8.01956 8.95388 8.05756 9.04563C8.09557 9.13738 8.15127 9.22075 8.2215 9.29097L11.9305 13L8.2215 16.709C8.15127 16.7793 8.09557 16.8626 8.05756 16.9544C8.01956 17.0461 8 17.1445 8 17.2438C8 17.3431 8.01956 17.4414 8.05756 17.5332C8.09557 17.6249 8.15127 17.7083 8.2215 17.7785C8.29172 17.8487 8.37508 17.9044 8.46683 17.9424C8.55859 17.9804 8.65692 18 8.75623 18C8.85554 18 8.95388 17.9804 9.04563 17.9424C9.13738 17.9044 9.22075 17.8487 9.29097 17.7785L13 14.0695L16.709 17.7785C16.7793 17.8487 16.8626 17.9044 16.9544 17.9424C17.0461 17.9804 17.1445 18 17.2438 18C17.3431 18 17.4414 17.9804 17.5332 17.9424C17.6249 17.9044 17.7083 17.8487 17.7785 17.7785C17.8487 17.7083 17.9044 17.6249 17.9424 17.5332C17.9804 17.4414 18 17.3431 18 17.2438C18 17.1445 17.9804 17.0461 17.9424 16.9544C17.9044 16.8626 17.8487 16.7793 17.7785 16.709L14.0695 13L17.7785 9.29097C18.0667 9.00274 18.0667 8.51731 17.7785 8.22908Z"
                  fill="#003087"
                />
              </svg>
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription"
          >
            <Box
              className="clock-in-content clock-content"
              sx={{
                padding: '20px'
              }}
            >
              <Grid
                container
                direction="column"
                justifyContent="space-around"
                alignItems="center"
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    marginTop: '20px'
                  }}
                >
                  <svg
                    width="46"
                    height="46"
                    viewBox="0 0 46 46"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M43.7881 22.894C43.7881 34.4335 34.4335 43.7881 22.894 43.7881C11.3546 43.7881 2 34.4335 2 22.894C2 11.3546 11.3546 2 22.894 2C34.4335 2 43.7881 11.3546 43.7881 22.894L43.7881 22.894Z"
                      stroke="#003087"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M22.8945 10.8027V22.9937L31.0218 27.0573"
                      stroke="#003087"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    marginTop: '20px'
                  }}
                >
                  <Paper>
                    <Typography variant="h2" className="text-black">
                      {staffName}
                    </Typography>
                  </Paper>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    marginTop: '20px'
                  }}
                >
                  <Button
                    className="clock-in-button"
                    id="demo-customized-button"
                    // aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    // aria-expanded={open ? 'true' : undefined}
                    disableElevation
                    onClick={openRoleMenu}
                    endIcon={
                      <KeyboardArrowDownIcon className="primary-color" />
                    }
                  >
                    {staffRole}
                  </Button>
                  {/* 
              <Menu
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={closeRoleMenu}
                keepMounted
                // TransitionComponent={Slide}
                PaperProps={{
                  style: {
                    maxHeight: 40 * 5,
                    width: '25ch'
                  }
                }}
              >
                {assignedRoles.map((assignedRole) => (
                  <MenuItem
                    key={assignedRole.role}
                    value={assignedRole.role}
                    onClick={(e) => changeRole(e)}
                  >
                    {assignedRole.role}
                  </MenuItem>
                ))}
              </Menu> */}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    marginTop: '20px'
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box marginTop={1}>
                      <DatePicker
                        className="clock-date-picker"
                        variant="contained"
                        onChange={handleDateChange}
                        value={clockInDate}
                        disabled={disableEditClock}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{ width: '100%' }}
                            className="clock-datepicker"
                            variant='standard'
                          />
                        )}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Box>
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    marginTop: '20px'
                  }}
                  className="clock-time-edit"
                >
                  <TextField
                    fullWidth
                    name="Time"
                    type="time"
                    sx={{ marginTop: '10px' }}
                    value={format24HoursInTime}
                    onChange={(e) => setTimeIn(e.target.value)}
                    className="input-field-styling"
                    InputLabelProps={{
                      className: 'label-style-create-franchisee',
                      shrink: true
                    }}
                  />
                </Grid>
                {checkClockOutTime ? (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      marginTop: '20px'
                    }}
                    className="clock-time-edit"
                  >
                    <TextField
                      fullWidth
                      name="Time"
                      type="time"
                      sx={{ marginTop: '10px' }}
                      value={format24HoursOutTime}
                      onChange={(e) => setTimeOut(e.target.value)}
                      className="input-field-styling"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                ) : (
                  ''
                )}

                <Grid
                  item
                  xs={12}
                  sx={{
                    marginTop: '20px'
                  }}
                >
                  <Button
                    sx={{ mt: { xs: 0, md: 0 }, mr: 2 }}
                    variant="contained"
                    className="btn-primary clock-button"
                    onClick={saveTime}
                    disabled={!isEdit}
                  >
                    Save
                  </Button>
                </Grid>

                <Grid
                  container
                  direction="column"
                  alignItems="flex-end"
                  justify="center"
                  xs={12}
                  className="clock-in-location"
                >
                  <Box sx={{}}>
                    <Button
                      className="clock-in-button"
                      id="change-location"
                      // aria-controls={open ? 'demo-customized-menu' : undefined}
                      aria-haspopup="true"
                      // aria-expanded={open ? 'true' : undefined}
                      disableElevation
                      onClick={openLocationMenu}
                      endIcon={
                        <KeyboardArrowDownIcon className="primary-color" />
                      }
                    >
                      {staffLocationName}
                    </Button>

                    {/* <Menu
                  open={Boolean(anchorLocation)}
                  anchorEl={anchorLocation}
                  onClose={closeLocationMenu}
                  keepMounted
                  // TransitionComponent={Slide}
                  PaperProps={{
                    style: {
                      maxHeight: 40 * 5,
                      width: '25ch'
                    }
                  }}
                > */}
                    {/* {assignedRoles.map((assignedRole) => (
                  <MenuItem key={assignedRole.role} value={assignedRole.role} onClick={(e) => changeLocation(e)}>
                    {assignedRole.role}
                  </MenuItem>
                ))}  */}
                    {/* </Menu> */}
                  </Box>
                </Grid>
                {finalApiErr && (
                  <FormHelperText className="Mui-error final-error">
                    {finalApiErrMsg}
                  </FormHelperText>
                )}
              </Grid>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* confirmation dialog */}
      <Dialog
        open={openConfirmation}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleConfirmationClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          Congratulations
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                {/* The clock time has been updated successfully!
                 */}

                {succesMessage}
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleConfirmationClose('Discard')}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditClock;
