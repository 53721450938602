import React, { useState } from 'react';
import Box from '@mui/material/Box';
import {
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  FormControl,
  InputLabel,
  Select,
  MenuItem
  //   TextField,
  //   Autocomplete
} from '@mui/material';
// import './Addteam.css';
// import { makeStyles } from '@material-ui/core/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function SwitchLocationPopup({
  openSwitchLocationPopup,
  setOpenSwitchLocationPopup,
  locationOptions
}) {
  const [switchLocation, setSwitchLocation] = useState('');

  const handleAddteamClose = (value) => {
    if (value === 'Close') {
      setOpenSwitchLocationPopup(false);
    }
  };

  const handleSwitchLocation = (location) => {
    setSwitchLocation(location);
  };

  return (
    <>
      <Dialog
        open={openSwitchLocationPopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleAddteamClose('Close')}
        aria-describedby="details-dialog"
        className="dialog-box dialog-box-right not "
        maxWidth="sm"
        sx={{ maxHeight: '400px', mt: 15, ml: 2 }}
      >
        <Box>
          <DialogTitle className="dialog-box-right-addTask -team-container-white-box">
            {/* this class is commented for the above code className="alert-title align-right dialog-header" */}
            <Box
              className="dialog-text"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center', // Center the text horizontally
                justifyContent: 'center', // Center the text vertically
                fontWeight: '700',
                position: 'relative' // Add relative positioning to the container
              }}
            >
              <Typography className="text-primary">
                <span
                  style={{
                    color: '#003087',
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: 'normal'
                  }}
                >
                  Set Your Location
                </span>
              </Typography>
              {/* remove icon with absolute positioning */}
              <Button
                className="details-remove-icon task-cross p-0 no-hover"
                onClick={() => handleAddteamClose('Close')}
                sx={{
                  position: 'absolute',
                  top: '10px', // Adjust the top value to position the icon vertically
                  right: '10px' // Adjust the right value to position the icon horizontally
                }}
              >
                <svg
                  width="30"
                  height="31"
                  viewBox="0 0 30 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 27.2695C8.385 27.2695 3 21.8845 3 15.2695C3 8.65453 8.385 3.26953 15 3.26953C21.615 3.26953 27 8.65453 27 15.2695C27 21.8845 21.615 27.2695 15 27.2695ZM15 0.269531C6.705 0.269531 0 6.97453 0 15.2695C0 23.5645 6.705 30.2695 15 30.2695C23.295 30.2695 30 23.5645 30 15.2695C30 6.97453 23.295 0.269531 15 0.269531ZM18.885 9.26953L15 13.1545L11.115 9.26953L9 11.3845L12.885 15.2695L9 19.1545L11.115 21.2695L15 17.3845L18.885 21.2695L21 19.1545L17.115 15.2695L21 11.3845L18.885 9.26953Z"
                    fill="#E64646"
                  />
                </svg>
              </Button>
            </Box>
          </DialogTitle>
        </Box>

        <DialogContent className="-team-container-white-box">
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription"
          >
            <Box mt={2}>
              <FormControl
                //   error={stateErroruser}
                fullWidth
                className="select-drop-down-label-styling required-field"
              >
                <InputLabel
                  id="demo-simple-select-helper-label"
                  className="input-field-dropdown-styling required-label"
                >
                  SELECT LOCATION
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={switchLocation}
                  label="SELECT LOCATION"
                  className="input-field-styling"
                  onChange={(e) => handleSwitchLocation(e.target.value)}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 224,
                        overflowY: 'auto'
                      }
                    }
                  }}
                >
                  {locationOptions.map((data) => (
                    <MenuItem value={data.locationId}>
                      {data.locationName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box mt={2}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className="button-style-add-team field-top-margin-edit-location"
                //   onClick={AddTeam}
                sx={{ borderRadius: '50px' }}
              >
                <Typography className="next-button-text">Proceed</Typography>
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default SwitchLocationPopup;
