/* eslint-disable no-useless-computed-key */
/* eslint-disable eqeqeq */
/* eslint-disable spaced-comment */
/* eslint-disable no-unused-vars */
import Loader from 'src/components/Loader';
import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState, useContext } from 'react';
import Footer from 'src/components/Footer';
import { DateTime } from "luxon";
import {
  SearchIconWithBlueBorder,
  LocationIconWithBlueBorder,
  StaffDashLogo
} from 'src/assets/SVG/SvgIcons';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Icon } from '@iconify/react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ClockIcon from '@mui/icons-material/AccessTime';
import { clientData, subCardData } from './MyClientData';
import { ApiService } from '../../../services/ApiService';
import {
  Button,
  Grid,
  Card,
  CardHeader,
  Typography,
  CardContent,
  CircularProgress
} from '@mui/material';
import GlobalData from '../../../global';
import { Box } from '@mui/system';
import { getLocalData } from 'src/utils';
import Header from '../../../components/Header';
import { convertDateTimeToUserTime } from 'src/utils';
import { UserContext } from 'src/contexts/UserContext';

function StaffDashboard() {
  const [recentSchedule, setRecentSchedule] = useState([]);
  const [clockInOut, setClockInOut] = useState([]);
  const [staffTask, setStaffTask] = useState([]);
  const [locationId, setLocationId] = useState('');
  const [loading, setLoading] = useState(false);
  const userId = getLocalData('userId');
  const role = getLocalData('userRole');
  const [isTaskLoading, setisTaskLoading] = useState(false);
  const [isClockLoading, setisClockLoading] = useState(false);
  const [isScheduleLoading, setisScheduleLoading] = useState(false);
  const {isCheckInOutApi } = useContext(UserContext);

  useEffect(() => {
    handleGetLocationId();
  }, [userId]);

  useEffect(() => {
    if (locationId) {
      getStaffTasks();
    }
  }, [locationId]);

  const handleGetLocationId = async () => {
    setLoading(true);
    const franchiseeId = getLocalData('franchiseeId');
    const isFranchisor = getLocalData('isFranchisor');
    const userRole = getLocalData('userRole');
    const userId = getLocalData('userId');

    let getLocationsUrl = `${process.env.REACT_APP_API_END_POINT}locations/getUserLocations`;
    let data = {
      userId: userId,
      franchiseeId: franchiseeId,
      role: userRole,
      isFranchisor: isFranchisor
    };

    try {
      let response = await ApiService.post(getLocationsUrl, data);
      let Item = response?.data?.Items ?? [];
      setLocationId(Item[0]?.locationId ?? '');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const todayDate = () => {
    const date = new Date();

    const day = date.getDate().toString().padStart(2, '0'); // Get the day and pad with leading zero if necessary
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get the month (note that months are zero-based) and pad with leading zero if necessary
    const year = date.getFullYear();

    const formattedDate = `${month}-${day}-${year}`;
    return formattedDate;
  }
  const weekDate = () => {
    const currentDate = new Date();
    const sevenWeeksLater = new Date(currentDate.getTime() + 7 * 1 * 24 * 60 * 60 * 1000); // 7 weeks in milliseconds

    const day = sevenWeeksLater.getDate().toString().padStart(2, '0');
    const month = (sevenWeeksLater.getMonth() + 1).toString().padStart(2, '0');
    const year = sevenWeeksLater.getFullYear();

    const formattedDate = `${month}-${day}-${year}`;
    return formattedDate;
  };

  const gettStaffDetails = async () => {
    setisScheduleLoading(true)
    // const data = {
    //   clientParentId: userId,
    //   type: 'upcoming',
    //   locationId: locationId
    // };
    const data = {
      // clientParentId: userId,
      fromDate: todayDate(),
      toDate: weekDate(),
      // type: 'upcoming',
      trainerId: userId,
      locationId: locationId
    };
    // data coment --------------------
    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/getAppointments`,
        data
      );

      if (response.statusCode && !response.error) {
        setRecentSchedule(response.data.Items);
        setisScheduleLoading(false)
      }
    } catch (error) {
      console.log(error);
      setisScheduleLoading(false)
    }
  };
  
  const gettClockInOut = async () => {
    setisClockLoading(true)
    const currentDate = new Date();
    const formattedDate = `${currentDate.getMonth() + 1
      }-${currentDate.getDate()}-${currentDate.getFullYear()}`;
    let payload = {
      "staffId": userId,
      "locationId": locationId,
      "role": role,
      "filter": "month",
      "date": formattedDate
    }
    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}staff/clock-in-out`,
        payload
      );

      if (response.statusCode && !response.error) {
        setClockInOut(response.data.Items);
        setisClockLoading(false)
      }
    } catch (error) {
      console.log(error);
      setisClockLoading(false)
    }
  };

  const getStaffTasks = async () => {
    setisTaskLoading(true)
    let encodedStaffId = encodeURIComponent(userId);
    let encodedLocationId = encodeURIComponent(locationId);
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}staff/getStaffProfileDetails?locationId=${encodedLocationId}&staffId=${encodedStaffId}`
      );

      if (response.statusCode && !response.error) {
        setStaffTask(response.data.Items);
        setisTaskLoading(false)
      }
      setisTaskLoading(false)
    } catch (error) {
      console.log(error);
      setisTaskLoading(false)
    }
  };

  function DateFormate(dateString) {
    const options = { weekday: 'long', day: '2-digit', month: 'short' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  }

  useEffect(() => {
    if (locationId) {
      gettStaffDetails();
    }
  }, [locationId]);

  useEffect(() => {
    if (locationId) {
      gettClockInOut();
    }
  }, [locationId,isCheckInOutApi]);
  // const layoutConfig = {
  //   'Trainer': { sm: 6, md: 6 },
  //   'Front Desk': { sm: 12, md: 12 },
  //   // Add more roles and their corresponding layout here
  // };
  // const roleLayout = layoutConfig[role] 
  // Get the title from global data
  const title = GlobalData[0].title;
  let staffTaskDetail = staffTask[0]?.allTasks;
  return (
    <>
      <Loader IsLoading={loading} />
      <Helmet>
        <title>Staff Dashboard | {title}</title>
      </Helmet>
      <Header title="" />
      <Grid container sx={{ pl: '10px' }}>
        <Grid container>
          {/* <Grid item xs={12} sm={6} className='staff-dashboard-role-btn'>
                        <Button
                            className="role-button"
                            id="demo-customized-button"
                            aria-haspopup="true"
                            disableElevation
                            endIcon={<KeyboardArrowDownIcon className="primary-color" />}
                        >
                            Trainer
                        </Button>
                    </Grid> */}
          <Grid
            item
            xs={12}
            sm={12}
            sx={{ mt: '12px' }}
            container
            justifyContent="flex-end"
          >
            {/* <Button disableRipple startIcon={<SearchIconWithBlueBorder />} className={`list-btn-link no-hover cursor-pointer p-0`} /> */}
            {/* <Button disableRipple startIcon={<LocationIconWithBlueBorder />} className={`list-btn-link no-hover cursor-pointer p-0`} /> */}
          </Grid>
        </Grid>
        <Card className="staff-title-card">
          <Grid container>
            <Grid item md={1} sm={1} className="staff-dash-img">
              <StaffDashLogo />
            </Grid>
            <Grid item md={10} sm={10}>
              <p className="staff-dashboard-title">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex Lorem ipsum dolor sit amet, consectetur
                adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                exercitation ullamco laboris nisi ut aliquip ex
              </p>
            </Grid>
          </Grid>
        </Card>
        <Grid container sx={{ padding: '25px' }} spacing={2}>
          {/* <Grid item xs={12} sm={4} md={4}>
    <Card className="staff-dash-container" style={{ backgroundColor: '#F8FBFF', border: '0.4px solid #C5C5C5', pb: 1, display: 'flex', flexDirection: 'column', maxHeight: '315px', overflowY: 'auto' }}>
      <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <CardHeader className='staff-dash-main' title="MY CLIENTS" />
        <MoreVertIcon />
      </Box>
      <Box style={{ flex: 1, overflowX: 'auto' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {clientData.map((client) => (
            <Card key={client.id} style={{ backgroundColor: 'rgba(255, 255, 255, 1)', border: '2px solid rgba(231, 239, 249, 1)', marginTop: '10px', width: '490px', height: '95px', display: 'flex', alignItems: 'center' }}>
              <img src={client.imageUrl} alt={client.name} style={{ width: '50px', height: '50px', borderRadius: '50%', marginLeft: '15px' }} />
              <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography variant="h6" className='client-name-staff'>{client.name}</Typography>
                <Typography variant="body2" className='client-description-staff'>{client.description}</Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      </Box>
    </Card>
  </Grid> */}

          <Grid item xs={12} sm={6} md={6}>
            <Card
              className="staff-dash-container"
              sx={{
                backgroundColor: '#F8FBFF',
                border: '0.4px solid #C5C5C5',
                pb: 1,
                display: 'flex',
                flexDirection: 'column',
                maxHeight: '315px',
                height: '400px',
                overflowY: 'auto'
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <CardHeader
                  className="staff-dash-main"
                  title="RECENT SCHEDULES"
                />
                <MoreVertIcon />
              </Box>
              <Box style={{ flex: 1, overflowX: 'auto' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  {isScheduleLoading ? <CircularProgress /> : recentSchedule?.map((client) => (
                    <Card
                      key={client.sortKey}
                      sx={{
                        backgroundColor: 'rgba(255, 255, 255, 1)',
                        border: '2px solid rgba(231, 239, 249, 1)',
                        marginTop: '10px',
                        width: '95%',
                        height: '95px',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <CardContent
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%'
                        }}
                      >
                        <Box
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            marginLeft: '10px'
                          }}
                        >
                          <Box
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <ClockIcon
                              fontSize="small"
                              color="primary"
                              sx={{ mt: 1 }}
                            />{' '}
                            {/* Icon here */}
                            <Typography
                              variant="h6"
                              sx={{ mt: 1, ml: 1 }}
                              className="day-date-staff"
                            >
                              {convertDateTimeToUserTime(client.fromDate).formattedDate}
                              {/* {DateFormate(client.fromDate)} */}
                            </Typography>
                          </Box>
                          <Box
                            className="day-date-staff"
                            style={{ display: 'flex', alignItems: 'center' }}
                            sx={{ mt: 1, ml: 3.5 }}
                          >
                            {client.scheduleType === 'enrollment' ? 'Enrollment' : 'Appointment'}
                          </Box>
                          {/* {client.clientDetails.map((clientDetail, index) => (
                  <Box
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                  <img
                      src={clientDetail.clientProfileImage}
                      alt={client.name}
                      style={{
                        width: '50px',
                        height: '50px',
                        borderRadius: '50%'
                      }}
                    />
                    <Box style={{ marginLeft: 10 }}>
                      <div key={index}>
                        <Typography
                          variant="body2"
                          className="client-name-staff-dash"
                        >
                          {clientDetail.clientParentName}
                        </Typography>
                      </div>
                       <Typography
                        variant="body2"
                        className="client-body-staff"
                      >
                        {client.description}
                      </Typography>{' '}
                    </Box>
                  </Box>
                ))} */}
                        </Box>
                        <Box
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end'
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{ marginBottom: '5px', mr: 2 }}
                            className="day-date-staff"
                          >
                            {convertDateTimeToUserTime(client.fromDate).formattedTime}-{convertDateTimeToUserTime(client.toDate).formattedTime}
                          </Typography>
                          <Typography
                            className="classname-staff-dash"
                            variant="body2"
                            sx={{ mr: 2, mt: 1 }}
                          >
                            {client?.serviceName}
                          </Typography>{' '}
                          {/* Add class name */}
                        </Box>
                      </CardContent>
                    </Card>
                  ))}
                </Box>
              </Box>
            </Card>
          </Grid>


          <Grid item xs={12} sm={6} md={6}>
            <Card
              className="staff-dash-container"
              sx={{
                backgroundColor: '#F8FBFF',
                border: '0.4px solid #C5C5C5',
                pb: 1,
                display: 'flex',
                flexDirection: 'column',
                maxHeight: '315px',
                height: '400px',
                overflowY: 'auto'
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <CardHeader className="staff-dash-main" title="CLOCK IN/OUT" />
                <MoreVertIcon />
              </Box>
              <Box style={{ flex: 1, overflowX: 'auto' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  {isClockLoading ? <CircularProgress /> :
                    clockInOut.map((client) => (
                      <Card
                        key={client.punchId}
                        sx={{
                          backgroundColor: 'rgba(255, 255, 255, 1)',
                          border: '2px solid rgba(231, 239, 249, 1)',
                          marginTop: '10px',
                          width: '95%',
                          height: '72px',
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <CardContent
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%'
                          }}
                        >
                          <Box
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                              marginLeft: 10
                            }}
                          >
                            <Typography
                              variant="h6"
                              className="staff-dash-date-time"
                              sx={{ marginBottom: '10px', mt: 2 }}
                            >
                              {DateFormate(client?.clockInDate.replaceAll('-', '/'))}
                            </Typography>
                            <Box
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '5px'
                              }}
                            >
                              <Icon
                                icon="mdi:clock-fast"
                                className="custom-image"
                              />
                              <Typography
                                variant="body2"
                                className="staff-dash-date-time"
                                sx={{ mr: 4, ml: 1 }}
                              >
                                {client.clockInTime}
                              </Typography>
                              <Icon
                                icon="mdi:clock-fast"
                                flip
                                vFlip
                                className="custom-image-rotate"
                              />
                              <Typography
                                sx={{ ml: 1 }}
                                className="staff-dash-date-time"
                                variant="body2"
                              >
                                {client.clockOutTime}
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-end'
                            }}
                          >
                            <Typography
                              sx={{ marginBottom: '20px', mr: 3, mt: 1 }}
                              className="staff-dash-role"
                              variant="body2"
                            >
                              {client.role}
                            </Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    ))}
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
        <Grid container sx={{ pl: '25px', pr: '25px' }} spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Card
              className="staff-dash-container"
              sx={{
                backgroundColor: '#F8FBFF',
                border: '0.4px solid #C5C5C5',
                pb: 1,
                display: 'flex',
                flexDirection: 'column',
                height: '390px',
                overflowY: 'auto'
              }}
            >
              <CardHeader className="staff-dash-main" title="ALL TASK" />
              <CardContent sx={{
                display: 'flex', justifyContent: 'center', alignItems: 'center'
              }}>
                {isTaskLoading ? <CircularProgress /> :
                  <Grid container spacing={2}>
                    {staffTaskDetail?.slice(0, 4).map((task) => (
                      <Grid key={task.sortKey} item xs={12} sm={6} md={6}>
                        <Card
                          sx={{
                            backgroundColor: 'rgba(255, 255, 255, 1)',
                            border: '2px solid rgba(231, 239, 249, 1)',
                            pb: 0,
                            height: '125px'
                          }}
                        >
                          <CardHeader
                            className="client-name-staff-dash"
                            title={task.title}
                            sx={{ pb: 0.5 }}
                          />
                          <CardContent sx={{ paddingTop: 0 }}>
                            {' '}
                            {/* Adjust the top padding here */}
                            <Typography
                              variant="body2"
                              sx={{ marginBottom: '8px' }}
                              className="day-date-staff"
                            >
                              {' '}
                              {/* Adjust the margin bottom here */}
                              {task.description}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                }
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}

export default StaffDashboard;
