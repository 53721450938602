/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import Box from '@mui/material/Box';
import {
  Button,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Checkbox,
  DialogTitle,
  Autocomplete,
  Slide,
  CardMedia
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, FormHelperText } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { TextField, Grid } from '@mui/material';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import { ApiService } from '../../../services/ApiService';
import axios from 'axios';
import { formatPhoneNumber, getLocalData, setFullLocationId } from 'src/utils';
import Loader from '../../../components/Loader';
import { CustomDatePicker } from 'src/components/CustomDatePicker';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import { HeaderContext } from 'src/contexts/HeaderContext';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function AddBusinessEntities({ setShowScreen, refreshData }) {
  const locationId = setFullLocationId(getLocalData('locationId'));
  const franchiseeId = getLocalData('franchiseeId');

  let userId = sessionStorage.getItem('UserId');
  // get user role and provide access of that roles
  const isFranchisor = getLocalData('isFranchisor');
  // check field updated or not
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);

  // const [imgObj, setImageObj] = useState('');
  //  const [productData, setProductData] = useState([]);

  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);

  /* States for getting values from input fields of Add product form as follows */

  const [businessName, setBusinessName] = useState('');
  const [businessEmail, setBusinessEmail] = useState('');

  const [businessPhone, setBusinessPhone] = useState('');
  const [address2, setaddress2] = useState('');
  const [businessEntityTypeId, setBusinessEntityTypeId] = useState({});
  const [stateType, setStateType] = useState('');

  // const [barCode, setbarCode] = useState(0);
  const [manufacturer, setmanufacturer] = useState('');
  const [manufacturerUrl, setmanufacturerUrl] = useState('');
  const [zoomRoomProductName, setzoomRoomProductName] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactPhone, setContactPhone] = useState('');

  const [productCategory, setproductCategory] = useState([]);
  const [varientType, setvarientType] = useState([]);
  const [varientProduct, setvarientProduct] = useState([]);
  const [price, setprice] = useState('');
  const [wholeSalePrice, setWholeSalePrice] = useState('');
  const [supplierList, setsupplierList] = useState([]);
  const [sellingPoint, setsellingPoint] = useState('');
  const [productDescription, setproductDescription] = useState('');
  const [productCategoryList, setproductCategoryList] = useState([]);
  const [isRequired, setisRequired] = useState(false);
  const [isBestSeller, setisBestSeller] = useState(false);
  const [istaxExempt, setistaxExempt] = useState(false);
  const [isDiscontinued, setisDiscontinued] = useState(false);
  const [variantId, setvariantId] = useState('');
  const [supplierId, setsupplierId] = useState('');
  const [valueId, setvalueId] = useState('');
  const [imgObj, setimgObj] = useState([]);
  const [variantDisabled, setvariantDisabled] = useState(true);
  const [expiryDate, setexpiryDate] = useState();
  const [isUpdated, setIsUpdated] = useState(false);
  const [multipleImage, setMultipleImage] = useState([]);
  const [skuProduct, setSkuProduct] = useState(null);
  const [skuCode, setSkuCode] = useState(null);
  /* States for getting and setting add product errors  */
  const [businessNameError, setBusinessNameError] = useState('');
  const [businessEmailerror, setBusinessEmailerror] = useState('');
  const [businessPhoneError, setBusinessPhoneError] = useState('');
  const [contactPhoneError, setContactPhoneError] = useState('');

  const [zipCodeError, setZipCodeError] = useState('');
  const [contactNameError, setContactNameError] = useState('');
  const [contactEmailError, setContactEmailError] = useState('');

  const [address2Error, setaddress2Error] = useState('');
  const [stateTypeError, setStateTypeError] = useState(false);
  const [cityError, setCityError] = useState(false);

  // const { setHeaderText } = useContext(HeaderContext);
  //  setHeaderText('Add Product');
  // const [barCodeError, setbarCodeError] = useState('');
  const [manufacturerError, setmanufacturerError] = useState('');
  const [skuCodeError, setSkuCodeError] = useState('');
  const [manufacturerUrlError, setmanufacturerUrlError] = useState('');
  const [zoomRoomProductNameError, setzoomRoomProductNameError] = useState('');
  const [businessEntityTypeError, setBusinessEntityTypeError] = useState(false);
  const [PopUpAddEntityTypeError, setPopUpAddEntityTypeError] = useState('');
  const [varientTypeError, setvarientTypeError] = useState(false);
  const [varientError, setvarientError] = useState(false);
  // const [priceError, setpriceError] = useState('');
  const [supplierError, setsupplierError] = useState('');
  const [sellingPointError, setsellingPointError] = useState('');
  const [productDescriptionError, setproductDescriptionError] = useState('');
  const [open, setOpen] = React.useState(false);
  const [expiryDateError, setexpiryDateError] = useState('');
  // show error message
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // check manufacturere url is valid or not
  const [isManufacturerUrlValid, setIsManufacturerUrlValid] = useState(false);

  /* States for opening of dialog */
  // const [openAddproduct, setopenAddproduct] = useState(openProductPopup);
  const [openAddVariant, setopenAddVariant] = useState(false);
  const [openAddCategory, setopenAddCategory] = useState(false);
  const [openAddVarientType, setopenAddVarientType] = useState(false);
  // const [openAddSupplier, setopenAddSupplier] = useState(false);
  const [variantName, setvariantName] = useState('');
  const [categoryName, setcategoryName] = useState('');
  const [categoryDescription, setcategoryDescription] = useState('');
  const [varientTypeName, setvarientTypeName] = useState('');
  const [variantTypeId, setvariantTypeId] = useState('');

  /*  const [searchTerm, setSearchTerm] = useState('');
    const [categories, setCategories] = useState(['Apple', 'Banana', 'Orange']);
    const [selectedCategory, setSelectedCategory] = useState('');
  
    const handleSearchTermChange = (e) => {
      setSearchTerm(e.target.value);
    };
  
    const handleCategorySelect = (category) => {
      setSelectedCategory(category);
      setSearchTerm('');
    };
  
    const handleAddNewCategory = () => {
      if (searchTerm && !categories.includes(searchTerm)) {
        setCategories([...categories, searchTerm]);
      }
      setSelectedCategory(searchTerm);
      setSearchTerm('');
    };
  
    const filteredCategories = categories.filter(category =>
      category.toLowerCase().includes(searchTerm.toLowerCase())
    );
  */
  useEffect(() => {
    // getProductCategory();
    clear();
    getCategory();
    getVariantType();
    // getSupplier();
  }, []);

  /* Function to clear form fields when dialog form is closed */
  const clear = () => {
    setmanufacturer('');
    setmanufacturerUrl('');
    setzoomRoomProductName('');
    setBusinessName('');
    setvarientType('');
    setvarientProduct([]);
    setprice('');
    setWholeSalePrice('');
    setsupplierList([]);
    setsellingPoint('');
    setproductDescription('');
    setproductCategoryList([]);
    setisRequired(false);
    setisBestSeller(false);
    setistaxExempt(false);
    setisDiscontinued(false);
    setvariantDisabled(true);
    setimgObj([]);
    setexpiryDate('');
    setvariantId('');
    setsupplierId('');
    setIsFieldUpdated(false);

    setBusinessEmail('');
    setBusinessPhone('');
    setmanufacturerUrl('');
    setaddress2('');
    setCity('');
    setZipCode('');
    setContactName('');
    setContactEmail('');
    setContactPhone('');
    setStateType('');
    setBusinessEntityTypeId({});
  };
  const useAutocompleteStyles = makeStyles({
    inputRoot: {
      // borderRadius: 10, // or any other value you prefer
    },
    option: {
      '&:hover': {
        color: 'white'
        // or any other color you prefer
      }
    },
    clearIndicator: {
      color: '#003087', // or any other color you prefer
      backgroundColor: '#F8FBFF',
      '&:hover': {
        backgroundColor: '#F8FBFF'
      }
    }
  });
  const autoCompleteClasses = useAutocompleteStyles();

  // open variant dialog
  // const handleOpenVariant = () => {
  //   setopenAddVariant(true);
  // };

  // close variant dialog
  const handleCloseVariant = () => {
    setPopUpAddEntityTypeError('')
    setopenAddVariant(false);
    setopenAddCategory(false);
    setopenAddVarientType(false);
  };
  // create new variant
  const handleCreateVariantType = () => {
    if (varientTypeName) {
      // Now show the loader
      setIsLoading(true);
      const data = {
        variantType: varientTypeName,
        createdBy: userId
        // isActive: true
      };
      createVariantType(data);
    }
  };

  const addNewVariant = () => {
    setvarientTypeName('');
    setopenAddVarientType(true);
  };

  // create variant type
  const createVariantType = (data) => {
    axios
      .post(`${process.env.REACT_APP_PRODUCT_API_END_POINT}/variants`, data)
      .then((res) => {
        if (res.data.statusCode === 200 && !res.data.error) {
          setvarientTypeName('');
          getVariantType();
          setvariantId(res.data.data.variantId);
          setopenAddVarientType(false);
          // Now hide the loader
          setIsLoading(false);
          setIsFieldUpdated(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addVariant = () => {
    setvariantName('');
    setvariantTypeId('');
    setopenAddVariant(true);
  };

  // Method to set variant type
  const handleVariantTypeChange = (e) => {
    setvariantTypeId(e.target.value);
  };

  // Method to create new variant
  const handleCreateVariant = () => {
    if (variantName && variantTypeId) {
      // Now show the loader
      setIsLoading(true);
      const data = {
        value: variantName,
        variantId: variantTypeId,
        createdBy: userId
        // isActive: true
      };
      createVariant(data);
    }
  };

  // create variant
  const createVariant = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_PRODUCT_API_END_POINT}/variant-values`,
        data
      )
      .then((res) => {
        if (res.data.statusCode === 200 && !res.data.error) {
          setvariantName('');
          setvariantTypeId('');
          if (variantId) getVariants(variantId);
          // set variant value id
          setvalueId(res.data.data.valueId);
          setopenAddVariant(false);
          // Now hide the loader
          setIsLoading(false);
          setIsFieldUpdated(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /* Method for Add product category modal */
  const addNewCategory = () => {
    setcategoryName('');
    setopenAddCategory(true);
  };

  // Entity type pop up error handle here
  const handleEntityPopupError = () => {
    setPopUpAddEntityTypeError('')
  }

  // Now create category
  const handleCreateCategory = () => {
    if (categoryName) {
      console.log(categoryName);
      // Now show the loader
      setIsLoading(true);
      const data = {
        businessEntityType: categoryName,
        createdBy: userId
        // isActive: true
      };
      createCategory(data);
      
    }
    else{
      setPopUpAddEntityTypeError("Entity Type is required")
    } 
  };
  // create category
  const createCategory = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}/business-entity/addBusinessEntityType`,
        data
      )
      .then((res) => {
        if (res.data.statusCode === 200 && !res.data.error) {
          setcategoryName('');
          getCategory();
          setBusinessEntityTypeId(res.data.data.businessEntityTypeId);
          setopenAddCategory(false);
          // Now hide the loader
          setIsLoading(false);
          setIsFieldUpdated(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Method to open the update dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Method to close the update dialog
  const handleClose = (val) => {
    if (val === 'Save') {
      setOpen(false);
      // setopenAddproduct(true);
    }
    if (val === 'Discard') {
      setShowScreen(0);
      clear();
      setOpen(false);
      // setopenAddproduct(false);
      // setopenProductPopup(false);
    }
  };

  /* Method for closing of Add product modal */

  const handleCloseAddProduct = () => {
    if (isFieldUpdated) {
      setOpen(true);
      // setopenAddproduct(true);
    } else {
      setShowScreen(0);
      clear();
      // setopenAddproduct(false);
      // setopenProductPopup(false);
    }
  };

  /* Function to get getAllBusinessEntityTypes */
  const getCategory = async () => {
    try {
    const encodedLocationId = encodeURIComponent(locationId);
    let response = await ApiService.get(
      `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}business-entity/getAllBusinessEntityTypes`);
      setproductCategoryList(response.data.Items);

    } catch (error) {
      console.log(error);
  };
}
  /* const getCategory = async () => {
    const encodedLocationId = encodeURIComponent(locationId);

    await fetch(
      `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}business-entity/getAllBusinessEntityTypes`,

      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setproductCategoryList(data.data.Items);
      });
  }; */

  /* Function to get  states */

  /* const getVariantType = async () => {
    await fetch(
      `${process.env.REACT_APP_API_END_POINT}locations/getAllStates`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log(data.data.Items);
        setvarientType(data.data.Items);
      });
  }; */
  const getVariantType = async () => {
    try {
      let response = await ApiService.get(
      `${process.env.REACT_APP_API_END_POINT}locations/getAllStates`);
      setvarientType(response.data.Items);
    } catch (error) {
      console.log(error);
      }
  };

  /* Function to get the suppliers list */

  const getSupplier = async () => {
    await fetch(`${process.env.REACT_APP_PRODUCT_API_END_POINT}suppliers`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setsupplierList(data.data.Items);
      });
  };

  /* Function to get variant on basis of variant id */
  const getVariants = async (id) => {
    console.log(id, 'id');
    let variantId = encodeURIComponent(id);
    await fetch(
      `${process.env.REACT_APP_PRODUCT_API_END_POINT}variant-values/getVariantValues?variantId=${variantId}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setvarientProduct(data.data.Items);
      });
  };

  /* Functions to validate Add products fields validation and setting value */

  /* Function to set business name setting value */

  const businessNameValidate = (businessName) => {
    if (!businessName) {
      setIsFieldUpdated(true);
      setBusinessName('');
      setBusinessNameError('Business  name is required');
    } else {
      setIsFieldUpdated(true);
      setBusinessNameError('');
      setBusinessName(businessName);
    }
  };

  /* Function to set manufacturer contact name setting value */

  const contactNameValidate = (contactName) => {
    if (!contactName) {
      setIsFieldUpdated(true);
      setContactName('');
      setContactNameError('');
    } else {
      setIsFieldUpdated(true);
      setContactNameError('');
      setContactName(contactName);
    }
  };

  /* Function for validation of Manufacturer  */

  const manufacturervalidate = (manufacturer) => {
    let manufacturerPattern = /[a-zA-Z ]/;
    // set field updated to true

    if (!manufacturer) {
      setIsFieldUpdated(true);
      setmanufacturerError('Manufacturer is required');
      setmanufacturer('');
    } else if (!manufacturerPattern.test(manufacturer)) {
      setIsFieldUpdated(true);
      setmanufacturerError('Manufacturer should be text');
      setmanufacturer(manufacturer);
    } else {
      setIsFieldUpdated(true);
      setmanufacturerError('');
      setmanufacturer(manufacturer);
    }
  };

  /* Function for validation of Business email  */

  const businessEmailValidate = (businessEmail) => {
    let manufacturerPattern = /\S+@\S+\.\S+/;
    // set field updated to true

    if (!businessEmail) {
      setIsFieldUpdated(true);
      setBusinessEmailerror('');
      setBusinessEmail('');
    } else if (!manufacturerPattern.test(businessEmail)) {
      setIsFieldUpdated(true);
      setBusinessEmailerror('Please enter valid email address');
      setBusinessEmail(businessEmail);
    } else {
      setIsFieldUpdated(true);
      setBusinessEmailerror('');
      setBusinessEmail(businessEmail);
    }
  };

  /* Function for validation of contact email  */

  const contactEmailValidate = (contactEmail) => {
    let manufacturerPattern = /\S+@\S+\.\S+/;
    // set field updated to true

    if (!contactEmail) {
      setIsFieldUpdated(true);
      setContactEmailError('');
      setContactEmail('');
    } else if (!manufacturerPattern.test(contactEmail)) {
      setIsFieldUpdated(true);
      setContactEmailError('Please enter valid email address');
      setContactEmail(contactEmail);
    } else {
      setIsFieldUpdated(true);
      setContactEmailError('');
      setContactEmail(contactEmail);
    }
  };

  /* Function for validation of Business phone  */

  const businessPhoneValidate = (contactPhone) => {
    let formatWorkPhoneNumber = formatPhoneNumber(contactPhone);

    if (!formatWorkPhoneNumber) {
      setIsFieldUpdated(true);
      setBusinessPhoneError('');
      setBusinessPhone('');
    } else if (formatWorkPhoneNumber.length !== 12) {
      setIsFieldUpdated(true);
      setBusinessPhoneError('Business phone should have exactly 10 digits');
      setBusinessPhone(formatWorkPhoneNumber.substring(0, 12)); // Truncate to 10 digits
    } else {
      setIsFieldUpdated(true);
      setBusinessPhoneError('');
      setBusinessPhone(formatWorkPhoneNumber);
    }
  };

  /* Function for validation of contact phone  */

  const contactPhoneValidate = (contactPhone) => {
    let formatWorkPhoneNumber = formatPhoneNumber(contactPhone);
    if (!formatWorkPhoneNumber) {
      setIsFieldUpdated(true);
      setContactPhoneError('');
      setContactPhone('');
    } else if (formatWorkPhoneNumber.length !== 12) {
      setIsFieldUpdated(true);
      setContactPhoneError('Contact phone should have exactly 10 digits');
      setContactPhone(formatWorkPhoneNumber.substring(0, 12)); // Truncate to 10 digits
    } else {
      setIsFieldUpdated(true);
      setContactPhoneError('');
      setContactPhone(formatWorkPhoneNumber);
    }
  };

  /* Function for validation of zipcode  */

  const zipCodeValidate = (zipCode) => {
    let zipCodePattern = zipCode.replace(/\D/g, ''); // Remove non-digit characters

     // If zipCodePattern has more than 5 digits, truncate it
     if (zipCodePattern.length > 5) {
      zipCodePattern = zipCodePattern.substring(0, 5);
  }
    
    if (!zipCodePattern) {
      setIsFieldUpdated(true);
      setZipCodeError('');
      setZipCode('');
    } else if (zipCodePattern.length !== 5) {
      setIsFieldUpdated(true);
      setZipCodeError('ZIP code should have exactly 5 digits');
      setZipCode(zipCodePattern.substring(0, 5)); // Truncate to 5 digits
    } else {
      setIsFieldUpdated(true);
      setZipCodeError('');
      setZipCode(zipCodePattern);
    }
  };

  /* Function for validation of SKU Code  */

  const skuCodeValidate = (skuCode) => {
    // Remove all non-digit characters
    const skuCodePattern = skuCode.replace(/\D/g, '');

    if (!skuCode) {
      setIsFieldUpdated(true);
      setSkuCodeError('SKU Code is required');
      setSkuCode('');
    } else if (skuCode !== skuCodePattern) {
      setIsFieldUpdated(true);
      setSkuCodeError('SKU code should be a number');
      setSkuCode(skuCodePattern); // Set the SKU code to the filtered version with only numbers
    } else {
      setIsFieldUpdated(true);
      setSkuCodeError('');
      setSkuCode(skuCodePattern); // Set the SKU code to the filtered version with only numbers
    }
  };

  /* Function for setting value of manufacturer url */
  /* const isValidHttpUrl = (str)  => {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', // fragment locator
      'i'
    );
    return pattern.test(str);
  } */

  const manufacturerUrlvalidate = (manufacturerUrl) => {
    // if (!manufacturerUrl) {
    //   setIsFieldUpdated(true);
    //   setmanufacturerUrlError('Manufacturer url is required');
    // } else
    // const URL_REGEX = /^(http|https):\/\/([a-z]*\.)?[a-z]*\.[a-z]{2,}(\/)?$/;
    // const URL_REGEX = /^(http|https):\/\/(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/)?$/;
    const URL_REGEX =  /^(https?:\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})(\/[^\s]*)?$/;

    if (manufacturerUrl && !URL_REGEX.test(manufacturerUrl)) {
      setIsFieldUpdated(true);
      setmanufacturerUrlError('business url is not valid');
      setIsManufacturerUrlValid(true);
      setmanufacturerUrl(manufacturerUrl);
    } else {
      setIsFieldUpdated(true);
      setmanufacturerUrlError('');
      setmanufacturerUrl(manufacturerUrl);
      setIsManufacturerUrlValid(false);
    }
  };
  /* Function for validation of Zoom room product name  */

  const zoomRoomProductNamevalidate = (zoomRoomProductName) => {
    if (!zoomRoomProductName) {
      setzoomRoomProductName(
        zoomRoomProductName !== '' ? '' : zoomRoomProductName
      );
      setIsFieldUpdated(true);

      setzoomRoomProductNameError('');
    } else {
      setIsFieldUpdated(true);
      setzoomRoomProductNameError('');
      setzoomRoomProductName(zoomRoomProductName);
    }
  };

  const address2validate = (address2) => {
    if (!address2) {
      setaddress2(address2 !== '' ? '' : address2);
      setIsFieldUpdated(true);

      setaddress2Error('');
    } else {
      setIsFieldUpdated(true);
      setaddress2Error('');
      setaddress2(address2);
    }
  };

  /* Function for setting value of product category validate */

  const businessEntityTypeValidate = (businessEntityType) => {
    if (!businessEntityType) {
      setIsFieldUpdated(true);
      setBusinessEntityTypeError(true);
      setBusinessEntityTypeId('');
    } else {
      setIsFieldUpdated(true);
      setBusinessEntityTypeError(false);
      setBusinessEntityTypeId(businessEntityType);
    }
  };

  /* Function for setting value of product category validate */

  const stateTypeValidate = (stateTypeValidate) => {
    if (!stateTypeValidate) {
      setIsFieldUpdated(true);
      setStateTypeError(false);
      setStateType('');
    } else {
      setIsFieldUpdated(true);
      setStateTypeError(false);
      setStateType(stateTypeValidate);
    }
  };

  /* Function for setting value of product category validate */

  const cityValidate = (cityTypeValidate) => {
    if (!stateTypeValidate) {
      setIsFieldUpdated(true);
      setCityError(false);
      setCity('');
    } else {
      setIsFieldUpdated(true);
      setCityError(false);
      setCity(cityTypeValidate);
    }
  };

  /* Function for state drop-down of varient type */

  const varientTypeValidate = (varientType) => {
    if (!varientType) {
      setIsFieldUpdated(true);
      // setvarientTypeError('Varient type is required');
      setvarientTypeError(true);
    } else {
      setIsFieldUpdated(true);
      // setvarientTypeError('');
      setvarientTypeError(false);
      setvariantDisabled(false);
      getVariants(varientType);
      // setvarientType(varientType);
      setvariantId(varientType);
    }
  };

  /* Function for setting varient */

  const varientSet = (varient) => {
    if (!varient) {
      setIsFieldUpdated(true);
      // setvarientError('Varient is requried');
      setvarientError(true);
    } else {
      setIsFieldUpdated(false);
      setvalueId(varient);
      setvarientError(false);
    }
  };

  /* Function to set value of price  */

  const priceSet = (event) => {
    let input = event.target.value;
    input = input.replace(/[^0-9.]/g, '');
    // Allow only two digits after the decimal point
    const decimalIndex = input.indexOf('.');
    if (decimalIndex !== -1) {
      const parts = input.split('.');
      input = parts[0] + '.' + parts[1].slice(0, 2);
    }
    setprice(input);
  };

  /* Function to set value of price  */

  const wholeSalePriceSet = (event) => {
    let input = event.target.value;
    input = input.replace(/[^0-9.]/g, '');
    // Allow only two digits after the decimal point
    const decimalIndex = input.indexOf('.');
    if (decimalIndex !== -1) {
      const parts = input.split('.');
      input = parts[0] + '.' + parts[1].slice(0, 2);
    }
    setWholeSalePrice(input);
  };

  /* Function to set value of supplier */

  const supplierValidate = (supplier) => {
    if (!supplier) {
      setIsFieldUpdated(true);
      // setsupplierError('Supplier is required');
      setsupplierError(true);
    } else {
      setIsFieldUpdated(true);
      // setsupplierError('');
      setsupplierError(false);
      // setsupplierList(supplier);
      setsupplierId(supplier);
      // console.log(supplier);
    }
  };

  /* Function to set value of selling point */

  const sellingpointSet = (sellingPoint) => {
    // if (!sellingPoint) {
    //   setIsFieldUpdated(true);
    //   setsellingPointError('Selling point is error');
    //   setsellingPoint("");
    // } else {
    setIsFieldUpdated(true);
    setsellingPointError('');
    setsellingPoint(sellingPoint);
    // }
  };

  /* Function to set value of product description */

  const productDescriptionSet = (productDescription) => {
    // if (!productDescription) {
    //   setIsFieldUpdated(true);
    //   setproductDescriptionError('');
    // } else {
    //   setIsFieldUpdated(true);
    //   setproductDescriptionError('');
    //   setproductDescription(productDescription);
    // }
    setproductDescription(productDescription);
  };

  /* Function to Sku of product */

  // const SkuProductSet = (event) => {

  //   const keyCode = event.keyCode || event.which;
  //   const isValidKey = keyCode >= 48 && keyCode <= 57; // Check if the key code is for a number (0-9)

  //   if (!isValidKey) {
  //     event.preventDefault(); // Prevent the default behavior for non-numeric keys
  //   }
  //   else {
  //     const valueEntered = isValidKey ? event.target.value + String.fromCharCode(keyCode) : '';
  //     if (valueEntered.length < 7) {
  //       setSkuProduct(valueEntered)
  //     }
  //     else {
  //       event.preventDefault();
  //     }

  //   }

  //   if (keyCode === 8) {
  //     // Backspace key pressed
  //     const updatedSkuProduct = skuProduct.slice(0, -1);
  //     setSkuProduct(updatedSkuProduct);
  //   }
  // }

  /* Json for add product  */
  const addProducts = async () => {
    setIsLoading(true);

    // Now show the loader
    const dataAddProduct = {
      locationId: locationId,
      businessEntityTypeId: businessEntityTypeId.sortKey,
      businessEntityType: businessEntityTypeId.businessEntityType,
      businessName: businessName,
      businessEmail: businessEmail,
      phone: businessPhone,
      businessUrl: manufacturerUrl,
      addressLine1: zoomRoomProductName,
      addressLine2: address2,
      state: stateType,
      city: city,
      postalCode: zipCode,
      contactName: contactName,
      contactEmail: contactEmail,
      contactPhone: contactPhone,

      notes: productDescription,

      createdBy: userId,
      status: 1
    };

    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}business-entity/addBusinessEntity`,dataAddProduct);
        if (response.statusCode) {
            setIsLoading(false);
            setIsUpdated(true);
            setOpen(true);
            refreshData();
      }
  

      } catch (error) {
        setIsLoading(false);
        console.log(error);
    }

    // previous fetch post method
    /* await fetch(
      `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}business-entity/addBusinessEntity`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },

        body: dataAddProduct
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log(data.data.productId);

        if (data.statusCode) {
          // if (imgObj) formData.append('media', imgObj);

          setIsLoading(false);
          setIsUpdated(true);
          setOpen(true);
          // setOpen(true);
          // setopenAddproduct(false);
          // setopenProductPopup(false);
        }
        // Method to add product media
      }); */
  };

  

  /* Function call when user going to  submit Add product  form */

  // eslint-disable-next-line consistent-return
  const addProduct = (e) => {
    // eslint-disable-next-line no-debugger
    // handleOpenVariant();

    e.preventDefault();
    e.stopPropagation();

    // if (!manufacturer) {
    //   setmanufacturerError('Manufacturer is required');
    // }

    if (Object.keys(businessEntityTypeId).length === 0) {
      setBusinessEntityTypeError(true);
    }
    if (!variantId) {
      // setvariantError('Variant type is required');
      setvarientTypeError(true);
    }
    if (!valueId) {
      // setvalueError('Variant is required');
      setvarientError(true);
    }
    if (!supplierId) {
      // setsupplierError('Supplier is required');
      setsupplierError(true);
    }

    if (!businessName) {
      // setsupplierError('Supplier is required');
      setBusinessNameError('Business name is required');
    }

    // if (!businessEmail) {
    //   // setsupplierError('Supplier is required');
    //   setBusinessEmailerror('Business email is required');
    // }

    // if (!contactPhone) {
    //   setContactPhoneError('Contact phone is required');
    // }

    
    
    // if (!price) {
    //   setpriceError('Price is required');
    // }

    // if (!expiryDate) {
    //   setexpiryDateError('Expiry date is required');
    // }

    if (
      businessName &&
      Object.keys(businessEntityTypeId).length > 0 &&
      // businessEmail
      businessName

      // skuCode &&
      // manufacturer &&
      // zoomRoomProductName &&
      // businessEntityTypeId &&
      // variantId &&
      // valueId &&
      // supplierId &&
      // price &&
      // !isManufacturerUrlValid
      // expiryDate
    ) {
      // return 0;
      addProducts();
    } // else {
    // addProducts();
    // }
    // Now open the dialog box to update if any of the field is updated
    // if (isFieldUpdated) handleClickOpen();
  };

  return (
    <>
      {/* , paddingRight: '10px', paddingLeft: '58px' */}
      {/* show loaded element */}
      <Loader IsLoading={isLoading} />
      <Grid sx={{ padding: '0 2rem', mt: 1 }}>
        <Box className="Main-container">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Box
                  onClick={() => handleCloseAddProduct()}
                  className="back-arrow-close"
                >
                  <ArrowLeft />
                </Box>

                <Typography className="header-blue-32">
                  Add Business Entity
                </Typography>
              </Box>
            </Grid>

            <Grid item>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {' '}
                  <Button
                    sx={{ mt: 1 }}
                    type="button"
                    variant="contained"
                    className="common-button"
                    onClick={addProduct}
                  >
                    <Typography className="next-button-text">Save</Typography>
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box className="container-text-field">
            <Card
              sx={{
                backgroundColor: '#FFFFFF'
              }}
            >
              <CardContent className="pr-0 pl-0">
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <Typography className="table-heade header-blue-20" mb={2}>
                      Business Information
                    </Typography>
                    <Grid container spacing={2} component="form" id="form-add">
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          className="select-drop-down-label-styling  required-field"
                          fullWidth
                          error={businessEntityTypeError}
                        >
                          <InputLabel
                            id="demo-simple-select-helper-label"
                            className="input-field-dropdown-styling required-label"
                          >
                            BUSINESS ENTITY TYPE
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            label="BUSINESS ENTITY TYPE"
                            className="input-field-styling"
                            value={businessEntityTypeId}
                            onChange={(e) =>
                              businessEntityTypeValidate(e.target.value)
                            }
                            MenuProps={{
                              PaperProps: { style: { maxHeight: 200 } }
                            }}
                            //   options={product.map((option) => option.categoryName)}
                          >
                            {productCategoryList.length > 0 &&
                              productCategoryList.map((option) => (
                                <MenuItem key={option.sortKey} value={option}>
                                  {option.businessEntityType}
                                </MenuItem>
                              ))}
                          </Select>
                          {businessEntityTypeError && (
                            <FormHelperText>
                              Please select business entity type
                            </FormHelperText>
                          )}
                        </FormControl>
                        {/* <FormControl
                            className="field-top-margin-edit-location location-field required-field"
                            fullWidth
                            error={productCategoryError}
                          >
                            <Autocomplete
                              classes={autoCompleteClasses}
                              // value={getCity || ''}
                              // value={filters.categoryName ?? ''}
                              onChange={(event, newValue) => {
                                productCategoryvalidate(newValue);
                              }}
                              // onInputChange={(event, newInputValue) => {
                              //   handleCategoryChange(newInputValue);
                              // }}
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              className="status-field field-label-css"
                              options={productCategoryList.map((option) => option.categoryName)}
                              sx={{
                                width: '100%',
                                borderRadius: '19px !important'
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  className="product_category input-field-dropdown-styling required-label"
                                  label="PRODUCT CATEGORY"
                                  sx={{
                                    fontSize: '16px !important',
                                    fontWeight: '600 !important',
                                  }}
                                />
                              )}
                            />
                            {productCategoryError && (
                              <FormHelperText>
                                Please select product category
                              </FormHelperText>
                            )}
                          </FormControl> */}
                        {isFranchisor !== 'true' && (
                          <Typography
                            sx={{
                              fontSize: 12,
                              fontWeight: 700,
                              color: '#003087',
                              textDecoration: 'underline',
                              marginRight: '5px'
                              // display: 'none'
                            }}
                            className="add-new-category align-right"
                          >
                            <span
                              className="cursor-pointer"
                              onClick={() => {
                                addNewCategory();
                              }}
                            >
                              +Add ENTITY
                            </span>
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {/* <div>
                            <TextField
                              fullWidth
                              value={searchTerm}
                              onChange={handleSearchTermChange}
                              placeholder="Search..."
                            />
                            {filteredCategories.length > 0 && (
                              <ul>
                                {filteredCategories.map(category => (
                                  <li key={category} role='button' onClick onKeyDown={() => handleCategorySelect(category)}>
                                    {category}
                                  </li>
                                ))}
                              </ul>
                            )}
                            {!selectedCategory && searchTerm && !categories.includes(searchTerm) && (
                              <button type='button' onClick={handleAddNewCategory}>Add new category: {searchTerm}</button>
                            )}
                          </div> */}
                        <TextField
                          fullWidth
                          name="BUSINESS NAME"
                          autocomplete="BUSINESS NAME"
                          label="BUSINESS NAME"
                          placeholder="BUSINESS NAME"
                          id="locationId"
                          value={businessName}
                          className="location-field input-field-styling required-field"
                          error={Boolean(businessNameError)}
                          helperText={businessNameError}
                          onChange={(e) => businessNameValidate(e.target.value)}
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee required-label'
                          }}
                          InputProps={{}}
                        />
                      </Grid>
                      {/* <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name="SKU CODE"
                          autocomplete="SKU CODE"
                          label="SKU CODE"
                          className="location-field required-field input-field-styling"
                          error={Boolean(skuCodeError)}
                          helperText={skuCodeError}
                          value={skuCode}
                          placeholder="SKU CODE"
                          onChange={(e) => skuCodeValidate(e.target.value)}
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee required-label'
                          }}
                          InputProps={{}}
                        />
                      </Grid> */}
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name="BUSINESS EMAIL"
                          autocomplete="BUSINESS EMAIL"
                          label="BUSINESS EMAIL"
                          className="field-top-margin-edit-location location-field input-field-styling"
                          error={Boolean(businessEmailerror)}
                          helperText={businessEmailerror}
                          value={businessEmail}
                          placeholder="BUSINESS EMAIL"
                          onChange={(e) =>
                            businessEmailValidate(e.target.value)
                          }
                          InputLabelProps={{
                            className: 'label-style-create-franchisee'
                          }}
                          InputProps={{}}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name="BUSINESS PHONE"
                          autocomplete="BUSINESS PHONE"
                          label="BUSINESS PHONE"
                          className="field-top-margin-edit-location location-field input-field-styling"
                          error={Boolean(businessPhoneError)}
                          helperText={businessPhoneError}
                          value={businessPhone}
                          placeholder="BUSINESS PHONE"
                          onChange={(e) =>
                            businessPhoneValidate(e.target.value)
                          }
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee required-label'
                          }}
                          InputProps={{}}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name="BUSINESS URL"
                          autocomplete="BUSINESS URL"
                          label="BUSINESS URL"
                          className="field-top-margin-edit-location location-field input-field-styling"
                          value={manufacturerUrl}
                          error={Boolean(manufacturerUrlError)}
                          helperText={manufacturerUrlError}
                          onChange={(e) =>
                            manufacturerUrlvalidate(e.target.value)
                          }
                          placeholder="BUSINESS URL"
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee edit-address-label'
                          }}
                          InputProps={{}}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} mt={2}>
                        <TextField
                          fullWidth
                          name="ADDRESS"
                          autocomplete="ADDRESS"
                          label="ADDRESS"
                          placeholder="ADDRESS"
                          className=" location-field  input-field-styling"
                          error={Boolean(zoomRoomProductNameError)}
                          helperText={zoomRoomProductNameError}
                          onChange={(e) =>
                            zoomRoomProductNamevalidate(e.target.value)
                          }
                          value={zoomRoomProductName}
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee edit-address-label '
                          }}
                          InputProps={{}}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} mt={2}>
                        <TextField
                          fullWidth
                          name="ADDRESS 2"
                          autocomplete="ADDRESS 2"
                          label="ADDRESS 2"
                          placeholder="ADDRESS 2"
                          className=" location-field  input-field-styling"
                          error={Boolean(address2Error)}
                          helperText={address2Error}
                          onChange={(e) => address2validate(e.target.value)}
                          value={address2}
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee edit-address-label '
                          }}
                          InputProps={{}}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} mt={2}>
                        <FormControl
                          error={stateTypeError}
                          fullWidth
                          className="select-drop-down-label-styling "
                        >
                          <InputLabel
                            id="demo-simple-select-helper-label"
                            className="input-field-dropdown-styling "
                          >
                            STATE
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            label="STATE"
                            name="state"
                            value={stateType}
                            onChange={(e) => stateTypeValidate(e.target.value)}
                            error={Boolean(stateTypeError)}
                            className="input-field-styling"
                            MenuProps={{
                              PaperProps: { style: { maxHeight: 200 } }
                            }}
                          >
                            {varientType.length > 0 &&
                              varientType.map((r, i) => (
                                <MenuItem key={i} value={r.state}>
                                  {r.state}
                                </MenuItem>
                              ))}
                          </Select>
                          {stateTypeError && (
                            <FormHelperText>Please select state</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} mt={2}>
                        <TextField
                          fullWidth
                          name="CITY"
                          autocomplete="CITY"
                          label="CITY"
                          placeholder="CITY"
                          className=" location-field  input-field-styling"
                          error={Boolean(cityError)}
                          helperText={cityError}
                          onChange={(e) => cityValidate(e.target.value)}
                          value={city}
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee edit-address-label '
                          }}
                          InputProps={{}}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} mt={2}>
                        <TextField
                          fullWidth
                          name="ZIPCODE"
                          autocomplete="ZIPCODE"
                          label="ZIPCODE"
                          placeholder="ZIPCODE"
                          className=" location-field  input-field-styling"
                          error={Boolean(zipCodeError)}
                          helperText={zipCodeError}
                          onChange={(e) => zipCodeValidate(e.target.value)}
                          value={zipCode}
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee edit-address-label '
                          }}
                          InputProps={{}}
                        />
                      </Grid>

                      {/* <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidthl
                          name="PRICE"
                          autocomplete="PRICE"
                          label="PRICE"
                          className="field-top-margin-edit-locatio location-field input-field-styling"
                          value={price}
                          // error={Boolean(priceError)}
                          // helperText={priceError}
                          onChange={priceSet}
                          placeholder="PRICE"
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee edit-address-label'
                          }}
                          InputProps={{}}
                        />
                      </Grid> */}

                      {/* <Grid container sx={{ marginTop: '8px' }} xs={12} > */}

                      {/* </Grid> */}
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Typography className="table-heade header-blue-20" mb={2}>
                      Primary Contact Information
                    </Typography>
                    <Grid container spacing={2} component="form" id="form-add">
                      <Grid item xs={12} sm={6} mt={2}>
                        <TextField
                          fullWidth
                          name="Contact Name"
                          autocomplete="Contact Name"
                          label="CONTACT NAME"
                          placeholder="Contact Name"
                          className="location-field input-field-styling"
                          error={Boolean(contactNameError)}
                          helperText={contactNameError}
                          onChange={(e) => contactNameValidate(e.target.value)}
                          value={contactName}
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee edit-address-label'
                          }}
                          InputProps={{}}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} mt={2}>
                        <TextField
                          fullWidth
                          name="Contact Email"
                          autocomplete="Contact Email"
                          label="CONTACT EMAIL"
                          placeholder="Contact Email"
                          className=" location-field  input-field-styling"
                          error={Boolean(contactEmailError)}
                          helperText={contactEmailError}
                          onChange={(e) => contactEmailValidate(e.target.value)}
                          value={contactEmail}
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee edit-address-label'
                          }}
                          InputProps={{}}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} mt={2}>
                        <TextField
                          fullWidth
                          name="Contact Phone"
                          autocomplete="Contact Phone"
                          label="CONTACT PHONE"
                          placeholder="Contact Phone"
                          className=" location-field  input-field-styling "
                          error={Boolean(contactPhoneError)}
                          helperText={contactPhoneError}
                          onChange={(e) => contactPhoneValidate(e.target.value)}
                          value={contactPhone}
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee edit-address-label '
                          }}
                          InputProps={{}}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          fullWidth
                          multiline
                          id="my-textarea"
                          name="Notes"
                          autocomplete="Notes"
                          label="NOTES"
                          placeholder="Notes"
                          variant="outlined"
                          value={productDescription}
                          className="location-description field-top-margin-edit-location"
                          error={Boolean(productDescriptionError)}
                          helperText={productDescriptionError}
                          onChange={(e) =>
                            productDescriptionSet(e.target.value)
                          }
                          InputLabelProps={{
                            className: 'label-style-create-franchisee'
                          }}
                          InputProps={{ rows: 6 }}
                        />
                      </Grid>
                      {/* <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidthl
                          name="PRICE"
                          autocomplete="PRICE"
                          label="PRICE"
                          className="field-top-margin-edit-locatio location-field input-field-styling"
                          value={price}
                          // error={Boolean(priceError)}
                          // helperText={priceError}
                          onChange={priceSet}
                          placeholder="PRICE"
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee edit-address-label'
                          }}
                          InputProps={{}}
                        />
                      </Grid> */}

                      {/* <Grid container sx={{ marginTop: '8px' }} xs={12} > */}

                      {/* </Grid> */}
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    {showError && (
                      <Box className="error-message">{errorMessage}</Box>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Grid>

      {/* Add confirmation dialog */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isUpdated ? 'Congratulations!' : 'Discard Changes?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
                <Typography className="title-text-sub">
                  You have unsaved changes. 
                  <Typography className="title-text-sub">What do you want to do?</Typography>
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  Business Entity has been created successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClose('Discard')}
          >
            {isUpdated ? 'Close' : ' Discard Changes'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('Save')}
            >
              Go Back to Editing
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* Create Variant dialog */}
      {/* {openAddVariant && (<CustomizedDialogs />)} */}
      <Dialog
        open={openAddVarientType}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleCloseVariant()}
        aria-describedby="alert-dialog-slide-description"
        // sx={{ width: '350px' }}
      >
        <DialogTitle className="alert-title align-center">
          {'Add Variant Type'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box
              display="flex"
              justifyContent="center"
              direction="column"
              width={300}
              mt={1}
              mb={2}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="VARIANT TYPE"
                    name="variantTypeName"
                    className="input-field-styling label-style-create-franchisee"
                    onChange={(e) => setvarientTypeName(e.target.value)}
                    required
                    value={varientTypeName}
                    variant="outlined"
                    InputLabelProps={{
                      sx: { fontWeight: '600', fontSize: '16px' }
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleCloseVariant()}
          >
            {'Close'}
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 no-hover"
            onClick={() => handleCreateVariantType()}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {/* Create Category dialog */}
      <Dialog
        open={openAddCategory}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleCloseVariant()}
        aria-describedby="alert-dialog-slide-description"
        // sx={{ width: '350px' }}
      >
        <DialogTitle className="alert-title align-center">
          {'Add Entity'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box
              display="flex"
              justifyContent="center"
              direction="column"
              width={300}
              mt={1}
              mb={2}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    name="categoryName"
                    label="BUSINESS ENTITY TYPE"
                    error={PopUpAddEntityTypeError}
                    className="location-field input-field-styling required-field"
                    onChange={(e) => { 
                      handleEntityPopupError();
                      setcategoryName(e.target.value)
                      
                    }
                    }
                    required
                    value={categoryName}
                    variant="outlined"
                    InputLabelProps={{
                      sx: { fontWeight: '600', fontSize: '16px' }
                    }}
                  />
                    <FormHelperText>
                      {PopUpAddEntityTypeError}
                    </FormHelperText>
                </Grid>
              </Grid>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleCloseVariant()}
          >
            {'Close'}
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 no-hover"
            onClick={() => handleCreateCategory()}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {/* Create Variant dialog */}
      <Dialog
        open={openAddVariant}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleCloseVariant()}
        aria-describedby="alert-dialog-slide-description"
        // sx={{ width: '350px' }}
      >
        <DialogTitle className="alert-title align-center">
          {'Add Variant'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box
              display="flex"
              justifyContent="center"
              direction="column"
              width={300}
              mt={1}
              mb={2}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="VARIANT NAME"
                    name="variantName"
                    className="input-field-styling label-style-create-franchisee"
                    onChange={(e) => setvariantName(e.target.value)}
                    required
                    value={variantName}
                    variant="outlined"
                    InputLabelProps={{
                      sx: { fontWeight: '600', fontSize: '16px' }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl
                    fullWidth
                    className="select-drop-down-label-styling"
                  >
                    <InputLabel
                      id="demo-simple-select-helper-label"
                      className="weight-700 font-15"
                    >
                      VARIANT TYPE
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      name="variantId"
                      onChange={handleVariantTypeChange}
                      value={variantTypeId}
                      label="VARIANT TYPE"
                      className="input-field-styling align-left"
                      required
                      MenuProps={{
                        PaperProps: { style: { maxHeight: 200 } }
                      }}
                    >
                      {varientType.length > 0 &&
                        varientType.map((r, i) => (
                          <MenuItem key={i} value={r.variantId}>
                            {r.variantType}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleCloseVariant()}
          >
            {'Close'}
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 no-hover"
            onClick={() => handleCreateVariant()}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddBusinessEntities;
